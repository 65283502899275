import { useMemo } from 'react';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { CRMTypes } from '@work4all/models/lib/Classes/CRMTypes.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { useMaskConfig } from '../../hooks/mask-context';
import { MaskControllerProps } from '../../types';

import { CRMTypesIntersection } from './CrmOverlayController';

export const useCrmRequestData = (props: MaskControllerProps) => {
  const mask = useMaskConfig(props);

  return useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    const filter = [{ id: { $eq: mask.id } }];
    let entity = Entities.task;

    let data: CRMTypes<EMode.query> = {};
    const commonFields: CRMTypesIntersection = {
      id: null,
      title: null,
      note: null,
      date: null,
      attachmentList: [
        {
          note: null,
          id: null,
          fileName: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          date: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      user: {
        id: null,
        displayName: null,
      },
      userId: null,
      creatorUserId: null,
      topicMarkList: [
        {
          name: null,
          id: null,
        },
      ],
      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            name: null,
            id: null,
            number: null,
            mainContactId: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
            isPrivateCustomer: null,
          },
          supplier: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
            isPrivateCustomer: null,
          },
        },
      },
      contact: {
        name: null,
        firstName: null,
        displayName: null,
        id: null,
        businessPartnerType: null,
        businessPartnerId: null,
        eMail: null,
      },
      project: {
        name: null,
        id: null,
        number: null,
      },
      projectId: null,
      projectProcessId: null,
      projectProcess: {
        process: null,
        id: null,
      },
      customFieldList: [CUSTOM_FIELDS_DATA],
    };

    // TODO dirty mapping between enums, this needs to be consolidated
    if (mask.entity === Entities.task) {
      entity = Entities.task;
      const taskData: Task<EMode.query> = {
        ...commonFields,
        endDate: null,
        reminderDate: null,
        status: null,
        priority: null,
        timeRequired: null,
      } as Task<EMode.query>;

      data = taskData as unknown as CRMTypes<EMode.query>;
    }
    if (mask.entity === Entities.callMemo) {
      entity = Entities.callMemo;
      const callMemoData: CallMemo<EMode.query> = {
        ...commonFields,
        contactId: null,
      } as CallMemo<EMode.query>;

      data = callMemoData as unknown as CRMTypes<EMode.query>;
    }
    if (mask.entity === Entities.note) {
      entity = Entities.note;
      // wait until business partner union type works correctly on backend side
      const noteData: Note<EMode.query> = {
        ...commonFields,
        contactId: null,
      } as Note<EMode.query>;
      data = noteData as unknown as CRMTypes<EMode.query>;
    }
    return {
      filter,
      entity,
      data,
    };
  }, [mask.entity, mask.id]);
};
