import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'rxjs';

import { usePermissions, useUser } from '@work4all/data';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditERPStatus } from '@work4all/utils/lib/permissions';

import { ErpStatusPicker } from '../../../entity-picker/erp-status-picker/ErpStatusPicker';
import {
  mapErpStatus,
  mapFromErpStatus,
} from '../../../entity-picker/erp-status-picker/use-map-erp-status';
import {
  InvoiceStatusPicker,
  mapInvoiceStatus,
} from '../../../entity-picker/invoice-status-picker/InvoiceStatusPicker';
import { formatters } from '../../components/formatters';
import { PreviewContentField } from '../../components/PreviewContent';

interface UseErpPreviewFieldsProps {
  entity: Entities;
  entitiesData: (ERPTypes & InboundInvoice)[];
}

export const useErpPreviewFields = (props: UseErpPreviewFieldsProps) => {
  const { entity, entitiesData } = props;
  const user = useUser();
  const ERPStatusEditAllowed = canEditERPStatus(user);
  const { canEdit } = usePermissions();

  const { t } = useTranslation();
  return useMemo<PreviewContentField<ERPTypes>[]>(() => {
    return [
      {
        key: 'note',
        label: t('MORE.EMPLOYEE'),
        formatter: (_, entry) => entry.user?.displayName,
      },
      {
        key: entity === Entities.contract ? 'contractDate' : 'date',
        label: t('COMMON.DATE'),
        formatter: formatters.dateSimple2year,
      },
      {
        entity: Entities.customer,
        key: 'businessPartnerId',
        label: (entry) =>
          entry.businessPartnerContactCombined?.businessPartner.data
            .__typename === 'Kunde'
            ? t('COMMON.CUSTOMER')
            : t('COMMON.SUPPLIER'),
        getData: (entry) =>
          entry.businessPartnerContactCombined?.businessPartner.data,
        formatter: (_, entry) =>
          entry.businessPartnerContactCombined?.businessPartner?.data.name,
      },
      {
        key: 'contactId',
        label: t('COMMON.CONTACT'),
        formatter: (_, entry) =>
          entry.businessPartnerContactCombined?.contact?.displayName,
      },
      {
        entity: Entities.project,
        key: 'project',
        label: t('COMMON.PROJECT'),
        formatter: (_, entry) => entry.project?.name,
        getData: (entry) => entry.project,
        disabled: true,
      },
      {
        key: 'frozen',
        label: t('COMMON.STATUS'),
        formatter: (_, entry) => {
          if (entity === Entities.invoice) {
            const status = mapInvoiceStatus(entry.ra, entry.paid);
            return t('COMMON.INVOICE_STATUS_PICKER_' + status);
          }
          const status = mapErpStatus(entry.frozen, entry.isClosed);
          return t('COMMON.ERP_STATUS_PICKER_' + status);
        },
        disabled:
          !ERPStatusEditAllowed ||
          entity === Entities.invoice ||
          !(entitiesData || []).every((record) => canEdit({ entity, record })),
        isCommon: (commonFields: string[]) => {
          if (entity === Entities.invoice)
            return commonFields.includes('ra') && commonFields.includes('paid');
          return (
            commonFields.includes('isClosed') && commonFields.includes('frozen')
          );
        },
        getPicker: ({ entry, onEdit }) => {
          if (entity === Entities.invoice) {
            const status = mapInvoiceStatus(entry.ra, entry.paid);
            return (
              <InvoiceStatusPicker
                multiple={false}
                value={{
                  id: status,
                  name: t('COMMON.INVOICE_STATUS_PICKER_' + status),
                }}
                onChange={noop}
              />
            );
          }
          const status = mapErpStatus(entry.frozen, entry.isClosed);
          return (
            <ErpStatusPicker
              multiple={false}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              value={{
                id: status,
                name: t('COMMON.ERP_STATUS_PICKER_' + status),
              }}
              onChange={(value) => {
                const { frozen, isClosed } = mapFromErpStatus(value?.id);
                onEdit({ frozen, isClosed });
              }}
            />
          );
        },
      },
    ];
  }, [t, entity, ERPStatusEditAllowed, canEdit, entitiesData]);
};
