import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const AppointmentPreviewFields: Appointment<EMode.query> = {
  id: null,
  title: null,
  user: {
    id: null,
    displayName: null,
  },
  city: null,
  startDate: null,
  endDate: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
  },
  project: {
    id: null,
    name: null,
  },
  contractId: null,
  contract: {
    id: null,
    note: null,
    contractNumber: null,
  },
  deliveryNoteId: null,
  deliveryNote: {
    id: null,
    note: null,
    number: null,
  },
  note: null,
  isWholeDay: null,
  toAbsolute: null,
  fromAbsolute: null,
  appointmentState: { id: null, name: null },
  appointmentAttendeeList: [
    {
      id: null,
      ressource: {
        displayName: null,
        id: null,
        userKind: null,
        loginName: null,
      },
      user: {
        id: null,
        displayName: null,
      },
      contact: {
        id: null,
        displayName: null,
      },
      businessPartner: {
        id: null,
        data: {
          customer: { id: null, name: null, website: null },
          supplier: { id: null, name: null, website: null },
        },
      },
    },
  ],
};
