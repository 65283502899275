import { Close, Save } from '@mui/icons-material';
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectStepLinkDirection } from '@work4all/components/lib/components/entity-picker/project-step-link-direction-picker copy/ProjectStepLinkDirectionPicker';

import { useDataMutation, useDataProvider, useFormPlus } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputProjectStepLinkRelation } from '@work4all/models/lib/Classes/InputProjectStepLinkRelation.entity';
import { InputProjectStepRelation } from '@work4all/models/lib/Classes/InputProjectStepRelation.entity';
import { InputProjektVorgang } from '@work4all/models/lib/Classes/InputProjektVorgang.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';

import { useFormContextPlus } from '../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { Form } from '../../../../../../../../../containers/mask-overlays/mask-overlay/components';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../../../../../../../../../containers/mask-overlays/mask-overlay/hooks/mask-context';
import { useFormUpdate } from '../../../../../../../../../containers/mask-overlays/mask-overlay/utils/use-form-update';

import { AssignmentSection } from './components/assignment-section/AssignmentSection';
import { ConnectionsSection } from './components/connections-section/ConnectionsSection';
import { GeneralSection } from './components/general-section/GeneralSection';
import { NoteSection } from './components/note-section/NoteSection';
import { PlanningSection } from './components/planning-section/PlanningSection';
import {
  ConnectionDefinition,
  ProjectProcessFormValue,
  ProjectProcessQuickCreateDialogProps,
  ProjectProcessQuickCreateFormProps,
  ProjectProcessTemplate,
} from './types';
import { PROJECT_PROCESS_DATA } from './utils';

export function ProjectProcessQuickCreateDialog(
  props: ProjectProcessQuickCreateDialogProps
) {
  const { open, id } = props;

  const request = useMemo<DataRequest>(
    () => ({
      data: PROJECT_PROCESS_DATA,
      entity: Entities.projectProcess,
      filter: [
        {
          id: {
            $eq: id,
          },
        },
      ],
    }),
    [id]
  );

  const { data: persistedData } = useDataProvider(request, !id);

  const defaultTempalte: ProjectProcess = {
    startDatum: new Date().toISOString(),
    endDateInner: new Date().toISOString(),
  };

  return (
    <Dialog fullWidth open={open}>
      {id ? (
        persistedData?.[0] && (
          <ProjectProcessQuickCreateForm
            {...props}
            template={persistedData?.[0]}
          />
        )
      ) : (
        <ProjectProcessQuickCreateForm
          {...props}
          template={{ ...defaultTempalte, ...props.template }}
        />
      )}
    </Dialog>
  );
}

function ProjectProcessQuickCreateForm(
  props: ProjectProcessQuickCreateFormProps
) {
  const { template, onClose } = props;

  const { t } = useTranslation();

  const schema = useEntityJsonSchema(Entities.projectProcess);
  const resolver = useJSONSchemaResolver(schema);

  const progress = useMemo(() => {
    return template?.completionFeedbacks?.length
      ? [...template.completionFeedbacks].sort((a, b) => {
          return new Date(a.timestamp) < new Date(b.timestamp)
            ? 1
            : new Date(a.timestamp) > new Date(b.timestamp)
            ? -1
            : 0;
        })?.[0]?.completionPercent
      : 0;
  }, [template?.completionFeedbacks]);

  const connections = useMemo<ConnectionDefinition[]>(() => {
    const result: ConnectionDefinition[] = [];

    if (template?.projectStepLinksIncomming?.length) {
      for (const link of template.projectStepLinksIncomming) {
        result.push({
          id: link.id,
          direction: ProjectStepLinkDirection.Incoming,
          linkType: link.linkType,
          connectedProcessName: link.projectStep1.process,
          connectedProcessId: link.projectStep1.id,
          connectedProcessKind: link.projectStep1.kindId,
          uuid: link.id.toString(),
        });
      }
    }

    if (template?.projectStepLinksOutgoing?.length) {
      for (const link of template.projectStepLinksOutgoing) {
        result.push({
          id: link.id,
          direction: ProjectStepLinkDirection.Outgoing,
          linkType: link.linkType,
          connectedProcessName: link.projectStep2.process,
          connectedProcessId: link.projectStep2.id,
          connectedProcessKind: link.projectStep2.kindId,
          uuid: link.id.toString(),
        });
      }
    }

    return result;
  }, [template]);

  const form = useFormPlus<ProjectProcessFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: template?.id
      ? { ...template, progress, connections }
      : makeDefaultValues(template),
    context: {
      schema,
    },
  });

  useFormUpdate(
    {
      user: (user: User) => {
        return {
          creatorUserId: user?.id || 0,
        };
      },
      project: (project: Project) => {
        return {
          projectId: project?.id || 0,
        };
      },
      customer: (customer: Customer) => {
        return {
          customerId: customer?.id || 0,
        };
      },
      supplier: (supplier: Supplier) => {
        return {
          supplierId: supplier?.id || 0,
        };
      },
      ressourceClass: (ressourceClass: LookUp) => {
        return {
          ressourceClassId: ressourceClass?.id || 0,
        };
      },
      parent: (parent: ProjectProcess) => {
        return {
          parentId: parent?.id || 0,
        };
      },
      startDatum: (date) => {
        const endDate = form.getValues('endDateInner');
        if (new Date(date) > new Date(endDate))
          return {
            endDateInner: date,
          };
        return;
      },
      endDateInner: (date) => {
        const startDatum = form.getValues('startDatum');
        if (new Date(date) < new Date(startDatum))
          return {
            startDatum: date,
          };
        return;
      },
    },
    form
  );

  const [mutate] = useDataMutation<
    ProjectProcess,
    EMode.upsert,
    InputProjectStepRelation
  >({
    entity: Entities.projectProcess,
    mutationType: EMode.upsert,
    responseData: PROJECT_PROCESS_DATA,
    onCompleted: onClose,
  });

  const onSubmit = async (data: ProjectProcessFormValue) => {
    const update: InputProjektVorgang = {
      id: 0,
      ...data,
      __typename: undefined,
    };

    const connectionIdList = data.connections?.map((y) => y.id) ?? [];

    const links: InputProjectStepLinkRelation = template.id
      ? {
          add: data.connections
            .filter((x) => !x.id)
            .map((connection) => {
              return {
                linkType: connection.linkType,
                projectCode: template.projectId,
                projectStep1Code:
                  connection.direction === ProjectStepLinkDirection.Outgoing
                    ? template.id
                    : connection.connectedProcessId,
                projectStep2Code:
                  connection.direction === ProjectStepLinkDirection.Incoming
                    ? template.id
                    : connection.connectedProcessId,
              };
            }),
          remove: [
            ...template.projectStepLinksIncomming,
            ...template.projectStepLinksOutgoing,
          ]
            .filter((x) => !connectionIdList.includes(x.id))
            .map((x) => x.id),
        }
      : undefined;

    const relations: InputProjectStepRelation = {
      addCompletionFeedback: data.progress,
      links,
    };

    mutate(update, { relations });
  };

  const maskContext = useMaskContextValue({
    entity: Entities.projectProcess,
    isCreateMode: true,
    isEditMode: false,
    mode: 'create',
    wip: false,
    params: {},
  });

  return (
    <MaskContextProvider value={maskContext}>
      <Box paddingBottom="1rem">
        <Stack
          direction="row"
          alignItems="center"
          p="0 1rem"
          borderBottom="1px solid var(--ui04)"
        >
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <Typography variant="h4" p="1rem 0" width="100%">
            {t('COMMON.PROJECT_PROCESS')}
          </Typography>

          <Button
            size="large"
            startIcon={<Save />}
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('INPUTS.SAVE')}
          </Button>
        </Stack>

        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormContent />
          </Form>
        </FormProvider>
      </Box>
    </MaskContextProvider>
  );
}

function makeDefaultValues(
  template: ProjectProcessTemplate
): ProjectProcess & { progress: number } {
  return {
    ...template,
    creatorUserId: 0,
    progress: 0,
  };
}

export function FormContent() {
  const { watch } = useFormContextPlus<ProjectProcessFormValue>();

  const kindId = watch('kindId');
  const id = watch('id');

  return (
    <>
      <GeneralSection />

      {kindId === ProjectProcessKind.VORGANG && (
        <>
          <AssignmentSection />
          <PlanningSection />
        </>
      )}

      {id && <ConnectionsSection />}

      <NoteSection />
    </>
  );
}
