import styles from './TicketSolutionDialog.module.scss';

import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Dialog, DialogActions, DialogContent } from '../../../../dialog';
import { TextEditor } from '../../../../input/format-text/TextEditor/TextEditorLazy';
import { generateFileUploadUrl } from '../../../../utils/generateFileUploadUrl';

export interface TicketSolutionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (input: string) => void;
}

export const TicketSolutionDialog = (props: TicketSolutionDialogProps) => {
  const { open, onClose, onConfirm } = props;
  const { t } = useTranslation();
  const [text, setText] = useState('');

  const user = useUser();
  const { activeTenant } = useTenant();

  return (
    <Dialog
      classes={{
        dialog: {
          paper: styles.dialogRoot,
        },
      }}
      open={open}
      title={t(`TICKET.SOLUTION_TEXT`)}
      onClose={onClose}
    >
      <DialogContent>
        <Typography style={{ paddingBottom: '1rem' }}>
          {t('TICKET.SOLUTION_TEXT_LABEL')}
        </Typography>
        <TextEditor
          enableTextBuildingBlocks
          value={text}
          onChange={setText}
          placeholderText={t('INPUTS.ENTER_SOLUTION')}
          config={{
            requestHeaders: {
              'x-work4all-mandant': activeTenant,
            },
            imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
            heightMin: 220,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
        <Button disabled={!text} onClick={() => onConfirm(text)}>
          {t('ALERTS.BTN_SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
