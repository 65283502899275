import { DateTime } from 'luxon';

export function timeModifier(
  value: string,
  section: number,
  direction: 'up' | 'down' | 'none'
) {
  const date = DateTime.now();
  const directionValue = direction === 'none' ? 0 : direction === 'up' ? 1 : -1;

  if (section === 0) {
    return date
      .set({ hour: parseInt(value, 10) })
      .plus({ hours: directionValue })
      .toFormat('HH');
  } else if (section === 1) {
    return date
      .set({ minute: parseInt(value, 10) })
      .plus({ minutes: directionValue })
      .toFormat('mm');
  } else if (section === 2) {
    if (direction === 'up') return 'AM';
    if (direction === 'down') return 'PM';
  }
  return value;
}
