import styles from './TableRow.module.scss';

import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';

import { ITableRowComponentProps } from '../../types';
import { GROUP_ROW_IDENT } from '../row-indent/RowIndent';

// FORWARD REF
export const TableRow = forwardRef(function (
  props: ITableRowComponentProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    children,
    isSelected,
    isGrouped,
    className,
    displayModifiers = {},
    cardsView = false,
    groupLevel = 0,
    ...rest
  } = props;

  const row = (
    <div
      ref={ref}
      className={clsx(
        styles.row,
        {
          [styles.card]: cardsView,
          [styles.grouped]: isGrouped,
          [styles.bold]: displayModifiers?.isBold,
          [styles.faded]: displayModifiers?.isFaded,
          [styles.bgShade1]: displayModifiers?.isShade1,
          [styles.bgShade2]: displayModifiers?.isShade2,
          [styles.bgShade3]: displayModifiers?.isShade3,
          [styles.bgShade4]: displayModifiers?.isShade4,
          [styles.bgShade5]: displayModifiers?.isShade5,
          [styles.bgShade6]: displayModifiers?.isShade6,
        },

        isSelected && styles['row--selected'],
        className
      )}
      {...rest}
      style={{
        ...rest?.style,
        paddingLeft: isGrouped
          ? 0
          : `calc((${
              groupLevel - 1
            } * ${GROUP_ROW_IDENT}px) + (1.5rem + 10px))`,
      }}
    >
      {children}
    </div>
  );

  if (displayModifiers.tooltip)
    return (
      <Tooltip title={displayModifiers.tooltip} placement="top-start">
        {row}
      </Tooltip>
    );

  return row;
});
