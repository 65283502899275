import styles from './MoreActionButtonButton.module.scss';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { IdArray } from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';

interface MoreActionButtonButtonProps {
  convertToInternal: (copyIds: IdArray) => void;
  disabled: boolean;
  positions: Position[];
}

export const MoreActionButtonButton = (props: MoreActionButtonButtonProps) => {
  const { convertToInternal, disabled, positions } = props;
  const { t } = useTranslation();
  const ref = useRef();
  const [open, setOpen] = useState(false);

  const { selectedPositionIds } = useSelectedPositionState();
  if (!(selectedPositionIds.length > 0)) return null;

  const handleConvertToInternal = () => {
    setOpen(false);
    convertToInternal(selectedPositionIds);
  };

  const disableConvert =
    selectedPositionIds.length > 1 ||
    positions
      .filter((x) => {
        const firstElement = selectedPositionIds?.[0];
        if (typeof firstElement === 'string') {
          const localArray = selectedPositionIds as string[];
          return localArray.includes(x.localId);
        }
        return true;
      })
      .some(
        (x) =>
          x.posId ||
          x.positionKind === ErpPositionsKind.INTERNE_POSITION ||
          x.positionKind === ErpPositionsKind.INTERNE_STUECKLISTE
      );

  return (
    <React.Fragment>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        classes={{ paper: styles.root }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ListItem className={styles.container}>
          <ListItemButton
            className={clsx(styles.color, styles.flex)}
            role={undefined}
            disabled={disableConvert}
            onClick={() => handleConvertToInternal()}
          >
            <Typography>{t('ERP.TRANSFORM.TO_INTERNAL')}</Typography>
          </ListItemButton>

          <ListItemButton
            className={clsx(styles.color, styles.flex)}
            role={undefined}
            disabled
          >
            <Typography>{t('ERP.TRANSFORM.TO_ARTICLE')}</Typography>
          </ListItemButton>
        </ListItem>
      </Popover>

      <IconButton
        ref={ref}
        color="primary"
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    </React.Fragment>
  );
};
