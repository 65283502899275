import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TableSettingsOverlay } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/TableSettingsOverlay';
import { TablesLayoutBordersSettingsKey } from '../../../../../../../../../../settings/settings';

interface PositionsSettingsProps {
  settingsKey?: TablesLayoutBordersSettingsKey;
  entityType?: Entities;
}

export const PositionsSettings = (props: PositionsSettingsProps) => {
  const { settingsKey = 'ERP', entityType = Entities.position } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <TableSettingsOverlay
        settingsKey={settingsKey}
        entityType={entityType}
        isOpen={open}
        close={() => setOpen(false)}
      />
    </React.Fragment>
  );
};
