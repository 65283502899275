import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

import { useBottomViews } from '../../../bottom-views';
import { ProjectPlanningView } from '../components/project-table-bottom-section/components/project-planning-view/ProjectPlanningView';
import { ProjectContractsTable } from '../components/project-table-bottom-section/components/ProjectContractsTable';

type Props = {
  selectedEntities: Project[];
};

export const useProjectBottomArea = ({ selectedEntities }: Props) => {
  const projectIdList = useMemo(
    () => (selectedEntities ?? []).map((x) => x.id),
    [selectedEntities]
  );

  return useBottomViews({
    size: 'fill',
    views: [
      {
        type: 'custom',
        content: (
          <ProjectPlanningView
            projectIdList={projectIdList}
            layout={projectIdList?.length > 1 ? 'stacked' : 'gantt'}
          />
        ),
        titleTranslationKey: 'COMMON.PROJECTPROCESS_plural',
      },
      // TODO WW-4968 use the `table` type instead of `custom`.
      {
        type: 'custom',
        content: <ProjectContractsTable projectIdList={projectIdList} />,
        titleTranslationKey: 'COMMON.CONTRACT_plural',
      },
    ],
  });
};
