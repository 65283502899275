import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDragLayer } from 'react-dnd';

import { useDataProvider, usePermissions } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToEntity } from '@work4all/utils';

import { ErpDialogConfirm } from '../../../../../../../components/data-tables/offers-table/ErpDialog';
import {
  CloneConvertDialogCallbacks,
  useCloneConvertDialogCallbacks,
} from '../../../../../../../components/data-tables/offers-table/hooks/use-clone-convert-dialog';
import { ENTITY_BY_CONVERT_CONFIGS } from '../../../../../../../components/data-tables/offers-table/hooks/use-clone-convert-erp';
import { useConvertBzObjectMutation } from '../../../../../../../components/data-tables/offers-table/hooks/use-convert-bz-object-mutation';
import { bpCombined } from '../../../../../../mask-overlays/mask-overlay/views/erp/hooks/use-bz-shadow-object-api/use-shadow-bz-object-graphql';

export const useCardDnd = (targetEntity: Entities) => {
  const { canConvert } = usePermissions();
  const { mutate } = useConvertBzObjectMutation({ targetEntity });
  const { item: monitorItem } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }));

  const sourceEntity = useMemo(() => {
    return typeNameToEntity(monitorItem?.__typename);
  }, [monitorItem]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: [{ id: { $eq: monitorItem?.id } }],
      entity: sourceEntity,
      data: { id: null, businessPartnerContactCombined: bpCombined },
    };
  }, [sourceEntity, monitorItem]);

  const response = useDataProvider(requestData, !sourceEntity);

  const [item, setItem] = useState(monitorItem);
  useEffect(() => {
    if (response.data.length) {
      setItem({ ...monitorItem, ...response.data[0] });
    }
  }, [monitorItem, response.data]);

  const onConfirm = useCallback(
    async (confirm: ErpDialogConfirm) => {
      await mutate({
        sourceEntity,
        sourceIds: [item.id],
        targetEntity,
        ...confirm,
      });
    },
    [item, sourceEntity, mutate, targetEntity]
  );

  const dialogProps: Omit<CloneConvertDialogCallbacks, 'open'> = useMemo(() => {
    return {
      onConfirm,
      sourceEntity,
      targetEntity,
      data: [item],
    };
  }, [item, sourceEntity, onConfirm, targetEntity]);

  const { open } = useCloneConvertDialogCallbacks(dialogProps);

  const value = useMemo(() => {
    const canDrag = () => {
      const canUserConvertRecord = canConvert({ entity: targetEntity });

      /**
       * If the user has the right to convert & the entity was defined
       * inside `ENTITY_BY_CONVERT_CONFIGS` as a key, that means you
       * can convert from it to other entities so you can drag an item
       * from the entity list.
       */
      return canUserConvertRecord && !!ENTITY_BY_CONVERT_CONFIGS[targetEntity];
    };
    const canDrop = () => {
      if (targetEntity === sourceEntity) return true;

      const sourceEntityConfigs = ENTITY_BY_CONVERT_CONFIGS[sourceEntity];
      return (
        sourceEntityConfigs &&
        sourceEntityConfigs.allowedEntities.includes(targetEntity)
      );
    };
    const canSkip = () => {
      if (targetEntity === sourceEntity) return false;

      const sourceEntityConfigs = ENTITY_BY_CONVERT_CONFIGS[sourceEntity];
      const allowedEntities = sourceEntityConfigs?.allowedEntities ?? [];
      const blockedEntities = sourceEntityConfigs?.blockedEntities ?? [];

      return ![...allowedEntities, ...blockedEntities].includes(targetEntity);
    };

    const isEntitiesIdentical = sourceEntity === targetEntity;

    const onDrop = async (item: object) => {
      if (!('id' in item && typeof item.id === 'number'))
        throw new Error('Wrong dropped item in useCardDnd');

      open();
    };

    return { canDrag, canDrop, canSkip, onDrop, isEntitiesIdentical };
  }, [sourceEntity, targetEntity, canConvert, open]);

  return value;
};
