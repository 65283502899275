import styles from './FileListPreview.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { MouseEventHandler, useEffect } from 'react';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';
import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';

import { useDataMutation, useUser } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  ConvertPopover,
  IConvertPopoverProps,
} from '../../convert-popover/ConvertPopover';
import {
  PreviewInputsProvider,
  PreviewTitleInput,
} from '../components/preview-input';
import { RequestedDocumentData } from '../document-preview/consts/RequestDocumentData';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { FileListGalleryPagination } from './FileListGalleryPagination';
import {
  FileListPreviewContent,
  FileListPreviewContentProps,
} from './FileListPreviewContent';
import { useSelectedIndex } from './use-selected-index';

export interface FileListPreviewProps
  extends Omit<
    FileListPreviewContentProps,
    'selectedIndex' | 'onSelectedIndexChange'
  > {
  title: string;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onPdfEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  onSelectedIndexChange?: (index: number) => void;
  document: Document[];
  onVisibilityToggle?: (visible: boolean) => void;
}

export function FileListPreview(props: FileListPreviewProps) {
  const {
    title,
    files,
    iconProps,
    onCloseClick,
    onEditClicked,
    onPdfEditClicked,
    onShareClicked,
    onDeleteClicked,
    convertProps,
    onSelectedIndexChange,
    document,
    onVisibilityToggle,
  } = props;

  const [_index, setIndex] = useSelectedIndex({ length: files.length });
  const index = files.length === 1 ? 0 : _index;

  useEffect(() => {
    onSelectedIndexChange?.(_index);
  }, [_index, onSelectedIndexChange]);

  const convertPopoverState = usePopoverState('bottom');

  const user = useUser();

  const [mutate] = useDataMutation<Document, EMode.upsert>({
    entity: Entities.document,
    mutationType: EMode.upsert,
    responseData: RequestedDocumentData as unknown as Document,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.document,
    entries: document,
    mutate,
  });

  function renderHeader() {
    return (
      <PreviewInputsProvider
        loading={loading}
        activePicker={activePicker}
        handleLock={handleLock}
        commonFields={commonFields}
        isMultiselect={isMultiselect}
        entity={Entities.document}
        locked={locked}
        lockedByUser={lockedByUser}
        numberOfSelectedRows={document.length}
        canEditAllSelected={canEditAllSelected}
      >
        {convertProps && (
          <ConvertPopover
            {...convertProps}
            popoverState={convertPopoverState}
          />
        )}

        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== title) {
              onEdit({ note: e.target.value });
            }
            onPopoverClose();
          }}
          onVisibilityToggle={onVisibilityToggle}
          value={title}
          accessor="note"
          onCloseClick={onCloseClick}
          leftActions={
            <>
              {onPdfEditClicked ? (
                <IconButton
                  color="primary"
                  onClick={onPdfEditClicked}
                  className={styles['fileheader-icon']}
                  size="small"
                >
                  <EditCopyIcon />
                </IconButton>
              ) : null}

              {onShareClicked && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={onShareClicked}
                >
                  <ShareIcon />
                </IconButton>
              )}
              {canEditAllSelected ? (
                <IconButton
                  color="primary"
                  onClick={onEditClicked}
                  className={styles['fileheader-icon']}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              ) : null}

              {onDeleteClicked ? (
                <IconButton
                  color="primary"
                  onClick={onDeleteClicked}
                  className={styles['fileheader-icon']}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}

              {convertProps ? (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={convertPopoverState.handleClick}
                >
                  <ConvertIcon style={{ height: '1.5rem', width: '1.5rem' }} />
                </IconButton>
              ) : null}

              {files.length > 1 && index !== -1 && (
                <FileListGalleryPagination
                  page={index}
                  count={files.length}
                  onChange={setIndex}
                />
              )}
            </>
          }
        />
      </PreviewInputsProvider>
    );
  }

  function renderContent() {
    return (
      <FileListPreviewContent
        files={files}
        selectedIndex={index}
        onSelectedIndexChange={setIndex}
        iconProps={iconProps}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {renderHeader()}
      {renderContent()}
    </div>
  );
}
