import { useMemo } from 'react';

import { useTableStateBag } from '@work4all/components';

import { Group } from '@work4all/models/lib/Classes/Group.entity';

import { groupsWithoutChildren } from './groups-without-children';
import {
  UseStringifyTableFiltersProps,
  UseStringifyTableFiltersReturn,
} from './types';

export const useStringifyTableGroupFilter = <TEntity>({
  groupKey,
  groupIdKey,
}: UseStringifyTableFiltersProps<TEntity>): UseStringifyTableFiltersReturn => {
  const { tableState } = useTableStateBag();

  const group = useMemo(() => {
    const filter = tableState?.filters.find((f) => f.id === groupIdKey);
    const groups = (filter?.value?.value as Group[]) ?? [];
    const groupsLength = groups.length;

    if (groupsLength === 0) {
      return null;
    }

    /**
     * If `groupsLength` is 1, it is clear that only one Group is selected,
     * so return it directly.
     *
     * If `groupsLength` is greater than 1, we need to check if the selected
     * Groups are nested. This is because when a Group is selected within nested Groups,
     * and it is the only Group in its parent, both the selected Group and its parent
     * are considered selected.
     *
     * To optimize this check, we use `groupsWithoutChildren` with a `maxResult` of 2,
     * which helps avoid resource-intensive operations.
     *
     * If there is exactly one Group without a children, we simply return it.
     * Otherwise, we return `null`.
     */

    if (groupsLength === 1) {
      return groups[0];
    }

    const groupsWithoutChildrenResult = groupsWithoutChildren({
      groups,
      maxResult: 2,
    });

    if (groupsWithoutChildrenResult.length === 1) {
      return groupsWithoutChildrenResult[0];
    }

    return null;
  }, [groupIdKey, tableState?.filters]);

  const result = useMemo(() => {
    if (group) {
      const data = {
        [groupIdKey]: group.id,
        [groupKey]: group,
      };

      return JSON.stringify(data);
    }

    return undefined;
  }, [group, groupIdKey, groupKey]);

  return result;
};
