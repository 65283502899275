import { JsonSchema } from 'rxdb';

import { getEntityRelations } from '@work4all/models/lib/entity-relations';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function mapRelationFields(
  entityType: Entities,
  schema: JsonSchema
): void {
  const entityRelations = getEntityRelations(entityType);

  if (entityRelations) {
    const toAdd = new Set<string>();
    const toRemove = new Set<string>();

    // If `object` property exists, remove the `objectId` property from
    // "required" and add the `object` property.
    for (const idProperty of schema.required as string[]) {
      const objectProperty = entityRelations.byId[idProperty];
      if (objectProperty) {
        toRemove.add(idProperty);
        toAdd.add(objectProperty);
      }
    }

    schema.required = schema.required
      .filter((property) => !toRemove.has(property))
      .concat(...toAdd);

    for (const property of toAdd) {
      schema.properties[property] = {
        type: 'object',
      };
    }
  }
}
