export enum Entities {
  /**  Alias: Abteilung*/
  department = 'department',
  /**  Alias: AbwesenheitUebersicht*/
  absenceOverview = 'absenceOverview',
  /**  Alias: AdressVerknuepfung*/
  addressConnection = 'addressConnection',
  /**  Alias: Angebot*/
  offer = 'offer',
  /**  Alias: Anrede*/
  salutation = 'salutation',
  /**  Alias: Ansprechpartner*/
  contact = 'contact',
  /**  Alias: ArchivPdf*/
  archivePdf = 'archivePdf',
  /**  Alias: Artikel*/
  article = 'article',
  /**  Alias: ArtikelPreise*/
  articlePrices = 'articlePrices',
  /**  Alias: Aufgabe*/
  task = 'task',
  /**  Alias: Auftrag*/
  contract = 'contract',
  /**  Alias: Bedarfsanforderung*/
  demand = 'demand',
  /**  Alias: Benutzer*/
  user = 'user',
  /**  Alias: Bestellung*/
  order = 'order',
  /**  Alias: Besuchsbericht*/
  visitationReport = 'visitationReport',
  /**  Alias: Brief*/
  letter = 'letter',
  /**  Alias: Checkliste*/
  checkList = 'checkList',
  /**  Alias: ChecklistePosition*/
  checkListPosition = 'checkListPosition',
  /**  Alias: CustomField*/
  customField = 'customField',
  /**  Alias: DhlSendung*/
  dhlShipment = 'dhlShipment',
  /**  Alias: Dokument*/
  document = 'document',
  /**  Alias: EMail*/
  eMail = 'eMail',
  /**  Alias: EMailAnhang*/
  eMailAttachment = 'eMailAttachment',
  /**  Alias: Eingangslieferschein*/
  inboundDeliveryNote = 'inboundDeliveryNote',
  /**  Alias: Eingangsrechnung*/
  inboundInvoice = 'inboundInvoice',
  /**  Alias: Einheit*/
  unit = 'unit',
  /**  Alias: Einkaufspreis*/
  purchasePrice = 'purchasePrice',
  /**  Alias: Einzelpreis*/
  singlePrice = 'singlePrice',
  /**  Alias: EntityChangendInfo*/
  entityChangedInfo = 'entityChangedInfo',
  /**  Alias: EntitySchema*/
  entitySchema = 'entitySchema',
  /**  Alias: ErpAnhang*/
  erpAttachment = 'erpAttachment',
  /**  Alias: FileMetaInfo*/
  fileMetaInfo = 'fileMetaInfo',
  /**  Alias: GeneralSettings*/
  generalSettings = 'generalSettings',
  /**  Alias: Geschaeftspartner*/
  businessPartner = 'businessPartner',
  /**  Alias: Group*/
  group = 'group',
  /**  Alias: GroupQueryResult*/
  groupQueryResult = 'groupQueryResult',
  /**  Alias: GroupQueryResultItem*/
  groupQueryResultItem = 'groupQueryResultItem',
  /**  Alias: Kalkulation*/
  calculation = 'calculation',
  /**  Alias: KategorieZuordnung*/
  categoryAssignment = 'categoryAssignment',
  /**  Alias: KommtGehtZeitkarteMonatsKorrektur*/
  workingTimeTimeCardMonthCorrection = 'workingTimeTimeCardMonthCorrection',
  /**  Alias: KommtGehtZeitkarteTagInfo*/
  workingTimeTimeCardDayInfo = 'workingTimeTimeCardDayInfo',
  /**  Alias: Kostenstelle*/
  costCenter = 'costCenter',
  /**  Alias: Krankheit*/
  sickness = 'sickness',
  /**  Alias: Kunde*/
  customer = 'customer',
  /**  Alias: KundenGruppe*/
  customerGroup = 'customerGroup',
  /**  Alias: KundenIndividuellerPreis*/
  customerIndividualPrice = 'customerIndividualPrice',
  /**  Alias: Layout*/
  layout = 'layout',
  /**  Alias: LayoutData*/
  layoutData = 'layoutData',
  /**  Alias: Lieferant*/
  supplier = 'supplier',
  /**  Alias: LieferantenGruppe*/
  supplierGroup = 'supplierGroup',
  /**  Alias: Lieferschein*/
  deliveryNote = 'deliveryNote',
  /**  Alias: LieferungArt*/
  deliveryKind = 'deliveryKind',
  /**  Alias: LookUp*/
  lookUp = 'lookUp',
  /**  Alias: Mandant*/
  tenant = 'tenant',
  /**  Alias: Mutation*/
  mutation = 'mutation',
  /**  Alias: Nebenadresse*/
  businessPartnerContactCombined = 'businessPartnerContactCombined',
  /**  Alias: Notiz*/
  note = 'note',
  /**  Alias: ObjectLock*/
  objectLock = 'objectLock',
  /**  Alias: Position*/
  position = 'position',
  /**  Alias: Preisgruppe*/
  priceGroup = 'priceGroup',
  /**  Alias: Produktionsauftrag*/
  productionContract = 'productionContract',
  /**  Alias: Projekt*/
  project = 'project',
  /**  Alias: ProjektBeteiligte*/
  projectInvolveds = 'projectInvolveds',
  /**  Alias: ProjektGruppe*/
  projectGroup = 'projectGroup',
  /**  Alias: ProjektVerteiler*/
  projectDistributor = 'projectDistributor',
  /**  Alias: ProjektVerteilerEintrag*/
  projectDistributorEntry = 'projectDistributorEntry',
  /**  Alias: ProjektVorgang*/
  projectProcess = 'projectProcess',
  /**  Alias: ProjektePlanungRueckmeldung*/
  projectPlanningFeedback = 'projectPlanningFeedback',
  /**  Alias: Query*/
  query = 'query',
  /**  Alias: RESachkontenSplit*/
  rELedgerAccountSplit = 'rELedgerAccountSplit',
  /**  Alias: Rechnung*/
  invoice = 'invoice',
  /**  Alias: Reisekostenabrechnung*/
  travelExpenses = 'travelExpenses',
  /**  Alias: Report*/
  report = 'report',
  /**  Alias: Sachkonto*/
  ledgerAccount = 'ledgerAccount',
  /**  Alias: StammdatenSprache*/
  baseDataLanguage = 'baseDataLanguage',
  /**  Alias: TeilrechnungLogik*/
  partialInvoiceLogic = 'partialInvoiceLogic',
  /**  Alias: TeilrechnungLogikDetails*/
  partialCalculationLogicDetails = 'partialCalculationLogicDetails',
  /**  Alias: Telefonat*/
  callMemo = 'callMemo',
  /**  Alias: Termin*/
  appointment = 'appointment',
  /**  Alias: TerminTeilnehmer*/
  appointmentAttendee = 'appointmentAttendee',
  /**  Alias: Textbaustein*/
  textBuildingBlock = 'textBuildingBlock',
  /**  Alias: Ticket*/
  ticket = 'ticket',
  /**  Alias: TicketAnhang*/
  ticketAttachment = 'ticketAttachment',
  /**  Alias: TicketArt*/
  ticketKind = 'ticketKind',
  /**  Alias: TicketKategorie1*/
  ticketCategory1 = 'ticketCategory1',
  /**  Alias: Tour*/
  tour = 'tour',
  /**  Alias: Urlaub*/
  vacation = 'vacation',
  /**  Alias: Verteiler*/
  category = 'category',
  /**  Alias: VerteilerKlasse*/
  categoryClass = 'categoryClass',
  /**  Alias: Waehrung*/
  currency = 'currency',
  /**  Alias: Wareneingang*/
  inboundPosition = 'inboundPosition',
  /**  Alias: Wartungsvertrag*/
  serviceContract = 'serviceContract',
  /**  Alias: WartungsvertragPosition*/
  serviceContractPosition = 'serviceContractPosition',
  /**  Alias: WidgetData*/
  widgetData = 'widgetData',
  /**  Alias: Work4allSubscription*/
  work4allSubscription = 'work4allSubscription',
  /**  Alias: Zahlungsart*/
  paymentKind = 'paymentKind',
  /**  Alias: Zeiterfassung*/
  timeTracking = 'timeTracking',
  /**  Alias: Zeitkarte*/
  timeCard = 'timeCard',
  /**  Alias: Bankverbindung*/
  bankDetails = 'bankDetails',
  /**  Alias: Preisstaffel*/
  bulkPriceTier = 'bulkPriceTier',
  /**  Alias: PreisstaffelItem*/
  bulkPriceTierItem = 'bulkPriceTierItem',
  /**  Alias: ObjectLockResult*/
  objectLockResult = 'objectLockResult',
  /**  Alias: DocumentClass*/
  documentClass = 'documentClass',
  /**  Alias: ArtikelGruppe*/
  articleGroup = 'articleGroup',
  /**  Alias: DeleteEntityResult*/
  deleteEntityResult = 'deleteEntityResult',
  /**  Alias: KpisCustomer*/
  kpisCustomer = 'kpisCustomer',
  /**  Alias: KpisCustomerSalesValueYear*/
  kpisCustomerSalesValueYear = 'kpisCustomerSalesValueYear',
  /**  Alias: DeleteErrorMessage*/
  deleteErrorMessage = 'deleteErrorMessage',
  /**  Alias: ModifyShadowBzObjectResult*/
  modifyShadowBzObjectResult = 'modifyShadowBzObjectResult',
  /**  Alias: AufgabeAnhang*/
  taskAttachment = 'taskAttachment',
  /**  Alias: NotizAnhang*/
  noteAttachment = 'noteAttachment',
  /**  Alias: TerminAnhang*/
  appointmentAttachment = 'appointmentAttachment',
  /**  Alias: TelefonatAnhang*/
  callMemoAttachment = 'callMemoAttachment',
  /**  Alias: TerminFarbe*/
  appointmentState = 'appointmentState',
  /**  Alias: MailSearchContactsResultItem*/
  mailSearchContactsResultItem = 'mailSearchContactsResultItem',
  /**  Alias: EMailVorlage*/
  eMailTemplate = 'eMailTemplate',
  /**  Alias: EMailSignatur*/
  eMailSignature = 'eMailSignature',
  /**  Alias: GroupQueryResultRow*/
  groupQueryResultRow = 'groupQueryResultRow',
  /**  Alias: SendEMailResult*/
  sendEMailResult = 'sendEMailResult',
  /**  Alias: ContactUnionWrapper*/
  contactUnionWrapper = 'contactUnionWrapper',
  /**  Alias: Steuerschluessel*/
  taxKey = 'taxKey',
  /**  Alias: WaehrungKurs*/
  exchangeRate = 'exchangeRate',
  /**  Alias: FileEntity*/
  fileEntity = 'fileEntity',
  /**  Alias: ThemaSimple*/
  topicSimple = 'topicSimple',
  /**  Alias: OcrInvoiceData*/
  ocrInvoiceData = 'ocrInvoiceData',
  /**  Alias: OcrInvoiceSupplierGuessing*/
  ocrInvoiceSupplierGuessing = 'ocrInvoiceSupplierGuessing',
  /**  Alias: Land*/
  country = 'country',
  /**  Alias: StatisticSalesVoumeYear*/
  statisticSalesVolumeYear = 'statisticSalesVolumeYear',
  /**  Alias: StatisticSalesVoumeYearItem*/
  statisticSalesVolumeYearItem = 'statisticSalesVolumeYearItem',
  /**  Alias: ConvertTempFileResult*/
  convertTempFileResult = 'convertTempFileResult',
  /**  Alias: MehrwertsteuerSatz*/
  vatRate = 'vatRate',
  /**  Alias: ArtikelText*/
  articleText = 'articleText',
  /**  Alias: LetterTemplateGroup*/
  letterTemplateGroup = 'letterTemplateGroup',
  /**  Alias: ProcessWordTemplateResult*/
  processWordTemplateResult = 'processWordTemplateResult',
  /**  Alias: StatisticSalesVoumeByCustomer*/
  statisticSalesVoumeByCustomer = 'statisticSalesVoumeByCustomer',
  /**  Alias: WordLetterTemplate*/
  wordLetterTemplate = 'wordLetterTemplate',
  /**  Alias: TemplateGroup*/
  templateGroup = 'templateGroup',
  /**  Alias: DocumentTemplateGroup*/
  documentTemplateGroup = 'documentTemplateGroup',
  /**  Alias: WordDocumentTemplate*/
  wordDocumentTemplate = 'wordDocumentTemplate',
  /**  Alias: SubDirectoryTemplateItem*/
  subDirectoryTemplateItem = 'subDirectoryTemplateItem',
  /**  Alias: TimeTrackingOverviewItem*/
  timeTrackingOverviewItem = 'timeTrackingOverviewItem',
  /**  Alias: Feiertag*/
  holiday = 'holiday',
  /**  Alias: StatisticsSalesVolumeByCustomerColumn*/
  statisticsSalesVolumeByCustomerColumn = 'statisticsSalesVolumeByCustomerColumn',
  /**  Alias: StatisticsSalesVolumeByCustomerRow*/
  statisticsSalesVolumeByCustomerRow = 'statisticsSalesVolumeByCustomerRow',
  /**  Alias: StatisticSalesVoumeByItem*/
  statisticSalesVoumeByItem = 'statisticSalesVoumeByItem',
  /**  Alias: StatisticsSalesVolumeByItemColumn*/
  statisticsSalesVolumeByItemColumn = 'statisticsSalesVolumeByItemColumn',
  /**  Alias: StatisticsSalesVolumeByItemRow*/
  statisticsSalesVolumeByItemRow = 'statisticsSalesVolumeByItemRow',
  /**  Alias: StatisticSalesVoumeByCostCenter*/
  statisticSalesVoumeByCostCenter = 'statisticSalesVoumeByCostCenter',
  /**  Alias: StatisticsSalesVolumeByCostCenterColumn*/
  statisticsSalesVolumeByCostCenterColumn = 'statisticsSalesVolumeByCostCenterColumn',
  /**  Alias: StatisticsSalesVolumeByCostCenterRow*/
  statisticsSalesVolumeByCostCenterRow = 'statisticsSalesVolumeByCostCenterRow',
  /**  Alias: SetUserPresenceResult*/
  setUserPresenceResult = 'setUserPresenceResult',
  /**  Alias: Appearance*/
  appearance = 'appearance',
  /**  Alias: AppearanceBackgroundImage*/
  appearanceBackgroundImage = 'appearanceBackgroundImage',
  /**  Alias: UserApperanceConfiguration*/
  userApperanceConfiguration = 'userApperanceConfiguration',
  /**  Alias: CompanyKpis*/
  companyKpis = 'companyKpis',
  /**  Alias: ShadowRe*/
  shadowRe = 'shadowRe',
  /**  Alias: Setting*/
  setting = 'setting',
  /**  Alias: VaultSettingItem*/
  vaultSettingItem = 'vaultSettingItem',
  /**  Alias: ModifyShadowREResult*/
  modifyShadowREResult = 'modifyShadowREResult',
  /**  Alias: StammdatenAnhang*/
  baseDataAttachment = 'baseDataAttachment',
  /**  Alias: KommtGehtZeitkarteAnwesenheitInfo*/
  userPresenceTimeInfo = 'userPresenceTimeInfo',
  /**  Alias: UserPresence*/
  userPresence = 'userPresence',
  /**  Alias: ProduktionsauftragGruppe*/
  productionContractGroup = 'productionContractGroup',
  /**  Alias: LicenseInfo*/
  licenseInfo = 'licenseInfo',
  /**  Alias: LicenseModulInfo*/
  licenseModulInfo = 'licenseModulInfo',
  /**  Alias: BesuchsberichtTeilnehmer*/
  visitationReportAttendee = 'visitationReportAttendee',
  /**  Alias: Gespraechspunkt*/
  talkingPoint = 'talkingPoint',
  /**  Alias: WorkTimeShortInfo*/
  workTimeShortInfo = 'workTimeShortInfo',
  /**  Alias: Urlaubsanspruch*/
  vacationEntitlement = 'vacationEntitlement',
  /**  Alias: BenutzerRolle*/
  userRole = 'userRole',
  /**  Alias: CreateEvent*/
  createEvent = 'createEvent',
  /**  Alias: ChangeEvent*/
  changeEvent = 'changeEvent',
  /**  Alias: ChangeEventChangeInfo*/
  changeEventChangeInfo = 'changeEventChangeInfo',
  /**  Alias: LegalNoticeParseResult*/
  legalNoticeParseResult = 'legalNoticeParseResult',
  /**  Alias: Datei*/
  file = 'file',
  /**  Alias: EMailVorlageAnhang*/
  eMailTemplateAttachment = 'eMailTemplateAttachment',
  /**  Alias: ProjektOberkategorie*/
  projectCategoryClass = 'projectCategoryClass',
  /**  Alias: ProjektKategorie*/
  projectCategory = 'projectCategory',
  /**  Alias: SaveSendMailJob*/
  saveSendMailJob = 'saveSendMailJob',
  /**  Alias: ValidationMessage*/
  validationMessage = 'validationMessage',
  /**  Alias: VacationRequest*/
  vacationRequest = 'vacationRequest',
  /**  Alias: KommtGehtZeitstempel*/
  userPresenceTimeInfoItem = 'userPresenceTimeInfoItem',
  /**  Alias: TicketChecklisteMark*/
  ticketChecklisteMark = 'ticketChecklisteMark',
  /**  Alias: EMailTemplateGroup*/
  eMailTemplateGroup = 'eMailTemplateGroup',
  /**  Alias: UserCollection*/
  userCollection = 'userCollection',
  /**  Alias: SubObjectLockResult*/
  subObjectLockResult = 'subObjectLockResult',
  /**  Alias: Staffelpreis*/
  bulkPrice = 'bulkPrice',
  /**  Alias: ChronoFileItem*/
  chronoFileItem = 'chronoFileItem',
  /**  Alias: ModuleAccessRight*/
  moduleAccessRight = 'moduleAccessRight',
  /**  Alias: VacationInfo*/
  vacationInfo = 'vacationInfo',
  /**  Alias: ProcessedEMailVorlagen*/
  processedEMailTemplate = 'processedEMailTemplate',
  /**  Alias: UserLicenseInformation*/
  userLicenseInformation = 'userLicenseInformation',
  /**  Alias: UserLicenseInformationItem*/
  userLicenseInformationItem = 'userLicenseInformationItem',
  /**  Alias: EntityAccessRights*/
  entityAccessRights = 'entityAccessRights',
  /**  Alias: EntityAccessRightsState*/
  entityAccessRightsState = 'entityAccessRightsState',
  /**  Alias: TextbausteinGruppe*/
  textBuildingBlockGroup = 'textBuildingBlockGroup',
  /**  Alias: ContactTelephonenumbers*/
  contactTelephonenumbers = 'contactTelephonenumbers',
  /**  Alias: TicketFilter*/
  ticketFilter = 'ticketFilter',
  /**  Alias: SumValues*/
  sumValues = 'sumValues',
  /**  Alias: ProcessedEMailVorlageAttachment*/
  processedEMailTemplateAttachment = 'processedEMailTemplateAttachment',
  /**  Alias: CustomFieldDefinition*/
  customFieldDefinition = 'customFieldDefinition',
  /**  Alias: ManagementDashboard*/
  managementDashboard = 'managementDashboard',
  /**  Alias: TempDatei*/
  tempFile = 'tempFile',
  /**  Alias: PostIt*/
  postIt = 'postIt',
  /**  Alias: Mailbox*/
  mailbox = 'mailbox',
  /**  Alias: MailboxFolder*/
  mailboxFolder = 'mailboxFolder',
  /**  Alias: MailboxContent*/
  mailboxContent = 'mailboxContent',
  /**  Alias: DefaultErpText*/
  defaultErpText = 'defaultErpText',
  /**  Alias: Notification*/
  notification = 'notification',
  /**  Alias: SupplierDemandAssign*/
  supplierDemandAddign = 'supplierDemandAddign',
  /**  Alias: SavedListFilter*/
  savedListFilter = 'savedListFilter',
  /**  Alias: Urlaubsart*/
  vacationKind = 'vacationKind',
  /**  Alias: Verkaufschance*/
  salesOpportunities = 'salesOpportunities',
  /**  Alias: SalesOpportunityGroup*/
  salesOpportunityGroup = 'salesOpportunityGroup',
  /**  Alias: SalesOpportunityRating*/
  salesOpportunityRating = 'salesOpportunityRating',
  /**  Alias: ChildItemCreated*/
  childItemCreated = 'childItemCreated',
  /**  Alias: MailboxConfiguration*/
  mailboxConfiguration = 'mailboxConfiguration',
  /**  Alias: ProjectAccessRights*/
  projectAccessRights = 'projectAccessRights',
  /**  Alias: Mention*/
  mention = 'mention',
  /**  Alias: CreateReportResult*/
  createReportResult = 'createReportResult',
  /**  Alias: Thumbnail*/
  thumbnail = 'thumbnail',
  /**  Alias: CommentEvent*/
  commentEvent = 'commentEvent',
  /**  Alias: ReportOption*/
  reportOption = 'reportOption',
  /**  Alias: SalesOpportunityRatingStatus*/
  salesOpportunityRatingStatus = 'salesOpportunityRatingStatus',
  /**  Alias: ProjectAccessGroup*/
  projectAccessGroup = 'projectAccessGroup',
  /**  Alias: StatisticCompanyReport*/
  statisticCompanyReport = 'statisticCompanyReport',
  /**  Alias: StatisticCompanyReportColumn*/
  statisticCompanyReportColumn = 'statisticCompanyReportColumn',
  /**  Alias: StatisticCompanyReportKeyFigures*/
  statisticCompanyReportKeyFigures = 'statisticCompanyReportKeyFigures',
  /**  Alias: StatisticCompanyReportFigures*/
  statisticCompanyReportFigures = 'statisticCompanyReportFigures',
  /**  Alias: KpisSupplier*/
  kpisSupplier = 'kpisSupplier',
  /**  Alias: KpisSupplierSalesValueYear*/
  kpisSupplierSalesValueYear = 'kpisSupplierSalesValueYear',
  /**  Alias: BzObjectConversionResult*/
  bzObjectConversionResult = 'bzObjectConversionResult',
  /**  Alias: SlModeSetting*/
  slModeSetting = 'slModeSetting',
  /**  Alias: InboundInvoicePayment*/
  inboundInvoicePayment = 'inboundInvoicePayment',
  /**  Alias: SalesOpportunityAttachement*/
  salesOpportunityAttachement = 'salesOpportunityAttachement',
  /**  Alias: DuplicateResponse*/
  duplicateResponse = 'duplicateResponse',
  /**  Alias: Ra*/
  ra = 'ra',
  /**  Alias: RaErloeskontoSplit*/
  raLedgerAccountSplit = 'raLedgerAccountSplit',
  /**  Alias: RaZahlung*/
  raPayment = 'raPayment',
  /**  Alias: Artikelbild*/
  articleImage = 'articleImage',
  /**  Alias: ReisekostenabrechnungBeleg*/
  travelReceipts = 'travelReceipts',
  /**  Alias: Report2*/
  report2 = 'report2',
  /**  Alias: IncomingMailBlockedSender*/
  incomingMailBlockedSender = 'incomingMailBlockedSender',
  /**  Alias: UserrightDefinition*/
  userRightDefinition = 'userRightDefinition',
  /**  Alias: Sprint*/
  sprint = 'sprint',
  /**  Alias: SetMfaModeResponse*/
  setMfaModeResponse = 'setMfaModeResponse',
  /**  Alias: ValidateSetMfaModeResponse*/
  validateSetMfaModeResponse = 'validateSetMfaModeResponse',
  /**  Alias: ReisekostenabrechnungBelegart*/
  travelCostInvoiceKind = 'travelCostInvoiceKind',
  /**  Alias: ReisekostenBelegartenFahrtkosten*/
  travelCostInvoiceKindTransportCost = 'travelCostInvoiceKindTransportCost',
  /**  Alias: ReisekostenabrechnungZahlungsart*/
  travelExpenseReportPaymentMethod = 'travelExpenseReportPaymentMethod',
  /**  Alias: FileLink*/
  fileLink = 'fileLink',
  /**  Alias: TaxGroup*/
  taxGroup = 'taxGroup',
  /**  Alias: ProjectDirectoryDefinition*/
  projectDirectoryDefinition = 'projectDirectoryDefinition',
  /**  Alias: ArticleLedgerAccount*/
  articleLedgerAccount = 'articleLedgerAccount',
  /**  Alias: BzObjectReleaseInformation*/
  bzObjectReleaseInformation = 'bzObjectReleaseInformation',
  /**  Alias: ReViewModel*/
  reViewModel = 'reViewModel',
  /**  Alias: ProjectStepLink*/
  projectProcessLink = 'projectProcessLink',
  /**  Alias: StatisticCustomerDatasheet*/
  statisticCustomerDatasheet = 'statisticCustomerDatasheet',
  /**  Alias: StatisticCustomerDatasheetColumn*/
  statisticCustomerDatasheetColumn = 'statisticCustomerDatasheetColumn',
  /**  Alias: StatisticCustomerDatasheetStatistic*/
  statisticCustomerDatasheetStatistic = 'statisticCustomerDatasheetStatistic',
  /**  Alias: ProjectStepCompletionFeedback*/
  projectProcessCompletionFeedback = 'projectProcessCompletionFeedback',
  /**  Alias: RaViewModel*/
  raViewModel = 'raViewModel',
  /**  Alias: ResourceUtilizationInfo*/
  resourceUtilizationInfo = 'resourceUtilizationInfo',
  /**  Alias: FileServiceProviderConfig*/
  fileServiceProviderConfig = 'fileServiceProviderConfig',
  /**  Alias: BzObjectMutationResponse*/
  bzObjectMutationResponse = 'bzObjectMutationResponse',
  /**  Alias: ArtikelKategorieMark*/
  articleCategoryMark = 'articleCategoryMark',
  /**  Alias: ArtikelKategorie*/
  articleCategory = 'articleCategory',
  /**  Alias: ArticleInventoryInfo*/
  articleInventoryInfo = 'articleInventoryInfo',
  /**  Alias: Lagerort*/
  storageLocation = 'storageLocation',
  /**  Alias: BenutzerGruppe*/
  userGroup = 'userGroup',
  /**  Alias: Komponente*/
  bOMComponent = 'bOMComponent',
  /**  Alias: ArticlePurchaseInformation*/
  articlePurchaseInformation = 'articlePurchaseInformation',
  /**  Alias: FullUser*/
  fullUser = 'fullUser',
  /**  Alias: PausenRegel*/
  pauseRule = 'pauseRule',
  /**  Alias: BenutzerTagessoll*/
  userDailyTarget = 'userDailyTarget',
  /**  Alias: GroupQuery*/
  groupQuery = 'groupQuery',
  /**  Alias: GroupFieldDefinition*/
  groupFieldDefinition = 'groupFieldDefinition',
  /**  Alias: GroupQuerySortInfo*/
  groupQuerySortInfo = 'groupQuerySortInfo',
  /**  Alias: InputLayout*/
  inputLayout = 'inputLayout',
  /**  Alias: InputCustomField*/
  inputCustomField = 'inputCustomField',
  /**  Alias: InputSetting*/
  inputSetting = 'inputSetting',
  /**  Alias: InputKunde*/
  inputKunde = 'inputKunde',
  /**  Alias: InputLieferant*/
  inputLieferant = 'inputLieferant',
  /**  Alias: InputNotiz*/
  inputNotiz = 'inputNotiz',
  /**  Alias: InputNotizRelation*/
  inputNotizRelation = 'inputNotizRelation',
  /**  Alias: InputCrmAnhangAttachementsRelation*/
  inputCrmAnhangAttachementsRelation = 'inputCrmAnhangAttachementsRelation',
  /**  Alias: InputCrmAnhangAttachementAdd*/
  inputCrmAnhangAttachementAdd = 'inputCrmAnhangAttachementAdd',
  /**  Alias: InputCrmAnhangAttachementModify*/
  inputCrmAnhangAttachementModify = 'inputCrmAnhangAttachementModify',
  /**  Alias: InputTopicMarkRelation*/
  inputTopicMarkRelation = 'inputTopicMarkRelation',
  /**  Alias: InputTelefonat*/
  inputTelefonat = 'inputTelefonat',
  /**  Alias: InputTelefonatRelation*/
  inputTelefonatRelation = 'inputTelefonatRelation',
  /**  Alias: InputAufgabe*/
  inputAufgabe = 'inputAufgabe',
  /**  Alias: InputDokument*/
  inputDokument = 'inputDokument',
  /**  Alias: InputBrief*/
  inputBrief = 'inputBrief',
  /**  Alias: InputCheckliste*/
  inputCheckliste = 'inputCheckliste',
  /**  Alias: InputChecklistePosition*/
  inputChecklistePosition = 'inputChecklistePosition',
  /**  Alias: InputEMail*/
  inputEMail = 'inputEMail',
  /**  Alias: InputEMailRelation*/
  inputEMailRelation = 'inputEMailRelation',
  /**  Alias: InputEMailAnhangAttachementsRelation*/
  inputEMailAnhangAttachementsRelation = 'inputEMailAnhangAttachementsRelation',
  /**  Alias: InputEMailVorlage*/
  inputEMailVorlage = 'inputEMailVorlage',
  /**  Alias: InputEMailSignatur*/
  inputEMailSignatur = 'inputEMailSignatur',
  /**  Alias: InputTelefonatAnhang*/
  inputTelefonatAnhang = 'inputTelefonatAnhang',
  /**  Alias: InputTerminAnhang*/
  inputTerminAnhang = 'inputTerminAnhang',
  /**  Alias: InputAufgabeAnhang*/
  inputAufgabeAnhang = 'inputAufgabeAnhang',
  /**  Alias: InputNotizAnhang*/
  inputNotizAnhang = 'inputNotizAnhang',
  /**  Alias: InputBesuchsbericht*/
  inputBesuchsbericht = 'inputBesuchsbericht',
  /**  Alias: InputAnsprechpartner*/
  inputAnsprechpartner = 'inputAnsprechpartner',
  /**  Alias: InputAdressVerknuepfung*/
  inputAdressVerknuepfung = 'inputAdressVerknuepfung',
  /**  Alias: InputTermin*/
  inputTermin = 'inputTermin',
  /**  Alias: InputTerminRelation*/
  inputTerminRelation = 'inputTerminRelation',
  /**  Alias: InputTerminTeilnehmerRelation*/
  inputTerminTeilnehmerRelation = 'inputTerminTeilnehmerRelation',
  /**  Alias: InputTerminTeilnehmerAdd*/
  inputTerminTeilnehmerAdd = 'inputTerminTeilnehmerAdd',
  /**  Alias: InputTicket*/
  inputTicket = 'inputTicket',
  /**  Alias: InputKalkulation*/
  inputKalkulation = 'inputKalkulation',
  /**  Alias: InputAngebot*/
  inputAngebot = 'inputAngebot',
  /**  Alias: InputPosition*/
  inputPosition = 'inputPosition',
  /**  Alias: InputAuftrag*/
  inputAuftrag = 'inputAuftrag',
  /**  Alias: InputRechnung*/
  inputRechnung = 'inputRechnung',
  /**  Alias: InputLieferschein*/
  inputLieferschein = 'inputLieferschein',
  /**  Alias: InputBedarfsanforderung*/
  inputBedarfsanforderung = 'inputBedarfsanforderung',
  /**  Alias: InputBestellung*/
  inputBestellung = 'inputBestellung',
  /**  Alias: InputEingangslieferschein*/
  inputEingangslieferschein = 'inputEingangslieferschein',
  /**  Alias: InputProjekt*/
  inputProjekt = 'inputProjekt',
  /**  Alias: InputZeiterfassung*/
  inputZeiterfassung = 'inputZeiterfassung',
  /**  Alias: InputKundeRelation*/
  inputKundeRelation = 'inputKundeRelation',
  /**  Alias: InputCategoryMarkRelation*/
  inputCategoryMarkRelation = 'inputCategoryMarkRelation',
  /**  Alias: InputCategoryMarkRelationAdd*/
  inputCategoryMarkRelationAdd = 'inputCategoryMarkRelationAdd',
  /**  Alias: InputCategoryMarkRelationModify*/
  inputCategoryMarkRelationModify = 'inputCategoryMarkRelationModify',
  /**  Alias: InputLieferantRelation*/
  inputLieferantRelation = 'inputLieferantRelation',
  /**  Alias: InputAufgabeRelation*/
  inputAufgabeRelation = 'inputAufgabeRelation',
  /**  Alias: InputDokumentRelation*/
  inputDokumentRelation = 'inputDokumentRelation',
  /**  Alias: InputBriefRelation*/
  inputBriefRelation = 'inputBriefRelation',
  /**  Alias: InputTemplateFileRelation*/
  inputTemplateFileRelation = 'inputTemplateFileRelation',
  /**  Alias: InputLetterDocumentFileRelation*/
  inputLetterDocumentFileRelation = 'inputLetterDocumentFileRelation',
  /**  Alias: InputEMailAnhangAttachementAdd*/
  inputEMailAnhangAttachementAdd = 'inputEMailAnhangAttachementAdd',
  /**  Alias: AppearanceSettingsInput*/
  appearanceSettingsInput = 'appearanceSettingsInput',
  /**  Alias: AppearanceSettingsInputAddBackgroundImage*/
  appearanceSettingsInputAddBackgroundImage = 'appearanceSettingsInputAddBackgroundImage',
  /**  Alias: UserApperanceConfigurationInput*/
  userApperanceConfigurationInput = 'userApperanceConfigurationInput',
  /**  Alias: InputBankAccountRelation*/
  inputBankAccountRelation = 'inputBankAccountRelation',
  /**  Alias: InputbankAccountRelationAddModify*/
  inputbankAccountRelationAddModify = 'inputbankAccountRelationAddModify',
  /**  Alias: InputAnsprechpartnerRelation*/
  inputAnsprechpartnerRelation = 'inputAnsprechpartnerRelation',
  /**  Alias: InputRESachkontenSplit*/
  inputRESachkontenSplit = 'inputRESachkontenSplit',
  /**  Alias: InputAddressConnectionRelation*/
  inputAddressConnectionRelation = 'inputAddressConnectionRelation',
  /**  Alias: InputAddressConnectionRelationAdd*/
  inputAddressConnectionRelationAdd = 'inputAddressConnectionRelationAdd',
  /**  Alias: InputStammdatenAttachementsRelation*/
  inputStammdatenAttachementsRelation = 'inputStammdatenAttachementsRelation',
  /**  Alias: InputAttachementAdd*/
  inputAttachementAdd = 'inputAttachementAdd',
  /**  Alias: InputStammdatenAttachementModify*/
  inputStammdatenAttachementModify = 'inputStammdatenAttachementModify',
  /**  Alias: StringKeyValue*/
  stringKeyValue = 'stringKeyValue',
  /**  Alias: InputUrlaub*/
  inputUrlaub = 'inputUrlaub',
  /**  Alias: InputKrankheit*/
  inputKrankheit = 'inputKrankheit',
  /**  Alias: InputTicketRelation*/
  inputTicketRelation = 'inputTicketRelation',
  /**  Alias: InputTicketAttachementsRelation*/
  inputTicketAttachementsRelation = 'inputTicketAttachementsRelation',
  /**  Alias: UserRightsFilter*/
  userRightsFilter = 'userRightsFilter',
  /**  Alias: InputVacationRequest*/
  inputVacationRequest = 'inputVacationRequest',
  /**  Alias: InputProjektRelation*/
  inputProjektRelation = 'inputProjektRelation',
  /**  Alias: InputProjectCategoryRelation*/
  inputProjectCategoryRelation = 'inputProjectCategoryRelation',
  /**  Alias: InputTicketChecklisteMarkRelation*/
  inputTicketChecklisteMarkRelation = 'inputTicketChecklisteMarkRelation',
  /**  Alias: InputKommtGehtZeitstempel*/
  inputKommtGehtZeitstempel = 'inputKommtGehtZeitstempel',
  /**  Alias: InputUserCollection*/
  inputUserCollection = 'inputUserCollection',
  /**  Alias: InputUserCollectionRelation*/
  inputUserCollectionRelation = 'inputUserCollectionRelation',
  /**  Alias: InputUserRelation*/
  inputUserRelation = 'inputUserRelation',
  /**  Alias: InputEMailVorlagenRelation*/
  inputEMailVorlagenRelation = 'inputEMailVorlagenRelation',
  /**  Alias: InputEMailVorlagenAnhangRelation*/
  inputEMailVorlagenAnhangRelation = 'inputEMailVorlagenAnhangRelation',
  /**  Alias: InputEMailVorlagenAnhangAdd*/
  inputEMailVorlagenAnhangAdd = 'inputEMailVorlagenAnhangAdd',
  /**  Alias: InputEMailTemplateGroup*/
  inputEMailTemplateGroup = 'inputEMailTemplateGroup',
  /**  Alias: InputTicketAttachementModify*/
  inputTicketAttachementModify = 'inputTicketAttachementModify',
  /**  Alias: InputEingangsrechnung*/
  inputEingangsrechnung = 'inputEingangsrechnung',
  /**  Alias: InputTextbaustein*/
  inputTextbaustein = 'inputTextbaustein',
  /**  Alias: InputTicketFilter*/
  inputTicketFilter = 'inputTicketFilter',
  /**  Alias: InputErpAnhangAttachementsRelation*/
  inputErpAnhangAttachementsRelation = 'inputErpAnhangAttachementsRelation',
  /**  Alias: InputErpAnhangAttachementModify*/
  inputErpAnhangAttachementModify = 'inputErpAnhangAttachementModify',
  /**  Alias: InputEingangsrechnungRelation*/
  inputEingangsrechnungRelation = 'inputEingangsrechnungRelation',
  /**  Alias: InputStammdatenPostIt*/
  inputStammdatenPostIt = 'inputStammdatenPostIt',
  /**  Alias: InputStammdatenPostItDetails*/
  inputStammdatenPostItDetails = 'inputStammdatenPostItDetails',
  /**  Alias: InputCompanyDefaultErpText*/
  inputCompanyDefaultErpText = 'inputCompanyDefaultErpText',
  /**  Alias: InputDefaultErpTextAddModify*/
  inputDefaultErpTextAddModify = 'inputDefaultErpTextAddModify',
  /**  Alias: InputModifySupplierDemandAssign*/
  inputModifySupplierDemandAssign = 'inputModifySupplierDemandAssign',
  /**  Alias: InputModifyAddSupplierDemandAssign*/
  inputModifyAddSupplierDemandAssign = 'inputModifyAddSupplierDemandAssign',
  /**  Alias: InputSavedListFilter*/
  inputSavedListFilter = 'inputSavedListFilter',
  /**  Alias: InputVerkaufschance*/
  inputVerkaufschance = 'inputVerkaufschance',
  /**  Alias: InputVerkaufschanceRelation*/
  inputVerkaufschanceRelation = 'inputVerkaufschanceRelation',
  /**  Alias: InputSalesOpportunityRatingRelation*/
  inputSalesOpportunityRatingRelation = 'inputSalesOpportunityRatingRelation',
  /**  Alias: InputSalesOpportunityRatingSet*/
  inputSalesOpportunityRatingSet = 'inputSalesOpportunityRatingSet',
  /**  Alias: InputNotificationKey*/
  inputNotificationKey = 'inputNotificationKey',
  /**  Alias: CreateCrystalReportRequest*/
  createCrystalReportRequest = 'createCrystalReportRequest',
  /**  Alias: CrystalReportFormular*/
  crystalReportFormular = 'crystalReportFormular',
  /**  Alias: InputBenutzer*/
  inputBenutzer = 'inputBenutzer',
  /**  Alias: InputSalesOpportunityRatingTemplate*/
  inputSalesOpportunityRatingTemplate = 'inputSalesOpportunityRatingTemplate',
  /**  Alias: BzObjectRelation*/
  bzObjectRelation = 'bzObjectRelation',
  /**  Alias: InputSalesOpportunityRatingStatus*/
  inputSalesOpportunityRatingStatus = 'inputSalesOpportunityRatingStatus',
  /**  Alias: CopyPosition*/
  copyPosition = 'copyPosition',
  /**  Alias: InputProjectAccessGroup*/
  inputProjectAccessGroup = 'inputProjectAccessGroup',
  /**  Alias: InputProjectAccessGroupRelation*/
  inputProjectAccessGroupRelation = 'inputProjectAccessGroupRelation',
  /**  Alias: InputProjectAccessGroupUserRelation*/
  inputProjectAccessGroupUserRelation = 'inputProjectAccessGroupUserRelation',
  /**  Alias: InputProjectAccessGroupProjectRelation*/
  inputProjectAccessGroupProjectRelation = 'inputProjectAccessGroupProjectRelation',
  /**  Alias: InputProjectAccessGroupGroupRelation*/
  inputProjectAccessGroupGroupRelation = 'inputProjectAccessGroupGroupRelation',
  /**  Alias: ErpObjectConversionRequest*/
  erpObjectConversionRequest = 'erpObjectConversionRequest',
  /**  Alias: ErpObjectConversionRequestOptions*/
  erpObjectConversionRequestOptions = 'erpObjectConversionRequestOptions',
  /**  Alias: InputSalesOpportunityAttachementRelation*/
  inputSalesOpportunityAttachementRelation = 'inputSalesOpportunityAttachementRelation',
  /**  Alias: InputSalesOpportunityAttachementModify*/
  inputSalesOpportunityAttachementModify = 'inputSalesOpportunityAttachementModify',
  /**  Alias: DuplicateRequest*/
  duplicateRequest = 'duplicateRequest',
  /**  Alias: InputRa*/
  inputRa = 'inputRa',
  /**  Alias: InputRaRelation*/
  inputRaRelation = 'inputRaRelation',
  /**  Alias: InputRaPaymentRelation*/
  inputRaPaymentRelation = 'inputRaPaymentRelation',
  /**  Alias: InputRaZahlung*/
  inputRaZahlung = 'inputRaZahlung',
  /**  Alias: InputReRelation*/
  inputReRelation = 'inputReRelation',
  /**  Alias: InputRePaymentRelation*/
  inputRePaymentRelation = 'inputRePaymentRelation',
  /**  Alias: InputWordLetterTemplate*/
  inputWordLetterTemplate = 'inputWordLetterTemplate',
  /**  Alias: InputReisekostenabrechnungBeleg*/
  inputReisekostenabrechnungBeleg = 'inputReisekostenabrechnungBeleg',
  /**  Alias: InputReisekostenabrechnungBelegRelation*/
  inputReisekostenabrechnungBelegRelation = 'inputReisekostenabrechnungBelegRelation',
  /**  Alias: InputWordDocumentTemplate*/
  inputWordDocumentTemplate = 'inputWordDocumentTemplate',
  /**  Alias: InputIncomingMailBlockedSender*/
  inputIncomingMailBlockedSender = 'inputIncomingMailBlockedSender',
  /**  Alias: InputSetMfaMode*/
  inputSetMfaMode = 'inputSetMfaMode',
  /**  Alias: InputValidateSetMfaMode*/
  inputValidateSetMfaMode = 'inputValidateSetMfaMode',
  /**  Alias: InputBenutzerRelation*/
  inputBenutzerRelation = 'inputBenutzerRelation',
  /**  Alias: InputProjectDirectoryDefinition*/
  inputProjectDirectoryDefinition = 'inputProjectDirectoryDefinition',
  /**  Alias: InputPromptResult*/
  inputPromptResult = 'inputPromptResult',
  /**  Alias: ReportOptionValue*/
  reportOptionValue = 'reportOptionValue',
  /**  Alias: InputProjektVorgang*/
  inputProjektVorgang = 'inputProjektVorgang',
  /**  Alias: InputProjectStepRelation*/
  inputProjectStepRelation = 'inputProjectStepRelation',
  /**  Alias: InputProjectStepLinkRelation*/
  inputProjectStepLinkRelation = 'inputProjectStepLinkRelation',
  /**  Alias: InputProjectStepLink*/
  inputProjectStepLink = 'inputProjectStepLink',
  /**  Alias: BzObjectMutationRequest*/
  bzObjectMutationRequest = 'bzObjectMutationRequest',
  /**  Alias: InputSetProjectDirectory*/
  inputSetProjectDirectory = 'inputSetProjectDirectory',
  /**  Alias: InputArtikel*/
  inputArtikel = 'inputArtikel',
  /**  Alias: InputArticleRelation*/
  inputArticleRelation = 'inputArticleRelation',
  /**  Alias: InputArticleTextRelation*/
  inputArticleTextRelation = 'inputArticleTextRelation',
  /**  Alias: InputArticleTextAddRelation*/
  inputArticleTextAddRelation = 'inputArticleTextAddRelation',
  /**  Alias: InputArticleTextModifyRelation*/
  inputArticleTextModifyRelation = 'inputArticleTextModifyRelation',
  /**  Alias: InputArticlePrices*/
  inputArticlePrices = 'inputArticlePrices',
  /**  Alias: InputArticlePricesSetSimplePrice*/
  inputArticlePricesSetSimplePrice = 'inputArticlePricesSetSimplePrice',
  /**  Alias: InputObjectGroupRelation*/
  inputObjectGroupRelation = 'inputObjectGroupRelation',
  /**  Alias: ErpObjectConversionPositionDetails*/
  erpObjectConversionPositionDetails = 'erpObjectConversionPositionDetails',
  /**  Alias: InputArticleLedgerAccountsAssignmentsRelation*/
  inputArticleLedgerAccountsAssignmentsRelation = 'inputArticleLedgerAccountsAssignmentsRelation',
  /**  Alias: InputArticleSetLedgerAccountsAssignmentRelation*/
  inputArticleSetLedgerAccountsAssignmentRelation = 'inputArticleSetLedgerAccountsAssignmentRelation',
  /**  Alias: InputArticleBomComponents*/
  inputArticleBomComponents = 'inputArticleBomComponents',
  /**  Alias: InputArticleUpsertBomComponent*/
  inputArticleUpsertBomComponent = 'inputArticleUpsertBomComponent',
  /**  Alias: InputReport2*/
  inputReport2 = 'inputReport2',
  /**  Alias: InvoiceToOutgoingInvoiceLedgerRequest*/
  invoiceToOutgoingInvoiceLedgerRequest = 'invoiceToOutgoingInvoiceLedgerRequest',
  /**  Alias: InputReZahlung*/
  inputReZahlung = 'inputReZahlung',
  /**  Alias: InputArticleAttachment*/
  inputArticleAttachment = 'inputArticleAttachment',
  /**  Alias: InputArticleAttachmentAdd*/
  inputArticleAttachmentAdd = 'inputArticleAttachmentAdd',
  /**  Alias: InputArticleAttachmentModify*/
  inputArticleAttachmentModify = 'inputArticleAttachmentModify',
  /**  Alias: InputChecklisteRelation*/
  inputChecklisteRelation = 'inputChecklisteRelation',
  /**  Alias: InputChecklistPositionRelation*/
  inputChecklistPositionRelation = 'inputChecklistPositionRelation',
  /**  Alias: InputFullUser*/
  inputFullUser = 'inputFullUser',
  /**  Alias: MutateProjectGroups*/
  mutateProjectGroups = 'mutateProjectGroups',
  /**  Alias: InputGroup*/
  inputGroup = 'inputGroup',
  /**  Alias: InputAbteilung*/
  inputAbteilung = 'inputAbteilung',
  businessPartnerUnion = 'businessPartnerUnion',
  bzObject = 'bzObject',
  articleImageUnion = 'articleImageUnion',
  chronoFileItemUnion = 'chronoFileItemUnion',
  notificationUnion = 'notificationUnion',
  mentionUnion = 'mentionUnion',
  contactUnion = 'contactUnion',
  shadowBzObject = 'shadowBzObject',
  searchResultItem = 'searchResultItem',
  widgetDataUnion = 'widgetDataUnion',
  event = 'event',
  childItemCreatedObject = 'childItemCreatedObject',
  duplicatedObjectUnion = 'duplicatedObjectUnion',
}
