import styles from './ZoomableTimeline.module.scss';

import { print } from '@mobiscroll/print';
import {
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarDayData,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventConnection,
  MbscEventCreatedEvent,
  MbscEventDragEvent,
  MbscPageLoadedEvent,
  MbscResource,
} from '@mobiscroll/react';
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import clsx from 'clsx';
import { LegacyRef } from 'react';

import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { usePageTheme } from '../../../../../../../providers/HookedMuiThemeProvider';
import { ZoomValue } from '../project-planning-view/components/timeline-zoom/TimelineZoom';
import { useColors } from '../project-planning-view/hooks/use-colors';
import { useRenderDay } from '../project-planning-view/hooks/use-render-day';

const DAY_IN_MINUTES = 60 * 24;
const EVENT_CALENDAR_MODULES = [print];

export interface ZoomableTimelineProps {
  zoom?: ZoomValue;
  leftAreaWidth?: number;
  leftAreaCollapsed?: boolean;
  date: Date;
  onSelectedDateChange?: (props: { date: Date }) => void;
  componentRef?: LegacyRef<Eventcalendar>;
  onEventClick?: (args: MbscEventClickEvent, inst: EventcalendarBase) => void;
  onEventDoubleClick?: (
    args: MbscEventClickEvent,
    inst: EventcalendarBase
  ) => void;
  onEventDragEnd?: (args: MbscEventDragEvent, inst: EventcalendarBase) => void;
  resources: MbscResource[];
  events: MbscCalendarEvent[];
  renderDay?: (args: MbscCalendarDayData) => void;
  view: MbscEventcalendarView;
  renderResource?: (resource: MbscResource, day?: Date) => void;
  renderResourceHeader?;
  compactView?: boolean;
  connections?: MbscEventConnection[];
  dragToMove?: boolean;
  dragBetweenResources?: boolean;
  dragInTime?: boolean;
  dragToResize?: boolean;
  clickToCreate?: boolean;
  eventDelete?: boolean;
  showControls?: boolean;
  dragToCreate?: boolean;
  colors?: MbscCalendarColor[];
  externalDrag?: boolean;
  externalDrop?: boolean;
  className?: string;
  onPageLoaded?: (args: MbscPageLoadedEvent, inst: EventcalendarBase) => void;
  onEventCreated?: (
    args: MbscEventCreatedEvent,
    inst: EventcalendarBase
  ) => void;
  onDestroy?: (args, inst) => void;
  maxEventStack?: number;
  showConnections?: boolean;
  newEventText?: string;
}

export const ZoomableTimeline = ({
  zoom,
  leftAreaWidth,
  leftAreaCollapsed,
  date,
  onSelectedDateChange,
  componentRef,
  onEventClick,
  onEventDragEnd,
  resources,
  events,
  view: initialView,
  renderResource,
  renderResourceHeader,
  compactView,
  connections,
  clickToCreate,
  dragToMove,
  dragBetweenResources,
  dragInTime,
  dragToResize,
  eventDelete,
  showControls,
  dragToCreate,
  colors: initialColors,
  externalDrag,
  externalDrop,
  className,
  onDestroy,
  onPageLoaded,
  onEventCreated,
  onEventDoubleClick,
  showConnections = true,
  newEventText,
}: ZoomableTimelineProps) => {
  const language = useMobiscrollLanguage();

  const pageTheme = usePageTheme();

  const view: MbscEventcalendarView = { ...initialView };

  const renderDay = useRenderDay({ unit: view.timeline?.type });

  const colors = useColors({ resources });

  return (
    <Eventcalendar
      width="100%"
      clickToCreate={clickToCreate}
      dragToCreate={dragToCreate}
      dragToMove={dragToMove}
      dragBetweenResources={dragBetweenResources}
      dragInTime={dragInTime}
      dragToResize={dragToResize}
      eventDelete={eventDelete}
      showControls={showControls}
      dragTimeStep={DAY_IN_MINUTES}
      externalDrop={externalDrop}
      externalDrag={externalDrag}
      themeVariant={pageTheme.value}
      locale={language}
      cssClass={clsx(
        className || '',
        styles.eventCalendar,
        styles['zoom-' + zoom],
        styles['leftAreaWidth-' + leftAreaWidth],
        { [styles.leftAreaCollapsed]: leftAreaCollapsed }
      )}
      selectedDate={date}
      onSelectedDateChange={(e) => {
        e && onSelectedDateChange?.({ date: e.date as Date });
      }}
      ref={componentRef}
      modules={EVENT_CALENDAR_MODULES}
      onEventClick={onEventClick}
      onEventDragEnd={onEventDragEnd}
      colors={initialColors || colors}
      resources={resources}
      data={events}
      renderDay={renderDay}
      view={view}
      renderResource={renderResource}
      renderResourceHeader={renderResourceHeader}
      connections={showConnections && !compactView ? connections : []}
      onPageLoaded={onPageLoaded}
      onDestroy={onDestroy}
      onEventCreated={onEventCreated}
      onEventDoubleClick={onEventDoubleClick}
      newEventText={newEventText}
    />
  );
};
