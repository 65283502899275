import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  canAddDocument,
  canAddEmail,
  canAddLetter,
  canAddVisitationReport,
  canDeleteDocument,
  canDeleteEmail,
  canDeleteLetter,
  canDeleteVisitationReport,
  canEditDocument,
  canEditEmail,
  canEditLetter,
  canEditVisitationReport,
} from '@work4all/utils/lib/permissions';

import { PermissionsConfigsByEntity } from '../types';

export const DMS_PERMISSIONS_CONFIGS: PermissionsConfigsByEntity = {
  [Entities.visitationReport]: {
    permissionsFunctions: {
      canAdd: canAddVisitationReport,
      canEdit: canEditVisitationReport,
      canDelete: canDeleteVisitationReport,
    },
  },
  [Entities.letter]: {
    permissionsFunctions: {
      canAdd: canAddLetter,
      canEdit: canEditLetter,
      canDelete: canDeleteLetter,
    },
  },
  [Entities.eMail]: {
    permissionsFunctions: {
      canAdd: canAddEmail,
      canEdit: canEditEmail,
      canDelete: canDeleteEmail,
    },
  },
  [Entities.document]: {
    permissionsFunctions: {
      canAdd: canAddDocument,
      canEdit: canEditDocument,
      canDelete: canDeleteDocument,
    },
  },
};
