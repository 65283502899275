import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { mapAppointmentToEmailParams } from '../../../../containers/mask-overlays/mask-overlay/views/appointment/utils/mapAppointmentToEmailParams';

export const useGetAppoinmentEmailsParams = (context: Appointment) => {
  const { t } = useTranslation();
  return useCallback(async () => {
    const entityTemplate = {
      entity: Entities.appointment,
      id: context.id,
    };

    return {
      entityTemplate,
      params: mapAppointmentToEmailParams(context, {
        hourKey: t('COMMON.CLOCK'),
        wholeDay: t('COMMON.WHOLEDAYEVENT'),
      }),
    };
  }, [t, context]);
};
