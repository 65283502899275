import { Stack } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ProjectPlanningView } from '../../../../../components/data-tables/project-table/components/project-table-bottom-section/components/project-planning-view/ProjectPlanningView';

import { TopBar } from './top-bar/TopBar';

export enum ProjectPlanningPageSearchParamKeys {
  projectIds = 'projectIds',
  layout = 'layout',
}

export const ProjectPlanningPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const projectIdsParams = searchParams.get(
    ProjectPlanningPageSearchParamKeys.projectIds
  );

  const layout =
    (searchParams.get(ProjectPlanningPageSearchParamKeys.layout) as
      | 'gantt'
      | 'stacked') || 'gantt';

  const projectIds = projectIdsParams?.split(',').map((x) => parseInt(x)) || [];

  const updateSearchParams = (key: string, value) => {
    const newParams = new URLSearchParams(searchParams);

    if (value !== null && value !== undefined) {
      newParams.set(key, value);
    } else {
      newParams.delete(key);
    }

    const search = newParams.toString();

    navigate({ pathname, search }, { replace: true });
  };

  return (
    <Stack height="100%" width="100%">
      <TopBar
        layout={layout}
        onLayoutChanged={(layout) => {
          updateSearchParams(ProjectPlanningPageSearchParamKeys.layout, layout);
        }}
      />
      <ProjectPlanningView
        projectIdList={projectIds}
        showFullscreenAction={false}
        onProjectIdsChange={(ids) => {
          const idsParam = ids.length ? ids.join(',') : null;
          updateSearchParams(
            ProjectPlanningPageSearchParamKeys.projectIds,
            idsParam
          );
        }}
        layout={layout}
      />
    </Stack>
  );
};
