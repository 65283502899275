import { PropsWithChildren } from 'react';

import { useBottomViewsContext } from '../hooks';

export const BottomViewsContent = ({ children }: PropsWithChildren) => {
  const { isCollapsed } = useBottomViewsContext();

  if (isCollapsed) {
    return null;
  }

  return children;
};
