import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { mergeActions } from './utils';

interface EntityActionState {
  custom?: CustomToolbar<CustomToolbarAction>;
}

interface EntityActionValue {
  actions: EntityActionState;
  addActions: (value: CustomToolbar<CustomToolbarAction>) => void;
}

const DEFAULT_VALUE = {
  actions: {},
  addActions: () => {},
};
const Context = createContext<EntityActionValue>(DEFAULT_VALUE);
export const useEntityActions = () => useContext(Context);

type EntityActionChildren = React.FC<EntityActionState>;
interface EntityActionsProps {
  entity: Entities;
  children: EntityActionChildren;
}

export const EntityActionProvider = (props: EntityActionsProps) => {
  const { entity, children } = props;

  const [actions, setActions] = useState<EntityActionState>({});

  // clear action when entity changed
  useEffect(() => {
    setActions({});
  }, [entity]);

  const addActions = useCallback(
    (newActions?: CustomToolbar<CustomToolbarAction>) => {
      setActions((p) => ({
        ...p,
        custom: mergeActions(p.custom, newActions),
      }));
    },
    []
  );
  const value = useMemo(
    () => ({
      actions,
      addActions,
    }),
    [actions, addActions]
  );
  return <Context.Provider value={value}>{children(actions)}</Context.Provider>;
};
