import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IInfoCardProps,
  InfoCard,
} from '@work4all/components/lib/components/entity-preview/components';

import { formatNumberAsCurrency } from '@work4all/utils';
import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { ControlWrapper } from '../../../../components';
import { useMaskContext } from '../../../../hooks/mask-context';
import { Payment } from '../../types';

export const GeneralData = () => {
  const mask = useMaskContext();
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const currency = watch('currency.name');
  const payments: Payment[] = watch('payments') || [];
  const grossAmount = watch('grossAmount');
  const rest =
    grossAmount - payments?.map((x) => x.amount).reduce((a, b) => a + b, 0);

  const watchData = watch();

  return (
    <>
      <ControlWrapper columns={0}>
        <DataCard
          label={t(`PAYMNET_MASK.${mask.entity.toUpperCase()}`)}
          children={`${watchData.number} ${
            watchData.note ? `| ${watchData.note}` : ''
          }`}
        />

        <DataCard
          label={`${
            watchData.businessPartner?.__typename === 'Kunde'
              ? t('COMMON.CUSTOMER')
              : t('COMMON.SUPPLIER')
          } ${watchData.businessPartner?.number}`}
          children={
            <div>
              <Typography>{watchData.businessPartner?.name}</Typography>
              <Typography>{watchData.businessPartner?.street}</Typography>
              <Typography>
                {watchData.businessPartner?.postalCode}{' '}
                {watchData.businessPartner?.city}
              </Typography>
            </div>
          }
        />
      </ControlWrapper>

      <ControlWrapper columns={0}>
        <ControlWrapper columns={3}>
          <DataCard
            label={t('COMMON.DATE')}
            children={DateTime.fromISO(watchData.date).toLocaleString(
              DateTimeCustom.DATE_SIMPLE_2YEAR
            )}
          />
          <DataCard
            label={t('COMMON.INBOUND_INVOICE.DUE')}
            children={DateTime.fromISO(watchData.dueDate).toLocaleString(
              DateTimeCustom.DATE_SIMPLE_2YEAR
            )}
          />
        </ControlWrapper>

        <ControlWrapper columns={3}>
          <DataCard
            label={t('COMMON.GROSS')}
            children={formatNumberAsCurrency(watchData.grossAmount)}
          />
          <DataCard
            label="Rest"
            children={formatNumberAsCurrency(rest)}
            color={rest !== 0 ? 'red' : undefined}
          />
        </ControlWrapper>

        {currency && currency !== 'EUR' && (
          <ControlWrapper columns={3}>
            <DataCard
              label={`${t('COMMON.GROSS')} [${currency}]`}
              children={formatNumberAsCurrency(
                watchData.grossAmount * watchData.exchangeRate
              )}
            />
            <DataCard
              label={`Rest [${currency}]`}
              children={formatNumberAsCurrency(rest * watchData.exchangeRate)}
            />
            <DataCard
              label={t('COMMON.ERP.FACTOR')}
              children={watchData.exchangeRate}
            />
          </ControlWrapper>
        )}
      </ControlWrapper>
    </>
  );
};

const DataCard = (props: IInfoCardProps) => (
  <InfoCard staticField truncate {...props} />
);
