import styles from './ProjectProcessPicker.module.scss';

import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IResponse, useDataProvider } from '@work4all/data';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { FilterTextInput } from '../components';
import { HeaderRow } from '../components/custom-list-entity-picker/HeaderRow';
import { ItemRow } from '../components/custom-list-entity-picker/ItemRow';
import { NoResultsRow } from '../components/custom-list-entity-picker/NoResultsRow';
import { useKeyboardNavigation } from '../components/custom-list-entity-picker/useKeyboardNavigation';
import { prepareRequest } from '../components/list-entity-picker/utils';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IProjectProcessPickerProps = IPickerProps<ProjectProcess, false> & {
  projectId: number;
  allowSelection?: ProjectProcessKind[];
  onChange: (projectProcess: Selection<ProjectProcess, false>) => void;
};

// This is a trimmed down version of ListEntityPicker with customizations.
export function ProjectProcessPicker(props: IProjectProcessPickerProps) {
  const { projectId, data, onChange, allowSelection, value, ...rest } = props;
  const allFields = useMemo(
    () => ({ ...PROJECTPROCESS_FIELDS, ...data }),
    [data]
  );

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const prefilter: unknown[] = useMemo(() => {
    let result: unknown[] = [{ projectId: { $eq: projectId } }];
    if (props?.prefilter) {
      result = [...result, ...props.prefilter];
    } else {
      result = [
        ...result,
        {
          $or: [
            { kindId: { $eq: ProjectProcessKind.VORGANG } },
            { kindId: { $eq: ProjectProcessKind.MEILENSTEIN } },
          ],
        },
      ];
    }
    return result;
  }, [projectId, props.prefilter]);

  const dataRequest = useMemo(
    () => ({
      ...prepareRequest({
        ...rest,
        entity: Entities.projectProcess,
        query: queryTrimmed,
        prefilter,
        data: allFields,
        filterBy: ['process'],
        sortBy: 'startDatum',
        skip: false,
        vars: null,
        searchHistory: [],
      }),
    }),
    [rest, queryTrimmed, prefilter, allFields]
  );

  const patchedProcesses = usePatchedDefaultData(
    useDataProvider(dataRequest),
    PAGE_SIZE
  )?.data;

  const isInteractive = useCallback(
    (item: ProjectProcess) =>
      allowSelection
        ? allowSelection.includes(item.kindId)
        : item.kindId === ProjectProcessKind.VORGANG,
    [allowSelection]
  );

  const { onKeyDown, itemRefs, focusedIndex } = useKeyboardNavigation(
    patchedProcesses,
    isInteractive,
    (selectedItem: ProjectProcess) => onChange(selectedItem)
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusedIndex]);

  const renderRow = (projectProcess: ProjectProcess, index: number) => {
    const isSelected =
      projectProcess.process === (value as ProjectProcess)?.process;
    const isHighlighted = index === focusedIndex;

    return (
      <ItemRow
        key={projectProcess.id}
        ref={(el) => (itemRefs.current[index] = el)}
        isInteractive={isInteractive(projectProcess)}
        hasSelection={!!value}
        isSelected={isSelected}
        title={projectProcess.process}
        className={isHighlighted ? styles.highlight : ''}
        onClick={() => onChange(projectProcess)}
        onClear={() => onChange(null)}
        content={
          <>
            <span className={styles.process}>{projectProcess.process}</span>
            <span className={styles.startDate}>
              {DateTime.fromISO(projectProcess.startDatum).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </span>
          </>
        }
      />
    );
  };

  return (
    <div className={styles.root}>
      <FilterTextInput
        ref={inputRef}
        autoFocus
        value={query}
        placeholder={t('PROJECT_PROCESS.SEARCH.PLACEHOLDER')}
        className={styles.searchBar}
        onChange={setQuery}
        onKeyDown={onKeyDown}
      />

      <div
        style={{
          height: LIST_ITEM_HEIGHT * 6,
          overflow: 'auto',
          flex: 'auto',
          isolation: 'isolate',
        }}
      >
        <HeaderRow
          hasSelection={!!value}
          content={
            <>
              <span className={styles.process}>
                {t('PROJECT_PROCESS.COLUMNS.NAME')}
              </span>
              <span className={styles.startDate}>
                {t('PROJECT_PROCESS.COLUMNS.START_DATE')}
              </span>
            </>
          }
        />
        {patchedProcesses.length === 0 && <NoResultsRow />}
        {patchedProcesses.map(renderRow)}
      </div>
    </div>
  );
}

const calculateRange = (length: number, pageSize: number) => {
  const startIndex = length;
  const stopIndex = startIndex + pageSize - 1;
  return { startIndex, stopIndex };
};

function usePatchedDefaultData(response: IResponse, pageSize: number) {
  return useMemo(
    () => ({
      ...response,
      total: Math.min(response.total, response.data.length + 3),

      fetchMore: async () => {
        const { startIndex, stopIndex } = calculateRange(
          response.data.length,
          pageSize
        );
        await response.fetchMore(startIndex, stopIndex);
      },
    }),
    [response, pageSize]
  );
}

const LIST_ITEM_HEIGHT = 40;
const PAGE_SIZE = 50;

const PROJECTPROCESS_FIELDS: ProjectProcess = {
  id: null,
  process: null,
  startDatum: null,
  kindId: null,
};
