import produce from 'immer';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { z } from 'zod';

import { EntityByLayoutType, LayoutTypesUnion } from '@work4all/models';
import { DocumentClass } from '@work4all/models/lib/Classes/DocumentClass.entity';
import { WidgetData } from '@work4all/models/lib/Classes/WidgetData.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { FileType, FileTypes } from '@work4all/models/lib/File';
import {
  ILayoutDefinition,
  ILayoutGroupDefinition,
  LayoutTypes,
  RowModifierCondition,
  WidgetsDefinitions,
} from '@work4all/models/lib/Layout';

import { invariant } from '@work4all/utils';
import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

import { useLayoutsData } from '../data-retriever/hooks/useLayoutsData';
import { useWidgetsDefinitions } from '../data-retriever/hooks/useWidgetsDifinitions';
import { useSetting } from '../settings';
import { fileWidgetLayouts } from '../settings/settings';

import { useDocumentClasses } from './document-classes';
import { useHiddenEntities } from './use-hidden-entities';

export const fileViewModeZod = z.enum(['list', 'tiles', 'individual']);
export type FileViewMode = DeepRequired<z.infer<typeof fileViewModeZod>>;

type Breakpoint = 'lg' | 'md' | 'sm' | 'xs';

const querySize = {
  lg: 3,
  md: 3,
  sm: 3,
  xs: 0,
};

interface IWigetsBag {
  widgetsData?: Record<string, WidgetData> | undefined | null;
  fileType: FileTypes;
  widgetsDefinitions: WidgetsDefinitions | undefined;
  maxRows: number;
  querySizeByWidgetId;
  currentBreakpoint: Breakpoint;
  setCurrentBreakpoint: (value: Breakpoint) => void;
  loading: boolean;
}

const WidgetsDefinitionsContext = React.createContext<Record<
  LayoutTypes,
  WidgetsDefinitions
> | null>(null);
const WidgetsContext = React.createContext<IWigetsBag | null>(null);

export interface WidgetSelectedItemId {
  widgetId: string;
  id: number | string;
  type: Entities;
}

interface IWidgetLayoutsProviderProps {
  layoutType: LayoutTypes;
  id: string | number;
  fileType: FileTypes;
  contactId: number | null;
  viewMode: FileViewMode;
  /**
   * The currently selected item. If both `selectedItem` and
   * `onSelectedItemChange` are provided, the selected item will be searched for
   * in all of the loaded widgets. If it is not found, the selected item will be
   * set to `null`. This is useful for when a selected item is deleted or the
   * widgets data is updated and no longer includes the selected item to
   * automatically unset the selected item.
   */
  selectedItem: null | WidgetSelectedItemId;
  /**
   * @see `selectedItem`
   */
  onSelectedItemChange: (
    item: { id: number | string; type: Entities } | null
  ) => void;
}

type ConvertedFileTypes =
  | FileTypes.Projects
  | FileTypes.Customers
  | FileTypes.Suppliers;

const FILE_TYPE_BY_FILE_TYPES: Record<ConvertedFileTypes, FileType> = {
  [FileTypes.Projects]: FileType.PROJECT,
  [FileTypes.Customers]: FileType.CUSTOMER,
  [FileTypes.Suppliers]: FileType.SUPPLIER,
};

export const WidgetsDefinitionsProvider: React.FC<
  PropsWithChildren<{ tenant: number }>
> = (props) => {
  // This component should be rendered as high a possible in order to avoid
  // querying data several times when page needs to be rerendered. This
  // allows us to render widgets instantly.
  const { tenant } = props;
  const widgetsDefinitions = useWidgetsDefinitions({ tenant });

  useEffect(() => {
    validateWidgetsDefinitions(widgetsDefinitions);
  }, [widgetsDefinitions]);

  const mappedDefinitions = useMemo(() => {
    return mapDefinitions(widgetsDefinitions);
  }, [widgetsDefinitions]);

  return (
    <WidgetsDefinitionsContext.Provider value={mappedDefinitions}>
      {props.children}
    </WidgetsDefinitionsContext.Provider>
  );
};

export const WidgetsDataProvider: React.FC<
  PropsWithChildren<IWidgetLayoutsProviderProps>
> = (props) => {
  const { selectedItem, onSelectedItemChange, viewMode } = props;

  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    'lg' as Breakpoint
  );

  const persistedFileWidgetLayouts = useSetting(fileWidgetLayouts());
  const resultingLayouts = useMemo(
    () =>
      viewMode === 'tiles' ? [] : persistedFileWidgetLayouts.value.layouts,
    [persistedFileWidgetLayouts.value.layouts, viewMode]
  );

  const querySizeByWidgetId = useMemo(
    () =>
      resultingLayouts?.length
        ? resultingLayouts
            .flatMap((l) => l[currentBreakpoint])
            .reduce((acc, l) => {
              acc[l.i] = (l.h - 1) * 2;
              return acc;
            }, {})
        : {},
    [resultingLayouts, currentBreakpoint]
  );

  const { isHidden } = useHiddenEntities();
  const allWidgetsDefinitions = useWidgetsDefintionsContext();
  const _widgetsDefinitions = allWidgetsDefinitions?.[props.layoutType];

  const businessPartnerType = resolveBusinessPartnerType(props.layoutType);
  const documentClasses = useDocumentClasses({ businessPartnerType });

  const isHiddenEntity = useCallback(
    (options: { entityType: Entities; entityVariant: string }) => {
      const { entityType, entityVariant } = options;
      return isHidden({ entityType, entityVariant, businessPartnerType });
    },
    [businessPartnerType, isHidden]
  );

  const widgetsDefinitions = useMemo(() => {
    if (!_widgetsDefinitions) {
      return _widgetsDefinitions;
    }

    const widgetGroups = _widgetsDefinitions.definition;
    const filteredWidgetGroups = processWidgetDefinitions(
      widgetGroups,
      documentClasses,
      isHiddenEntity
    );

    return { ..._widgetsDefinitions, definition: filteredWidgetGroups };
  }, [_widgetsDefinitions, documentClasses, isHiddenEntity]);

  const { data: widgetsData, loading: widgetDataLoading } = useLayoutsData(
    typeof props.id === 'string' ? parseInt(props.id) : props.id,
    props.contactId,
    widgetsDefinitions,
    (querySize[currentBreakpoint] - 1) * 2,
    FILE_TYPE_BY_FILE_TYPES[props.fileType],
    querySizeByWidgetId
  );

  // If selected item is not found in widgets data, then unset selected item.
  useEffect(() => {
    function isItemPresent(item: {
      id: number | string;
      type: Entities;
    }): boolean {
      for (const widgetData of Object.values(widgetsData)) {
        for (const widget of widgetData.data) {
          const entityType =
            EntityByLayoutType[
              (widget as { __typename: LayoutTypesUnion }).__typename
            ];

          if (widget.id === item.id && entityType === item.type) {
            return true;
          }
        }
      }

      return false;
    }

    if (!selectedItem) {
      return;
    }

    if (!isItemPresent(selectedItem) && viewMode === 'tiles') {
      onSelectedItemChange(null);
    }
  }, [widgetsData, selectedItem, onSelectedItemChange, viewMode]);

  return (
    <WidgetsContext.Provider
      value={{
        widgetsData,
        fileType: props.fileType,
        widgetsDefinitions,
        maxRows: querySize[currentBreakpoint],
        currentBreakpoint,
        setCurrentBreakpoint,
        querySizeByWidgetId,
        loading: widgetDataLoading,
      }}
    >
      {props.children}
    </WidgetsContext.Provider>
  );
};

export const useWidgetsDataBag = () => {
  const bag = useContext(WidgetsContext);
  if (!bag) {
    throw new Error(
      'bag is undefined. Make sure you wrapped your component with <DetailViewBagProvider />'
    );
  }
  return bag;
};

export const useWidgetsDefintionsContext = () => {
  return useContext(WidgetsDefinitionsContext);
};

function resolveBusinessPartnerType(
  layoutType: LayoutTypes
): SdObjType | undefined {
  switch (layoutType) {
    case LayoutTypes.Customer:
      return SdObjType.KUNDE;
    case LayoutTypes.Supplier:
      return SdObjType.LIEFERANT;
    case LayoutTypes.Project:
      return SdObjType.PROJEKT;
    default:
      return undefined;
  }
}

function mapDefinitions(
  translatedDefinitions: WidgetsDefinitions[]
): Record<LayoutTypes, WidgetsDefinitions> {
  return translatedDefinitions.reduce<Record<LayoutTypes, WidgetsDefinitions>>(
    (acc, definition) => {
      acc[definition.layoutType] = definition;
      return acc;
    },
    {} as Record<LayoutTypes, WidgetsDefinitions>
  );
}

function validateWidgetsDefinitions(layouts: WidgetsDefinitions[]): void {
  for (const layout of layouts) {
    for (const group of layout.definition) {
      for (const widget of group.widgets) {
        for (const entity of widget.config.entities) {
          if (entity.ui.rowModifiers) {
            for (const modifier of entity.ui.rowModifiers) {
              for (const rule of modifier.rules) {
                try {
                  validateRowModifierCondition(rule.condition);
                } catch (error) {
                  if (error instanceof Error) {
                    const modifierIndex =
                      entity.ui.rowModifiers.indexOf(modifier);
                    const ruleIndex = modifier.rules.indexOf(rule);

                    const message = [
                      error.message,
                      `\nSource:`,
                      `Layout: ${layout.name}`,
                      `Widget: ${widget.title}`,
                      `Entity: ${entity.entityTypeName}`,
                      `RowModifiers index: ${modifierIndex}`,
                      `Rule index: ${ruleIndex}`,
                      `\nCondition: ${JSON.stringify(rule.condition, null, 2)}`,
                    ].join('\n');

                    // Re-throw the original error to get access to the stacktrace.
                    Promise.reject(error);

                    throw new Error(message);
                  } else {
                    throw error;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

function validateRowModifierCondition(condition: RowModifierCondition): void {
  const msg = (details: string) => {
    const message = 'Invalid RowModifierCondition';
    return `${message}\n${details}`;
  };

  const operators = ['$eq', '$ne', '$in', '$nin'];

  invariant(
    typeof condition === 'object' && condition !== null,
    msg('The condition must be an object.')
  );

  const keys = Object.keys(condition);

  invariant(
    !keys.some((key) => operators.includes(key)),
    msg(
      [
        'Reserved operators can not appear at the top level of the condition object.',
        'Example of a valid condition: { amount: { $ne: 0 } }',
        `Received: ${JSON.stringify(condition)}`,
      ].join('\n')
    )
  );

  const values = Object.values(condition);

  for (const rule of values) {
    invariant(typeof rule === 'object' && rule !== null);

    const keys = Object.keys(rule);

    invariant(
      keys.length === 1,
      msg(
        [
          'Each rule must contain exactly one operator.',
          `Received: ${JSON.stringify(rule)}`,
        ].join('\n')
      )
    );

    const [operator] = keys;

    invariant(
      operators.includes(operator),
      msg(
        [
          `Unknown operator: ${operator}.`,
          `Supported operators: ${operators.join(', ')}.`,
        ].join('\n')
      )
    );
  }
}

/**
 * Update the widget definition to duplicate widgets for Document entities
 * using document classes applicable to the current file type.
 */
function processWidgetDefinitions(
  definitions: ILayoutGroupDefinition[],
  documentClasses: DocumentClass[],
  isHiddenEntity: (options: {
    entityType: Entities;
    entityVariant?: string;
  }) => boolean
): ILayoutGroupDefinition[] {
  return definitions
    .map<ILayoutGroupDefinition>((definition) => {
      return {
        ...definition,
        widgets: definition.widgets
          // Remove invalid widget definitions
          .filter((widget) => {
            return (
              widget.type === 'EntityList' &&
              widget.config.entities.length === 1
            );
          })
          // Duplicate Document variants
          .flatMap((widget) => {
            // If the current widget is for entity Document, duplicate the
            // widget using the provided list of document classes.

            const layoutType = widget.config.entities[0].entityTypeName;
            const entityType = EntityByLayoutType[layoutType];

            if (entityType === Entities.document) {
              return duplicateDocumentWidgets(widget, documentClasses);
            }

            // If the widget config is not matched as a Document entity widget,
            // just return the original config.
            return widget;
          })
          // Remove widgets that are hidden for the current user
          .filter((widget) => {
            const layoutType = widget.config.entities[0].entityTypeName;
            const entityType = EntityByLayoutType[layoutType];
            const entityVariant = widget.derivate?.value;

            return !isHiddenEntity({ entityType, entityVariant });
          }),
      };
    })
    .filter((definition) => {
      return definition.widgets.length > 0;
    });
}

/**
 * Duplicate the widget config using the list of document classes. Returns `n+1`
 * widget configs, where `n` is the number of document classes. The extra one is
 * the default document class (equal to `""`). Every widget config will contain
 * a filter by the `infoWindowName` property.
 */
function duplicateDocumentWidgets(
  widget: ILayoutDefinition,
  documentClasses: DocumentClass[]
): ILayoutDefinition[] {
  const allDocumentClasses = [
    null,
    ...documentClasses.map((documentClass) => documentClass.name),
  ];

  return allDocumentClasses.map((documentClass) => {
    const documentClassWidget = produce(widget, (draft) => {
      if (documentClass !== null) {
        // Each widget needs its own id to maintain the expanded/collapsed state
        // independently.
        draft.id = widget.id + ':' + documentClass;
        draft.title = `${documentClass}` as unknown as LayoutTypesUnion;
        draft.derivate = {
          field: 'infoWindowName',
          value: documentClass,
        };
      }

      const config = draft.config.entities[0];

      if (!config.filters) {
        config.filters = [];
      }
      //if there has been a default filter defined in the widget config, we can use it here or will get wron results
      config.filters = config.filters.filter((el) => !el.infoWindowName);

      config.filters.push({ infoWindowName: { $eq: documentClass ?? '' } });
    });

    return documentClassWidget;
  });
}
