import { DateTime, DurationObjectUnits } from 'luxon';

import { settings, useSetting } from '../../../settings';
import {
  DEFAULT_CALENDAR_WORK_DAY_END,
  DEFAULT_CALENDAR_WORK_DAY_START,
} from '../constants';

export interface CalendarHoursConfig {
  workDayStart: string;
  workDayEnd: string;
}

export function useCalendarHoursConfig(): CalendarHoursConfig {
  const workDayStartSetting = useSetting(settings.calendarWorkDayStart());
  const workDayEndSetting = useSetting(settings.calendarWorkDayEnd());

  const workDayStart =
    parseTime(workDayStartSetting.value) ?? DEFAULT_CALENDAR_WORK_DAY_START;
  const workDayEnd =
    parseTime(workDayEndSetting.value) ?? DEFAULT_CALENDAR_WORK_DAY_END;

  return {
    workDayStart,
    workDayEnd,
  };
}

/** Parsed the given ISO date and returns the time in HH:mm format. If the
 * `date` is `null` or cannot be parsed, returns `null`. */
function parseTime(date: string): string {
  if (date == null) return null;

  const dt = DateTime.fromISO(date);

  if (!dt.isValid) return null;

  return dt.toLocaleString({
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

/* Get correct day as for 'allDay' calendar returns end date as +1 */
export function getCalendarEndDate(isWholeDay: boolean, end: Date) {
  if (isWholeDay) {
    const endDate = getEdgeDate('end', 'day', end)
      .minus({ days: 1 })
      .set({ second: 0, millisecond: 0 });

    return endDate;
  }
  return DateTime.fromJSDate(end);
}

/* Get start or end of a specific unit, like 'day', in a proper time format */
const getEdgedDate = (
  unit: keyof DurationObjectUnits,
  edge: 'start' | 'end',
  dateToFormat: DateTime
) => {
  if (edge === 'start') {
    return dateToFormat.startOf(unit);
  }
  return dateToFormat.endOf(unit);
};

export function getEdgeDate(
  edge: 'start' | 'end',
  unit: keyof DurationObjectUnits,
  date: string | Date
): DateTime {
  if (date == null) return null;

  if (typeof date === 'string') {
    return getEdgedDate(unit, edge, DateTime.fromISO(date));
  } else {
    return getEdgedDate(unit, edge, DateTime.fromJSDate(date));
  }
}
