import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { useDialogs, useTableStateBag } from '@work4all/components';
import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { usePermissions } from '@work4all/data';
import { getPopoverOrigin } from '@work4all/data/lib/hooks/usePopoverState';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ErpDialogConfirm } from '../ErpDialog';
import { CloneConvertDialogProps, ErpObject } from '../types';

import { useCloneConvertDialog } from './use-clone-convert-dialog';
import { useConvertBzObjectMutation } from './use-convert-bz-object-mutation';

export type ConvertEntity =
  | Entities.order
  | Entities.inboundDeliveryNote
  | Entities.demand
  | Entities.offer
  | Entities.contract
  | Entities.calculation
  | Entities.deliveryNote
  | Entities.invoice;

type EntityByConvertConfigs = {
  [key in ConvertEntity]: {
    allowedEntities: Entities[];
    blockedEntities?: Entities[];
  };
};
export const ENTITY_BY_CONVERT_CONFIGS: EntityByConvertConfigs = {
  // supplier
  [Entities.demand]: {
    allowedEntities: [
      // Entities.order,
      Entities.inboundDeliveryNote,
      // Entities.inboundInvoice, // special case no 5. x -> inboundInvoice NOT NOW
    ],
  },
  [Entities.order]: {
    allowedEntities: [
      Entities.demand,
      Entities.inboundDeliveryNote, // special case no 4. order -> incomindDeliveryNote
      // Entities.inboundInvoice, // special case no 5. x -> inboundInvoice
    ],
  },
  [Entities.inboundDeliveryNote]: {
    allowedEntities: [
      Entities.demand,
      // Entities.order,
      // Entities.inboundInvoice, // special case no 5. x -> inboundInvoice
    ],
  },
  // customer
  [Entities.offer]: {
    allowedEntities: [
      Entities.contract,
      Entities.calculation,
      Entities.invoice,
      Entities.deliveryNote,
    ],
    blockedEntities: [
      Entities.order,
      Entities.demand,
      Entities.inboundDeliveryNote,
    ],
  },
  [Entities.contract]: {
    allowedEntities: [
      Entities.offer,
      Entities.calculation,
      Entities.invoice,
      Entities.deliveryNote, // special case no 1. contract -> deliveryNote
      // Entities.order, // special case no 3. contract -> order
    ],
  },

  [Entities.calculation]: {
    allowedEntities: [
      Entities.contract,
      Entities.offer,
      Entities.deliveryNote,
      Entities.invoice,
    ],
  },

  [Entities.deliveryNote]: {
    allowedEntities: [
      Entities.offer,
      Entities.calculation,
      Entities.invoice,
      Entities.inboundDeliveryNote, // / special case no 3. deliveryNote -> inboundDeliveryNote
    ],
  },

  [Entities.invoice]: {
    allowedEntities: [
      Entities.offer,
      Entities.calculation,
      Entities.deliveryNote,
      Entities.contract,
      // Entities.credit special case no 2. invoice -> credit
    ],
  },
};

interface UseCloneConvertErpOptions {
  data?: (Order | Offer | Invoice | Contract | Calculation | DeliveryNote)[];
  entity: ConvertEntity;
}

export const useCloneConvertTableErpActions = (props: {
  entity: ConvertEntity;
}) => {
  const { entity } = props;

  const tableStateBag = useTableStateBag();
  const data: ErpObject[] = useMemo(
    () => tableStateBag.tableInstance?.selectedFlatRows.map((x) => x.original),
    [tableStateBag]
  );

  return useCloneConvertErpActions({ entity, data });
};

export const useCloneConvertErpActions = (props: UseCloneConvertErpOptions) => {
  const { entity, data } = props;

  const isSmUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const { t } = useTranslation();
  const [targetEntity, setTargetEntity] = useState<Entities>(null);

  const { canPreview: canPreviewChecker, canConvert } = usePermissions();
  const canUserConvertRecord = canConvert({ entity });
  const canPreview = canPreviewChecker({ entity });

  const { mutate } = useConvertBzObjectMutation({ targetEntity });
  const convertRootRef = useRef<HTMLButtonElement>();
  const dialogs = useDialogs();

  // On mobile, the button is displayed at the bottom of the screen, so the menu
  // should open above it. On tablet and up, flit it around.
  // const convertPopoverState = usePopoverState(isSmUp ? 'bottom' : 'top');

  const convertOnClick = useCallback((entity: Entities) => {
    setTargetEntity(entity);
  }, []);
  const convertTypes = useMemo(
    () => ENTITY_BY_CONVERT_CONFIGS?.[entity]?.allowedEntities ?? [],
    [entity]
  );

  const actions: CustomToolbarAction[] = useMemo((): CustomToolbarAction[] => {
    return [
      {
        actionKey: 'cloneOrConvert',
        IconComponent: ConvertIcon,
        title: t('MASK.CONVERT'),
        disabled: !canUserConvertRecord,
        handler: (_id, event) => {
          convertRootRef.current = event?.currentTarget as HTMLButtonElement;
          const { id } = dialogs.open(
            (props) => (
              <ConvertPopover
                copyTypes={data?.length === 1 ? [entity] : undefined}
                convertTypes={convertTypes}
                onClick={(entity) => {
                  convertOnClick(entity);
                  dialogs.close(id);
                }}
                popoverState={{
                  open: props.open,
                  anchorEl: convertRootRef.current,
                  handleClick: () => {},
                  onClose: () => {
                    dialogs.close(id);
                  },
                  ...getPopoverOrigin(isSmUp ? 'bottom' : 'top'),
                }}
                convertTitle={t('COMMON.CONVERT_TO')}
                copyTitle={t('COMMON.COPY_CREATE')}
              />
            ),
            {}
          );
        },
        mode: 'Delete',
        sections: ['mobile', 'toolbar'],
        disableReason: canUserConvertRecord
          ? undefined
          : canPreview
          ? t('MASK.ERP.PREVIEW')
          : t('RIGHTS.MISSING'),
      },
    ];
  }, [
    t,
    canUserConvertRecord,
    canPreview,
    dialogs,
    data?.length,
    entity,
    convertTypes,
    isSmUp,
    convertOnClick,
  ]);

  const onClose = useCallback(() => {
    setTargetEntity(null);
  }, []);

  const onConfirm = useCallback(
    async (confirm: ErpDialogConfirm) => {
      onClose();

      const response = await mutate({
        sourceEntity: entity,
        sourceIds: data.map((x) => x.id),
        targetEntity,
        ...confirm,
      });

      if (response.errors.length === 0)
        await dialogs.alert({
          title: t('COMMON.INFO'),
          description:
            data.length > 1
              ? t('COMMON.ERP.CONVERT.SUCCESS_MULTI')
              : t('COMMON.ERP.CONVERT.SUCCESS'),
          closeLabel: t('COMMON.OK'),
        });
      else {
        await dialogs.alert({
          title: t('MASK.WARNING'),
          description: t('ERROR.COULD_NOT_REACH_SERVICE'),
          closeLabel: t('ALERTS.CLOSE'),
        });
      }
    },
    [data, dialogs, entity, mutate, onClose, targetEntity, t]
  );

  const dialogProps: Omit<CloneConvertDialogProps, 'open'> = useMemo(() => {
    return {
      onClose,
      onConfirm,
      sourceEntity: entity,
      targetEntity,
      data,
    };
  }, [data, entity, onClose, onConfirm, targetEntity]);

  useCloneConvertDialog(dialogProps);

  return { actions };
};
