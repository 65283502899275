import styles from './SalesOpportunitiesPreview.module.scss';

import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import type React from 'react';
import { MouseEventHandler, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IConvertPopoverProps } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { SalesOpportunitiesKindLookUp } from '@work4all/components/lib/components/entity-picker/sales-opportunities-kind-picker/SalesOpportunitiesKindPicker';
import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewWrapper,
} from '@work4all/components/lib/components/entity-preview/components';
import {
  ContactPreviewPickerInput,
  CustomerPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
} from '@work4all/components/lib/components/entity-preview/components/preview-input';
import { EntityHistory } from '@work4all/components/lib/components/entity-preview/EntityHistory';
import { useEntityPreview } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';

import { FormValue as SalesOpportunitiesEvaluationFormValue } from '../sales-opportunities-evaluation-picker/SalesOpportunitiesEvaluationPicker';
import { SalesOpportunitiesEvaluationPreviewPickerInput } from '../SalesOpportunitiesEvaluationPreviewPickerInput';

import { SalesOpportunitiesFields } from './SalesOpportunitiesContainer';

export type SalesOpportunitiesHandlers = {
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export type SalesOpportunitiesPreviewProps = {
  entries: SalesOpportunities[];
  onVisibilityToggle?: (visible: boolean) => void;
} & SalesOpportunitiesHandlers;

enum previewFields {
  DATE_NEXT_REVIEW = 'dateNextReview',
  DECISION_DATE = 'decisionDate',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  STATUS = 'status',
  EDITOR = 'editor',
  DESCRIPTION = 'description',
  EVALUATION = 'evaluation',
  PROJECT = 'project',
}

export function SalesOpportunitiesPreview(
  props: SalesOpportunitiesPreviewProps
) {
  const {
    entries,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;
  const { t } = useTranslation();

  const user = useUser();

  const { isInaccessible } = useInaccessibleFields();

  const valueIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'value'
  );
  const expectedCostsIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'expectedCosts'
  );

  const kindIsAccessible = !isInaccessible(Entities.salesOpportunities, 'kind');
  const groupIsAccessible = !isInaccessible(
    Entities.salesOpportunities,
    'group'
  );

  const evaluationRef = useRef<EntityPickerPopover>(null);

  const openPicker = useCallback((picker: previewFields) => {
    switch (picker) {
      case previewFields.EVALUATION: {
        evaluationRef.current.open();
        break;
      }

      default:
        return;
    }
  }, []);

  const [mutate] = useDataMutation<
    SalesOpportunities,
    EMode.upsert,
    {
      comment: string;
      attachements: InputTicketAttachementsRelation;
    }
  >({
    entity: Entities.salesOpportunities,
    mutationType: EMode.upsert,
    responseData: SalesOpportunitiesFields as unknown as SalesOpportunities,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.salesOpportunities,
    entries: entries,
    openPicker,
    mutate,
  });

  const salesOpportunity = entries[0];

  const customerIsPrivate =
    salesOpportunity?.businessPartner?.data?.isPrivateCustomer;

  const expectedCostIsCommon = commonFields.includes('expectedCosts');
  const valueIsCommon = commonFields.includes('value');
  const customerIsCommon = commonFields.includes('businessPartner');
  const kindIsCommon = commonFields.includes('kind');
  const groupIsCommon = commonFields.includes('groupId');

  return (
    <PreviewInputsProvider
      loading={loading}
      activePicker={activePicker}
      handleLock={handleLock}
      commonFields={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.salesOpportunities}
      locked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={entries.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== salesOpportunity?.description) {
              onEdit({ description: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          value={salesOpportunity?.description}
          accessor="description"
          disabled={isMultiselect}
          onVisibilityToggle={onVisibilityToggle}
        />

        {isMultiselect && (
          <PreviewEditMessage entityId={salesOpportunity.id.toString()} />
        )}

        <Stack direction={'column'}>
          <InfoCards>
            <CustomerPreviewPickerInput
              labelTranslationKey="COMMON.CUSTOMER"
              onClose={onPopoverClose}
              value={salesOpportunity?.businessPartner?.data}
              onChange={(value) => {
                onEdit({
                  businessPartner: value
                    ? {
                        data: value,
                        businessPartnerType: SdObjType.KUNDE,
                        id: value?.id,
                      }
                    : null,
                  businessPartnerId: value ? value?.id : 0,
                  businessPartnerType: SdObjType.KUNDE,
                  contactId: value?.mainContact?.id ?? null,
                });
              }}
              accessor="businessPartner"
              showFooter
            />

            {!customerIsPrivate && (
              <ContactPreviewPickerInput
                labelTranslationKey="COMMON.CONTACT"
                onClose={onPopoverClose}
                value={salesOpportunity?.contact}
                onChange={(value) => {
                  onEdit({
                    contact: value,
                    contactId: value ? value.id : 0,
                  });
                }}
                accessor="contact"
                prefilter={[
                  {
                    businessPartnerType: { $eq: SdObjType.KUNDE },
                  },
                  {
                    businessPartnerId: {
                      $eq: salesOpportunity?.businessPartner?.data.id ?? 0,
                    },
                  },
                ]}
                disabled={
                  !customerIsCommon || !salesOpportunity?.businessPartner?.data
                }
                showFooter
                entityTemplateValue={{
                  id: salesOpportunity?.businessPartner?.data?.id,
                  entity: SdObjType.KUNDE
                    ? Entities.customer
                    : Entities.supplier,
                }}
              />
            )}

            <ProjectPreviewPickerInput
              labelTranslationKey="COMMON.PROJECT"
              onClose={onPopoverClose}
              value={salesOpportunity?.project}
              onChange={(value) => {
                onEdit({
                  project: value,
                  projectId: value && !Array.isArray(value) ? value.id : 0,
                });
              }}
              accessor="project"
              showFooter
            />

            <SalesOpportunitiesEvaluationPreviewPickerInput
              labelTranslationKey={t('COMMON.EVALUATION')}
              value={salesOpportunity}
              onClose={onPopoverClose}
              onChange={(newValue: SalesOpportunitiesEvaluationFormValue) => {
                onEdit(
                  {
                    probabilityPercent: newValue?.probabilityPercent,
                    ratingStatusId: newValue?.ratingStatusId,
                    status: newValue?.status,
                  },
                  newValue?.comment && {
                    relations: {
                      ratings: {
                        set: [
                          {
                            comment: newValue?.comment,
                          },
                        ],
                      },
                    },
                  }
                );
              }}
              accessor="probabilityPercent"
            />

            <DatePreviewPickerInput
              labelTranslationKey="COMMON.NEXT_REVIEW"
              onClose={onPopoverClose}
              value={salesOpportunity?.dateNextReview}
              onChange={(value) => {
                onEdit({ dateNextReview: new Date(value).toISOString() });
              }}
              accessor="dateNextReview"
            />

            <DatePreviewPickerInput
              labelTranslationKey="COMMON.DECISION_DATE"
              onClose={onPopoverClose}
              value={salesOpportunity?.decisionDate}
              onChange={(value) => {
                onEdit({ decisionDate: new Date(value).toISOString() });
              }}
              accessor="decisionDate"
            />

            {expectedCostsIsAccessible && (
              <InfoCard label={t('COMMON.EXPECTED_SALES')} disabled>
                {/* <LabeledInputWithUnit
                unit={'EUR'}
                value={salesOpportunity?.expectedCosts}
                onChange={(val) => {
                  onEdit({
                    expectedCosts: parseFloat(val.target.value),
                  });
                }}
              /> */}
                {expectedCostIsCommon
                  ? `${formatNumberAsCurrency(salesOpportunity?.value).replace(
                      /,.*$/,
                      ',-'
                    )} ${formatCurrency(salesOpportunity.currency, t)}`
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            )}

            {valueIsAccessible && (
              <InfoCard label={t('COMMON.RATED')} disabled>
                {valueIsCommon
                  ? `${formatNumberAsCurrency(
                      salesOpportunity?.value *
                        ((salesOpportunity?.probabilityPercent ?? 100) / 100)
                    ).replace(/,.*$/, ',-')} ${formatCurrency(
                      salesOpportunity.currency,
                      t
                    )}`
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            )}

            {kindIsAccessible && (
              <InfoCard label={t('COMMON.KIND')} disabled>
                {kindIsCommon
                  ? `${t(
                      SalesOpportunitiesKindLookUp[salesOpportunity?.kind ?? 0]
                    )}`
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            )}

            {groupIsAccessible && (
              <InfoCard label={t('COMMON.GROUP')} disabled>
                {groupIsCommon
                  ? salesOpportunity?.group?.name ?? '-'
                  : `(${t('COMMON.MULTIPLE')})`}
              </InfoCard>
            )}
          </InfoCards>
          {!isMultiselect ? (
            <>
              <Divider orientation="horizontal" />
              <EntityHistory
                fitContainer={false}
                entity={Entities.salesOpportunities}
                fullWidth
                id={salesOpportunity.id}
                className={styles.hist}
                breadcrumbTitle={`${salesOpportunity.description}`}
              />
            </>
          ) : null}
        </Stack>
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
