import { useCallback, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { TRAVEL_RECEIPTS_DATA } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';
import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';

import { useDataMutation, useDataProvider, useUser } from '@work4all/data';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputCrmAnhangAttachementsRelation.entity';
import { InputReisekostenabrechnungBelegRelation } from '@work4all/models/lib/Classes/InputReisekostenabrechnungBelegRelation.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { TravelCostInvoiceKind } from '@work4all/models/lib/Classes/TravelCostInvoiceKind.entity';
import { TravelCostInvoiceKindTransportCost } from '@work4all/models/lib/Classes/TravelCostInvoiceKindTransportCost.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ReceiptKindInternal } from '@work4all/models/lib/Enums/ReceiptKindInternal.enum';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { settings, useSetting } from '../../../../../settings';
import { LockOverride } from '../../components/LockOverride';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';
import { CurrencyExchangeInfoContext } from '../inbound-invoice/currency-exchange-info-context';
import { CurrencyExchangeInfoContextProvider } from '../inbound-invoice/CurrencyExchangeInfoContextProvider';

import { General } from './components/General';
import { useTravelReceipts } from './hooks/use-travel-receipts';
import { TravelReceiptsFormValue } from './type';

export const TravelReceiptsOverlayController = (props: MaskControllerProps) => {
  return (
    <CurrencyExchangeInfoContextProvider>
      <TravelReceiptsOverlayControllerInternal {...props} />
    </CurrencyExchangeInfoContextProvider>
  );
};

const TravelReceiptsOverlayControllerInternal = (
  props: MaskControllerProps
) => {
  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const setting = useSetting(settings.travelReceiptDefaults());
  const { request, newEntityData } = useTravelReceipts(mask, setting.value);

  const customRules = useCallback(
    (data: TravelReceiptsFormValue) => {
      const errors: Partial<
        Record<keyof TravelReceiptsFormValue, { message: string; type: string }>
      > = {};

      if (!data.description) {
        errors.description = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (!data.paymentMethod) {
        errors.paymentMethod = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (!data.receiptKind) {
        errors.receiptKind = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (
        data.receiptKind?.typeOfReceiptType === ReceiptKindInternal.FAHRTKOSTEN
      ) {
        if (!data.receiptKindTravelCost) {
          errors.receiptKindTravelCost = {
            message: t('ERROR.FIELD_REQUIRED'),
            type: 'customValidation',
          };
        }
      }
      if (data.vat1 === null || data.vat1 === undefined) {
        errors.vat1 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (data.vat2 === null || data.vat2 === undefined) {
        errors.vat2 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (data.vat3 === null || data.vat3 === undefined) {
        errors.vat3 = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }

      if (Object.keys(errors).length) return errors;
      return true;
    },
    [t]
  );

  const getTempFileInitialData = useCallback(
    (entity: TravelReceiptsFormValue) => {
      return entity?.file?.map((x) => {
        return {
          ...x,
          fileName: x.localFilename,
        };
      });
    },
    []
  );

  const normalizeData = useCallback(
    (
      entity: TravelReceiptsFormValue,
      newEntity: TravelReceiptsFormValue,
      isCreateMode: boolean
    ) => {
      return isCreateMode
        ? newEntity
        : {
            ...entity,
            // API return NULL but it should be undefined
            enumReceiptKindTravelCosts:
              entity?.enumReceiptKindTravelCosts ?? undefined,
          };
    },
    []
  );

  const overlay = useMaskOverlay<TravelReceiptsFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customRules,
    getSubTitle: (x) => x.note,
    getTempFileInitialData,
    normalizeData,
  });

  const { form, tempFileManager, data } = overlay;
  const { formState } = form;

  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();
  const status = getTravelReceiptsStatus(data?.travelExpenses).key;

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const [mutate] = useDataMutation<
    TravelReceipts,
    EMode.upsert,
    InputReisekostenabrechnungBelegRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData:
      TRAVEL_RECEIPTS_DATA as unknown as TravelReceipts<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave(data);
    },
  });

  const ledgerAccountRequestData = useMemo(() => {
    return {
      entity: Entities.ledgerAccount,
      data: {
        id: null,
        name: null,
        number: null,
      },
    };
  }, []);

  const ledgerAccounts = useDataProvider<LedgerAccount>(
    ledgerAccountRequestData
  );
  const ledgerAccountsRef = useRef(ledgerAccounts);
  ledgerAccountsRef.current = ledgerAccounts;

  const exchangeInfoContext = useContext(CurrencyExchangeInfoContext);
  const exchangeRef = useRef(exchangeInfoContext);
  exchangeRef.current = exchangeInfoContext;

  const updateVatAndAccount = (
    receiptKindTravelCost:
      | TravelCostInvoiceKind
      | TravelCostInvoiceKindTransportCost
  ) => {
    const res: TravelReceiptsFormValue = {};
    if (ledgerAccountsRef.current?.data?.length) {
      const ledgerAccount1 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount1
      );
      const ledgerAccount2 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount2
      );
      const ledgerAccount3 = ledgerAccountsRef.current.data.find(
        (x) => x.number === receiptKindTravelCost.ledgerAccount3
      );
      if (ledgerAccount1) {
        res.ledgerAccount1 = ledgerAccount1;
        res.vat1 = receiptKindTravelCost.vat1;
      }
      if (ledgerAccount2) {
        res.ledgerAccount2 = ledgerAccount2;
        res.vat2 = receiptKindTravelCost.vat2;
      }
      if (ledgerAccount3) {
        res.ledgerAccount3 = ledgerAccount3;
        res.vat3 = receiptKindTravelCost.vat3;
      }
    }
    return res;
  };

  useFormUpdate(
    {
      project(project) {
        return {
          projectId: project?.id ?? 0,
        };
      },
      costCenter(costCenter) {
        return {
          costCenterId: costCenter?.id ?? 0,
        };
      },
      currency(currency) {
        let course = 1;
        if (currency?.id && exchangeRef.current) {
          course = 1 / exchangeRef.current.getExchangeRate(currency?.id);
        }

        return {
          currencyId: currency?.id ?? 0,
          course,
        };
      },
      ledgerAccount1(ledgerAccount) {
        return {
          ledgerAccount1Id: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount2(ledgerAccount) {
        return {
          ledgerAccount2Id: ledgerAccount?.number ?? 0,
        };
      },
      ledgerAccount3(ledgerAccount) {
        return {
          ledgerAccount3Id: ledgerAccount?.number ?? 0,
        };
      },
      receiptKindTravelCost(receiptKindTravelCost) {
        const update = updateVatAndAccount(receiptKindTravelCost);
        return {
          receiptKindTravelCostId: receiptKindTravelCost?.id ?? 0,
          ...update,
        };
      },
      receiptKind(receiptKind) {
        const update = updateVatAndAccount(receiptKind);
        return {
          receiptKindId: receiptKind?.id ?? 0,
          ...update,
        };
      },
      paymentMethod(paymentMethod) {
        return {
          paymentKind: paymentMethod?.id ?? 0,
        };
      },
    },
    form
  );

  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.inputCrmAnhangAttachementsRelation,
      'id'
    );

  const user = useUser();
  const handleSubmit = useCallback(
    async (input: TravelReceiptsFormValue) => {
      const updateRaw = mask.isCreateMode
        ? input
        : pickUpdateFields(input, formState.dirtyFields);

      if (typeof updateRaw.amountGross1 === 'string')
        updateRaw.amountGross1 = parseFloat(updateRaw.amountGross1);
      if (typeof updateRaw.amountGross2 === 'string')
        updateRaw.amountGross3 = parseFloat(updateRaw.amountGross2);
      if (typeof updateRaw.amountGross3 === 'string')
        updateRaw.amountGross3 = parseFloat(updateRaw.amountGross3);
      if (typeof updateRaw.vat1 === 'string')
        updateRaw.vat1 = parseFloat(updateRaw.vat1);
      if (typeof updateRaw.vat2 === 'string')
        updateRaw.vat2 = parseFloat(updateRaw.vat2);
      if (typeof updateRaw.vat3 === 'string')
        updateRaw.vat3 = parseFloat(updateRaw.vat3);

      setting.set(updateRaw);
      await mutate(updateRaw, {
        relations: {
          attachements: attachementsRelation?.attachements,
          supplierCode: user.supplierCode,
        },
      });
    },
    [
      mask.isCreateMode,
      formState.dirtyFields,
      setting,
      mutate,
      attachementsRelation,
      user.supplierCode,
    ]
  );

  const disabled =
    (mask.isEditMode && status !== 'parked') || !user.supplierCode;

  return (
    <LockOverride
      forceLock={disabled}
      lockReason={t('ALERTS.TRAVEL_RECEIPT_LOCKED')}
    >
      <OverlayController<TravelReceiptsFormValue>
        {...overlay}
        onSubmit={handleSubmit}
      >
        <AttachmentsDropZone single>
          <General />
        </AttachmentsDropZone>
      </OverlayController>
    </LockOverride>
  );
};
