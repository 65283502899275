import { DateTime } from 'luxon';
import { Path } from 'react-router-dom';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  makeTableSettings,
  stringifyTableSettings,
} from '../data-tables/make-table-settings';

/**
 * Will try to parse the given link and if it is recognized as an entity list
 * link, will add a `"settings"` param with date filter applied.
 */
export function addDateParamToLink(href: string): string {
  const regex = /\/more\/entity\/(?<entityType>[^/?]+)/;

  const result = regex.exec(href);

  // If the link is not recognized, just return it as is.
  if (!result) {
    return href;
  }

  const entityType = resolveEntityType(result.groups.entityType);

  const startDate = DateTime.now().startOf('year').minus({ years: 1 }).toISO();
  const endDate = DateTime.now().endOf('year').toISO();

  const settings = makeTableSettings(entityType, {
    date: {
      value: {
        startDate,
        endDate,
      },
    },
  });

  if (settings) {
    const url = new URL(href, window.location.origin);
    url.searchParams.set('settings', stringifyTableSettings(settings));
    return url.pathname + url.search;
  }

  return href;
}

export function addDateParamToUrl(href: string): Partial<Path> {
  const url = new URL(addDateParamToLink(href));
  const searchParams = new URLSearchParams(url.search);
  const pathname = url.pathname;
  const search = searchParams.toString();
  return { pathname, search };
}

/**
 * Resolve base entity type for normal and virtual entities.
 */
function resolveEntityType(entityTypeParam: string): Entities {
  const regex = /^(?<entityName>.+?)\((?<entityVariant>.*)\)$/;

  const result = regex.exec(entityTypeParam);

  // This does not look like a virtual entity. Just treat it as normal entity
  // and return full string.
  if (result === null) {
    return entityTypeParam as Entities;
  }

  const { entityName } = result.groups;

  return entityName as Entities;
}
