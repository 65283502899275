import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useUser } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import {
  OpenMaskOverride,
  useParameterizedOpenMask,
} from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { useStringifyTableGroupFilter } from '../../../hooks';
import { OrganisationArea } from '../../organisation-area';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { useFileActions } from '../FileActions';
import { GridStateProvider } from '../grid/GridStateProvider';
import { useProjectPlanningActions } from '../ProjectPlanningActions';
import jsonSchema from '../schemata/project-table-schema.json';
import { TableAreas } from '../table/Table';
import { useTableLayoutState } from '../table-layout';

import { useProjectBottomArea } from './hooks/use-project-bottom-area';

type IProjectTableCustomColumns = Record<string, never>;

const defaultSort = [
  { field: 'startDateOuter', direction: SortDirection.DESCENDING },
];

export const ProjectTable = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTable(props, ref) {
    return (
      <GridStateProvider>
        <ProjectTableInner {...props} ref={ref} />
      </GridStateProvider>
    );
  }
);

export const ProjectTableInner = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTableInner(props, ref) {
    const { t } = useTranslation();
    const user = useUser();
    const schema = jsonSchema as unknown as ITableSchema<never>;
    const { entity: entityType } = schema;

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const forceRequestFields: Project = useMemo<Project>(() => {
      if (!user.isMaster) return props.forceRequestFields;
      return {
        ...props.forceRequestFields,
        projectAccessGroups: [
          {
            id: null,
          },
        ],
      };
    }, [props.forceRequestFields, user.isMaster]);

    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Project,
      IProjectTableCustomColumns
    >({
      schema,
      defaultSort,
      forceRequestFields,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.projectStatus?.closedStatus === -1,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { fileActionDefinitions, openInNewTab } = useFileActions({
      entity: entityType,
    });

    const { planCalendarActionDefinitions } = useProjectPlanningActions();

    const actions = useMemo(() => {
      return {
        openInNewTab: openInNewTab,
        custom: {
          left: [...fileActionDefinitions, ...planCalendarActionDefinitions],
        },
        createWidget: false,
      };
    }, [fileActionDefinitions, planCalendarActionDefinitions, openInNewTab]);

    const bottomArea = useProjectBottomArea({
      selectedEntities: dataTable.selectedEntities,
    });

    const areas = useMemo<TableAreas>(() => {
      return {
        left: {
          title: t('COMMON.ORGANISATION'),
          content: <OrganisationArea entity={Entities.project} />,
          resizable: true,
          collapsible: true,
          custom: true,
        },
        bottom: bottomArea,
      };
    }, [bottomArea, t]);

    const presetFields = useStringifyTableGroupFilter<Project>({
      groupKey: 'group',
      groupIdKey: 'groupId',
    });

    const onOpenMask = useParameterizedOpenMask({
      entityType: Entities.project,
      params: { create: { presetFields } },
    });

    return (
      <OpenMaskOverride value={{ onOpenMask }}>
        <EntityTable
          ref={ref}
          layout={layout}
          areas={areas}
          actions={actions}
          {...dataTable}
          prepareRowDisplayModifiers={rowModifiers}
          {...props}
        />
      </OpenMaskOverride>
    );
  }
);
