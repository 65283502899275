import i18next from 'i18next';
import { Settings } from 'luxon';

import { throwInDev } from '../throw-in-dev';

export const fallbackLng = {
  en: 'en-GB',
  de: 'de',
};

export const initializeLuxonConfig = () => {
  // Set the Luxon locale based on the current i18next language
  Settings.defaultLocale = getLanguage(i18next.language);

  // Listen for changes to the i18next language
  i18next.on('languageChanged', (lng) => {
    if (!fallbackLng[lng]) {
      throwInDev(
        'Unsupported language. No Luxon configuration for this language available'
      );
    }
    Settings.defaultLocale = getLanguage(lng);
  });
};

const getLanguage = (lng: string) => fallbackLng[lng] || i18next.language;
