import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { IdArray } from '../edit-table/types';

interface SelectedPositionProviderState {
  selectedPositionIds?: IdArray;
  setSelectedPositionIds?: (input: IdArray) => void;
}

const SelectedPositionContext =
  createContext<SelectedPositionProviderState>(null);

export const useSelectedPositionState = () => {
  const context = useContext(SelectedPositionContext);
  if (context === null)
    throw new Error(
      'useSelectedPosition must be used within a <SelectedPositionProvider>'
    );
  return context;
};

export const SelectedPositionProvider = (props: PropsWithChildren<unknown>) => {
  const [selectedPositionIds, setSelectedPositionIdsInternal] =
    useState<IdArray>([]);

  const setSelectedPositionIds = useCallback((newIds: IdArray) => {
    if (Array.isArray(newIds)) setSelectedPositionIdsInternal(newIds);
  }, []);

  const value = useMemo(() => {
    return { selectedPositionIds, setSelectedPositionIds };
  }, [selectedPositionIds, setSelectedPositionIds]);

  return (
    <SelectedPositionContext.Provider value={value}>
      {props.children}
    </SelectedPositionContext.Provider>
  );
};
