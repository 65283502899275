import styles from './EntityPicker.module.scss';

import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type InboundDeliveryNotePickerProps<TMultiple extends boolean> =
  IPickerProps<InboundDeliveryNote, TMultiple>;

export function InboundDeliveryNotePicker<TMultiple extends boolean>(
  props: InboundDeliveryNotePickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...INBOUND_DELIVERY_NOTE_DATA, ...data };
  }, [data]);

  const renderItem = (item: InboundDeliveryNote) => {
    const label = item.note;

    return (
      <Tooltip title={label}>
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Typography variant="body2" noWrap flex="3">
            {label}
          </Typography>
          <Typography
            variant="body2"
            noWrap
            className={styles.secondaryInfo}
            flex="1"
          >
            {item.number}
          </Typography>
          <Typography variant="body2" noWrap flex="1">
            {DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_SHORT)}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.inboundDeliveryNote}
      data={allFields}
      filterBy={['number', 'note']}
      sortBy="date"
      sortByDirection={SortDirection.DESCENDING}
      {...rest}
      renderItemContent={renderItem}
      renderChipContent={(el: InboundDeliveryNote) => el.note}
    />
  );
}

const INBOUND_DELIVERY_NOTE_DATA: InboundDeliveryNote = {
  id: null,
  date: null,
  number: null,
  note: null,
};
