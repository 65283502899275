import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  selectURI,
  split,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as ReactSentry from '@sentry/react';
import { GraphQLError, print } from 'graphql';
import i18next from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { SentryActions } from '@work4all/components';

import { useUser } from '@work4all/data';
import {
  logoutUser,
  refreshUserMeta,
} from '@work4all/data/lib/actions/user-actions';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { useCustomSnackbar } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { BatchableContext } from '@work4all/models/lib/DataProvider';

import { formatErrorDetails } from '@work4all/utils/lib/formatErrorDetails';
import { getDebugInfoParts } from '@work4all/utils/lib/getDebugInfoParts';

import { useApiVersionContext } from '../app-updates/api-version-context';
import { useApiVersionLinkContext } from '../app-updates/api-version-link-context';

import { useTokenRefresher } from './hooks/useTokenRefresher';
import { createAuthLink } from './utils/createAuthLink';
import {
  createTokenRefreshLink,
  isAuthorizationError,
} from './utils/createTokenRefreshLink';
import { timeZoneLink } from './utils/timeZoneLink';
import { IGNORED_CODES, ValidationErrors } from './ValidationErrors';

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
  children: JSX.Element;
}
let uniqueBatchCount = 0;

export const Apollo: React.FC<Props> = (props) => {
  const user = useUser();
  const { activeTenant } = useTenant();

  const dispatch = useDispatch();
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(props.client);
  const [initing, setIniting] = useState(true);

  const { enqueueSnackbar, closeSnackbar, hideApiErrors } = useCustomSnackbar();

  const refreshToken = useTokenRefresher();

  const { link: apiVersionCheckLink } = useApiVersionLinkContext();

  const customerNumber = user?.kundennummer;

  const { version } = useApiVersionContext();

  const debugInfoData = useMemo(() => {
    return getDebugInfoParts({ customerNumber, apiVersion: version });
  }, [customerNumber, version]);

  const previousUserInfoRef = useRef<{
    userId: number | null;
    tenant: number | null;
  } | null>(null);

  // Keep a reference to the websocket client so we can re-use it when creating
  // the ws link.
  const wsClientStateRef = useRef<{
    uri: string;
    client: SubscriptionClient;
    params: Record<string, string>;
  } | null>(null);

  useEffect(() => {
    return () => {
      // Close the websocket client if the component unmounts
      if (wsClientStateRef.current) {
        wsClientStateRef.current.client.close();
        wsClientStateRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!user || !activeTenant) {
      // Reset client cache when a user logs out.
      if (previousUserInfoRef.current !== null) {
        props.client.cache.reset();
      }

      // Close the websocket client if a user logs out.
      if (wsClientStateRef.current) {
        wsClientStateRef.current.client.close();
        wsClientStateRef.current = null;
      }

      previousUserInfoRef.current = null;

      setClient(new ApolloClient({ cache: new InMemoryCache() }));
      setIniting(false);
      return;
    }

    // Log any GraphQL errors or network error that occurred
    const errorLink = onError((response) => {
      const context = response.operation.getContext() as BatchableContext;
      const { hideError = false } = context;

      const { graphQLErrors } = response;
      //default inform about errors except validation errors as they are handled by the tokenrefreseher
      const handleError = (
        e: GraphQLError | NetworkError,
        message = i18next.t('ERROR.COULD_NOT_REACH_SERVICE'),
        createSentryEvent = true
      ) => {
        let eventId = null;

        const renderAction = (key) => {
          if (!createSentryEvent) {
            return (
              <IconButton
                sx={{
                  color: 'var(--ui01)',
                }}
                onClick={() => closeSnackbar(key)}
              >
                <Close />
              </IconButton>
            );
          }

          const errorDetails = formatErrorDetails(eventId, debugInfoData);
          return (
            <SentryActions
              onClose={() => closeSnackbar(key)}
              eventId={eventId}
              debugInfoData={errorDetails}
            />
          );
        };
        if (
          graphQLErrors &&
          ValidationErrors.shouldCaptureError(graphQLErrors) &&
          !hideApiErrors
        ) {
          eventId = ReactSentry.captureException(
            new Error(
              `${e.message}${'extensions' in e ? `:${e.extensions.code}` : ''}`
            ),
            (scope) => {
              scope.setTag('operationName', response.operation.operationName);
              scope.setExtra('query', print(response.operation.query));
              scope.setExtra('variables', response.operation.variables);
              return scope;
            }
          );

          if (!hideError && ValidationErrors.shouldShowError(graphQLErrors))
            enqueueSnackbar(message, {
              variant: 'error',
              action: renderAction,
            });
          else console.error('Sentry.eventId', eventId);
        }
      };

      if (graphQLErrors) {
        graphQLErrors
          .filter((x) => !IGNORED_CODES.includes(x.extensions?.code))
          .forEach((err) => {
            console.error('[GraphQL error]:', err);
            if (ValidationErrors.hasTranslation(err)) {
              handleError(
                err,
                ValidationErrors.translateError(err.extensions?.code)
              );
            } else {
              handleError(err);
            }
          });
      }

      if (!isAuthorizationError(response)) {
        console.error(`[Network error]: ${response.networkError}`);
        if (response.networkError) handleError(response.networkError);
      }
    });

    const authLink = createAuthLink(user.token, activeTenant, user.baseUrl);
    const tokenRefreshLink = createTokenRefreshLink({
      refreshToken,
      onError: () => {
        dispatch(logoutUser());
      },
    });

    const wsBaseUrl = user.baseUrl.replace('http', 'ws');
    const wsUri = `${wsBaseUrl}/graphql`;

    // Close the websocket client if the API URL has changed.
    if (wsClientStateRef.current && wsClientStateRef.current.uri !== wsUri) {
      wsClientStateRef.current.client.close();
      wsClientStateRef.current = null;
    }

    // Initialize the websocket client if it hasn't been initialized yet.
    if (!wsClientStateRef.current) {
      wsClientStateRef.current = {
        uri: wsUri,
        client: new SubscriptionClient(wsUri, {
          reconnect: true,
          connectionParams: () => wsClientStateRef.current?.params,
        }),
        params: {},
      };
    }

    // Update the websocket client's connection parameters.
    wsClientStateRef.current.params = {
      authorization: `bearer ${user.token}`,
      'x-work4all-mandant': `${activeTenant}`,
    };

    const wsLink = new WebSocketLink(wsClientStateRef.current.client);

    const httpLink = ApolloLink.from([
      timeZoneLink,
      authLink,
      errorLink,
      tokenRefreshLink,
      apiVersionCheckLink,
      new BatchHttpLink({
        uri: `${user.baseUrl}/graphql`,
        batchKey: (operation: Operation) => {
          const context = operation.getContext() as BatchableContext;
          const { batch = true } = context;

          const contextConfig = {
            http: context.http,
            options: context.fetchOptions,
            credentials: context.credentials,
            headers: context.headers,
          };

          let unifier = JSON.stringify(contextConfig);
          const batchingEnabled =
            !window.dev.batchModeDisabled && batch === true;
          if (!batchingEnabled) {
            unifier =
              typeof context.batch === 'string'
                ? context.batch //string -> use the batchgroup name provided
                : `${operation.operationName}-${uniqueBatchCount++}`; //false -> default to a unique batch group name
          }

          //may throw error if config not serializable
          return selectURI(operation, `${user.baseUrl}/graphql`) + unifier;
        },
      }),
    ]);

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const previousUserInfo = previousUserInfoRef.current;

    // Reset cache if user or tenant changed.
    if (
      previousUserInfo !== null &&
      (user.benutzerCode !== previousUserInfo.userId ||
        activeTenant !== previousUserInfo.tenant)
    ) {
      props.client.cache.reset();
    }

    previousUserInfoRef.current = {
      userId: user.benutzerCode,
      tenant: activeTenant,
    };

    props.client.setLink(link);

    setClient(props.client);
    setIniting(false);
  }, [
    dispatch,
    refreshToken,
    user,
    debugInfoData,
    props.client,
    enqueueSnackbar,
    closeSnackbar,
    activeTenant,
    apiVersionCheckLink,
    hideApiErrors,
  ]);

  useEffect(() => {
    if (client && typeof user?.benutzerCode === 'number' && activeTenant) {
      dispatch(refreshUserMeta(user.benutzerCode));
    }
  }, [client, dispatch, user?.benutzerCode, activeTenant]);

  if (initing) {
    return null;
  }

  if (!client) {
    return props.children;
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
