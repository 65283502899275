import React, { useContext } from 'react';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useNavigate } from '@work4all/data';

import { addDateParamToUrl } from '../../add-date-param-to-link';
import { PopoverList } from '../popover/PopoverList';

import { LinksOverflowContext } from './links-overflow-context';
import { OverflowFolderView } from './OveflowFolderView';

export interface IOverflowRootViewProps {
  onClose: () => void;
}

export function OverflowRootView({ onClose }: IOverflowRootViewProps) {
  const { offset, items, activeLink } = useContext(LinksOverflowContext);

  const navigate = useNavigate();
  const { go } = useHistoryStack();

  return (
    <PopoverList
      startIndex={offset}
      items={items}
      activeLink={activeLink}
      onLinkClick={(link) => {
        navigate(addDateParamToUrl(link.href));
        onClose();
      }}
      onFolderClick={(folder) => {
        go({
          title: folder.name,
          view: <OverflowFolderView folderId={folder.id} onClose={onClose} />,
        });
      }}
    />
  );
}
