import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntitySuggestionPickerField } from '../../../../../../../../../../components/entity-picker/EntitySuggestionPickerField';
import { SalutationPickerField } from '../../../../../../../../../../components/entity-picker/SalutationPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ContactMaskFormValue } from '../../../../../types';

export const PersonalData: React.FC = () => {
  const { t } = useTranslation();
  const { control, register } = useFormContextPlus<ContactMaskFormValue>();

  return (
    <Collapse defaultOpen={true} title={t('MASK.PERSONAL_DATA')}>
      <ControlWrapper>
        <ControllerPlus
          name="salutation"
          control={control}
          render={({ field }) => {
            return <SalutationPickerField {...field} />;
          }}
        />
        <ControllerPlus
          name={'titleExtension'}
          control={control}
          render={({ field }) => {
            return (
              <EntitySuggestionPickerField
                label={t('INPUTS.TITLE_EXTENSION')}
                {...field}
                entity={Entities.contact}
                field="titleExtension"
              />
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput label={t('INPUTS.NAME')} {...register('firstName')} />
        <LabeledInput label={t('INPUTS.LAST_NAME')} {...register('name')} />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.NAME_EXTENSION')}
          {...register('nameExtension')}
        />
        <LabeledInput
          label={t('INPUTS.ADDRESS_EXTENSION')}
          {...register('addressExtension')}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.GREETING_LETTER_EMAIL')}
          {...register('letterSalutation')}
        />
        <DateTimeInputPicker
          withTime={false}
          required={false}
          dateLabel={t('INPUTS.BIRTH_DATE')}
          {...register('birthdayDate')}
        />
      </ControlWrapper>
    </Collapse>
  );
};
