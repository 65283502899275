import { DoSomePermissionRulesApplyFunction } from './types';

export const doSomePermissionRulesApply: DoSomePermissionRulesApplyFunction = ({
  rights,
  permission,
  permissionRules = {},
}) => {
  const permissionRulesKeys = Object.keys(permissionRules);

  if (permissionRulesKeys.length === 0) {
    return false;
  }

  return permissionRulesKeys.some((key) => {
    const rule = permissionRules[key];

    if (!rule) {
      return false;
    }

    const value = rule?.applyWhenValue;
    const permissions = rule?.applyWithPermissions ?? [];

    return value === rights[key] && permissions.includes(permission);
  });
};
