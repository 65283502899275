import { DateTime } from 'luxon';
import React, { useMemo, useRef } from 'react';

import { reactRefSetter } from '@work4all/utils';

import { useInputState } from '../date-time-input-picker/hooks/useInputState';
import { triggerChangeEvent } from '../date-time-input-picker/hooks/useOnChangeTrigger';
import { usePickerVisibilityState } from '../date-time-input-picker/hooks/usePickerVisibilityState';
import { LabeledTimeInputWithDropdown } from '../labeled-time-input';

import { timeUtils } from './timeUtils';
import { ITimeInputPickerProps } from './types';

export interface TimeInputPickerProps extends ITimeInputPickerProps {
  rootClassName?: string;
  onClear?: () => void;
  onDestroyPopover?: () => void;
}

export const TimeInputPicker = React.forwardRef<
  HTMLInputElement,
  TimeInputPickerProps
>(function TimeInputPicker(props, ref) {
  const inputRef = useRef<HTMLInputElement>();
  const labeledTimeInputRef = useRef<HTMLInputElement>();
  const anchorElRef = useRef<HTMLDivElement>();

  const { label, value, onClear, onDestroyPopover, ...inputProps } = props;

  const {
    showPicker: showTimePicker,
    onShowPicker: onShowTimePicker,
    onHidePicker: onHideTimePicker,
  } = usePickerVisibilityState();

  const {
    date: time,
    setDate: setTime,
    dateString: timeString,
    ...timeInputProps
  } = useInputState(
    timeUtils.validateTimeString,
    timeUtils.timeToString,
    value,
    (time) => emitChange(time)
  );

  const date = useMemo(
    () => value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value?.toFormat('dd.mm.yy')]
  );

  // Emit change event on the input when date/time is changed.
  function emitChange(time: DateTime) {
    triggerChangeEvent(value, inputRef.current, date, time);
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (
      event.key === 'Enter' ||
      event.key === 'Escape' ||
      event.key === 'Tab'
    ) {
      onHideTimePicker();
    }
  }

  return (
    <div ref={anchorElRef} className={props.rootClassName}>
      <input
        ref={reactRefSetter(ref, inputRef)}
        {...inputProps}
        hidden
        value={timeUtils.timeToString(value)}
      />
      <LabeledTimeInputWithDropdown
        disabled={inputProps.disabled}
        className={inputProps.className}
        showDropdown={showTimePicker}
        onHideDropdown={onHideTimePicker}
        onShowDropdown={onShowTimePicker}
        label={label}
        anchorEl={anchorElRef.current}
        value={timeString}
        inputProps={{
          ref: labeledTimeInputRef,
        }}
        onTimeSelect={(time) => {
          setTime(time);
          emitChange(time);
          labeledTimeInputRef?.current?.focus();
        }}
        onKeyDown={handleKeyDown}
        onClear={onClear}
        onDestroyPopover={onDestroyPopover}
        {...timeInputProps}
      />
    </div>
  );
});
