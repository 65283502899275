import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export enum ErpStatus {
  Open = 'OPEN',
  Frozen = 'FROZEN',
  FrozenAndClosed = 'FROZEN_AND_LOCKED',
}

export type EnumPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<{ id: string; name: string }, TMultiple>,
  'onChange' | 'value'
> & {
  value: string;
  onChange: (input: string) => void;
  values: string[];
};

export function EnumPicker<TMultiple extends boolean>(
  props: EnumPickerProps<TMultiple>
) {
  const { values, value, onChange, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = values.map((x) => ({
    id: x,
    name: t(`ENUMS.${x.toUpperCase()}`),
  }));

  const resolvedValue = dataSet.find((x) => x.id === value);

  const handleOnChange = (newValue) => {
    onChange(newValue?.id);
  };

  return (
    <FixedDataPicker
      // @ts-expect-error TECH_DEBT
      value={resolvedValue}
      onChange={handleOnChange}
      dataSet={dataSet}
      {...rest}
    />
  );
}
