import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { DeliveryKindPickerField } from '../../../../../../../../../../components/entity-picker/DeliveryKindPickerField';
import { TourPickerField } from '../../../../../../../../../../components/entity-picker/TourPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BPMaskFormValue } from '../../../../../types';

export const Delivery: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContextPlus<BPMaskFormValue>();

  const mask = useMaskContext();

  const currentadditionalAddress1: BusinessPartnerContactCombined =
    watch('additionalAddress1');

  return (
    <Collapse defaultOpen={true} title={t('MASK.DELIVERY')}>
      <ControlWrapper>
        <ControllerPlus
          name="additionalAddress1"
          control={control}
          render={({ field: adAddField, fieldState: addFieldState }) => {
            return (
              <ControllerPlus
                name="additionalAddress1.contact"
                control={control}
                render={({
                  field: contactField,
                  fieldState: contactFieldState,
                }) => {
                  return (
                    <MultiStepControls>
                      <Step active={true} index={0}>
                        <BusinessPartnerPickerField
                          {...adAddField}
                          error={addFieldState?.error?.message}
                          label={t('INPUTS.DELIVERY_ADDRESS')}
                          value={
                            (
                              adAddField?.value as BusinessPartnerContactCombined
                            )?.businessPartner?.data
                          }
                          onChange={(value) => {
                            contactField.onChange(
                              value?.data?.mainContact ?? null
                            );
                            const businessPartner = value?.data;

                            if (businessPartner) {
                              const updatedValue: BusinessPartnerContactCombined =
                                {
                                  businessPartner: {
                                    businessPartnerType:
                                      businessPartner.__typename === 'Kunde'
                                        ? SdObjType.KUNDE
                                        : SdObjType.LIEFERANT,
                                    data: businessPartner,
                                    id: businessPartner?.id,
                                  },
                                };
                              adAddField.onChange(updatedValue);
                            } else {
                              adAddField.onChange(null);
                            }
                          }}
                        />
                      </Step>
                      <Step
                        active={
                          adAddField.value !== undefined &&
                          adAddField.value !== null &&
                          !currentadditionalAddress1?.businessPartner?.data
                            ?.isPrivateCustomer
                        }
                        index={1}
                      >
                        <ContactPickerField
                          {...contactField}
                          businessPartnerId={
                            currentadditionalAddress1?.businessPartner?.id
                          }
                          businessPartnerType={
                            currentadditionalAddress1?.businessPartner
                              ?.businessPartnerType
                          }
                          error={contactFieldState?.error?.message}
                          value={contactField.value}
                        />
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            );
          }}
        />

        <ControllerPlus
          name="deliveryKind"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DeliveryKindPickerField
                {...field}
                error={fieldState?.error?.message}
              />
            );
          }}
        />
        {mask.entity === Entities.customer && (
          <ControllerPlus
            name="tour"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TourPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}
        {mask.entity === Entities.supplier && <div>&nbsp;</div>}
      </ControlWrapper>
    </Collapse>
  );
};
