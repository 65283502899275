import React, { useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';

import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/offer-table-schema.json';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';

import { useCloneConvertTableErpActions } from './hooks/use-clone-convert-erp';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const OffersTable = React.forwardRef<TableInstance, IEntityTable>(
  function OffersTable(_props, ref) {
    const template = useErpHandlersTemplate();
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Offer,
      never
    >({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
    });

    const rowModifiers = useCallback(
      (value: Offer) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { actions } = useCloneConvertTableErpActions({
      entity: Entities.offer,
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        actions={{
          custom,
        }}
        displayFooter
        prepareRowDisplayModifiers={rowModifiers}
      />
    );
  }
);
