import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@work4all/data';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { GeneralTabPanel } from './components/general/GeneralTabPanel';
import { PasswordChangeButton } from './components/password-change/PasswordChangeButton';
import { PersonalTabPanel } from './components/personal/PersonalTabPanel';
import { WorkTimeTabPanel } from './components/work-time/WorkTimeTabPanel';
import { UserFormValue } from './types';
import { useUserRequest } from './use-user-request';

const newEntityData: FullUser = {};

export const UserOverlayController: React.FC<MaskControllerProps> = (props) => {
  const mask = useMaskConfig(props);
  const request = useUserRequest({ id: mask.id });

  const overlay = useMaskOverlay<UserFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.displayName,
  });

  const [mutate] = useDataMutation<FullUser, EMode.upsert>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
  });

  const onSubmit = async (formData: UserFormValue) => {
    const toModify = pickUpdateFields(
      formData,
      overlay.form.formState.dirtyFields
    );
    mutate(toModify);
  };

  return (
    <OverlayController<UserFormValue>
      {...overlay}
      onSubmit={onSubmit}
      actions={<PasswordChangeButton />}
      tabs={<Tabs />}
    >
      <Content />
    </OverlayController>
  );
};

const Tabs = memo(function () {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')} />
      <MaskTab value="misc" label={t('USER.PERSONAL_DATA_TAB')} />
      <MaskTab value="prices" label={t('USER.WORK_TIME_TAB')} />
    </MaskTabs>
  );
});

const Content = memo(function () {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="misc">
        <PersonalTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="prices">
        <WorkTimeTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});
