import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { FileInfoFragment } from './FileInfoFragment';

export const DocumentFileFragment: Document<EMode.query> = {
  thumbnail: {
    id: null,
    filename: null,
    fileInfos: {
      ...FileInfoFragment,
    },
  },
  fileInfos: {
    ...FileInfoFragment,
  },
  noteHtml: null,
};
