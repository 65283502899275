import { useEffect } from 'react';

import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { TicketPreview } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreview';
import { TicketPreviewFields } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreviewFields';
import { EntityPreviewProps } from '@work4all/components/lib/components/entity-preview/types';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityActions } from '../../../../../../components/data-tables/entity-table/EntityActionProvider';
import { useTicketCustomActions } from '../use-ticket-custom-actions';

interface TicketPreviewContainerProps extends EntityPreviewProps<string> {}

export function TicketPreviewContainer(props: TicketPreviewContainerProps) {
  const { ids: ticketIds, ...handlers } = props;
  const tickets = useEntityPreviewData<Ticket>(
    ticketIds,
    Entities.ticket,
    TicketPreviewFields
  );

  const left = useTicketCustomActions({
    tickets,
  });

  const { addActions } = useEntityActions();

  useEffect(() => {
    addActions({
      left,
    });
  }, [left, addActions]);

  if (!tickets) {
    return null;
  }

  return <TicketPreview {...handlers} tickets={tickets} />;
}
