import styles from '../StatisticPage.module.scss';
import dashboardStyles from './dashboard/Dashboard.module.scss';

import { Grid, LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { CurrencyCell } from '@work4all/components';
import { getStatisticFormatedDate } from '@work4all/components/lib/dataDisplay/basic-table/reporting/getStatisticFormatedDate';

import { useDataProvider } from '@work4all/data';

import { CompanyKpis } from '@work4all/models/lib/Classes/CompanyKpis.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../settings';
import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

import { DashboardCalcCard } from './dashboard/DashboardCalcCard';
import { DashboardTableCard } from './dashboard/DashboardTableCard';
import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';

export function StatisticDashboardContainer() {
  const { t } = useTranslation();

  const [selectedMonth, setSelectedMonth] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(400)),
    DateTime.now().startOf('month').toJSDate()
  );

  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });

  const statisticConsiderInvoiceLedger = useSetting(
    settings.statisticConsiderInvoiceLedger()
  );

  const [calcEoMSalesAmountYear, setCalcEoMSalesAmountYear] = useState(true);
  const [calcEoMSalesAmountMonth, setCalcEoMSalesAmountMonth] = useState(true);
  const [calcEoMOrderAmountYear, setCalcEoMOrderAmountYear] = useState(true);
  const [calcEoMOrderAmountMonth, setCalcEoMOrderAmountMonth] = useState(true);

  const requestData: DataRequest = useMemo(() => {
    const data: CompanyKpis = {
      amountOpenOpportunities: null,
      amountOpenOpportunitiesRated: null,
      amountVacantPositionsCustomer: null,
      amountVacantPositionsCustomerDue: null,
      amountVacantPositionsSupplier: null,
      amountVacantPositionsSupplierDue: null,
      countNewCustomerFrom30kCurrentYear: null,
      countNewCustomerFrom30kLastYear: null,
      countNewCustomerUpTo10kLastYear: null,
      countNewCustomerUpTo1kLastYear: null,
      countNewCustomerUpTo30kLastYear: null,
      countNewCustomerUpTo10kCurrentYear: null,
      countNewCustomerUpTo1kCurrentYear: null,
      countNewCustomerUpTo30kCurrentYear: null,
      countOpenOffers: null,
      countOpenOpportunities: null,
      countProspectiveCustomerCurrentYear: null,
      countProspectiveCustomerLastYear: null,
      holidaysCurrentYear: null,
      holidaysLastYear: null,
      holidaysLastYearUntilDate: null,
      incommingOrdersAmountCurrentMonth: null,
      incommingOrdersAmountCurrentMonthLastYear: null,
      incommingOrdersAmountCurrentYear: null,
      incommingOrdersAmountLastYear: null,
      incommingOrdersAmountVariationMonthPercent: null,
      incommingOrdersAmountVariationYearPercent: null,
      salesAmountCurrentMonth: null,
      salesAmountCurrentMonthLastYear: null,
      salesAmountCurrentYear: null,
      salesAmountLastYear: null,
      salesAmountNewCustomerCurrentYear: null,
      salesAmountNewCustomerLastYear: null,
      salesAmountVariationMonthPercent: null,
      salesAmountVariationYearPercent: null,
      sickDaysCurrentYear: null,
      sickDaysLastYear: null,
      sickDaysLastYearUntilDate: null,
      timeTrackingProjectHoursCurrentYear: null,
      timeTrackingProjectLastYearUntilDate: null,
    };

    return {
      entity: Entities.companyKpis,
      data: data,
      vars: {
        onlyRa: statisticConsiderInvoiceLedger.value,
        date: selectedMonth,
        calcEoMSalesAmountYear,
        calcEoMSalesAmountMonth,
        calcEoMOrderAmountYear,
        calcEoMOrderAmountMonth,
      },
      completeDataResponse: true,
    };
  }, [
    statisticConsiderInvoiceLedger.value,
    selectedMonth,
    calcEoMSalesAmountYear,
    calcEoMSalesAmountMonth,
    calcEoMOrderAmountYear,
    calcEoMOrderAmountMonth,
  ]);

  const data = useDataProvider(requestData);

  const isLoading = data.loading || data.pending;

  const oldestInvoice = useOldestInvoice();

  const renderBusinessYear = useCallback(
    (offset: number, date: Date = selectedMonth) => {
      let result = DateTime.fromJSDate(date)
        .minus({ year: offset })
        .toFormat('yyyy');

      if (startBusinessYearSetting?.data?.[0]?.value === 1) {
        result = DateTime.fromJSDate(date)
          .minus({ year: offset })
          .toFormat('yy');
      } else if (
        selectedMonth.getMonth() + 1 >=
        startBusinessYearSetting?.data?.[0]?.value
      ) {
        result = DateTime.fromJSDate(date)
          .minus({ year: offset })
          .toFormat('yy');
        result +=
          '/' +
          DateTime.fromJSDate(date)
            .minus({ year: offset })
            .plus({ year: 1 })
            .toFormat('yy');
      } else if (
        selectedMonth.getMonth() + 1 <
        startBusinessYearSetting?.data?.[0]?.value
      ) {
        offset += 1;
        result = DateTime.fromJSDate(date)
          .minus({ year: offset })
          .toFormat('yy');
        result +=
          '/' +
          DateTime.fromJSDate(date)
            .minus({ year: offset })
            .plus({ year: 1 })
            .toFormat('yy');
      }

      return result;
    },
    [selectedMonth, startBusinessYearSetting?.data]
  );

  return (
    <div className={styles.tableControlWrap}>
      <div>
        {isLoading && (
          <LinearProgress
            style={{ position: 'absolute', width: '100%', zIndex: 200 }}
          />
        )}
      </div>
      <div className={styles.controlBar}>
        <div className={styles.picker}>
          <DateWheelPickerField
            min={
              oldestInvoice?.data?.[0]?.date
                ? new Date(oldestInvoice?.data?.[0]?.date)
                : new Date()
            }
            max={new Date()}
            unit="month"
            defaultValue={selectedMonth}
            onChange={(value) => {
              setSelectedMonth(value);
            }}
          />
        </div>
      </div>

      <div className={dashboardStyles.dashboardWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardCalcCard
              isLoading={isLoading}
              label={`${t('DASHBOARD.SALES')} ${DateTime.fromJSDate(
                selectedMonth
              ).toFormat('MMMM yyyy')}`}
              onChange={(value) => {
                setCalcEoMSalesAmountMonth(value);
              }}
              checkBoxValue={calcEoMSalesAmountMonth}
              currentValue={
                (data?.data as CompanyKpis)?.salesAmountCurrentMonth
              }
              previousValue={
                (data?.data as CompanyKpis)?.salesAmountCurrentMonthLastYear
              }
              variation={
                (data?.data as CompanyKpis)?.salesAmountVariationMonthPercent
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardCalcCard
              isLoading={isLoading}
              label={`${t('DASHBOARD.SALES_FISCAL_YEAR')} ${renderBusinessYear(
                0
              )}`}
              onChange={(value) => {
                setCalcEoMSalesAmountYear(value);
              }}
              checkBoxValue={calcEoMSalesAmountYear}
              currentValue={(data?.data as CompanyKpis)?.salesAmountCurrentYear}
              previousValue={(data?.data as CompanyKpis)?.salesAmountLastYear}
              variation={
                (data?.data as CompanyKpis)?.salesAmountVariationYearPercent
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardCalcCard
              isLoading={isLoading}
              label={`${t(
                'DASHBOARD.INCOMING_ORDERS'
              )} ${getStatisticFormatedDate(selectedMonth, 'MMMM yyyy')} `}
              onChange={(value) => {
                setCalcEoMOrderAmountMonth(value);
              }}
              checkBoxValue={calcEoMOrderAmountMonth}
              currentValue={
                (data?.data as CompanyKpis)?.incommingOrdersAmountCurrentMonth
              }
              previousValue={
                (data?.data as CompanyKpis)
                  ?.incommingOrdersAmountCurrentMonthLastYear
              }
              variation={
                (data?.data as CompanyKpis)
                  ?.incommingOrdersAmountVariationMonthPercent
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardCalcCard
              isLoading={isLoading}
              label={`${t(
                'DASHBOARD.INCOMING_ORDERS_FISCAL_YEAR'
              )} ${renderBusinessYear(0)}`}
              onChange={(value) => {
                setCalcEoMOrderAmountYear(value);
              }}
              checkBoxValue={calcEoMOrderAmountYear}
              currentValue={
                (data?.data as CompanyKpis)?.incommingOrdersAmountCurrentYear
              }
              previousValue={
                (data?.data as CompanyKpis)?.incommingOrdersAmountLastYear
              }
              variation={
                (data?.data as CompanyKpis)
                  ?.incommingOrdersAmountVariationYearPercent
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardTableCard
              isLoading={isLoading}
              label={t('DASHBOARD.EMPLOYEE_KEY_FIGURES')}
              values={[
                {
                  label: t('DASHBOARD.SICK_DAYS'),
                  value: (
                    data?.data as CompanyKpis
                  )?.sickDaysCurrentYear?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: t('DASHBOARD.SICK_DAYS_PREVIOUS_YEAR'),
                  value: (
                    data?.data as CompanyKpis
                  )?.sickDaysLastYearUntilDate?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: t('DASHBOARD.HOLIDAYS'),
                  value: (
                    data?.data as CompanyKpis
                  )?.holidaysCurrentYear?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: t('DASHBOARD.HOLIDAYS_PREVIOUS_YEAR'),
                  value: (
                    data?.data as CompanyKpis
                  )?.holidaysLastYearUntilDate?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: t('DASHBOARD.PROJECT_HOURS'),
                  value: (
                    data?.data as CompanyKpis
                  )?.timeTrackingProjectHoursCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: t('DASHBOARD.PROJECT_HOURS_PREVIOUS_YEAR'),
                  value: (
                    data?.data as CompanyKpis
                  )?.timeTrackingProjectLastYearUntilDate?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardTableCard
              isLoading={isLoading}
              label={t('DASHBOARD.PROSPECTIVE_CUSTOMER')}
              values={[
                {
                  label: `${t(
                    'DASHBOARD.COUNT_PROSPECTIVE_CUSTOMER'
                  )} ${renderBusinessYear(0)}`,

                  value: (
                    data?.data as CompanyKpis
                  )?.countProspectiveCustomerCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.COUNT_PROSPECTIVE_CUSTOMER'
                  )} ${renderBusinessYear(1)}`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countProspectiveCustomerLastYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t('DASHBOARD.COUNT_OPEN_OFFERS')}`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countOpenOffers?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: `${t('DASHBOARD.COUNT_OPEN_OPPORTUNITIES')}`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countOpenOpportunities?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: `${t('DASHBOARD.SUM_OPEN_OPPORTUNITIES')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)?.amountOpenOpportunities
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t('DASHBOARD.SUM_OPEN_OPPORTUNITIES_RATED')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.amountOpenOpportunitiesRated
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardTableCard
              label={t('DASHBOARD.NEW_CUSTOMERS')}
              isLoading={isLoading}
              values={[
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES'
                  )} ${renderBusinessYear(0)}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.salesAmountNewCustomerCurrentYear
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 1.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo1kCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 10.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo10kCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 30.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo30kCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_BIGGER_THAN_VALUE'
                  )} 30.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerFrom30kCurrentYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t('DASHBOARD.NEW_CUSTOMERS_SALES')} 
                  ${renderBusinessYear(1)}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.salesAmountNewCustomerLastYear
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 1.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo1kLastYear?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  }),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 10.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo10kLastYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_TO_VALUE'
                  )} 30.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerUpTo30kLastYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
                {
                  label: `${t(
                    'DASHBOARD.NEW_CUSTOMERS_SALES_BIGGER_THAN_VALUE'
                  )} 30.000 €`,
                  value: (
                    data?.data as CompanyKpis
                  )?.countNewCustomerFrom30kLastYear?.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <DashboardTableCard
              isLoading={isLoading}
              label={t('DASHBOARD.COMPANY_KEY_FIGURES')}
              values={[
                {
                  label: `${t('DASHBOARD.CUSTOMER_VACANT_POSITIONS')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.amountVacantPositionsCustomer
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t('DASHBOARD.VACANT_POSITIONS_DUE')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.amountVacantPositionsCustomerDue
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t('DASHBOARD.SUPPLIER_VACANT_POSITIONS')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.amountVacantPositionsSupplier
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
                {
                  label: `${t('DASHBOARD.VACANT_POSITIONS_DUE')}`,
                  value: (
                    <CurrencyCell
                      value={
                        (data?.data as CompanyKpis)
                          ?.amountVacantPositionsSupplierDue
                      }
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                    />
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
