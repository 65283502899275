import { createContext } from 'react';

import { BottomViewsContextValue } from './types';

export const BottomViewsContext = createContext<BottomViewsContextValue>({
  views: [],
  isCollapsed: true,
  toggleCollapsibility: () => {},
  activeView: null,
  setActiveViewId: () => {},
});
