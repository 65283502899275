import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { StatisticCustomerDatasheet } from '@work4all/models/lib/Classes/StatisticCustomerDatasheet.entity';
import { StatisticCustomerDatasheetColumn } from '@work4all/models/lib/Classes/StatisticCustomerDatasheetColumn.entity';

export interface Stats {
  stat: StatisticItemRemodeled[];
  artikelData: {
    name: string;
    number: string;
    totalValue: number;
    monthlyData: { date: string; value: number }[];
  }[];
  costCenterData: {
    name: string;
    number: string;
    totalValue: number;
    monthlyData: { date: string; value: number }[];
  }[];
}

interface MonthDetails {
  artikelName: string;
  artikelNumber: string;
  value: number;
}

interface MonthDefinition {
  date: string;
  value: number;
  monthDetails: MonthDetails[];
}

interface StatisticItemRemodeled {
  year: string;
  sum: number;
  months: MonthDefinition[];
}

const getTotalSum = (months: MonthDefinition[]): number =>
  months.reduce((sum, { value }) => sum + value, 0);

function createMonths(
  year,
  statData,
  statName,
  startBusinessYearSetting
): MonthDefinition[] {
  return Array.from({ length: 12 }, (_, i) => {
    const nextMonth = new Date(year, startBusinessYearSetting + i - 1, 1);
    const formattedDate = DateTime.fromJSDate(new Date(nextMonth)).toFormat(
      'yyyy-MM-dd'
    );
    const correspondingData = statData.find(
      (item) => item.month === formattedDate
    );
    const monthDetails: MonthDetails[] = correspondingData
      ? correspondingData.statistics
          .filter((stat) => stat.statisticName === statName)
          .map((stat) => ({
            name: stat.name,
            number: stat.number,
            value: stat.value,
          }))
      : [];
    const value = monthDetails.reduce((sum, detail) => sum + detail.value, 0);

    return { date: formattedDate, value, monthDetails };
  });
}

const remodelSalesData = (
  statData: StatisticCustomerDatasheetColumn[],
  statName: string,
  startBusinessYearSetting: number,
  fiscalYears: number[]
): StatisticItemRemodeled[] => {
  const selectedYears = fiscalYears;
  return selectedYears.map((year) => ({
    year: String(year),
    months: createMonths(year, statData, statName, startBusinessYearSetting),
    sum: getTotalSum(
      createMonths(year, statData, statName, startBusinessYearSetting)
    ),
  }));
};

function extractDetailsDataByMonth(
  statData: StatisticCustomerDatasheetColumn[],
  selectedYear: number,
  startBusinessYearSetting: number,
  statName: string
): {
  name: string;
  number: string;
  totalValue: number;
  monthlyData: { date: string; value: number }[];
}[] {
  const uniqueArticlesMap: Record<
    string,
    {
      name: string;
      number: string;
      totalValue: number;
      monthlyData: { date: string; value: number }[];
    }
  > = {};

  const next12Months = Array.from({ length: 12 }, (_, i) => {
    const newMonth = new Date(
      selectedYear,
      startBusinessYearSetting + i - 1,
      1
    );

    return {
      date: DateTime.fromJSDate(new Date(newMonth)).toFormat('yyyy-MM-dd'),
      value: 0,
    };
  });

  statData.forEach((item) => {
    item.statistics.forEach((stat) => {
      if (stat.statisticName === statName) {
        const key = `${stat.name}-${stat.number}`;
        if (!uniqueArticlesMap[key]) {
          uniqueArticlesMap[key] = {
            name: stat.name,
            number: stat.number,
            totalValue: 0,
            monthlyData: next12Months.map((month) => ({ ...month })),
          };
        }
      }
    });
  });

  Object.entries(uniqueArticlesMap).forEach(([key, article]) => {
    statData.forEach((item) => {
      const month = item.month;
      item.statistics.forEach((stat) => {
        if (
          stat.statisticName === statName &&
          `${stat.name}-${stat.number}` === key
        ) {
          const monthEntry = article.monthlyData.find(
            (entry) => entry.date === month
          );
          if (monthEntry) {
            monthEntry.value += stat.value;
          }
          article.totalValue += stat.value;
        }
      });
    });
  });
  return Object.values(uniqueArticlesMap).sort(
    (a, b) => b.totalValue - a.totalValue
  );
}

export const useCustomerSheetData = (
  data: StatisticCustomerDatasheet,
  selectedYear: Date,
  startBusinessYearSetting: number,
  fiscalYears: number[],
  filteredDataSales: StatisticCustomerDatasheetColumn[],
  filteredDataArticle: StatisticCustomerDatasheetColumn[],
  filteredDataCostcenter: StatisticCustomerDatasheetColumn[]
): Stats[] => {
  const result: Stats[] = useMemo<Stats[]>(() => {
    if (data[0] === undefined) {
      return [];
    }
    return [
      {
        stat: remodelSalesData(
          filteredDataSales,
          'Umsätze',
          startBusinessYearSetting,
          fiscalYears
        ),
        artikelData: extractDetailsDataByMonth(
          filteredDataArticle,
          selectedYear.getFullYear(),
          startBusinessYearSetting,
          'Artikel'
        ),
        costCenterData: extractDetailsDataByMonth(
          filteredDataCostcenter,
          selectedYear.getFullYear(),
          startBusinessYearSetting,
          'Kostenstellen'
        ),
      },
    ];
  }, [
    data,
    filteredDataArticle,
    filteredDataCostcenter,
    filteredDataSales,
    fiscalYears,
    selectedYear,
    startBusinessYearSetting,
  ]);

  return result;
};
