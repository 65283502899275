import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { ResourceUtilizationInfo } from '@work4all/models/lib/Classes/ResourceUtilizationInfo.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useResourceInfo = (props: {
  userIdList: number[];
  startDate: Date;
  endDate: Date;
}) => {
  const { userIdList, startDate, endDate } = props;
  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        userId: null,
        user: {
          id: null,
          displayName: null,
        },
        date: null,
        planedValue: null,
        utilizedValue: null,
        absenceList: [
          {
            id: null,
          },
        ],
        vacationList: [
          {
            id: null,
            vacationKind: {
              id: null,
              name: null,
            },
          },
        ],
        appointmentList: [
          {
            id: null,
            title: null,
            startDate: null,
            endDate: null,
            isWholeDay: null,
            appointmentAttendeeList: [
              {
                id: null,
                businessPartner: {
                  id: null,
                  businessPartnerType: null,
                  data: {
                    customer: {
                      id: null,
                      number: null,
                      name: null,
                    },
                    supplier: {
                      id: null,
                      number: null,
                      name: null,
                    },
                  },
                },
              },
            ],
          },
        ],
      } as ResourceUtilizationInfo<EMode.query>,
      entity: Entities.resourceUtilizationInfo,
      completeDataResponse: true,
      vars: {
        userCodes: userIdList,
        startRange: DateTime.fromJSDate(startDate).toISODate(),
        endRange: DateTime.fromJSDate(endDate).toISODate(),
        usePlaningAppointments: true,
        useNonPlaningAppointments: true,
      },
    };
  }, [endDate, startDate, userIdList]);

  return useDataProvider<ResourceUtilizationInfo>(request);
};
