import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { CustomerGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';
import { TypeOfRemuneration } from '@work4all/models/lib/Enums/TypeOfRemuneration.enum';

import { CostCenterPickerField } from '../../../../../../../components/entity-picker/CostCenterPickerField';
import { EnumPickerField } from '../../../../../../../components/entity-picker/EnumPickerField';
import { LookUpPickerField } from '../../../../../../../components/entity-picker/LookUpPickerField';
import { SalutationPickerField } from '../../../../../../../components/entity-picker/SalutationPickerField';
import { SupplierPickerField } from '../../../../../../../components/entity-picker/SupplierPickerField';
import { UserGroupPickerField } from '../../../../../../../components/entity-picker/UserGroupPickerField';
import { UserPickerField } from '../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  PickerTargetButton,
} from '../../../../../locked-inputs';
import { Collapse, ControlWrapper, Panel } from '../../../../components';
import { UserFormValue } from '../../types';

export const GeneralTabPanel = () => {
  const { t } = useTranslation();
  const { register, control } = useFormContextPlus<UserFormValue>();

  return (
    <Panel style={{ height: '100%' }}>
      <Collapse defaultOpen title={t('USER.PERSONAL_DATA')}>
        <Card>
          <ControlWrapper columns={2}>
            <ControllerPlus
              control={control}
              name="salutation"
              render={({ field }) => {
                return (
                  <SalutationPickerField
                    {...field}
                    label={t('INPUTS.LETTER_SALUTATION')}
                  />
                );
              }}
            />

            <ControlWrapper>
              <LabeledInput
                {...register('title')}
                label={t('INPUTS.TITLE_EXTENSION')}
              />
              <LabeledInput
                {...register('shortName')}
                label={t('COMMON.SHORT_NAME')}
              />
            </ControlWrapper>
          </ControlWrapper>
          <ControlWrapper columns={2}>
            <ControlWrapper columns={2} paddingBottom={false}>
              <LabeledInput
                {...register('firstName')}
                label={t('INPUTS.NAME')}
              />
              <LabeledInput
                {...register('lastName')}
                label={t('INPUTS.LAST_NAME')}
              />
            </ControlWrapper>
            <ControlWrapper columns={2} paddingBottom={false}>
              <LabeledInput {...register('role')} label={t('COMMON.ROLE')} />
              <LabeledInput
                {...register('role')}
                label={t('INPUTS.ADDITIONAL_FUNCTION')}
              />
            </ControlWrapper>
          </ControlWrapper>
          <ControlWrapper columns={2}>
            <LabeledInput
              {...register('prefix')}
              label={t('INPUTS.PREFIX_SIGNATURE')}
            />
            <ControlWrapper>
              <CheckboxRadioItem {...register('iA')} label={t('INPUTS.IA')} />
              <CheckboxRadioItem {...register('iV')} label={t('INPUTS.IV')} />
              <CheckboxRadioItem
                {...register('alternativeSalutation')}
                label={t('INPUTS.ALTERNATIVE_SALUTATION')}
              />
            </ControlWrapper>
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.COMMUNICATION')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledInput
              {...register('name')}
              label={t('INPUTS.LOGIN_NAME')}
            />
            <LabeledInput
              {...register('designation')}
              label={t('INPUTS.DESCRIPTION')}
            />
            <LabeledInput {...register('eMail')} label={t('COMMON.EMAIL')} />
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <LabeledInput
              {...register('phoneNumber')}
              label={t('INPUTS.PHONE')}
            />
            <LabeledInput
              {...register('mobileNumber')}
              label={t('INPUTS.MOBILE')}
            />
            <LabeledInput {...register('faxNumber')} label={t('INPUTS.FAX')} />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.ORGANIZATION')}>
        <Card>
          <ControlWrapper columns={2}>
            <ControllerPlus
              control={control}
              name="supplier"
              render={({ field }) => {
                return (
                  <SupplierPickerField
                    label={t('COMMON.SUPPLIER_ASSIGNMENT')}
                    {...field}
                  />
                );
              }}
            />

            <LabeledInput
              {...register('departmentName')}
              label={t('INPUTS.DEPARTMENT')}
            />
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <ControllerPlus
              control={control}
              name="supervisor"
              render={({ field, fieldState }) => {
                return (
                  <UserPickerField
                    {...field}
                    label={t('COMMON.SUPERVISOR')}
                    error={fieldState?.error?.message}
                  />
                );
              }}
            />
            <ControllerPlus
              control={control}
              name="vacationApprover"
              render={({ field, fieldState }) => {
                return (
                  <UserPickerField
                    {...field}
                    label={t('COMMON.VACATION_APPROVER')}
                    error={fieldState?.error?.message}
                  />
                );
              }}
            />

            <ControllerPlus
              control={control}
              name="branch"
              render={({ field }) => {
                return (
                  <LookUpPickerField
                    label={t('INPUTS.BRANCH')}
                    lookupType={LookupType.NIEDERLASSUNGEN}
                    multiple={false}
                    layout="simple"
                    {...field}
                  />
                );
              }}
            />
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <ControllerPlus
              control={control}
              name="group"
              render={({ field }) => {
                return (
                  <UserGroupPickerField label={t('INPUTS.GROUP')} {...field} />
                );
              }}
            />

            <ControllerPlus
              control={control}
              name="costCenter"
              render={({ field }) => {
                return (
                  <CostCenterPickerField
                    label={t('COMMON.COST_CENTER')}
                    {...field}
                  />
                );
              }}
            />
            <ControllerPlus
              control={control}
              name="typeofRemuneration"
              render={({ field }) => {
                return (
                  <EnumPickerField
                    values={Object.values(TypeOfRemuneration)}
                    label={t('INPUTS.TYPE_OF_REMUNERATION')}
                    {...field}
                  />
                );
              }}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.MORE')}>
        <Card>
          <ControlWrapper columns={3}>
            <ControllerPlus
              name="defaultGroupForCustomer"
              control={control}
              render={({ field }) => {
                return (
                  <EntityPickerPopover
                    picker={
                      <CustomerGroupPicker
                        {...field}
                        value={[field?.value]}
                        onChange={(value) => field.onChange(value?.[0] || null)}
                        multiple={false}
                      />
                    }
                  >
                    <PickerTargetButton
                      onClear={() => {
                        field?.onChange(null);
                      }}
                      label={t('INPUTS.STANDARD_GROUP_FOR_CUSTOMERS')}
                      value={field?.value?.name || ''}
                    />
                  </EntityPickerPopover>
                );
              }}
            />

            <ControllerPlus
              control={control}
              name="ressourceClass"
              render={({ field }) => {
                return (
                  <LookUpPickerField
                    label={t('INPUTS.RESORUCE_CLASS')}
                    lookupType={LookupType.RESSOURCENKLASSEN}
                    multiple={false}
                    layout="simple"
                    {...field}
                  />
                );
              }}
            />
          </ControlWrapper>
          <ControlWrapper>
            <LabeledInput
              {...register('note')}
              label={t('INPUTS.NOTE')}
              multiline
              minRows={3}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
    </Panel>
  );
};
