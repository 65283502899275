import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { EditStateContext } from '../../hooks/use-editable-state';
import { EditTableEntry, IdArray, OnEditPosition } from '../../types';

interface EditTableProviderState<T extends EditTableEntry> {
  onAddPosition?: (props: EditStateContext) => void;
  onEditPosition?: (result: OnEditPosition<T>) => void;
  onRemovePosition?: (localId: IdArray) => void;
  onCollapsePosition?: (result: T) => void;
  onMovePosition?: (localId: string, index: number) => void;
}

const EditTableProviderContext = createContext<EditTableProviderState<unknown>>(
  {}
);

export interface EditTableProviderProps<T> extends EditTableProviderState<T> {}

export function EditTableProvider<T>(
  props: PropsWithChildren<EditTableProviderProps<T>>
) {
  const {
    onAddPosition,
    onEditPosition,
    onRemovePosition,
    onCollapsePosition,
    onMovePosition,
  } = props;
  const value = useMemo(() => {
    return {
      onAddPosition,
      onEditPosition,
      onRemovePosition,
      onCollapsePosition,
      onMovePosition,
    };
  }, [onAddPosition, onEditPosition, onRemovePosition, onCollapsePosition]);

  return (
    <EditTableProviderContext.Provider value={value}>
      {props.children}
    </EditTableProviderContext.Provider>
  );
}

export const useEditTable = () => {
  const context = useContext(EditTableProviderContext);

  if (context === null)
    console.warn(`useEditTable won't work without <EditTableProvider>`);

  return context;
};
