import styles from './TicketStatusPickerField.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITicketStatusPickerProps,
  TicketStatusPicker,
} from '@work4all/components/lib/components/entity-picker/ticket-status-picker/TicketStatusPicker';

import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITicketStatusPickerProps<false>, 'onChange'> {
  error?: string;
  label?: string;
  onChange: (value: string) => void;
}

export const TicketStatusPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, label, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { t } = useTranslation();

    const valueIsDone = TicketStatus.ERLEDIGT === value?.id;

    const Icon = valueIsDone ? CheckCircleRounded : CircleTwoTone;

    return (
      <EntityPickerPopover
        picker={
          <TicketStatusPicker
            {...pickerProps}
            clearable={false}
            multiple={false}
            onChange={(value) => onChange(value?.id)}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          startAdornment={
            value && (
              <Icon
                className={clsx(styles['check-circle'], {
                  [styles['check-circle-success']]:
                    value.id === TicketStatus.ERLEDIGT,
                  [styles['check-circle-edit']]:
                    value.id === TicketStatus.IN_BEARBEITUNG ||
                    value.id === TicketStatus.ZURUECKGESTELLT,
                })}
              />
            )
          }
          label={label || t('INPUTS.STATUS')}
          error={error}
          value={value ? t(`TICKET_STATUS.${value?.id ?? value}`) : ''}
        />
      </EntityPickerPopover>
    );
  }
);
