import React from 'react';

import { useMobileNavigation } from '@work4all/components/lib/navigation/mobile-navigation/useMobileNavigation';

import { useNavigate } from '@work4all/data';

import { UserButton } from './UserButton';

export const UserButtonMobile: React.FC = () => {
  const { profile } = useMobileNavigation();
  const navigate = useNavigate();

  const pathname = profile.pathname;

  const handleAvatarClick = () => {
    navigate(pathname);
  };

  return <UserButton onAvatarClick={handleAvatarClick} standalone />;
};
