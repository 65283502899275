import styles from './EventPopover.module.scss';

import { MbscCalendarEvent } from '@mobiscroll/react';
import { Popover, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';

import { calculateDuration } from '../utils/calculateDuration';

export interface EventPopoverProps {
  target: Element;
  event: MbscCalendarEvent;
}

export const EventPopover = ({ target, event }: EventPopoverProps) => {
  const { t } = useTranslation();

  const data = event?.original as TimeTracking;

  const content = useMemo(() => {
    if (!event) return null;

    const duration = (
      <Typography variant="caption" color="text.inverse" whiteSpace={'nowrap'}>
        {`${calculateDuration(event).toFormat('hh:mm')} h`}
      </Typography>
    );
    const rows = [
      {
        key: 'project',
        value: [data?.project?.number, data?.project?.name]
          .filter(Boolean)
          .join(' | '),
      },
      { key: 'customer', value: data?.customer?.name },
      { key: 'contract', value: data?.contract?.note },
      { key: 'ticket', value: data?.ticket?.title },
      { key: 'comment', value: event?.suggestion?.title ?? data?.comment },
    ].filter((el) => el.value);

    if (rows.length === 0) {
      return (
        <div className={clsx(styles.container, styles.sm)}>{duration}</div>
      );
    }

    return (
      <div className={styles.container}>
        {rows.map((row, index) => {
          return (
            <div key={index} className={styles.row}>
              {row.key === 'project' ? (
                <Typography variant="body1" color="text.inverse">
                  {row.value}
                </Typography>
              ) : (
                <div className={styles.row}>
                  <Typography
                    className={styles.key}
                    variant="caption"
                    color="text.tertiary"
                  >
                    {t(`COMMON.${row.key.toUpperCase()}`)}:
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.inverse"
                    className={clsx(
                      row.key !== 'comment' && styles.value,
                      row.key === 'comment' && styles.comment,
                      styles.commentValue
                    )}
                  >
                    {row.value}
                  </Typography>
                </div>
              )}

              {index === 0 && duration}
            </div>
          );
        })}
      </div>
    );
  }, [
    data?.comment,
    data?.contract?.note,
    data?.customer?.name,
    data?.project?.name,
    data?.project?.number,
    data?.ticket?.title,
    event,
    t,
  ]);

  return (
    <Popover
      open={!!target}
      anchorEl={target}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: styles.rootPopover,
      }}
    >
      {content}
    </Popover>
  );
};
