import { LockOpen } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCustomSnackbar } from '@work4all/data/lib/snackbar/PresetSnackbarProvider';

import { usePasswordChangeDialog } from './use-password-change-dialog';

export const PasswordChangeButton = () => {
  const { t } = useTranslation();
  const snackbar = useCustomSnackbar();
  const passwordDialog = usePasswordChangeDialog();

  const handleOpen = async () => {
    await passwordDialog();
    // if success
    snackbar.enqueueSnackbar(t('ALERTS.PASSWORD_CHANGED'));
  };

  return (
    <Button variant="text" startIcon={<LockOpen />} onClick={handleOpen}>
      {t('Password verwalten')}
    </Button>
  );
};
