import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { FC, Fragment } from 'react';

import { ArticlePreviewContainer } from '@work4all/components/lib/components/entity-preview/article-preview';
import { CallMemoPreviewContainer } from '@work4all/components/lib/components/entity-preview/call-memo-preview';
import { ChecklistPreviewContainer } from '@work4all/components/lib/components/entity-preview/checklist-preview/ChecklistPreviewContainer';
import { DocumentPreviewContainer } from '@work4all/components/lib/components/entity-preview/document-preview';
import { EmailSignaturePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-signature-preview';
import { EmailTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-template-preview';
import { LetterPreviewContainer } from '@work4all/components/lib/components/entity-preview/letter-preview';
import { NotePreviewContainer } from '@work4all/components/lib/components/entity-preview/note-preview';
import { TaskPreviewContainer } from '@work4all/components/lib/components/entity-preview/task-preview';
import { WordDocumentTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/template-preview/WordDocumentTemplatePreviewContainer';
import { WordLetterTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/template-preview/WordLetterTemplatePreviewContainer';
import { TextBuildingBlockPreviewContainer } from '@work4all/components/lib/components/entity-preview/text-building-block-preview';
import { TravelReceiptsPreviewContainer } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';
import { EntityPreviewProps } from '@work4all/components/lib/components/entity-preview/types';
import { VisitationReportPreviewContainer } from '@work4all/components/lib/components/entity-preview/visitation-report-preview';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { EntitiesOfErpGroups } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CommentProvider } from '@work4all/utils';

import { SalesOpportunitiesContainer } from '../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-preview/SalesOpportunitiesContainer';
import { PdfOverlayMaskProvider } from '../../../../mask-overlays/mask-overlay/views/pdf-editor/PdfOverlayMaskProvider';
import { CustomerFileInfoPanelContainer } from '../file-info-panel/customer-file-info-panel-container/CustomerFileInfoPanelContainer';
import { ProjectFileInfoPanelContainer } from '../file-info-panel/project-file-info-panel-container/ProjectFileInfoPanelContainer';
import { SupplierFileInfoPanelContainer } from '../file-info-panel/supplier-file-info-panel-container copy/SupplierFileInfoPanelContainer';

import { AppointmentPreviewContainer } from './containers/AppointmentPreviewContainer';
import { EmailPreviewContainer } from './containers/EmailPreviewContainer';
import { ErpPreviewContainer } from './containers/ErpPreviewContainer';
import { TicketPreviewContainer } from './containers/TicketPreviewContainer';
import { FilePreviewPanelProps } from './FilePreviewPanelProps';

const withOverlay = (WrappedComponent, OverlayProvider) => {
  return function WithOverlayComponent(props) {
    return (
      <OverlayProvider>
        <WrappedComponent {...props} />
      </OverlayProvider>
    );
  };
};

const containers: Record<string, React.ComponentType<EntityPreviewProps>> = {
  // moved to app
  [Entities.appointment]: AppointmentPreviewContainer,
  [Entities.eMail]: EmailPreviewContainer,
  // in lib
  [Entities.supplier]: SupplierFileInfoPanelContainer,
  [Entities.customer]: CustomerFileInfoPanelContainer,
  [Entities.visitationReport]: VisitationReportPreviewContainer,
  [Entities.note]: NotePreviewContainer,
  [Entities.callMemo]: CallMemoPreviewContainer,
  [Entities.project]: ProjectFileInfoPanelContainer,
  [Entities.task]: TaskPreviewContainer,
  [Entities.travelReceipts]: TravelReceiptsPreviewContainer,
  [Entities.checkList]: ChecklistPreviewContainer,
  [Entities.salesOpportunities]: SalesOpportunitiesContainer,
  [Entities.article]: ArticlePreviewContainer,
  [Entities.wordLetterTemplate]: WordLetterTemplatePreviewContainer,
  [Entities.wordDocumentTemplate]: WordDocumentTemplatePreviewContainer,
  [Entities.textBuildingBlock]: TextBuildingBlockPreviewContainer,
  [Entities.document]: withOverlay(
    DocumentPreviewContainer,
    PdfOverlayMaskProvider
  ),
  [Entities.letter]: withOverlay(
    LetterPreviewContainer,
    PdfOverlayMaskProvider
  ),
};

const textIdContainers: Record<
  string,
  React.ComponentType<EntityPreviewProps<string>>
> = {
  // moved to app
  [Entities.ticket]: TicketPreviewContainer,
  // in lib
  [Entities.eMailTemplate]: EmailTemplatePreviewContainer,
  [Entities.eMailSignature]: EmailSignaturePreviewContainer,
};

export const FilePreviewPanel: FC<FilePreviewPanelProps> = (props) => {
  return (
    <Fragment key={props.entityIds?.[0]}>
      <CommentProvider>
        <FilePreviewPanelChild {...props} />
      </CommentProvider>
    </Fragment>
  );
};

const FilePreviewPanelChild: FC<FilePreviewPanelProps> = (props) => {
  const {
    entityIds: nullableEntitiesId,
    entityType,
    // openInFullscreen = false,
    ...commonProps
  } = props;
  const entityIds = nullableEntitiesId.filter(Boolean);

  const isErpType = EntitiesOfErpGroups.includes(entityType);
  if (isErpType) {
    return <ErpPreviewContainer {...props} entityIds={entityIds} />;
  } else if (Object.keys(containers).includes(entityType)) {
    const Container = containers[entityType];
    return <Container {...commonProps} ids={entityIds as number[]} />;
  } else if (Object.keys(textIdContainers).includes(entityType)) {
    const Container = textIdContainers[entityType];
    return <Container {...commonProps} ids={entityIds as string[]} />;
  }

  return (
    <div style={{ padding: '1rem' }}>
      <IconButton
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          commonProps.onCloseClick(e as any);
        }}
        size="small"
      >
        <CloseIcon />
      </IconButton>
      <Caption>ToDo: not yet implemented</Caption>
    </div>
  );
};
