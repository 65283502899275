import { Timer } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { AppParts, useCanView, useNavigate } from '@work4all/data';

import { TimeTrackerMenuTabs } from './TimeTrackerMenuTabs';
import { useTimeTracker } from './use-time-tracker';
import { WorkHoursButton } from './WorkHoursButton';

export class TimeTrackerProps {
  className?: string;
}

export function TimeTracker(props: TimeTrackerProps) {
  const navigate = useNavigate();
  const canViewWorkTimeTracking = useCanView(AppParts.WORKTIMETRACKING);
  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);

  const tracker = useTimeTracker({
    disabled: !canViewWorkTimeTracking,
    amplitudeEntryPoint: 'NavigationBar',
  });

  if (!tracker.result) {
    return null;
  }

  return canViewWorkTimeTracking ? (
    <WorkHoursButton
      tracker={tracker}
      onClick={() =>
        navigate(`/more/timetracking?tab=${TimeTrackerMenuTabs.workingTime}`)
      }
      className={props.className}
    />
  ) : canViewProjectTimeTracking ? (
    <IconButton
      onClick={() =>
        navigate(`/more/timetracking?tab=${TimeTrackerMenuTabs.projectTime}`)
      }
    >
      <Timer
        sx={{
          color: 'white',
        }}
      />
    </IconButton>
  ) : null;
}
