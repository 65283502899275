import { useTranslation } from 'react-i18next';

import { GroupLabelProps } from './types';

type Props = Pick<GroupLabelProps, 'value'>;

export const YesOrNoGroupLabel = ({ value }: Props) => {
  const { t } = useTranslation();

  if (['-1', 'true'].includes(value)) {
    return t('COMMON.YES');
  }

  if (['0', 'false'].includes(value)) {
    return t('COMMON.NO');
  }

  return value;
};
