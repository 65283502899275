import { gql, useQuery } from '@apollo/client';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const QUERY_NAME_BY_ENTITY: Partial<Record<Entities, string>> = {
  projectGroup: 'getProjektGruppen',
  articleGroup: 'getArtikelGruppen',
  customerGroup: 'getKundenGruppen',
  supplierGroup: 'getLieferantenGruppen',
  userGroup: 'getObjectProtectionGroups',
};

const getGroupsQuery = (queryName: string) => gql`
    query ${queryName} {
      groups: ${queryName} {
        id: code
        name: name
        parentId: parentCode
        index
      }
    }
  `;

const getUserGroupsQuery = (queryName: string) => gql`
    query ${queryName} {
      groups: ${queryName} {
        id: code
        name: name
        index: grIndex
      }
    }
  `;

const getQuery = (entity: Entities, queryName: string) => {
  if (entity === Entities.userGroup) {
    return getUserGroupsQuery(queryName);
  }

  return getGroupsQuery(queryName);
};

interface GetGroupsResponse {
  groups: Group[];
}

export function useGroups(entity: Entities) {
  const queryName = QUERY_NAME_BY_ENTITY[entity];

  if (!queryName) {
    throw new Error(
      `Entity ${entity} is not defined inside "QUERY_NAME_BY_ENTITY".`
    );
  }

  const query = getQuery(entity, queryName);

  return useQuery<GetGroupsResponse>(query);
}
