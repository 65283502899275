import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  PauseRulePicker,
  PauseRulePickerProps,
} from '@work4all/components/lib/components/entity-picker/pause-rule-picker/PauseRulePicker';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends PauseRulePickerProps {
  error?: string;
  label?: string;
}

export const PauseRulePickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, label: _label, ...pickerProps } = props;
    const { value } = pickerProps;

    const { t } = useTranslation();

    return (
      <EntityPickerPopover picker={<PauseRulePicker {...pickerProps} />}>
        <PickerTargetButton
          ref={ref}
          label={uppercaseFirstLetter(t('INPUTS.BREAK_RULE'))}
          error={error}
          value={value?.name}
        />
      </EntityPickerPopover>
    );
  }
);
