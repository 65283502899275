import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { TicketPriority } from '@work4all/components/lib/components/entity-picker/PriorityPicker';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { MaskExtendedConfig } from '../../types';
import { useAssignableTemplateEntity } from '../../utils/use-assignable-template-entity';

export const useTicketDefaultData = (mask: MaskExtendedConfig): Ticket => {
  const template = useAssignableTemplateEntity(mask);
  const presetFieldsJSON = mask.params.presetFields;

  return useMemo<Ticket>(() => {
    const data: Ticket = {
      title: '',
      number: 0,
      problemDescription: '',
      followUpDate: DateTime.now().startOf('hour').toISO(),
      solutionText: '',
      businessPartner: {
        id: template?.data?.businessPartner?.data?.id ?? 0,
        data: template?.data?.businessPartner?.data
          ? { ...template.data.businessPartner.data, mainContact: null }
          : null,
      },
      businessPartnerId: template?.data?.businessPartner?.data.id || 0,
      businessPartnerType:
        template?.data?.businessPartnerType || SdObjType.KUNDE,
      contact:
        template?.data?.contact ||
        template?.data?.businessPartner?.data?.mainContact ||
        null,
      contactId:
        template?.data?.contact?.id ||
        template?.data?.businessPartner?.data?.mainContactId ||
        0,
      project: template?.data?.project || null,
      projectId: template?.data?.project?.id || 0,
      projectProcess: template?.data?.projectProcess || null,
      projectProcessId: template?.data?.projectProcess?.id || 0,
      ticketKind: null,
      ticketKindId: 0,
      ticketCategory1: null,
      ticketCategory2: null,
      category1Id: 0,
      category2Id: 0,
      status1: TicketStatus.OFFEN,
      status2: TicketStatus.OFFEN,
      editor1: null,
      editor2: null,
      editor1Id: 0,
      priority: TicketPriority.NORMAL,
      timeRequired: 0,
      attachmentList: [],
    };

    if (presetFieldsJSON) {
      try {
        const preset = JSON.parse(presetFieldsJSON);
        Object.assign(data, preset);
      } catch {
        console.error("Can't parse presetFields", presetFieldsJSON);
      }
    }

    return data;
  }, [template, presetFieldsJSON]);
};
