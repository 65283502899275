import { useCallback, useMemo } from 'react';

import { useUser } from '@work4all/data';

import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DndTypes } from '@work4all/utils/lib/variables';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import { TextmarkPickerResult } from '../../entity-picker/textmark-picker';
import { DocumentPreview } from '../document-preview/DocumentPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewProps } from '../types';

import { TextmarkPickerButton } from './TextmarkPickerButton';
import { useDocumentTemplateSettings } from './use-document-template-settings';

interface WordLetterTemplatePreviewContainerProps extends EntityPreviewProps {}

const requestedWordLetterTemplateData: WordLetterTemplate<EMode.query> = {
  id: null,
  templateFilename: null,
  fileInfos: {
    previewUrl: null,
    previewMimeType: null,
    fileServiceProviderInfos: {
      id: null,
      fileName: null,
      mimeType: null,
      fspUrl: null,
    },
  },
  title: null,
};

export function WordLetterTemplatePreviewContainer(
  props: WordLetterTemplatePreviewContainerProps
) {
  const { ids, onCloseClick, onVisibilityToggle, onEditClicked } = props;

  const wordLetterTemplateId = ids[0];
  const wordLetterTemplates = useEntityPreviewData<WordLetterTemplate>(
    [wordLetterTemplateId],
    Entities.wordLetterTemplate,
    requestedWordLetterTemplateData
  );

  const user = useUser();

  const { settings, update: updateSettings } = useDocumentTemplateSettings(
    wordLetterTemplateId,
    Entities.wordLetterTemplate
  );

  const handleTextmarkPickerChange = useCallback(
    async (value: TextmarkPickerResult) => {
      if (!value) {
        return;
      }

      updateSettings({
        pdfTextmarkItems: [
          ...settings.pdfTextmarkItems,
          {
            id: value.textmark.id,
            left: 0.5,
            top: 0.25,
            title: value.textmark.name,
            type: DndTypes.BOX,
            accessor: value.textmark.accessor,
            pageNumber: 1,
          },
        ],
      });
    },
    [settings.pdfTextmarkItems, updateSettings]
  );

  const pdfTextmarkConf = useMemo<PDFTextmarkConf>(() => {
    return {
      pdfTextMarkItems: settings.pdfTextmarkItems,
      onItemsChange: (items) => updateSettings({ pdfTextmarkItems: items }),
      disabled: !user.isMaster,
    };
  }, [settings.pdfTextmarkItems, updateSettings, user.isMaster]);

  const isPDF = useMemo(() => {
    const segments = wordLetterTemplates?.[0]?.templateFilename.split('.');
    return segments && segments[segments.length - 1].toLowerCase() === 'pdf';
  }, [wordLetterTemplates]);

  const selectedRowsIdsList = useMemo(() => {
    return wordLetterTemplates?.map((x) => x.id) ?? [];
  }, [wordLetterTemplates]);
  if (!wordLetterTemplates) return null;

  return (
    <DocumentPreview
      entries={wordLetterTemplates}
      entity={Entities.wordLetterTemplate}
      entityData={requestedWordLetterTemplateData}
      titleKeyField="title"
      title={
        wordLetterTemplates[0].title ||
        wordLetterTemplates[0].fileInfos?.fileServiceProviderInfos?.fileName ||
        ''
      }
      url={`${wordLetterTemplates[0].fileInfos.previewUrl}`}
      mimeType={wordLetterTemplates[0].fileInfos?.previewMimeType as MIME_TYPES}
      noPreviewUrl={
        wordLetterTemplates[0].fileInfos?.fileServiceProviderInfos?.fspUrl ||
        wordLetterTemplates[0].fileInfos?.downloadUrl
      }
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      pdfTextmarkConf={isPDF && pdfTextmarkConf}
      additionalTitleItems={
        user.isMaster &&
        isPDF && (
          <TextmarkPickerButton
            onChange={handleTextmarkPickerChange}
            type="document"
          />
        )
      }
      selectedRowsIdsList={selectedRowsIdsList}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
