import styles from '../StatisticPage.module.scss';

import { LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { StatisticCustomerSheetTable } from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticCustomerSheet/StatisticCustomerSheetTable';

import { useDataProvider } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { StatisticCustomerDatasheet } from '@work4all/models/lib/Classes/StatisticCustomerDatasheet.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CustomerPickerField } from '../../../components/entity-picker/CustomerPickerField';
import { settings, useSetting } from '../../../settings';
import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';

export function StatisticCustomerSheetContainer() {
  const oldestInvoice = useOldestInvoice();
  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });

  const { t } = useTranslation();

  const currentDate = DateTime.now().startOf('year').toJSDate();
  const [currentYear, setCurrentYear] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    currentDate
  );
  const [maxYear, setMaxYear] = useState(currentDate);

  const [customer, setCustomer] = useState<Customer>(null);

  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBordersStatistics()
  );

  useEffect(() => {
    const currentDate = DateTime.now();
    if (currentDate.month < startBusinessYearSetting?.data?.[0]?.value) {
      const date = currentDate
        .minus({ years: 1 })
        .set({ month: startBusinessYearSetting?.data?.[0]?.value })
        .startOf('month')
        .toJSDate();

      setCurrentYear(date);
      setMaxYear(date);
    }
  }, [setCurrentYear, startBusinessYearSetting?.data]);

  const requestData: DataRequest = useMemo(() => {
    const startDate = DateTime.fromJSDate(currentYear);
    const data: StatisticCustomerDatasheet = {
      sdObjMemberId: null,
      columns: [
        {
          month: null,
          statistics: [
            {
              value: null,
              statisticName: null,
              number: null,
              name: null,
            },
          ],
        },
      ],
    };
    return {
      entity: Entities.statisticCustomerDatasheet,
      data: data,
      vars: {
        rangeStart: startDate.toFormat('yyyy-MM-dd'),
        sdobjMemberCode: customer?.id,
      },
      completeDataResponse: true,
    };
  }, [currentYear, customer]);

  const data = useDataProvider(requestData, !customer);

  const fiscalYears: number[] = Array.from(
    new Set(
      ((data.data?.[0]?.columns as { month: string }[]) ?? []).map((item) => {
        const date = DateTime.fromFormat(item.month, 'yyyy-MM-dd');
        const year = date.year;
        const month = date.month;
        return month < (startBusinessYearSetting?.data?.[0]?.value ?? 0)
          ? year - 1
          : year;
      })
    )
  ).sort((a, b) => a - b);

  const calculatedMinYear =
    fiscalYears.length > 0 ? Math.min(...fiscalYears) : NaN;
  const calculatedMaxYear =
    fiscalYears.length > 0 ? Math.max(...fiscalYears) : NaN;

  const minDate = useMemo(() => {
    if (!isNaN(calculatedMinYear) && calculatedMinYear > 0) {
      return new Date(calculatedMinYear, 0);
    }

    if (oldestInvoice?.data?.[0]?.date) {
      return new Date(oldestInvoice.data[0].date);
    }

    return new Date();
  }, [calculatedMinYear, oldestInvoice]);

  const maxDate = useMemo(() => {
    if (!isNaN(calculatedMaxYear) && calculatedMaxYear > 0) {
      return new Date(calculatedMaxYear, 0);
    }

    return maxYear ? maxYear : new Date();
  }, [calculatedMaxYear, maxYear]);

  useEffect(() => {
    if (customer) {
      const selectedYear = DateTime.fromJSDate(currentYear).year;
      if (!fiscalYears.includes(selectedYear)) {
        setCurrentYear(maxDate);
      }
    }
  }, [customer, currentYear, maxDate, data, setCurrentYear, fiscalYears]);

  const isLoading = data.loading || data.pending;

  return (
    <>
      {isLoading && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: 200 }}
        />
      )}
      <div className={styles.controlBar}>
        <div style={{ width: '15rem', maxWidth: '50%' }}>
          <DateWheelPickerField
            min={minDate}
            max={maxDate}
            unit="year"
            label={t('COMMON.BUSINESS_YEAR')}
            value={currentYear}
            onChange={(value) => {
              setCurrentYear(value);
            }}
          />
        </div>
        <div style={{ width: '15rem', maxWidth: '50%' }}>
          <CustomerPickerField value={customer} onChange={setCustomer} />
        </div>
      </div>
      <div className={styles.tableWrapper} style={{ padding: '0 1rem' }}>
        <StatisticCustomerSheetTable
          data={data.data as StatisticCustomerDatasheet}
          selectedYear={currentYear}
          selectedCustomer={customer}
          borders={tablesLayoutBorders.value}
          startBusinessYearSetting={startBusinessYearSetting?.data?.[0]?.value}
          onDateChange={setCurrentYear}
        />
      </div>
    </>
  );
}
