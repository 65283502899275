import styles from './Main.module.scss';

import {
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventDragEvent,
  MbscResource,
} from '@mobiscroll/react';
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Fab, Stack, Theme, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserIconRegisterContext } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { useDataMutation, useDataProvider, useUser } from '@work4all/data';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { assertNever } from '@work4all/utils';
import { canEditAppointment } from '@work4all/utils/lib/permissions';

import { usePageTheme } from '../../../providers/HookedMuiThemeProvider';
import { settings, useSetting } from '../../../settings';
import { usePublicHolidays } from '../../vacations/use-public-holidays';
import { DEFAULT_APPOINTMENT_STATE } from '../constants';
import {
  getCalendarEndDate,
  getEdgeDate,
  useCalendarHoursConfig,
} from '../hooks/use-calendar-hours-config';
import {
  AgendaSize,
  AppointmentsGroupMode,
  AppointmentsTimeRange,
  CalendarCardColorBy,
} from '../types';
import { mapUsersToAppointmentAttendees } from '../utils/map-users-to-appointment-attendees';

import { AppointmentPopup } from './AppointmentPopup';
import {
  AppointmentQuickCreateDialog,
  AppointmentTemplate,
} from './AppointmentQuickCreateDialog';
import { CalendarUserIcon, CalendarUserIconProps } from './CalendarUserIcon';
import EventPopover, { HoveredEvent } from './EventPopover';

interface MbscResourceExtended extends MbscResource {
  type: CalendarUserIconProps['type'];
}

interface MainProps {
  users: User[];
  date: Date;
  onDateChange: (date: Date) => void;
  range: AppointmentsTimeRange;
  groupMode: AppointmentsGroupMode;
  appointmentStates: number[];
  onOpenAppointmentMask: (
    appointmentId: number | null,
    presetFields?: string
  ) => void;
  calendarCardColorBy: CalendarCardColorBy;
  forceMobileView?: boolean;
  agendaSize: AgendaSize;
  onAgendaSizeChange: (size: 'expanded' | 'shrinked') => void;
}
const HOLIDAY_BACKGROUND = 'var(--bg-holiday)';

const WEEKEND_CALENDAR_DAY: MbscCalendarColor = {
  background: HOLIDAY_BACKGROUND,
  cellCssClass: styles.holiday,
  recurring: { repeat: 'weekly', weekDays: 'SA,SU' },
};

export function Main(props: MainProps) {
  const calendarAppointmentCardShowBusinesspartner = useSetting(
    settings.calendarAppointmentCardShowBusinesspartner()
  );
  const calendarAppointmentCardShowProject = useSetting(
    settings.calendarAppointmentCardShowProject()
  );
  const calendarAppointmentShowTooltip = useSetting(
    settings.calendarAppointmentShowTooltip()
  );
  const calendarAppointmentTooltipShowBusinesspartner = useSetting(
    settings.calendarAppointmentTooltipShowBusinesspartner()
  );
  const calendarAppointmentTooltipShowProject = useSetting(
    settings.calendarAppointmentTooltipShowProject()
  );
  const calendarAppointmentTooltipShowNote = useSetting(
    settings.calendarAppointmentTooltipShowNote()
  );

  const calendarDragAndDropAppointments = useSetting(
    settings.calendarDragAndDropAppointments()
  );

  const {
    date,
    onDateChange,
    users,
    range,
    groupMode,
    appointmentStates,
    onOpenAppointmentMask,
    calendarCardColorBy,
    forceMobileView = false,
    agendaSize,
    onAgendaSizeChange,
  } = props;

  const { t } = useTranslation();
  const user = useUser();

  const { workDayStart, workDayEnd } = useCalendarHoursConfig();

  const [startDate, endDate] = useMemo(() => {
    switch (range) {
      case 'agenda':
      case 'day': {
        const start = getEdgeDate('start', 'day', date);
        return [start.toJSDate(), start.plus({ days: 1 }).toJSDate()];
      }

      case 'week-5':
      case 'week-7': {
        const start = getEdgeDate('start', 'week', date);
        return [start.toJSDate(), start.plus({ weeks: 1 }).toJSDate()];
      }

      case 'month': {
        const start = getEdgeDate('start', 'month', date);
        const end = start.plus({ months: 1 });

        // When using calendar view, previous and next months can be partially
        // on screen. Extend the loaded range, so that previous and next months'
        // appointments are still displayed.
        if (groupMode === 'grouped') {
          return [
            start.minus({ weeks: 2 }).toJSDate(),
            end.plus({ weeks: 2 }).toJSDate(),
          ];
        }

        return [start.toJSDate(), end.toJSDate()];
      }

      default:
        assertNever(range, `Unknown appointment time range ${range}`);
    }
  }, [date, range, groupMode]);

  const startDateJson = startDate.toISOString();
  const endDateJson = endDate.toISOString();

  const request = useMemo(() => {
    const dataRequest: DataRequest = {
      operationName: 'GetAppointmentsForCalendar',
      entity: Entities.appointment,
      data: {
        id: null,
        title: null,
        startDate: null,
        endDate: null,
        fromAbsolute: null,
        toAbsolute: null,
        isWholeDay: null,
        privat: null,
        parentId: null,
        businessPartner: {
          id: null,
          businessPartnerType: null,
          data: {
            customer: { id: null, name: null, isPrivateCustomer: null },
            supplier: { id: null, name: null, isPrivateCustomer: null },
          },
        },
        contact: {
          id: null,
          displayName: null,
          businessPartnerId: null,
          businessPartnerType: null,
          eMail: null,
          phoneNumber: null,
          phoneNumber2: null,
          phoneNumber3: null,
          phoneNumberPrivate: null,
        },
        project: { id: null, name: null },
        user: { id: null, displayName: null, shortName: null, userKind: null },
        appointmentState: {
          id: null,
          name: null,
          hexColorForeground: null,
          outOfOffice: null,
        },
        contractId: null,
        contract: {
          id: null,
          note: null,
          contractNumber: null,
        },
        deliveryNoteId: null,
        deliveryNote: {
          id: null,
          note: null,
          number: null,
        },
        appointmentAttendeeList: [
          {
            id: null,
            ressource: { displayName: null, id: null, userKind: null },
            user: {
              id: null,
              displayName: null,
            },
            contact: {
              id: null,
              displayName: null,
            },
            businessPartner: {
              id: null,
              data: {
                customer: { id: null, name: null },
                supplier: { id: null, name: null },
              },
            },
          },
        ],
        note: null,
        meetingUrl: null,
      } as Appointment<EMode.query>,
      filter: [
        { startDate: { $lt: endDateJson } },
        { endDate: { $gte: startDateJson } },
        {
          userId: {
            $in: users.length > 0 ? users.map((user) => user.id) : [-1],
          },
        },
        appointmentStates.includes(0)
          ? {
              $or: [
                { colorId: { $in: appointmentStates } },
                { colorId: { $eq: null } },
              ],
            }
          : appointmentStates.length > 0 && {
              colorId: { $in: appointmentStates },
            },
      ].filter(Boolean),
    };

    return dataRequest;
  }, [endDateJson, startDateJson, users, appointmentStates]);

  const response = useDataProvider<Appointment>(request, request.skip, 9999);

  const refetch = useEventCallback(response.refetch);
  const throttled = useMemo(() => throttle(refetch, 500), [refetch]);
  useEntityChanges({
    entity: Entities.appointment,
    changeType: [
      ChangeType.ITEM_INSERTED,
      ChangeType.ITEM_UPDATED,
      ChangeType.ITEM_DELETED,
    ],
    onEntityChanged: throttled,
  });

  const { data } = response;

  const viewSettings = useMemo<MbscEventcalendarView>(() => {
    if (forceMobileView) {
      return {
        calendar: {
          type: agendaSize === 'expanded' ? 'month' : 'week',
        },
        agenda: { type: 'day' },
      };
    }

    switch (range) {
      case 'agenda': {
        return {
          calendar: {
            type: agendaSize === 'expanded' ? 'month' : 'week',
          },
          agenda: { type: 'day' },
        };
      }
      case 'day': {
        switch (groupMode) {
          case 'grouped': {
            return {
              schedule: {
                type: 'day',
                size: 1,
              },
            };
          }

          case 'horizontal': {
            return {
              schedule: {
                type: 'day',
                size: 1,
              },
            };
          }

          case 'vertical': {
            return {
              timeline: {
                type: 'day',
                size: 1,
              },
            };
          }

          default:
            assertNever(groupMode, `Unknown group mode ${groupMode}`);
        }
      }

      case 'week-5':
        switch (groupMode) {
          case 'grouped': {
            return {
              schedule: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 5,
              },
            };
          }

          case 'horizontal': {
            return {
              schedule: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 5,
              },
            };
          }

          case 'vertical': {
            return {
              timeline: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 5,
              },
            };
          }

          default:
            assertNever(groupMode, `Unknown group mode ${groupMode}`);
        }

      case 'week-7': {
        switch (groupMode) {
          case 'grouped': {
            return {
              schedule: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 0,
              },
            };
          }

          case 'horizontal': {
            return {
              schedule: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 0,
              },
            };
          }

          case 'vertical': {
            return {
              timeline: {
                type: 'week',
                eventList: true,
                startDay: 1,
                endDay: 0,
              },
            };
          }

          default:
            assertNever(groupMode, `Unknown group mode ${groupMode}`);
        }
      }

      case 'month': {
        switch (groupMode) {
          case 'grouped': {
            return {
              calendar: {
                type: 'month',
              },
            };
          }

          case 'horizontal': {
            return {
              schedule: {
                type: 'month',
                eventList: true,
              },
            };
          }

          case 'vertical': {
            return {
              timeline: {
                type: 'month',
                eventList: true,
              },
            };
          }

          default:
            assertNever(groupMode, `Unknown group mode ${groupMode}`);
        }
      }

      default:
        assertNever(range, `Unknown appointment time range ${range}`);
    }
  }, [forceMobileView, range, groupMode, agendaSize]);

  const resources = useMemo<MbscResourceExtended[]>(() => {
    if (groupMode === 'grouped') {
      return [];
    }

    return users.map<MbscResourceExtended>((user) => {
      return {
        id: user.id,
        name:
          user.userKind === UserClass.RESSOURCE
            ? user.shortName
            : user.displayName,
        type: user.userKind === UserClass.RESSOURCE ? 'resource' : 'user',
      };
    });
  }, [users, groupMode]);

  const events = useMemo<MbscCalendarEvent[]>(
    () =>
      data.map<MbscCalendarEvent>((appointment) => ({
        start: appointment.isWholeDay
          ? getEdgeDate('start', 'day', appointment.fromAbsolute).toJSDate()
          : new Date(appointment.startDate),
        end: appointment.isWholeDay
          ? getEdgeDate('start', 'day', appointment.toAbsolute).toJSDate()
          : new Date(appointment.endDate),
        title: appointment.title,
        resource: groupMode === 'grouped' ? undefined : appointment.user.id,
        editable: canEditAppointment(user, { id: appointment.id }),
        original: appointment,
        allDay: appointment.isWholeDay,
        color: (appointment.appointmentState ?? DEFAULT_APPOINTMENT_STATE)
          .hexColorForeground,
      })),
    [data, groupMode, user]
  );

  const { data: publicHolidays } = usePublicHolidays({
    from: startDate,
    to: endDate,
  });

  const colors = useMemo<MbscCalendarColor[]>(() => {
    const colors: MbscCalendarColor[] = [];

    // Do not add "before work hours" and "after work hours" colors for calendar
    // views that don't display hours as they will take up full day instead.
    if (
      !(
        (range === 'week-5' || range === 'week-7') &&
        groupMode === 'vertical'
      ) &&
      !(
        range === 'month' &&
        (groupMode === 'grouped' || groupMode === 'vertical')
      )
    ) {
      colors.push(
        {
          start: '00:00',
          end: workDayStart,
          background: 'rgba(128, 128, 128, 0.5)',
          recurring: {
            repeat: 'daily',
          },
        },
        {
          start: workDayStart,
          end: workDayEnd,
          background: 'var(--ui01)',
          recurring: {
            repeat: 'daily',
          },
        },
        {
          start: workDayEnd,
          end: '24:00',
          background: 'rgba(128, 128, 128, 0.5)',
          recurring: {
            repeat: 'daily',
          },
        }
      );
    }

    colors.push(
      WEEKEND_CALENDAR_DAY,
      { ...WEEKEND_CALENDAR_DAY, start: workDayStart, end: workDayEnd },
      ...publicHolidays.flatMap<MbscCalendarColor>((holiday) => {
        return [
          {
            date: getEdgeDate('start', 'day', holiday.date).toJSDate(),
            background: HOLIDAY_BACKGROUND,
            cellCssClass: styles.holiday,
          },
          {
            background: HOLIDAY_BACKGROUND,
            cellCssClass: styles.holiday,
            start: DateTime.fromISO(holiday.date)
              .startOf('day')
              .plus(Duration.fromISOTime(workDayStart))
              .toJSDate(),
            end: DateTime.fromISO(holiday.date)
              .startOf('day')
              .plus(Duration.fromISOTime(workDayEnd))
              .toJSDate(),
          },
        ];
      })
    );

    return colors;
  }, [range, groupMode, workDayStart, workDayEnd, publicHolidays]);

  const language = useMobiscrollLanguage();

  const [popupConfig, setPopupConfig] = useState<{
    appointment: Appointment;
    open: boolean;
  }>({ appointment: null, open: false });

  const [createDialogConfig, setCreateDialogConfig] = useState<{
    template: AppointmentTemplate | null;
    open: boolean;
    showEditDetailsBtn?: boolean;
  }>({ template: null, open: false });

  // Set the initial calendar scroll position to 1 hour before the start of work
  // day.
  const refDate = useMemo(() => {
    return DateTime.fromJSDate(date)
      .startOf('day')
      .set({ hour: Math.max(0, parseInt(workDayStart, 10) - 1) })
      .toJSDate();
  }, [date, workDayStart]);

  const theme = useTheme();
  const userIconRegister = useContext(UserIconRegisterContext);

  /**
   * Return the color of a given appointment. It will be either user's avatar
   * fallback color if in "user" color mode, or appointment category's color in
   * "category" mode.
   */
  function getAppointmentColor(appointment: Appointment) {
    // Some calendar events might be rendered without an actual appointment
    // object (for example, when dragging over the calendar to create a new
    // appointment). In this case use the default appointment color.
    if (!appointment) {
      return DEFAULT_APPOINTMENT_STATE.hexColorForeground;
    }

    return calendarCardColorBy === CalendarCardColorBy.User
      ? theme.palette[userIconRegister?.register?.[appointment.user.id]?.shade]
      : (appointment.appointmentState ?? DEFAULT_APPOINTMENT_STATE)
          .hexColorForeground;
  }

  const getStartEndTime = useCallback(
    (event: MbscCalendarEvent) => {
      if (!event) return;

      const appointment = event.original as Appointment;

      if (event.allDay || !appointment?.startDate || !appointment?.endDate) {
        return t('COMMON.WHOLEDAYEVENT');
      }

      const formatTime = (date: string) => {
        return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
      };

      return `${formatTime(appointment.startDate)} - ${formatTime(
        appointment.endDate
      )} ${t('CALENDAR.CLOCK')}`;
    },
    [t]
  );

  const hoveredEventTimeOut = useRef(null);
  const [hoveredEvent, setHoveredEvent] = useState<HoveredEvent>();

  const onEventHoverIn = useCallback(
    (args) => {
      clearTimeout(hoveredEventTimeOut.current);

      if (popupConfig.open) {
        // If an event popover is open, do not show any hover popovers.
        //
        // The hover popover is hidden in onEventClick, but sometimes onHoverIn
        // is called before onEventClick for some reason, even though the hover
        // event happens first. So just ignore the event if an event popover is
        // currently open.
        return;
      }

      hoveredEventTimeOut.current = setTimeout(() => {
        setHoveredEvent({
          event: args.event,
          startEndTime: getStartEndTime(args.event),
          appointment: args.event.original,
          target: args.domEvent.target,
        });
      }, 500);
    },
    [getStartEndTime, popupConfig.open]
  );

  const onEventHoverOut = useCallback(() => {
    clearTimeout(hoveredEventTimeOut.current);
    setHoveredEvent(undefined);
  }, [hoveredEventTimeOut]);

  const mbscLocalePatched = useMemo(() => {
    if (forceMobileView || range !== 'day' || groupMode !== 'horizontal')
      return language;

    return { ...language, dateFormatLong: 'DDDD, D. MMMM YYYY' };
  }, [forceMobileView, groupMode, language, range]);

  const [mutate] = useDataMutation<Appointment, EMode.upsert>({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: { id: null },
  });

  const onDragEnd = useCallback(
    ({ event }: MbscEventDragEvent) => {
      if (event.original !== undefined) {
        const id = event.original.id;
        const start = event.start as Date;
        const end = event.end as Date;
        const startDate = event.allDay
          ? getEdgeDate('start', 'day', start).toJSDate()
          : start;
        const endDate = getCalendarEndDate(event.allDay, end).toJSDate();

        mutate({ id, endDate, startDate });
      }
    },
    [mutate]
  );

  const getHoursTimeDifference = (endDate, startDate) => {
    return (endDate - startDate) / (1000 * 3600);
  };

  const isMobile = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.down('sm'),
    {
      noSsr: true,
    }
  );

  const pageTheme = usePageTheme();

  return (
    <>
      <div style={{ gridArea: 'main', minHeight: 0, minWidth: 0 }}>
        {(range === 'agenda' || forceMobileView) && (
          <div className={styles.shrinkerContainer}>
            <Fab
              onClick={() =>
                onAgendaSizeChange(
                  agendaSize === 'expanded' ? 'shrinked' : 'expanded'
                )
              }
              className={clsx(styles.shrinker, {
                [styles.expanded]: agendaSize === 'expanded',
              })}
              size="small"
              color="primary"
            >
              {agendaSize === 'shrinked' ? (
                <KeyboardDoubleArrowDown />
              ) : (
                <KeyboardDoubleArrowUp />
              )}
            </Fab>
          </div>
        )}
        <div style={{ height: '100%' }}>
          <Eventcalendar
            newEventText={t('CALENDAR.NEW.EVENT.TITLE')}
            className={styles.calendar}
            locale={language}
            dataTimezone="utc"
            displayTimezone="local"
            view={viewSettings}
            refDate={refDate}
            selectedDate={refDate}
            onSelectedDateChange={(args) => {
              onDateChange(args.date as Date);
            }}
            data={events}
            resources={resources}
            colors={colors}
            onEventCreate={(args) => {
              const { start, end, resource } = args.event;
              const usersForDialog =
                groupMode === 'grouped'
                  ? users
                  : users.filter((u) => u.id === resource);

              const appointmentAttendeeList =
                mapUsersToAppointmentAttendees(usersForDialog);

              const isWholeDay =
                groupMode === 'vertical' ||
                (groupMode === 'grouped' && range === 'month');

              const startDate = DateTime.fromJSDate(start as Date).toISO();
              const endDate = getCalendarEndDate(
                isWholeDay,
                end as Date
              ).toISO();

              const template: AppointmentTemplate = {
                appointmentAttendeeList,
                startDate: startDate,
                endDate: endDate,
                isWholeDay: isWholeDay,
                isRealAppointment: true,
              };

              setCreateDialogConfig({
                open: true,
                template,
                showEditDetailsBtn: true,
              });

              return false;
            }}
            clickToCreate={true}
            dragToCreate={true}
            dragToResize={calendarDragAndDropAppointments.value}
            dragToMove={calendarDragAndDropAppointments.value}
            // This will make the "More" popover close automatically when you
            // click on an event.
            eventDelete={false}
            themeVariant={pageTheme.value}
            showControls={false}
            showEventTooltip={false}
            renderResource={(resource: MbscResourceExtended) => {
              switch (groupMode) {
                case 'vertical':
                  return (
                    <Tooltip title={resource.name}>
                      <div>
                        <CalendarUserIcon
                          type={resource.type}
                          userId={resource.id as number}
                          size="l"
                          withBorder={
                            calendarCardColorBy === CalendarCardColorBy.User
                          }
                        />
                      </div>
                    </Tooltip>
                  );

                case 'horizontal':
                  return (
                    <Stack
                      direction="row"
                      gap="0.5rem"
                      alignItems="center"
                      sx={{
                        width: 'max-content',
                        margin: 'auto',
                      }}
                    >
                      <CalendarUserIcon
                        type={resource.type}
                        userId={resource.id as number}
                        size="l"
                        withBorder={
                          calendarCardColorBy === CalendarCardColorBy.User
                        }
                      />
                      <Typography variant="body2">{resource.name}</Typography>
                    </Stack>
                  );

                default:
                  return null;
              }
            }}
            renderScheduleEvent={(event) => {
              const appointment = event.original.original as Appointment;

              const color = getAppointmentColor(appointment);

              const hoursDifference = getHoursTimeDifference(
                (event.original?.end as Date)?.getTime(),
                (event.original?.start as Date)?.getTime()
              );

              const renderSpacer = () => {
                return groupMode !== 'vertical' &&
                  appointment?.isWholeDay ? null : (
                  <div className={styles.eventInfo}>&nbsp;</div>
                );
              };

              const renderProject = () => {
                if (
                  (groupMode !== 'vertical' && appointment?.isWholeDay) ||
                  !appointment?.project
                ) {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {appointment.project.name}
                  </div>
                );
              };

              const renderBusinessPartner = () => {
                if (
                  (groupMode !== 'vertical' && appointment?.isWholeDay) ||
                  !appointment?.businessPartner
                ) {
                  return null;
                }

                return (
                  <div
                    className={
                      hoursDifference >= 1.5 &&
                      !isMobile &&
                      groupMode === 'grouped'
                        ? styles.eventInfoBig
                        : styles.eventInfo
                    }
                  >
                    {appointment.businessPartner.data.name}
                  </div>
                );
              };

              const renderStartEndTime = () => {
                if (groupMode !== 'vertical') {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {getStartEndTime(event.original)}
                  </div>
                );
              };

              return (
                <div className={styles.event} style={{ color: color }}>
                  <div className={styles.eventBackground} />
                  <div className={styles.eventContent}>
                    <div
                      className={
                        hoursDifference >= 1.15 &&
                        !isMobile &&
                        groupMode === 'grouped'
                          ? styles.eventTitleBig
                          : styles.eventTitle
                      }
                    >
                      {event.title}
                    </div>
                    {renderStartEndTime()}
                    {calendarAppointmentCardShowBusinesspartner.value &&
                      renderBusinessPartner()}
                    {calendarAppointmentCardShowProject.value &&
                      renderProject()}

                    {calendarAppointmentCardShowBusinesspartner.value &&
                      appointment?.businessPartner?.data?.name === undefined &&
                      renderSpacer()}
                    {calendarAppointmentCardShowProject.value &&
                      appointment?.project?.name === undefined &&
                      renderSpacer()}
                  </div>
                  <div className={styles.eventExtra}>
                    {appointment?.privat ? (
                      <div className={styles.eventPrivateIcon}>
                        <PersonIcon fontSize="inherit" />
                      </div>
                    ) : null}
                  </div>
                  {groupMode === 'grouped' &&
                  calendarCardColorBy ===
                    CalendarCardColorBy.AppointmentStatus &&
                  !!appointment?.user ? (
                    <div className={styles.eventParticipants}>
                      <CalendarUserIcon
                        type={
                          appointment.user.userKind === UserClass.RESSOURCE
                            ? 'resource'
                            : 'user'
                        }
                        userId={appointment.user.id}
                      />
                    </div>
                  ) : null}
                </div>
              );
            }}
            renderLabel={(event) => {
              const appointment = event.original.original as Appointment;

              const color = getAppointmentColor(appointment);

              const renderBusinessPartner = () => {
                if (
                  (groupMode === 'grouped' && range === 'month') ||
                  event.allDay ||
                  !appointment?.businessPartner
                ) {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {appointment.businessPartner.data.name}
                  </div>
                );
              };

              return (
                <div className={styles.calendarEvent} style={{ color: color }}>
                  <div className={styles.eventBackground} />

                  <div className={styles.eventContent}>
                    <div className={styles.eventTitle}>
                      {appointment?.title}
                    </div>
                    {renderBusinessPartner()}
                  </div>

                  <div className={styles.eventExtra}>
                    {appointment?.privat ? (
                      <div className={styles.eventPrivateIcon}>
                        <PersonIcon fontSize="inherit" />
                      </div>
                    ) : null}
                  </div>

                  {groupMode === 'grouped' &&
                  calendarCardColorBy ===
                    CalendarCardColorBy.AppointmentStatus &&
                  !!appointment?.user ? (
                    <div className={styles.eventParticipants}>
                      <CalendarUserIcon
                        type={
                          appointment.user.userKind === UserClass.RESSOURCE
                            ? 'resource'
                            : 'user'
                        }
                        userId={appointment.user.id}
                      />
                    </div>
                  ) : null}
                </div>
              );
            }}
            renderEvent={(event) => {
              const appointment = event.original.original as Appointment;

              const color = getAppointmentColor(appointment);

              const renderProject = () => {
                if (
                  (groupMode !== 'vertical' && appointment.isWholeDay) ||
                  !appointment?.project
                ) {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {appointment.project.name}
                  </div>
                );
              };

              const renderBusinessPartner = () => {
                if (
                  (groupMode !== 'vertical' && appointment.isWholeDay) ||
                  !appointment?.businessPartner
                ) {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {appointment.businessPartner.data.name}
                  </div>
                );
              };

              const renderStartEndTime = (force = false) => {
                if (!force && groupMode !== 'vertical') {
                  return null;
                }

                return (
                  <div className={styles.eventInfo}>
                    {getStartEndTime(event.original)}
                  </div>
                );
              };

              return (
                <div className={styles.agendaEvent} style={{ color: color }}>
                  <div className={styles.eventBackground} />
                  <div className={styles.eventContent}>
                    <div className={styles.eventTitle}>{event.title}</div>
                    {renderStartEndTime(true)}
                    {calendarAppointmentCardShowBusinesspartner.value &&
                      renderBusinessPartner()}
                    {calendarAppointmentCardShowProject.value &&
                      renderProject()}
                  </div>

                  <div className={styles.eventExtra}>
                    {appointment?.privat ? (
                      <div className={styles.eventPrivateIcon}>
                        <PersonIcon fontSize="inherit" />
                      </div>
                    ) : null}
                  </div>

                  {calendarCardColorBy ===
                    CalendarCardColorBy.AppointmentStatus &&
                  !!appointment?.user ? (
                    <div className={styles.eventParticipants}>
                      <CalendarUserIcon
                        type={
                          appointment.user.userKind === UserClass.RESSOURCE
                            ? 'resource'
                            : 'user'
                        }
                        userId={appointment.user.id}
                      />
                    </div>
                  ) : null}
                </div>
              );
            }}
            onEventClick={({ event }) => {
              const appointment = {
                ...event.original,
                endDate: getCalendarEndDate(
                  event.allDay,
                  event.end as Date
                ).toISO(),
              } as Appointment;

              setPopupConfig({
                appointment,
                open: true,
              });

              // Remove a hover popover if there is any.
              onEventHoverOut();
            }}
            onEventHoverIn={
              calendarAppointmentShowTooltip.value ? onEventHoverIn : undefined
            }
            onEventHoverOut={
              calendarAppointmentShowTooltip.value ? onEventHoverOut : undefined
            }
            onEventDragEnd={onDragEnd}
            {...mbscLocalePatched}
          />
          <EventPopover
            hoveredEvent={hoveredEvent}
            showBusinesspartner={
              calendarAppointmentTooltipShowBusinesspartner.value
            }
            showProject={calendarAppointmentTooltipShowProject.value}
            showNote={calendarAppointmentTooltipShowNote.value}
          />
        </div>
      </div>

      <AppointmentPopup
        {...popupConfig}
        onClose={() => setPopupConfig((config) => ({ ...config, open: false }))}
        onEditClick={() => {
          setPopupConfig((config) => ({ ...config, open: false }));
          onOpenAppointmentMask(
            popupConfig.appointment.parentId || popupConfig.appointment.id
          );
        }}
      />

      <AppointmentQuickCreateDialog
        {...createDialogConfig}
        onClose={() =>
          setCreateDialogConfig((config) => ({ ...config, open: false }))
        }
        onOpenMask={(appointment) => {
          onOpenAppointmentMask(null, JSON.stringify(appointment));
          setCreateDialogConfig((config) => ({ ...config, open: false }));
        }}
      />
    </>
  );
}
