import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const ARTICLE_BOM_COMPONENT_DATA: Article<EMode.query> = {
  id: null,
  number: null,
  longtext: null,
  articleKind: null,
  name: null,
  unit: {
    id: null,
    name: null,
  },
  entryPrice: null,
  netPrice: null,
  netPrice2: null,
  netPrice3: null,
  purchaseOnly: null,
  articlePrices: {
    purchasePriceList: [
      {
        id: null,
        purchasePrice: null,
      },
    ],
    singlePriceList: [
      {
        id: null,
        price: null,
      },
    ],
  },
};

export const BOM_COMPONENT_DATA: BOMComponent<EMode.query> = {
  id: null,
  name: null,
  amount: null,
  calculationLogic: null,
  calculationLogicFactor: null,
  onlyShopping: null,
  length: null,
  width: null,
  article: ARTICLE_BOM_COMPONENT_DATA,
};
