import styles from './ListEntityPicker.module.scss';

import { ArrowBack, Star, StarBorder } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  RadioGroup,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Index, InfiniteLoader } from 'react-virtualized';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { ReactComponent as ContextMenuIcon } from '@work4all/assets/icons/context-menu-icon.svg';
import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { IResponse, useDataProvider } from '@work4all/data';
import { FavoritesContext } from '@work4all/data/lib/hooks/favorites/FavoritesProvider';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';
import { EntityPickerSettingsFilterBy } from '@work4all/data/lib/settings/settings';

import {
  DataRequest,
  KeysArguments,
  SortDirection,
} from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData } from '@work4all/models/lib/File';

import { reactRefSetter } from '@work4all/utils';
import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { Chip } from '../../../../dataDisplay/chip/Chip';
import { ChipList } from '../../../../dataDisplay/chip/ChipList';
import { Divider } from '../../../../dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '../../../../input/checkbox-radio-item';
import { usePickerSettings } from '../../hooks/use-picker-settings';
import { useSelectionModel } from '../../hooks/use-selection-model';
import { EntityLike } from '../../types';
import type { Selection } from '../../utils/selection-model';

import { KeyNavigationHandlerContainer } from './KeyNavigationHandlerContainer';
import {
  IFilterPickerConfig,
  useFilterPickerConfig,
} from './use-filter-picker-config';
import {
  filterOutById,
  getEntityId,
  prepareRequest,
  prepareSettingsFilters,
  toSelectionValue,
} from './utils';

import { EntityPickerTab, EntityPickerTabBar, FilterTextInput } from '..';

export type { IFilterPickerConfig };

export enum QUERYVALUE {
  ALLITEMS = 'ALLITEMS',
  QUERY = 'QUERY',
}

export type ListEntityPickerWithoutTabsProps<
  TValue extends EntityLike,
  TMultiple extends boolean
> = EntityPickerCommonProps<TValue, TMultiple> &
  Omit<EntityPickerTabProps<TValue>, 'label'>;

export type ListEntityPickerWithTabsProps<
  TValue extends EntityLike,
  TMultiple extends boolean
> = EntityPickerCommonProps<TValue, TMultiple> & {
  tabs: EntityPickerTabProps<TValue>[];

  /**
   * This function will be called to determine to which tab a given item
   * belongs. This will decide which tab will be active when a picker is opened
   * (only it there is an initial value) and wether or not the initially
   * selected items will be shown at the top of the list in the active tab.
   *
   * @param value - This will be the one of the items passed as initial value
   * to the picker. The function will be called for every item in the list
   * (or the only item when using single-selection mode).
   */
  getTabIndex?: (value: TValue) => number;

  /**
   * This function will be called after the initial mount and whenever a user
   * switches the current tab.
   *
   * @param entity - The entity type that pas passed to the selected tab's config.
   */
  onTabChange?: (entity: Entities) => void;
};

type EntityPickerCommonProps<
  TValue extends EntityLike,
  TMultiple extends boolean
> = {
  /**
   * get a reference to prgramatically call inner functions of the list
   */
  pickerInterfaceRef?: React.RefObject<ListEntityPickerInterface<TValue>>;

  /**
   * Whether to use the picker in single or multiple selection mode.
   * In single-selection mode only one option can be selected at a time
   * and selecting a new option will deselect the previous one.
   * In multi-selection mode multiple values can be selected at the same time
   * and the list item will have a checkbox to represent selected items.
   *
   * **Important**: Using tabs in with with multi-selection is currently not
   * supported. Passing `multiple={false}` is still required for possible
   * future compatibility.
   */
  multiple: TMultiple;

  hideCheckboxes?: TMultiple;

  /**
   * Define if single selectable values can be cleared.
   *
   * @default true
   */
  clearable?: boolean;

  /**
   * Currently selected value(s). The initial value of this prop will be used
   * to display the list of initially selected entities on top of the list.
   */
  value: Selection<TValue, TMultiple>;

  /**
   * This function will be called whenever the picker's value changes as a
   * result of user interaction. The value will be the entity object (or `null`
   * if nothing is selected) in single-selection mode and an array of entities
   * in multi-selection mode.
   */
  onChange: (value: Selection<TValue, TMultiple>) => void;

  /**
   * This value will determine the height of the picker component.
   * If the total number of items is less that or equal to this value,
   * the filter text input and the top section with initially or
   * recently selected items will not be displayed.
   *
   * @default 5
   */
  maxItems?: number;

  /**
   * This will modify the chip display value
   *
   */
  renderChipContent?: (item: TValue) => React.ReactNode;

  /**
   * This function will be called after picker renders with different size.
   * This can be used to update a popover position, for example.
   */
  onResize?: () => void;

  /**
   * This function will be called when the user inputs a value into the search field.
   */
  onSearchValueChange?: (value: string) => void;

  /**
   * Suppress filtering
   */
  suppressFilter?: boolean;

  /**
   * Enables or disables certain features of the picker.
   *
   * In "simple" mode the text search input and chips section will be hidden and
   * the list items will be displayed in the same order as returned by the API
   * without tampering.
   *
   * In "advanced" mode will will show a search text input to filter the results
   * and in `multiple` mode will display selected items as chips above the
   * input. Additionally the items order will be changed to show selected items
   * (and last N selected, if enabled) at the top of the list. These "static"
   * items will be remembered on mount and will not change if you
   * select/deselect items during the lifetime of the component.
   *
   * In "auto" mode will choose on of the above depending on the size of the
   * dataset returned by the initial query. It will be set to "simple" while
   * fetching the initial data and might change to "advanced" if the dataset
   * size grows above the threshold, but will never change back from "advanced"
   * to "simple".
   *
   * Since in "auto" mode the actual UI will likely change after the initial
   * query is finished, it is recommended to explicitly set the layout to
   * "simple" or "advanced" whenever possible to avoid changes in the UI.
   *
   * @default "auto"
   */
  layout?: 'simple' | 'advanced' | 'auto';
  resultLayout?: QUERYVALUE;

  inputType?: React.HTMLInputTypeAttribute;

  /**
   * You can configure this picker to be used as a "smart filter" by providing
   * parent entity type, the name of the property of the parent entity that this
   * picker is used for and the value of the current filter.
   *
   * When this config is provided, the picker will only display items that are
   * not filtered out by the existing filter and will also display the number of
   * entities that will be matched if a given list item is selected.
   */
  filterConfig?: IFilterPickerConfig;

  initialQuery?: string;

  noResultsString?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vars?: any;

  fullscreen?: boolean;

  /**
   * List of items that are visible, but not clickable.
   */
  disabledItemsIds?: (number | string)[];
  onClose?: () => void;
  onListChanged?: (items: TValue[]) => void;

  autoFocus?: boolean;
};

export type EntityPickerTabProps<TValue extends EntityLike> = {
  entity: Entities;
  /**
   * Text to display inside tab controls.
   */
  label: string;

  /**pass a fixed set of data instead of querying it automatically eg for lokkuptypes that dont support querying */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fixedDataSet?: any; //IResponse<TValue>;

  /**pass a additional items to be displayed in the result list */
  additionalItems?: TValue[]; //IResponse<TValue>;

  /**
   * Configure the returned fields for the GraphQL query.
   * This value will be passed to to `useDataProvider`.
   * Make sure to add `id` and all the fields that you use in the `renderItem`.
   *
   * This value must be memoized.
   */
  data: TValue;

  /**
   * Any additional filters to send in GraphQL query.
   *
   * As these filters are prepended at the beginning of the filters list,
   * if you add a filter with the same field as in `filterBy`,
   * it will be overrriden by the generated filter.
   */
  prefilter?: unknown[];

  /**
   * This is prefilter which is applied when search query exist instead of prefilter field.
   * If it's empty prefilter will be applied as default.
   */
  searchPrefilter?: unknown[];

  /** Field name that will be used to create a filter for GraphQL query. */
  filterBy: string | string[];

  /**
   * Field name that will be used to specify sort for GraphQL query.
   */
  sortBy: string;

  /**
   * Field name that will be used to specify sort direction for GraphQL query.
   */
  sortByDirection?: SortDirection;

  /**
   * If `true` fixed data will be sorted according to `sortBy` and `sortByDirection`.
   */
  sortFixedData?: boolean;

  /**
   * This function will be called for every item in the list. You can render
   * any content here. The returned element will be wrapper inside
   * MUI's <ListItemButton> (height=50px) alongside some additional elements
   * if needed (checkbox, clear icon, etc...).
   */
  renderItemContent: (value: TValue, allItems?: TValue[]) => React.ReactNode;

  /**
   * Placeholder text to display inside the text input. By default will
   * display the same text for all pickers, but can be customized to
   * provide a user a better hint at how the items will be searched.
   */
  placeholder?: string;

  /**
   * Provide a function to customize the request passed to the `useDataProvider`
   * hook.
   *
   * When this prop is present, all other props configuring data fetching will
   * be ignored.
   */
  prepareRequest?: (params: { search: string }) => DataRequest;

  /**
   * Transform the value returned from `useDataProvider` before using it. This
   * can be useful if you want to construct a custom picker that doesn't have a
   * well suited API query, or if you want to map the results to some other
   * entity type.
   *
   * Due to how the `useDataProvider` works, this function can be called with
   * data loaded for a different tabs (when using multiple tabs). If you load
   * differently shaped data in different tabs, you should make sure that this
   * function can gracefully fall back when called with an unsupported dataset.
   */
  transformResponse?: <T>(response: IResponse<T>) => IResponse<TValue>;

  initialQuery?: string;

  favorites?: boolean;

  /**
   * @default false
   */
  useSearchHistory?: boolean;

  completeDataResponse?: boolean;

  keysArguments?: KeysArguments;
};

export type ListEntityPickerInterface<TValue> = {
  toggle: (val: TValue) => void;
  setQueryString: (val) => void;
  handleKeyboardEvent: (event: KeyboardEvent | React.KeyboardEvent) => void;
};

export function ListEntityPicker<
  TValue extends EntityLike,
  TMultiple extends boolean
>(props: ListEntityPickerWithoutTabsProps<TValue, TMultiple>): JSX.Element;
export function ListEntityPicker<
  TValue extends EntityLike,
  TMultiple extends boolean
>(props: ListEntityPickerWithTabsProps<TValue, TMultiple>): JSX.Element;

export function ListEntityPicker<
  TValue extends EntityLike,
  TMultiple extends boolean
>(
  props:
    | ListEntityPickerWithoutTabsProps<TValue, TMultiple>
    | ListEntityPickerWithTabsProps<TValue, TMultiple>
) {
  const {
    value,
    onChange,
    onSearchValueChange,
    initialQuery,
    renderChipContent,
    multiple,
    resultLayout = 'ALLITEMS',
    hideCheckboxes,
    maxItems = 5,
    clearable = true,
    pickerInterfaceRef: ref,
    filterConfig: context = null,
    layout: layoutProp = 'auto',
    inputType,
    noResultsString,
    vars,
    fullscreen,
    disabledItemsIds = [],
    onListChanged,
    onClose,
    autoFocus,
  } = props;
  const { t } = useTranslation();
  const { lastSearchItems, saveSearchItem } = useSearchHistory();

  const LIST_ITEM_HEIGHT = remToPx(3);
  const EMPTY_LIST = [] as never[];
  const MAX_SEARCH_HISTORY_ITEMS = 5;

  const inputRef = useRef<HTMLInputElement>(null);

  const showTabs = 'tabs' in props && props.tabs.length > 1;
  const getTabIndex = showTabs ? props.getTabIndex : undefined;

  const tabs: EntityPickerTabProps<TValue>[] =
    'tabs' in props
      ? props.tabs
      : [
          {
            label: '',
            entity: props.entity,
            data: props.data,
            prefilter: props.prefilter,
            searchPrefilter: props.searchPrefilter,
            filterBy: props.filterBy,
            sortBy: props.sortBy,
            sortByDirection: props.sortByDirection,
            renderItemContent: props.renderItemContent,
            fixedDataSet: props.fixedDataSet,
            additionalItems: props.additionalItems,
            placeholder: props.placeholder,
            prepareRequest: props.prepareRequest,
            transformResponse: props.transformResponse,
            useSearchHistory: props.useSearchHistory,
            favorites: props.favorites,
            completeDataResponse: props.completeDataResponse,
            sortFixedData: props.sortFixedData,
            keysArguments: props.keysArguments,
          },
        ];

  const [_activeTabIndex, setActiveTabIndex] = useState(() => {
    if (!getTabIndex) return 0;

    // Select the first tab that has an item selected.
    // If nothing is selected, select the first tab.

    const selection = toSelectionValue(value);

    if (selection.length === 0) return 0;

    const tabIndexes = selection.map((value) => getTabIndex(value));

    return Math.min(...tabIndexes);
  });

  // In case the current index ever goes out of bound, clamp it.
  const activeTabIndex = Math.min(tabs.length - 1, _activeTabIndex);

  const activeTab = tabs[activeTabIndex];

  const {
    entity,
    data,
    prefilter,
    searchPrefilter,
    filterBy,
    sortBy,
    sortByDirection,
    renderItemContent,
    placeholder,
    fixedDataSet,
    additionalItems = [],
    prepareRequest: prepareRequestProp,
    transformResponse: transformResponseProp,
    useSearchHistory: useSearchHistoryProp,
    favorites,
    completeDataResponse,
    sortFixedData,
    keysArguments,
  } = activeTab;

  const onTabChange = 'tabs' in props ? props.onTabChange : undefined;

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    onTabChange?.(entity);
  }, [entity, onTabChange]);

  const initialSelected = useRef(toSelectionValue(value)).current;

  const hasFilterContext = context !== null;

  const selection = useSelectionModel({
    multiple,
    keyFn: getEntityId,
    initialSelected,
  });

  useEffect(() => {
    selection.setSelected(toSelectionValue(value));
  }, [selection, value]);

  const handleSelect = (value: TValue) => {
    selection.select(value);
    onChange(selection.getSelected());
  };

  const handleDeselect = (value: TValue) => {
    selection.deselect(value);
    onChange(selection.getSelected());
  };

  const handleToggle = (value: TValue) => {
    if (selection.isSelected(value)) {
      if (multiple || clearable) {
        handleDeselect(value);
      }
    } else {
      if (
        useSearchHistoryProp &&
        [
          Entities.customer,
          Entities.supplier,
          Entities.project,
          Entities.article,
        ].includes(entity)
      ) {
        if (value?.id) {
          saveSearchItem(entity, {
            id: value?.id.toString(),
            //eslint-disable-next-line
            //@ts-ignore
            number: value?.number,
            //eslint-disable-next-line
            //@ts-ignore
            name: value?.name,
          });
        }
      }
      handleSelect(value);
    }
    setQuery('');
    inputRef.current?.focus();
  };

  const [activeRowIndex, setActiveRowIndex] = useState<number>(0);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showFavorites, setShowFavorites] = useState(false);

  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const enableSearchHistory =
    queryTrimmed === '' &&
    !!useSearchHistoryProp &&
    !hasFilterContext &&
    !fixedDataSet &&
    !showFavorites;

  const searchHistory = useMemo<FileData[]>(() => {
    if (!enableSearchHistory) return [];
    return lastSearchItems[entity]?.slice(0, MAX_SEARCH_HISTORY_ITEMS) ?? [];
  }, [enableSearchHistory, lastSearchItems, entity]);

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  // TODO This ref doesn't seem to be used anywhere. Is it needed?
  //
  // If it is, it should probably be changed to not use the `toggle` function and
  // update the selection value directly to so it doesn't call the `onChange`
  // prop. But you can already do it by just passing a new value to the
  // `selection` prop, so I'm not sure why this is even needed.
  //
  // Same fo the query string. If it needs to work in controlled mode, its
  // better to just add a new prop and pass the value directly instead.
  useImperativeHandle<
    ListEntityPickerInterface<TValue>,
    ListEntityPickerInterface<TValue>
  >(ref, () => {
    return {
      toggle: (val) => handleToggle(val),
      setQueryString: setQuery,
      handleKeyboardEvent: handleKeyNavigation,
    };
  });

  const {
    settings,
    filteredEntitiesSetting,
    setHideClosedPickerEntities,
    sortedPickerEntities,
    setSortedPickerEntities,
  } = usePickerSettings(entity);

  const handleSettingsSortChange = (_e, value: string) => {
    setSortedPickerEntities(value);
  };

  const handleSettingsFiltersChange = (value: EntityPickerSettingsFilterBy) => {
    const filterIndex = filteredEntitiesSetting?.findIndex(
      (filter) => filter.key === value.key
    );
    const updatedFilters = [...filteredEntitiesSetting];

    if (filterIndex !== -1) {
      // If the filter exists, delete it
      updatedFilters.splice(filterIndex, 1);
    } else {
      // If the filter does not exist, add it
      updatedFilters.push(value);
    }
    setHideClosedPickerEntities(updatedFilters.filter(Boolean));
  };

  const favoritesContext = useContext(FavoritesContext);

  const preparedSettingsFilter = useMemo(
    () => prepareSettingsFilters(filteredEntitiesSetting),
    [filteredEntitiesSetting]
  );
  const filterResult = useFilterPickerConfig(
    context
      ? {
          ...context,
          data,
          sortBy: sortedPickerEntities ?? sortBy,
          sortByDirection,
          filterBy,
          settingsFilters: filteredEntitiesSetting,
          query: queryTrimmed,
        }
      : null
  );

  const dataRequest = useMemo(
    () =>
      prepareRequestProp
        ? prepareRequestProp({ search: queryTrimmed })
        : {
            ...prepareRequest({
              entity,
              query: queryTrimmed,
              prefilter,
              data,
              filterBy,
              searchPrefilter,
              settingsFilter: preparedSettingsFilter,
              sortBy: sortedPickerEntities ?? sortBy,
              sortByDirection,
              searchHistory,
              skip: hasFilterContext,
              vars,
              showFavorites,
              keysArguments,
            }),
            completeDataResponse,
          },
    [
      prepareRequestProp,
      queryTrimmed,
      entity,
      searchPrefilter,
      showFavorites,
      prefilter,
      data,
      filterBy,
      preparedSettingsFilter,
      sortedPickerEntities,
      sortBy,
      sortByDirection,
      searchHistory,
      hasFilterContext,
      vars,
      completeDataResponse,
      keysArguments,
    ]
  );

  const PAGE_SIZE = 100;

  const _regularResult = usePatchedDefaultData(
    useDataProvider<TValue>(
      showFavorites
        ? favoritesContext.transformDataRequest({
            ...dataRequest,
          })
        : dataRequest,
      fixedDataSet !== undefined,
      PAGE_SIZE
    ),
    PAGE_SIZE
  );

  let regularResult = useMemo(() => {
    if (searchHistory.length > 0) {
      const sortBySearchHistoryOrder = (
        result: IResponse<TValue>
      ): IResponse<TValue> => {
        const map = new Map(result.data.map((item) => [item.id, item]));

        const itemsSorted: TValue[] = [];

        for (const searchHistoryItem of searchHistory) {
          const id = Number(searchHistoryItem.id);
          const item = map.get(id);

          if (item) {
            itemsSorted.push(item);
            map.delete(id);
          }
        }

        itemsSorted.push(...map.values());

        return { ...result, data: itemsSorted };
      };

      //eslint-disable-next-line
      //@ts-ignore
      return sortBySearchHistoryOrder(_regularResult);
    }

    if (transformResponseProp) {
      return transformResponseProp(_regularResult);
    }

    return _regularResult;
  }, [searchHistory, transformResponseProp, _regularResult]);

  const { pending } = _regularResult;

  if (fixedDataSet !== undefined) {
    regularResult = { ...fixedDataSet };
    if (query !== '' && !props.suppressFilter) {
      const filterArray = filterBy instanceof Array ? filterBy : [filterBy];
      const filterExpr = new RegExp(`.*${query}.*`, 'i');
      const filtered = regularResult.data.filter((el) => {
        let found = false;
        for (const fieldIdx in filterArray) {
          if (el[filterArray[fieldIdx]]?.toString().match(filterExpr)) {
            found = true;
            break;
          }
        }
        return found;
      });
      regularResult.data = filtered;
      regularResult.total = filtered.length;
    }

    if (sortFixedData) {
      if (sortByDirection === SortDirection.DESCENDING) {
        regularResult.data = [...regularResult.data].sort((a, b) =>
          b[sortBy]?.toString().localeCompare(a[sortBy]?.toString())
        );
      } else {
        regularResult.data = [...regularResult.data].sort((a, b) =>
          a[sortBy]?.toString().localeCompare(b[sortBy]?.toString())
        );
      }
    }
  }

  const itemsTotal = hasFilterContext
    ? filterResult.totalCount
    : regularResult.total;

  // Remember the max number of items in the list to decide
  // whether or not to show the filter text input.
  const itemsTotalMaxRef = useRef<number>(itemsTotal);
  itemsTotalMaxRef.current = Math.max(itemsTotalMaxRef.current, itemsTotal);

  function getResolvedLayout() {
    if (layoutProp === 'auto') {
      return showTabs ||
        itemsTotalMaxRef.current > maxItems ||
        searchPrefilter ||
        favorites ||
        enableSearchHistory
        ? 'advanced'
        : 'simple';
    } else {
      return layoutProp;
    }
  }

  const layout = getResolvedLayout();

  const showSimpleView = layout === 'simple' && !fullscreen;
  const showPrependedItems = !multiple && !showSimpleView && !query;

  const items = useMemo(() => {
    return [
      ...additionalItems,
      ...(hasFilterContext ? filterResult.nodes : regularResult.data),
    ];
  }, [
    additionalItems,
    filterResult.nodes,
    hasFilterContext,
    regularResult.data,
  ]);

  const initialSelectedInActiveTab = useMemo(() => {
    return getTabIndex
      ? initialSelected.filter((value) => getTabIndex(value) === activeTabIndex)
      : initialSelected;
  }, [activeTabIndex, getTabIndex, initialSelected]);

  const prependedItems = (
    showPrependedItems &&
    initialSelectedInActiveTab.length > 0 &&
    !showFavorites
      ? initialSelectedInActiveTab
      : EMPTY_LIST
  ) as TValue[];

  // Remove the prepended options from the main list, so that we don't show
  // them twice.
  const filteredItems = useMemo(() => {
    return items ? filterOutById(items, prependedItems) : [];
  }, [items, prependedItems]);

  // Add static options to the beginning of the list.
  // These options have already been filtered out from the main list, so they
  // will not be shown twice and the overall number of items should remain
  // the same.
  const allItems = useMemo(
    () => [...prependedItems, ...filteredItems],
    [prependedItems, filteredItems]
  );

  useEffect(() => {
    onListChanged?.(allItems);
  }, [onListChanged, allItems]);

  function isRowLoaded({ index }: Index) {
    return allItems[index] != null;
  }

  const loadMoreRows = () => {
    if (hasFilterContext) return filterResult.fetchMore?.();
    const { startIndex, stopIndex } = calculateRange(
      regularResult.data.length,
      PAGE_SIZE
    );
    return regularResult.fetchMore?.(startIndex, stopIndex);
  };

  function noRowsRenderer() {
    return (
      <ListItem style={{ height: LIST_ITEM_HEIGHT }} disablePadding>
        <ListItemButton
          className={styles['list-item-button']}
          dense
          role={undefined}
          disabled
        >
          <ListItemText
            primary={noResultsString || t('PICKER.NO_RESULTS_FOUND')}
            classes={{ primary: styles['nothing-found'] }}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  const infiniteListRef = useRef<VariableSizeList>();

  // If the picker updates its size we need to reposition the popover.
  // It can resize because of search input showing/hiding or number of
  // displayed items changing.
  const onResize = useLatest(props.onResize);

  useEffect(() => {
    onResize.current?.();
  }, [onResize, showSimpleView, maxItems]);

  const total = Math.max(1, itemsTotal, allItems.length);

  // The last row must be 1px shorter to avoid overflow.
  function getRowHeight(index: number) {
    return index === total - 1 ? LIST_ITEM_HEIGHT - 1 : LIST_ITEM_HEIGHT;
  }

  const handleKeyNavigation = useEventCallback(function handleKeyNavigation(
    e: React.KeyboardEvent<HTMLElement>
  ) {
    if (e.code === 'ArrowUp' && activeRowIndex > 0) {
      setActiveRowIndex(activeRowIndex - 1);
      infiniteListRef.current?.scrollToItem(activeRowIndex - 1);
    } else if (e.code === 'ArrowDown' && activeRowIndex < allItems.length - 1) {
      setActiveRowIndex(activeRowIndex + 1);
      infiniteListRef.current?.scrollToItem(activeRowIndex + 1);
    } else if (e.code === 'Enter') {
      e.preventDefault();
      if (allItems[activeRowIndex]) {
        if (disabledItemsIds.includes(allItems[activeRowIndex].id)) return;
        handleToggle(allItems[activeRowIndex]);
        setQuery('');
      }
    } else {
      setActiveRowIndex(0);
      infiniteListRef.current?.scrollToItem(0);
    }
  });

  const handleToggleFavorites = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setShowFavorites(!showFavorites);
      inputRef.current?.focus();
    },
    [showFavorites]
  );

  const handleToggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    inputRef.current?.focus();
  }, [showSettings]);

  const handleTabChange = (tab: number) => {
    setActiveTabIndex(tab);
    scrollToTopAfterNextRender.current = true;
    inputRef.current?.focus();
  };

  const showLoadingPlaceholder =
    total === 0 && (hasFilterContext ? filterResult : regularResult).loading;

  const scrollToTopAfterNextRender = useRef(false);

  useEffect(() => {
    if (scrollToTopAfterNextRender.current) {
      scrollToTopAfterNextRender.current = false;
      infiniteListRef.current?.scrollToItem(0);
    }
  });

  const allItemsForEmptyQuery = resultLayout === QUERYVALUE.ALLITEMS;

  const contextMenuRef = useRef(null);

  return (
    <KeyNavigationHandlerContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: fullscreen ? '100%' : 0,
      }}
      onKeyDown={handleKeyNavigation}
      autoFocus={autoFocus}
    >
      {!showSimpleView && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {multiple && (selection.getSelected() as any).length > 0 && (
            <div className={styles.chipListWrapper}>
              <ChipList>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {(selection.getSelected() as any).map((el) => {
                  return (
                    <Chip
                      key={getEntityId(el)}
                      maxWidth={false}
                      handleDelete={() => {
                        handleDeselect(el);
                      }}
                      label={
                        renderChipContent?.(el) ||
                        el?.displayName ||
                        el?.[filterBy instanceof Array ? filterBy[0] : filterBy]
                      }
                    />
                  );
                })}
              </ChipList>
            </div>
          )}
          <div
            className={clsx(styles.textWrapper, {
              [styles.showFavorites]: favorites,
            })}
          >
            {fullscreen ? (
              <IconButton ref={contextMenuRef} onClick={() => onClose()}>
                <ArrowBack />
              </IconButton>
            ) : null}
            <Box sx={{ flexGrow: 1, paddingLeft: '0.5rem' }}>
              <FilterTextInput
                ref={inputRef}
                autoFocus
                value={query}
                onChange={(e) => {
                  onSearchValueChange?.(e);
                  setQuery(e);
                }}
                placeholder={placeholder ?? t('PICKER.SEARCH.DEFAULT')}
                inputType={inputType}
                className={styles['list-picker-filter-input']}
              />
            </Box>
            {favorites && (
              <Button
                variant="outlined"
                color={showFavorites ? 'primary' : 'secondary'}
                onClick={handleToggleFavorites}
                className={clsx(styles['favorites-button'], {
                  [styles['favorites-button-selected']]: showFavorites,
                  [styles['favorites-button-no-settings']]: !settings,
                })}
              >
                {t('COMMON.FAVORITES').toUpperCase()}
              </Button>
            )}
            {settings && (
              <>
                <IconButton ref={contextMenuRef} onClick={handleToggleSettings}>
                  <ContextMenuIcon />
                </IconButton>
                {showSettings && (
                  <Popover
                    classes={{ paper: styles['settings-popover'] }}
                    open={showSettings}
                    onClose={() => setShowSettings(false)}
                    anchorEl={contextMenuRef.current}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Paper className={styles['settings-paper']}>
                      {settings.filter?.options?.length && (
                        <>
                          <Divider title={t('COMMON.FILTER')} size="body" />
                          {settings.filter.options.map((option) => (
                            <CheckboxRadioItem
                              key={option.key}
                              checked={
                                !filteredEntitiesSetting
                                  ?.map((setting) => setting.key)
                                  ?.includes(option.key)
                              }
                              label={t(
                                `INPUTS.SHOW_${option.key.toUpperCase()}`
                              )}
                              value={option.key}
                              onChange={() =>
                                handleSettingsFiltersChange(option)
                              }
                            />
                          ))}
                        </>
                      )}

                      <Divider title={t('COMMON.SORTING')} size="body" />
                      <RadioGroup onChange={handleSettingsSortChange}>
                        {settings.sort?.options?.map((option) => (
                          <CheckboxRadioItem
                            key={option}
                            checked={sortedPickerEntities === option}
                            value={option}
                            control="radio"
                            label={t(`INPUTS.SORT.${option.toUpperCase()}`)}
                          />
                        ))}
                      </RadioGroup>
                    </Paper>
                  </Popover>
                )}
              </>
            )}
          </div>
        </>
      )}

      {(showLoadingPlaceholder || pending) && <LinearProgress />}

      {showTabs && (
        <EntityPickerTabBar value={activeTabIndex} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <EntityPickerTab key={index} value={index}>
              {tab.label}
            </EntityPickerTab>
          ))}
        </EntityPickerTabBar>
      )}

      <div
        style={{
          height: LIST_ITEM_HEIGHT * maxItems - 1,
          flex: 'auto',
        }}
      >
        {!showLoadingPlaceholder && !pending && (
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={total}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <VariableSizeList
                      ref={reactRefSetter(registerChild, infiniteListRef)}
                      className="custom-scrollbar"
                      width={width}
                      // TODO Use some other alternative to <AutoSizer>?
                      // AutoSizer rounds values to the nearest integer.
                      // This sometimes causes the vertical scrollbar to appear when
                      // there is not enough space inside the container to display
                      // `maxItems` rows. With this there is no additional scrollbar,
                      // but the list is 1px shorter, so if there is exactly
                      // `maxItems` rows in the list, the scrollbar will appear.
                      // Reduce the height of the last row to avoid this.
                      height={height - 1}
                      itemSize={getRowHeight}
                      itemCount={
                        allItemsForEmptyQuery ? total : query.length ? total : 0
                      }
                      overscanCount={3}
                      itemData={{
                        activeRowIndex,
                        multiple,
                        hideCheckboxes,
                        clearable,
                        disabledItemsIds,
                        renderItemContent: (item) =>
                          renderItemContent(item as TValue, allItems),
                        items: allItemsForEmptyQuery
                          ? allItems
                          : query.length
                          ? allItems
                          : [],
                        counts: filterResult.counts,
                        isSelected: (item: TValue) =>
                          selection.isSelected(item),
                        onToggle: handleToggle,
                        isFav: (item) =>
                          (favoritesContext?.register[entity] || []).includes(
                            item.id
                          ),
                        onFavToggle: favorites
                          ? (item) => {
                              favoritesContext.toggleFavorite(entity, item.id);
                            }
                          : undefined,
                      }}
                      onItemsRendered={({
                        visibleStartIndex: startIndex,
                        visibleStopIndex: stopIndex,
                      }) => onRowsRendered({ startIndex, stopIndex })}
                      innerElementType={
                        allItems.length === 0 ? noRowsRenderer : undefined
                      }
                    >
                      {RenderItem}
                    </VariableSizeList>
                  );
                }}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </div>
    </KeyNavigationHandlerContainer>
  );
}

interface RenderItemData<TValue extends EntityLike> {
  items: TValue[];
  counts: Record<string, number>;
  activeRowIndex: number;
  multiple: boolean;
  hideCheckboxes?: boolean;
  clearable: boolean;
  renderItemContent: (item: TValue) => React.ReactNode;
  isSelected: (item: TValue) => boolean;
  onToggle: (item: TValue) => void;
  onFavToggle?: (item: TValue) => void;
  isFav: (item: TValue) => boolean;
  disabledItemsIds?: (number | string)[];
}

const RenderItem = memo(function RenderItem<TValue extends EntityLike>(
  props: ListChildComponentProps<RenderItemData<TValue>>
) {
  const { data, index, style } = props;
  const {
    items,
    counts,
    activeRowIndex,
    multiple,
    hideCheckboxes,
    clearable,
    isSelected,
    onToggle,
    renderItemContent,
    onFavToggle,
    isFav,
    disabledItemsIds,
  } = data;

  const value = items[index];

  if (!value) {
    return (
      <ListItem style={style} disablePadding>
        <Skeleton variant="text" height="100%" width="100%" />
      </ListItem>
    );
  }

  const active = activeRowIndex === index;
  const selected = isSelected(value);
  const count = counts[getEntityId(value)] ?? null;

  return (
    <ListItem
      style={style}
      disablePadding
      secondaryAction={
        onFavToggle && (
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation();
              onFavToggle(value);
            }}
          >
            {isFav(value) ? (
              <Star className={styles.favActive} />
            ) : (
              <StarBorder />
            )}
          </IconButton>
        )
      }
    >
      <ListItemButton
        disabled={disabledItemsIds.includes(value.id)}
        className={clsx(styles['list-item-button'], {
          [styles['list-item-button-active']]: active,
        })}
        dense
        role={undefined}
        onClick={() => onToggle(value)}
        selected={selected}
      >
        {multiple && !hideCheckboxes && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
        )}

        <Stack alignItems={'center'} direction="row" overflow="hidden" flex={1}>
          <div style={{ overflow: 'hidden', width: '100%' }}>
            {renderItemContent(value)}
          </div>

          {count !== null && (
            <Typography variant="body2" flex="none" ml={1}>
              ({count})
            </Typography>
          )}
        </Stack>

        {clearable && !multiple && selected ? (
          <ClearIcon className={styles['deselect-icon']} />
        ) : null}
      </ListItemButton>
    </ListItem>
  );
});

// Copied from useQueryTableData
function usePatchedDefaultData(response: IResponse, pageSize: number) {
  return useMemo(
    () => ({
      ...response,
      total: Math.min(response.total, response.data.length + 3),

      fetchMore: async () => {
        const { startIndex, stopIndex } = calculateRange(
          response.data.length,
          pageSize
        );
        await response.fetchMore(startIndex, stopIndex);
      },
    }),
    [response, pageSize]
  );
}

const calculateRange = (length: number, pageSize: number) => {
  const startIndex = length;
  const stopIndex = startIndex + pageSize - 1;
  return { startIndex, stopIndex };
};
