import styles from './EditableCell.module.scss';

import { Box, Input, TextareaAutosize } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { CellProps } from 'react-table';

import {
  formatAsFloat,
  formatInputValueAsFloat,
  parseAsFloat,
} from '@work4all/utils';

import { Unit } from '../../../../../../input/labeled-currency-input/components/unit/Unit';
import { EditModeCell } from '../../../../plugins/useEditMode';
import { ExtendedNumberCell } from '../../../../utils';

export interface EditableCellNumericProps {
  min?: number;
  max?: number;
  step?: number;
  transform?: (input: number) => number;
  transformInputValue?: (input: string) => string;
  unit?: string;
}

interface EditableCellUserProps extends EditableCellNumericProps {
  onSubmit?: () => void;
  onExit?: () => void;
  onNext?: (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    dir: 1 | -1
  ) => void;
  onChange?: (input: string) => void;
  onSelectionChange?: (start?: number, end?: number) => void;
  selectinRange: { start: number; end: number };
  autoFocus?: boolean;
  textarea?: boolean;
  type?: string;
  disabled?: boolean;
  isValueVisble?: boolean;
  isVisible: boolean;
  maxHeight: number;
  showZeros?: boolean;
}

export interface EditableCellProps
  extends CellProps<Record<string, unknown>>,
    EditableCellUserProps {
  cell: EditModeCell & ExtendedNumberCell;
}

const resultWithZero = (value: string | number, result: string | number) => {
  return result;
};

const resultWithoutZero = (value: string | number, result: string | number) =>
  value?.toString() === '0' ? '' : result;

export const EditableCell: React.FC<Partial<EditableCellProps>> = (
  props: EditableCellProps
) => {
  const initialConvert = props.showZeros ? resultWithZero : resultWithoutZero;

  const initialValue =
    props.type === 'number'
      ? initialConvert(
          props.value,
          formatAsFloat(props.value, {
            locale: 'de',
            isFinished: true,
            ...props.cell.column.cellParams,
          })
        )
      : props.value;

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const { autoFocus, onEdit } = props.cell.getEditableCellProps?.() ?? {
    onEdit: () => {},
  };

  function transformBeforeExit(value: string) {
    if (props.type === 'date') {
      return DateTime.fromFormat(value, 'yyyy-MM-dd');
    }
    if (props.type === 'time') {
      return DateTime.fromFormat(value, 'HH:mm');
    }
    if (props.type === 'number') {
      const resultValue = parseAsFloat(value) || 0;
      if (props.transform) {
        return props.transform(resultValue);
      }
      return resultValue;
    }
    return value;
  }

  const setSelection = props.onSelectionChange
    ? (
        event: null | {
          currentTarget: { selectionStart: number; selectionEnd: number };
        }
      ) => {
        if (!event) {
          props.onSelectionChange();
        } else if (
          event.currentTarget?.selectionStart &&
          event.currentTarget?.selectionEnd
        )
          props.onSelectionChange(
            event.currentTarget.selectionStart,
            event.currentTarget.selectionEnd
          );
      }
    : undefined;

  useEffect(() => {
    if (props.type === 'date') {
      const transformed = DateTime.fromISO(initialValue).toFormat('yyyy-MM-dd');
      setValue(transformed);
    }
    if (props.type === 'time') {
      const transformed = DateTime.fromISO(initialValue).toLocaleString(
        DateTime.TIME_SIMPLE
      );
      setValue(transformed);
    } else if (props.type === 'number') {
      const currentValueParsed = parseAsFloat(value);
      if (props.value === currentValueParsed) {
        return;
      }
      const floatValue = formatAsFloat(initialValue, {
        locale: 'de',
        isFinished: true,
        ...props.cell.column.cellParams,
      });
      setValue(floatValue);
    } else {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cell.column.cellParams, props.type, initialValue]);

  const onChange = (event) => {
    let value = event.target.value;
    if (props.type === 'number') {
      value = formatInputValueAsFloat(event.currentTarget, {
        locale: 'de',
        ...props.cell.column.cellParams,
      });
      if (props.transformInputValue) {
        value = props.transformInputValue(value);
      }
    }
    setValue(value);
    const resultValue = transformBeforeExit(value);
    onEdit(resultValue, 'update');
    props.onChange?.(value);
  };

  const [isFocused, setIsFocused] = useState(false);

  function onBlur(event) {
    if (props.type === 'number') {
      const result = formatInputValueAsFloat(event.currentTarget, {
        locale: 'de',
        isFinished: true,
        ...props.cell.column.cellParams,
      });
      setValue(result);
    }
    setSelection?.(null);
    const resultValue = transformBeforeExit(value);
    setIsFocused(false);
    if (props.onNext) {
      onEdit(resultValue, 'blur-update');
      return;
    }
    onEdit(resultValue, 'blur');
    props.onExit?.();
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (setSelection) setTimeout(() => setSelection(event), 0);
    switch (event.key) {
      case 'Tab': {
        const resultValue = transformBeforeExit(value);
        event.stopPropagation();
        onEdit(resultValue, 'update');
        props.onNext?.(event, event.shiftKey ? -1 : 1);
        break;
      }
      case 'Enter': {
        const resultValue = transformBeforeExit(value);
        if (props.type === 'number') {
          event.stopPropagation();
          onEdit(resultValue, 'update');
          props.onExit?.();
        }
        break;
      }
    }
  };
  const onFocus = (e) => {
    if (props.selectinRange) {
      e.currentTarget.setSelectionRange(
        props.selectinRange.start,
        props.selectinRange.end
      );
      return;
    }
    e.currentTarget.select();
    setIsFocused(true);
    setSelection?.(e);
  };
  const focusOnVisibleCell = autoFocus && props.isVisible;
  const innerProps = {
    autoFocus: props.autoFocus ?? focusOnVisibleCell,
    value:
      props.isValueVisble || props.isValueVisble === undefined ? value : '',
    onChange,
    onBlur,
    onKeyDown,
    onFocus,
    type: props.type === 'number' ? 'text' : props.type,
    disabled: props.disabled,
  };

  const inputStrictHandlers = {
    onSelect: setSelection,
    onInput: setSelection,
    onMouseDown: setSelection,
  };

  return props.textarea ? (
    <Box className={clsx(styles.input, { [styles.focused]: isFocused })}>
      <TextareaAutosize
        {...innerProps}
        {...inputStrictHandlers}
        className={clsx(styles.innerInput, styles.inputPadding)}
        minRows="1"
        style={{
          resize: 'none',
          maxHeight: props.maxHeight,
          overflowY: 'auto',
          minHeight: '1.4rem',
        }}
      />
    </Box>
  ) : (
    <Input
      {...innerProps}
      className={clsx(styles.input, styles.inputPaddingText, {
        [styles.focused]: isFocused,
      })}
      classes={{
        input: clsx(styles.innerInput, {
          [styles.number]: props.type === 'number',
        }),
      }}
      inputProps={{
        max: props.max,
        min: props.min,
        step: props.step,
        ...inputStrictHandlers,
      }}
      endAdornment={props.unit ? <Unit unit={'%'} /> : undefined}
    />
  );
};
