import styles from './PasswordChangeDialog.module.scss';

import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';

import { LabeledInput } from '../../../../../locked-inputs';

export interface TicketSolutionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (input: string) => void;
}

export const PasswordChangeDialog = (props: TicketSolutionDialogProps) => {
  const { open, onClose, onConfirm } = props;
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const passwordMatch = password === repeatedPassword;
  const disabled = !password || !repeatedPassword || !passwordMatch;
  return (
    <Dialog
      classes={{
        dialog: {
          paper: styles.dialogRoot,
        },
      }}
      open={open}
      title={t(`ALERTS.MANAGE_PASSWORD`)}
      onClose={onClose}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem" paddingTop="1rem">
          <LabeledInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            label={t('ALERTS.NEW_PASSWORD')}
          />
          <LabeledInput
            value={repeatedPassword}
            onChange={(e) => setRepeatedPassword(e.target.value)}
            required
            label={t('ALERTS.CONFIRM_NEW_PASSWORD')}
          />
          {!passwordMatch && (
            <Typography color="error" style={{ paddingBottom: '1rem' }}>
              {t('ALERTS.PASSWORD_NOT_MATCH')}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
        <Button disabled={disabled} onClick={() => onConfirm(password)}>
          {t('ALERTS.BTN_SAVE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
