import {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

const customActionplaceholder = { left: [], right: [] };

/**
 * Merge actions and keep newest by actionKey
 */
export const mergeActions = (
  oldActions: CustomToolbar<CustomToolbarAction> = customActionplaceholder,
  newActions: CustomToolbar<CustomToolbarAction> = customActionplaceholder
): CustomToolbar<CustomToolbarAction> => {
  const getUniqueActions = (key: 'left' | 'right') => {
    const existingActionKeys = new Set(
      newActions?.[key]?.map((action) => action.actionKey)
    );

    return (
      oldActions[key]?.filter(
        (action) => !existingActionKeys.has(action.actionKey)
      ) || []
    );
  };

  return {
    ...newActions,
    left: [...(newActions?.left || []), ...getUniqueActions('left')],
    right: [...(newActions?.right || []), ...getUniqueActions('right')],
  };
};
