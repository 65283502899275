import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useCommentContext } from '@work4all/utils';

export function useConfirmBeforeCloseMask(enabled: boolean) {
  const { t } = useTranslation();

  const { setObjectionListener, confirm } = useHistoryStack();

  const { comment } = useCommentContext();

  const isCommentEmpty = !comment;
  const shouldConfirm = enabled || !isCommentEmpty;

  const warningMessage = !isCommentEmpty
    ? t('ALERTS.UNSAVED_COMMENT')
    : t('ALERTS.UNSAVED_CHANGES');

  useEffect(() => {
    if (shouldConfirm) {
      setObjectionListener(() => {
        return confirm(warningMessage);
      });

      return () => {
        setObjectionListener(null);
      };
    }
  }, [confirm, setObjectionListener, shouldConfirm, warningMessage]);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return shouldConfirm && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(warningMessage).then((response) => {
        if (response) {
          blocker.proceed();
        } else {
          blocker.reset();
        }
      });
    }
  }, [blocker, confirm, warningMessage]);
}
