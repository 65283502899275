import styles from './Organization.module.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CategoryClassPicker } from '@work4all/components/lib/components/entity-picker/category-class-picker/CategoryClassPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CustomerGroupPicker,
  SupplierGroupPicker,
} from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { BaseDataLanguagePickerField } from '../../../../../../../../../../components/entity-picker/BaseDataLanguagePickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  LabeledInput,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BPMaskFormValue } from '../../../../../types';

export const Organization: React.FC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { register, control } = useFormContextPlus<BPMaskFormValue>();

  const categoryFieldRef = useRef(null);
  if (!mask.data) return <div></div>;

  return (
    <Collapse defaultOpen={true} title={t('MASK.ORGANIZATION')}>
      <ControlWrapper>
        <ControllerPlus
          name="number"
          control={control}
          render={({ field }) => {
            return (
              <LabeledInput
                {...field}
                label={t(
                  mask.entity === Entities.customer
                    ? 'INPUTS.CLIENT_NUMBER'
                    : 'INPUTS.SUPPLIER_NUMBER'
                )}
                value={field.value === 0 ? '' : field.value}
                onChange={(e) => {
                  const value = parseInt(e.currentTarget.value, 10) || 0;
                  field.onChange(value);
                }}
              />
            );
          }}
        />

        <LabeledInput
          label={t(
            mask.entity === Entities.customer
              ? 'INPUTS.NUMBER_AT_CUSTOMER'
              : 'INPUTS.NUMBER_AT_SUPPLIER'
          )}
          {...register('externalNumber')}
        />
        <ControllerPlus
          name="language"
          control={control}
          render={({ field: { ref: _ref, ...fieldProps }, fieldState }) => {
            return (
              <BaseDataLanguagePickerField
                {...fieldProps}
                error={fieldState?.error?.message}
                onChange={(value) => {
                  fieldProps.onChange(value);
                }}
              />
            );
          }}
        />

        <ControllerPlus
          name="group"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={
                  mask.entity === Entities.customer ? (
                    <CustomerGroupPicker
                      {...field}
                      value={[field?.value]}
                      onChange={(value) => field.onChange(value?.[0] || null)}
                      multiple={false}
                    />
                  ) : (
                    <SupplierGroupPicker
                      {...field}
                      value={[field?.value]}
                      onChange={(value) => field.onChange(value?.[0] || null)}
                      multiple={false}
                    />
                  )
                }
              >
                <PickerTargetButton
                  onClear={() => {
                    field?.onChange(null);
                  }}
                  label={t('INPUTS.GROUP')}
                  value={field?.value?.name || ''}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper ref={categoryFieldRef}>
        <ControllerPlus
          name="categoryAssignmentList"
          control={control}
          render={({ field }) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let prefilter: any = [{ hide: { $eq: false } }];
            if (mask.entity === Entities.customer) {
              prefilter = prefilter.concat([
                { isFirmCategory: { $eq: true } },
                { isCustomerCategory: { $eq: true } },
              ]);
            } else {
              prefilter = prefilter.concat([
                { isFirmCategory: { $eq: true } },
                { isSupplierCategory: { $eq: true } },
              ]);
            }

            return (
              <EntityPickerPopover
                anchorEl={categoryFieldRef.current}
                picker={
                  <CategoryClassPicker
                    multiple
                    value={
                      !Array.isArray(field?.value)
                        ? []
                        : field?.value?.map((categoryAssignment) => ({
                            id: categoryAssignment?.categoryId,
                          }))
                    }
                    prefilter={prefilter}
                    filterToCompanyCategories={true}
                    onChange={(value) => {
                      const newValue: CategoryAssignment[] = value?.map(
                        (category) => {
                          return {
                            categoryId: category.id,
                            categoryName: category.name,
                            categoryKindName: category?.categoryClass?.name,
                          };
                        }
                      );

                      field.onChange(newValue);
                    }}
                  />
                }
              >
                <PickerTargetButton
                  onClear={null}
                  value={
                    <ChipList>
                      {!Array.isArray(field?.value)
                        ? []
                        : field?.value?.map((categoryAssignment, idx) => {
                            return (
                              <Chip
                                key={idx}
                                maxWidth={18}
                                label={`${categoryAssignment.categoryKindName} / ${categoryAssignment.categoryName}`}
                                handleDelete={() => {
                                  const newValue = field?.value?.filter(
                                    (x) =>
                                      x.categoryId !==
                                      categoryAssignment.categoryId
                                  );
                                  field.onChange(newValue);
                                }}
                              />
                            );
                          })}
                    </ChipList>
                  }
                  label={t('INPUTS.CATEGORIES')}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          className={styles.textarea}
          label={t('COMMON.NOTE')}
          {...register('note')}
          multiline={true}
          minRows={10}
          maxRows={10}
        />
      </ControlWrapper>
    </Collapse>
  );
};
