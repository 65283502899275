import { ModuleAccessRight } from '@work4all/models/lib/Classes/ModuleAccessRight.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { useModuleRights } from './use-module-rights';

export enum AppParts {
  WORKTIMETRACKING,
  PROJECTTIMETRACKING,
  TIMECARD,
  TIME_TRACKING,
  VACATIONS,
  CALENDAR,
  PROJECTFILES,
  SUPPLIERFILES,
  CUSTOMERFILES,
  EDIT_MASK_OF_ENTITY,
  BIRTHDAYS,
  TRAVEL_RECEIPTS,
  SEARCH_BAR,
  HOME,
  CONTROL_CENTER,
  FULL,
}

export const EntitiesOfErpGroups = [
  Entities.calculation,
  Entities.productionContract,
  Entities.offer,
  Entities.contract,
  Entities.invoice,
  Entities.deliveryNote,
  Entities.demand,
  Entities.order,
  Entities.inboundDeliveryNote,
  Entities.reViewModel,
  Entities.raViewModel,
];

export const checkModuleRight = (
  rights: ModuleAccessRight[],
  right: ModuleAccessRightType
) => {
  return !!rights.find((el) => el.type === right && el.hasAccess === true);
};

export const useCanView = (part: AppParts | Entities, context?: Entities) => {
  const { rights } = useModuleRights();
  return canView(rights, part, context);
};
export function canView(
  rights: ModuleAccessRight[],
  part: AppParts | Entities,
  context?: Entities
) {
  const checkRight = (right: ModuleAccessRightType) => {
    return checkModuleRight(rights, right);
  };

  switch (part) {
    case AppParts.EDIT_MASK_OF_ENTITY:
      //this is a special behaviour stemming from the lightusers impl  https://work4all.atlassian.net/browse/WW-2625
      if (checkRight(ModuleAccessRightType.FEATURE_ERP_LIGHT)) {
        return (
          !EntitiesOfErpGroups.includes(context) ||
          context === Entities.deliveryNote
        );
      }
      return true;
    case AppParts.PROJECTTIMETRACKING:
      return checkRight(ModuleAccessRightType.FEATURE_PROJECT_TIME_TRACKING);
    case AppParts.WORKTIMETRACKING:
      return checkRight(ModuleAccessRightType.FEATURE_WORK_TIME_TRACKING);
    case AppParts.TIMECARD:
      return checkRight(
        ModuleAccessRightType.FEATURE_WORK_TIME_TRACKING_TIME_CARD
      );
    case AppParts.TIME_TRACKING:
      return (
        checkRight(ModuleAccessRightType.FEATURE_PROJECT_TIME_TRACKING_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_WORK_TIME_TRACKING_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL)
      );
    case AppParts.VACATIONS:
      return checkRight(ModuleAccessRightType.FEATURE_VACATIONS);
    case AppParts.CALENDAR:
      return (
        checkRight(ModuleAccessRightType.FEATURE_CALENDAR) ||
        checkRight(ModuleAccessRightType.FEATURE_CRM_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL)
      );
    case AppParts.TRAVEL_RECEIPTS:
      return checkRight(ModuleAccessRightType.FEATURE_RECEIPTS_TRAVEL_LIGHT);
    case AppParts.SUPPLIERFILES:
      return checkRight(ModuleAccessRightType.FEATURE_FILE_TAB_SUPPLIER);
    case AppParts.CUSTOMERFILES:
      return checkRight(ModuleAccessRightType.FEATURE_FILE_TAB_CUSTOMER);
    case AppParts.PROJECTFILES:
      return checkRight(ModuleAccessRightType.FEATURE_FILE_TAB_PROJECT);
    case AppParts.BIRTHDAYS:
      return checkRight(ModuleAccessRightType.FEATURE_BIRTHDAYS);
    case AppParts.CONTROL_CENTER:
      return (
        checkRight(ModuleAccessRightType.FEATURE_CRM_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_ERP_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_PROJECT_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL)
      );
    case AppParts.FULL:
      return checkRight(ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL);
    case AppParts.HOME:
    case AppParts.SEARCH_BAR:
      return (
        checkRight(ModuleAccessRightType.FEATURE_CRM_LIGHT) ||
        checkRight(ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL)
      );
    default:
      return false;
  }
}
