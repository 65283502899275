import styles from './HeaderRow.module.scss';

import clsx from 'clsx';
import React from 'react';

import { TableRow } from '../../../table-row/TableRow';

interface IHeaderRowProps {
  children?: React.ReactNode;
  className?: string;
  groupLevel?: number;
}

export const HeaderRow: React.FC<IHeaderRowProps> = (props) => {
  const { className, ...rest } = props;

  return <TableRow className={clsx(styles.row, className)} {...rest} />;
};
