import styles from './TopBar.module.scss';

import { ArrowDropDown } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from '@work4all/assets/icons/outline-arrow-24.svg';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

export function TopBar(props: {
  layout: 'gantt' | 'stacked';
  onLayoutChanged: (layout: 'gantt' | 'stacked') => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { layout, onLayoutChanged } = props;

  const { handleClick, ...menuState } = usePopoverState('bottom');

  return (
    <>
      <Menu {...menuState}>
        {(['gantt', 'stacked'] as Array<'gantt' | 'stacked'>).map((layout) => (
          <MenuItem
            key={layout}
            onClick={() => {
              onLayoutChanged(layout);
              menuState.onClose();
            }}
          >
            {t(`PROJECT_PLANNING.LAYOUT.${layout}`)}
          </MenuItem>
        ))}
      </Menu>
      <div className={styles.root}>
        <div className={styles.left}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            className={styles.goBackButton}
            size="large"
          >
            <ArrowIcon />
          </IconButton>
          <Stack direction="row" gap="0.5rem" alignItems="center">
            {t('COMMON.PROJECTPROCESS', { count: 2 })}
            <span style={{ color: 'var(--text03)' }}>|</span>
          </Stack>
          <BaseActionButton onClick={handleClick}>
            <Stack
              direction="row"
              style={{ color: 'var(--text03)' }}
              alignContent="center"
              alignItems="center"
              fontSize="1.25rem"
            >
              <Typography variant="h3">
                {t(`PROJECT_PLANNING.LAYOUT.${layout}`)}
              </Typography>
              <ArrowDropDown />
            </Stack>
          </BaseActionButton>
        </div>
      </div>
    </>
  );
}
