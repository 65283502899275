import { useEventCallback } from '@mui/material/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import {
  CURRENCY_PARAMS,
  NUMBER_PARAMS,
  NumberCell,
} from '@work4all/components';
import { EditableCell } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { parseAsFloat } from '@work4all/utils';

import { useEditableState } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import { EditTableColumns } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/types';
import { recalculatePositions } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/hooks/recalculatePositions';
import { defaultErpGetRelation } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/positions-table/defaultErpGetRelation';
import { settings, useSetting } from '../../../../../settings';
import { GroupedPosition, SelectedPosition } from '../../types';
import { OnItemsChangedOptions } from '../ErpDialogPositions';
import { SupplierPricePickerField } from '../SupplierPricePicker/SupplierPricePickerField';

interface UseGroupedPositionsProps {
  onItemsChanged: (
    positions: SelectedPosition[],
    options: OnItemsChangedOptions
  ) => void;
  positionList: GroupedPosition[];
}
export const useGroupedPositions = (props: UseGroupedPositionsProps) => {
  const { positionList } = props;

  const { t } = useTranslation();

  const mutateState = useCallback((input: GroupedPosition[]) => {
    const { result } = recalculatePositions(input, false, false);
    return result as GroupedPosition[];
  }, []);

  const { positions, onEditPosition, onCollapsePosition } = useEditableState({
    positions: positionList,
    mutateState,
  });

  const columns = useMemo(() => {
    const columns: EditTableColumns<GroupedPosition>[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        type: 'number',
        width: remToPx(5),
      },
      {
        Header: t('COMMON.ERP.DESCRIPTION'),
        accessor: 'name',
        type: 'text',
        width: remToPx(14),
      },
      {
        Header: t('COMMON.ERP.SHOULD'),
        accessor: 'should',
        width: remToPx(6),
        type: 'number',
        Cell: NumberCell,
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.AMOUNT'),
        accessor: 'amount',
        width: remToPx(6),
        type: 'number',
        transformInputValue: (input: string) => {
          const number = parseAsFloat(input);
          if (number < 0) return '0';
          return input;
        },
        Cell: (cell) => {
          if (cell.row.original.posId) return <NumberCell {...cell} />;
          return (
            <EditableCell
              {...(cell as object)}
              onChange={(value: string) => {
                const amount = parseAsFloat(value) || 0;
                onEditPosition({
                  forceUpdate: true,
                  position: {
                    localId: cell.row.id,
                    id: cell.row.original.id,
                    amount: Math.max(amount, 0),
                  },
                });
              }}
            />
          );
        },
        ...NUMBER_PARAMS,
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'unit',
        type: 'text',
        width: remToPx(4),
      },
      {
        Header: t('COMMON.SUPPLIER'),
        accessor: 'supplier',
        type: 'text',
        width: remToPx(7),
        Cell: (cell) => {
          return (
            <SupplierPricePickerField
              onChange={() => {}}
              articleId={cell.row.original.articleId}
              value={cell.value as { id: number; name: string }}
            />
          );
        },
      },
      {
        Header: t('COMMON.ERP.PRICE'),
        accessor: 'singlePriceNet',
        width: remToPx(7),
        type: 'number',
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('FIELDS.discount'),
        accessor: 'discount',
        width: remToPx(7),
        type: 'number',
        unit: '%',
        Cell: NumberCell,
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE'),
        accessor: 'totalPriceNet',
        type: 'number',
        width: remToPx(7),
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
    ];

    return columns;
  }, [t, onEditPosition]);

  const tableInstanceRef = useRef<TableInstance<GroupedPosition>>();

  const selectedItems = useRef<string[]>([]);
  const onSelectedItemIdsChange = useEventCallback((items: string[]) => {
    selectedItems.current = items;
    updateSelected();
  });

  // Update list when changing amount
  useEffect(() => {
    updateSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const updateSelected = useEventCallback(() => {
    const selectedPositions: SelectedPosition[] = [];
    const original = positionList || [];
    original.forEach((originalPos) => {
      if (!originalPos.isHeader) return;
      const currentPos = positions.find((x) => x.id === originalPos.id);
      if (!currentPos) return;
      const selected = selectedItems.current.includes(currentPos.localId);

      selectedPositions.push({
        position: currentPos,
        selected,
        newAmount: currentPos.amount,
        oldAmount: originalPos.amount,
      });
    });
  });

  const erpSize = useSetting(settings.erpRowSize());

  const allowedColumns = useMemo(() => {
    return columns.map((x) => x.accessor as string);
  }, [columns]);

  const decorators = useMemo(() => {
    return {
      getRelation: defaultErpGetRelation,
    };
  }, []);

  return {
    tableInstanceRef,
    items: positions,
    onCollapsePosition,
    columns,
    onSelectedItemIdsChange,
    allowedColumns,
    erpSize,
    decorators,
  };
};
