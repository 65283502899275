import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as ReactSentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { environment } from './../environments';

if (process.env.NODE_ENV === 'production') {
  Sentry.init(
    {
      dsn: 'https://60661856592ef1e88561c5e569c5f749@relay.sdc.work4allcloud.de/3',
      integrations: [
        ReactSentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        ReactSentry.replayIntegration({ useCompression: false }),
      ],
      tracePropagationTargets: [/.*work4all.*\/graphql$/],
      enableNative: !['electron'].includes(Capacitor.getPlatform()),
      environment: environment.configuration,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      release: window.SENTRY_RELEASE?.id,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.02,
      replaysOnErrorSampleRate: 1.0,
      normalizeDepth: 5,
    },
    ReactSentry.init
  );
}
export const SentryRoutes = ReactSentry.withSentryReactRouterV6Routing(Routes);
export const sentryCreateBrowserRouter =
  ReactSentry.wrapCreateBrowserRouter(createBrowserRouter);
