import { gql, useQuery } from '@apollo/client';

const GET_CITIES = gql`
  query GetCities(
    $postalCodeFilter: String
    $cityFilter: String
    $querySize: Int
    $queryPage: Int
  ) {
    getCities(
      postalCodeFilter: $postalCodeFilter
      cityFilter: $cityFilter
      querySize: $querySize
      queryPage: $queryPage
    ) {
      total
      data
    }
  }
`;

type Response = { getCities: { data: string[] } };
export function useCities(
  variables: {
    cityFilter?: string;
    postalCodeFilter?: string;
    querySize?: number;
    queryPage?: number;
  },
  skip = false
) {
  return useQuery<Response>(GET_CITIES, { variables, skip });
}
