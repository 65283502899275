import { useUser } from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewProps } from '../types';

import { ChecklistPreview } from './ChecklistPreview';

type IChecklistPreviewContainerProps = EntityPreviewProps;

export const checklistPreviewFields: CheckList<EMode.query> = {
  id: null,
  title: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
  },
  user: {
    id: null,
    displayName: null,
    lastName: null,
    firstName: null,
    shortName: null,
  },
  userId: null,
  date: null,
  positionList: [
    {
      kind: null,
      isChecked: null,
      checkListId: null,
      id: null,
      doneByUserId: null,
      plannedByUserId: null,
      doneDate: null,
      name: null,
      note: null,
      number: null,
      positionNumber: null,
      category1: null,
      category2: null,
    },
  ],
  contactId: null,
  note: null,
};

export function ChecklistPreviewContainer(
  props: IChecklistPreviewContainerProps
) {
  const {
    ids: checklistIds,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const currentUser = useUser();

  const checklists = useEntityPreviewData<CheckList>(
    checklistIds,
    Entities.checkList,
    checklistPreviewFields
  );
  if (!checklists) {
    return null;
  }
  return (
    <ChecklistPreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      checklists={checklists}
      currentUserId={currentUser.benutzerCode}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
