import { useLocation } from 'react-router-dom';

import { AppParts, useCanView } from '@work4all/data';

export const useRenderMobileConfig = () => {
  const location = useLocation();
  const canViewHome = useCanView(AppParts.HOME);
  //   this condition is used to remove the navigation bar from the list view.
  //   It's a temporary solution, until we figure out a better way or we refactor the pages.
  //   Tried to make the conditions as precise as possible, so the navBar would be removed from the lists when reached from the control center and the files
  if (!canViewHome) {
    return true;
  }
  if (location.pathname.includes('/entity/')) {
    return false;
  } else return true;
};
