import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class PauseRule<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: name */
  name?: string;

  __typename?: string;
}

const fields: FieldDefinitions<PauseRule> = {
  id: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  __typename: {
    alias: '__typename',
  },
};

export const pauseRuleEntityDefinition: EntitiyDefinition<PauseRule> = {
  local: {},
  remote: {
    queryName: 'getBreakTimeRules',
    fragmentName: 'PausenRegel',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
