import styles from './../../MaskOverlay.module.scss';

import PhoneIcon from '@mui/icons-material/Phone';
import { Theme, useMediaQuery } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { includesHtml } from '@work4all/components/lib/components/entity-preview/utils';
import { useLock } from '@work4all/components/lib/hooks/object-lock';
import { mapToCheckItem } from '@work4all/components/lib/input/check-list-field/LookupCheckListField';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { useHistoryStackTitleUpdate } from '@work4all/components/lib/navigation/hooks/use-history-stack-title-update';

import { useDataMutation, useFormPlus, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { InputTicketChecklisteMarkRelation } from '@work4all/models/lib/Classes/InputTicketChecklisteMarkRelation.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TicketCategory1 } from '@work4all/models/lib/Classes/TicketCategory1.entity';
import { TicketChecklisteMark } from '@work4all/models/lib/Classes/TicketChecklisteMark.entity';
import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { isHTMLEmpty, useJSONSchemaResolver } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import {
  canAddTicket,
  canDeleteTicket,
  canEditTicket,
} from '@work4all/utils/lib/permissions';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { settings, useSetting } from '../../../../../settings';
import { EmailTemplateButtonProvider } from '../../../components/email-template-button/EmailTemplateButtonProvider';
import { EmailTemplateIconButton } from '../../../components/email-template-button/EmailTemplateIconButton';
import { EmailTemplateMenuItemButton } from '../../../components/email-template-button/EmailTemplateMenuItemButton';
import { IconButton } from '../../../locked-inputs';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlayStandardSubmitButton } from '../../components/MaskOverlayStandardSubmitButton';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';

import { ContactsPopover } from './components/contacts-popover/ContactsPopover';
import { MaskOverlayTicketTimeTrack } from './components/mask-overlay-time-track/MaskOverlayTicketTimeTrack';
import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import {
  TicketMaskStateContext,
  TicketMaskStateContextValue,
} from './ticket-mask-state-context';
import { TicketMaskFormValue } from './types';
import { useTicketDefaultData } from './use-ticket-default-data';

export enum TicketTabKeys {
  general = 'general',
  attachments = 'attachments',
}

interface TicketOverlayControllerProps extends MaskControllerProps {
  registerOnClose: (handle: () => void) => void;
}

export const TicketOverlayController = (
  props: TicketOverlayControllerProps
) => {
  const user = useUser();

  const { onAfterSave, registerOnClose } = props;
  const { t } = useTranslation();

  const historyStack = useHistoryStack();
  const { setObjectionListener } = historyStack;

  const propsIn = useDeepMemo(() => props, [props]);
  const [lastProps, setLastProps] = useState<MaskControllerProps>(props);
  useEffect(() => {
    setLastProps(propsIn);
  }, [propsIn]);

  const mask = useMaskConfig(lastProps);

  const { value: isTicketSolutionRequiered } = useSetting(
    settings.isTicketSolutionRequiered()
  );

  const customRules = useCallback(
    (data: TicketMaskFormValue) => {
      const status = data?.status1;
      const solutionText = data?.solutionText ?? '';

      if (
        isTicketSolutionRequiered &&
        status === TicketStatus.ERLEDIGT &&
        isHTMLEmpty(solutionText)
      ) {
        return {
          solutionText: {
            message: t('ERROR.ENTER_SOLUTION_IF_STATUS_IS_DONE'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [isTicketSolutionRequiered, t]
  );

  const customFields = useCustomFieldsConfig({ entity: Entities.ticket });

  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const entity = Entities.ticket;
    const data: Ticket<EMode.query> = {
      id: null,
      title: null,
      number: null,
      problemDescription: null,
      followUpDate: null,
      solutionText: null,
      businessPartner: {
        id: null,
        businessPartnerType: null,
        data: {
          customer: {
            id: null,
            name: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                layedOff: null,
              },
            ],
            isPrivateCustomer: null,
          },
          supplier: {
            id: null,
            name: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                layedOff: null,
              },
            ],
            isPrivateCustomer: null,
          },
        },
      },
      businessPartnerId: null,
      businessPartnerType: null,
      contact: {
        id: null,
        displayName: null,
        eMail: null,
        businessPartnerId: null,
        businessPartnerType: null,
      },
      contactId: null,
      project: { id: null, name: null, number: null },
      projectId: null,
      projectProcess: {
        id: null,
        process: null,
        planningAmount: null,
        timeTrackings: [
          {
            id: null,
            amount: null,
          },
        ],
      },
      projectProcessId: null,
      ticketKind: {
        id: null,
        name: null,
      },
      ticketKindId: null,
      ticketCategory1: {
        id: null,
        name: null,
      },
      ticketCategory2: {
        id: null,
        name: null,
      },
      category1Id: null,
      category2Id: null,
      priority: null,
      status1: null,
      status2: null,
      editor1: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
        shortName: null,
      },
      editor1Id: null,

      editor2: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
        shortName: null,
      },
      editor2Id: null,
      checklist: [
        {
          id: null,
          lookupId: null,
        },
      ],
      profit: null,
      attachmentList: [
        {
          id: null,
          fileName: null,
          displayName: null,
          note: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          lastModificationDate: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      timeRequired: null,
      creator: {
        id: null,
        displayName: null,
      },
      customFieldList: [CUSTOM_FIELDS_DATA],
      contract: {
        id: null,
        note: null,
        number: null,
        contractNumber: null,
        positionList: [
          {
            id: null,
            positionKind: null,
            number: null,
            longtext: null,
            amount: null,
            unit: null,
          },
        ],
      },
      serviceContract: {
        id: null,
        note: null,
        number: null,
        serviceContractNumber: null,
      },
      position: {
        id: null,
        positionKind: null,
        number: null,
        longtext: null,
        amount: null,
        unit: null,
      },
    };
    return { filter, entity, data };
  }, [mask.id]);

  const initialFormValue = useInitialFormValue<Ticket>(
    requestData,
    mask.isCreateMode
  );

  const newEntityData = useTicketDefaultData(mask);

  const dataRaw = useMemo(() => {
    const cleanedProblemDescription =
      initialFormValue.value &&
      !includesHtml(initialFormValue.value.problemDescription)
        ? initialFormValue.value.problemDescription.replace(/\r\n/g, '<br>')
        : initialFormValue?.value?.problemDescription || '';

    return mask.isCreateMode
      ? newEntityData
      : (initialFormValue.value && {
          ...initialFormValue.value,
          problemDescription: cleanedProblemDescription,
        }) ??
          newEntityData;
  }, [initialFormValue.value, mask.isCreateMode, newEntityData]);

  const data = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(dataRaw), customFields);
  }, [dataRaw, customFields]);

  const attachList = useMemo(() => {
    return data?.attachmentList?.map((x) => {
      return {
        ...x,
        fileName: x.displayName,
      };
    });
  }, [data?.attachmentList]);

  const tempFileManager = useTempFileManager(attachList);

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useFormPlus<TicketMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { formState, handleSubmit, reset, getValues } = form;

  useLock(
    {
      subEntityType: Entities.ticket,
      subEntityIds: data.id ? [data.id] : [],
    },
    [data]
  );

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const { checklist: _, ...mutationData } = requestData.data as Ticket;
  const [mutate] = useDataMutation<
    Ticket,
    EMode.upsert,
    {
      attachements: InputTicketAttachementsRelation;
      checklistMarks: InputTicketChecklisteMarkRelation;
    }
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: mutationData,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      onAfterSave(data);
    },
  });

  const updateContractAndServiceContract = (
    entity: Entities.contract | Entities.serviceContract
  ) => {
    const contract = getValues('contract');
    const serviceContract = getValues('serviceContract');
    const position = getValues('position');
    const isContract = entity === Entities.contract;

    const values = {
      clearServiceContract: {
        serviceContract: null,
        serviceObjectId: 0,
      },
      setServiceContract: {
        serviceObjectId: serviceContract?.id ?? 0,
      },
      clearContract: {
        contract: null,
        bZObjMemberId: 0,
      },
      setContract: {
        bZObjMemberId: contract?.id ?? 0,
        bZObjType: BzObjType.AUFTRAG,
      },
    };

    let result = {};
    if (contract && serviceContract) {
      result = isContract
        ? { ...values.setContract, ...values.clearServiceContract }
        : { ...values.setServiceContract, ...values.clearContract };
    } else {
      result = isContract ? values.setContract : values.setServiceContract;
    }

    if (position) {
      result['position'] = null;
    }

    return result;
  };

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType =
          businessPartner?.__typename === 'supplier'
            ? SdObjType.LIEFERANT
            : SdObjType.KUNDE;
        const contact = businessPartner?.mainContact ?? null;

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId, projectProcess: null };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectProcess: null,
            'businessPartner.data': businessPartner,
          };
        }
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      ticketKind: (ticketKind: TicketKind) => {
        const ticketKindId = ticketKind?.id ?? 0;

        return { ticketKindId };
      },
      ticketCategory1: (ticketCategory1: TicketCategory1) => {
        const category1Id = ticketCategory1?.id ?? 0;

        return { category1Id };
      },
      ticketCategory2: (ticketCategory2: LookUp) => {
        const category2Id = ticketCategory2?.id ?? 0;

        return { category2Id };
      },
      editor1: (editor1: User) => {
        const editor1Id = editor1?.id ?? 0;
        return { editor1Id };
      },
      editor2: (editor2: User) => {
        const editor2Id = editor2?.id ?? 0;
        return { editor2Id };
      },
      contract: () => {
        return updateContractAndServiceContract(Entities.contract);
      },
      serviceContract: () => {
        return updateContractAndServiceContract(Entities.serviceContract);
      },
      position: (position: Position) => {
        return { positionId: position?.id || 0 };
      },
    },
    form
  );

  const [showSolution, setShowSolution] = useState(false);

  useEffect(() => {
    reset(data);
    setShowSolution((data.solutionText || '').trim() !== '');
  }, [reset, data]);

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);
  const attachementsRelation =
    useAttachementsRelation<InputTicketAttachementsRelation>(
      tempFileManager,
      Entities.ticketAttachment,
      'id'
    );

  const isDirty =
    Object.keys(formState.dirtyFields).length > 0 ||
    tempFileManager.fileListDirty ||
    attachementsRelation?.isDirty;

  useConfirmBeforeCloseMask(isDirty);

  const convertToCheckListMarks = useCallback(
    (checklist?: TicketChecklisteMark[]) => {
      if (!checklist) return {};
      const newSelection = checklist.map(mapToCheckItem).map((x) => x.id);
      const oldSelection = (data.checklist || [])
        .map(mapToCheckItem)
        .map((x) => x.id);
      const check = newSelection.filter((x) => !oldSelection.includes(x));
      const uncheck = oldSelection.filter((x) => !newSelection.includes(x));
      return { check, uncheck };
    },
    [data]
  );

  const onSubmit = useCallback(
    async (
      ticket: TicketMaskFormValue,
      _event: unknown,
      skipOnComplete = false
    ) => {
      const updateRaw = mask.isCreateMode
        ? ticket
        : pickUpdateFields(ticket, formState.dirtyFields);

      const updateMapped = prepareInputWithCustomFields(updateRaw);

      const checklistMarks = convertToCheckListMarks(ticket.checklist);

      return await mutate(
        updateMapped,
        {
          relations: {
            attachements: attachementsRelation?.attachements,
            checklistMarks,
          },
        },
        { skipOnComplete: skipOnComplete === true }
      );
    },
    [
      mask.isCreateMode,
      formState.dirtyFields,
      convertToCheckListMarks,
      mutate,
      attachementsRelation,
    ]
  );

  const defaultTicketSenderAddress = useSetting(
    settings.defaultTicketSenderAddress()
  );

  const updateMaskState = useHistoryStackTitleUpdate(mask.isCreateMode);
  const getEmailParams = useCallback(async () => {
    if (!formState.isValid) {
      form.trigger();
      return;
    }

    let ticketData = getValues();
    let ticketTitle: string;

    if (isDirty) {
      ticketData = (await onSubmit(ticketData, null, true)) as Ticket;
      setObjectionListener(null);

      form.reset(ticketData);

      setTimeout(() => {
        ticketTitle = `${ticketData.number} | ${ticketData.title}`;
        updateMaskState(ticketData.id, ticketTitle);
      }, 0);

      // We need to call onAfterSave callback when ticket was already saved, but masked not open.
      // So we register handler for onClose event
      if (registerOnClose) {
        setLastProps((prev) => ({ ...prev, id: ticketData.id }));
        registerOnClose(() => {
          onAfterSave(ticketData);
        });
      }
    }

    const bp = getValues('businessPartner.data');
    const contact = getValues('contact');
    const entityTemplate = bp
      ? {
          entity: contact ? Entities.contact : Entities.customer,
          id: contact ? `${contact.id}:customer:${bp.id}` : bp.id,
        }
      : undefined;

    return {
      entityTemplate,
      params: {
        ticketTemplateContext: JSON.stringify(ticketData),
        senderAddress: defaultTicketSenderAddress.value,
        processedMailTemplateArgs: JSON.stringify({
          noDirectContact: !contact,
        }),
      },
      subTitle: isDirty ? ticketTitle : undefined,
    };
  }, [
    formState.isValid,
    getValues,
    isDirty,
    defaultTicketSenderAddress.value,
    form,
    onSubmit,
    setObjectionListener,
    updateMaskState,
    registerOnClose,
    onAfterSave,
  ]);

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const entityRights = useMemo(
    () => ({
      create: canAddTicket(user),
      read: false,
      update: canEditTicket(user, data),
      delete: canDeleteTicket(user, data),
    }),
    [data, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const title = mask.isCreateMode
    ? data.title
    : `${data.number} | ${data.title}`;

  const state = useMemo<TicketMaskStateContextValue>(() => {
    return { showSolution, setShowSolution };
  }, [showSolution, setShowSolution]);

  const [isContactsPopoverOpend, setIsContactsPopoverOpend] = useState(false);
  const contactsPopoverRef = useRef();

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  const beforeTimeTrackMaskOpen = async () => {
    let ticketData = getValues();
    const isValid = formState.isValid;
    if (isDirty && isValid) {
      ticketData = (await onSubmit(getValues(), null, true)) as Ticket;
      setObjectionListener(null);

      form.reset(ticketData);

      setTimeout(() => {
        const ticketTitle = `${ticketData.number} | ${ticketData?.title}`;
        updateMaskState(ticketData.id, ticketTitle);
      }, 0);
    } else {
      form.trigger();
    }

    return {
      isValid,
      ticketData,
    };
  };

  const ticketDefaultValue = form.watch();

  return (
    <EmailTemplateButtonProvider
      kind={[EMailTemplateKind.TICKET, EMailTemplateKind.KEINE]}
      mainKind={EMailTemplateKind.TICKET}
      getEmailParams={getEmailParams}
    >
      <ContactsPopover
        popoverConfig={{
          open: isContactsPopoverOpend,
          anchorEl: contactsPopoverRef.current,
          onClose: () => setIsContactsPopoverOpend(false),
        }}
        businessPartner={data.businessPartner}
        creator={data.creator}
        editor1={data.editor1}
        editor2={data.editor2}
        highlightedExternalContact={data.contact}
      />
      <MaskContextProvider value={maskContext}>
        <EntityRightsContext.Provider value={entityRights}>
          <TicketMaskStateContext.Provider value={state}>
            <TempFileManagerContext.Provider value={tempFileManager}>
              <FormProvider {...form}>
                <MaskTabContext
                  defaultValue={props.openTab || TicketTabKeys.general}
                >
                  <Form
                    className={styles.maskForm}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <MaskOverlayHeader
                      title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                      subTitle={title}
                      actions={
                        <>
                          {mask.isEditMode && (
                            <IconButton
                              ref={contactsPopoverRef}
                              color="primary"
                              onClick={() => {
                                setIsContactsPopoverOpend(true);
                              }}
                            >
                              <PhoneIcon />
                            </IconButton>
                          )}
                          {isDesktop && (
                            <>
                              <EmailTemplateIconButton />
                              <MaskOverlayTicketTimeTrack
                                ticket={ticketDefaultValue}
                                beforeMaskOpen={beforeTimeTrackMaskOpen}
                              />
                            </>
                          )}
                          <MaskOverlayStandardSubmitButton isDirty={isDirty} />
                          <MaskOverlayFullscreenToggleButton />
                          {mask.isEditMode && (
                            <MaskOverlayMenuWrapper>
                              {!isDesktop && (
                                <>
                                  <EmailTemplateMenuItemButton />
                                  <MaskOverlayTicketTimeTrack
                                    key={'track'}
                                    menuItemMode={true}
                                    ticket={ticketDefaultValue}
                                    beforeMaskOpen={beforeTimeTrackMaskOpen}
                                  />
                                </>
                              )}
                              <MaskOverlayDeleteMenuItem
                                key={'remove'}
                                disabled={mask.wip || !entityRights.delete}
                                onClick={handleDeleteEntitiesClick}
                              />
                            </MaskOverlayMenuWrapper>
                          )}
                        </>
                      }
                      tabs={
                        <TicketTabs
                          renderIndividualTab={shouldRenderIndividualTab}
                        />
                      }
                    />
                    <Content renderIndividualTab={shouldRenderIndividualTab} />
                  </Form>
                </MaskTabContext>
              </FormProvider>
            </TempFileManagerContext.Provider>
          </TicketMaskStateContext.Provider>
        </EntityRightsContext.Provider>
      </MaskContextProvider>
    </EmailTemplateButtonProvider>
  );
};

const TicketTabs = memo(function AppointmentTabs({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab
        value={TicketTabKeys.general}
        label={t('MASK.GENERAL')}
      ></MaskTab>
      <MaskTab
        value={TicketTabKeys.attachments}
        label={t('MASK.ATTACHMENTS')}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value={TicketTabKeys.general}>
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value={TicketTabKeys.attachments}>
        <AttachmentsTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});
