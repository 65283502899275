import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { useTableStateBag } from '@work4all/components';

import { useSetting } from '@work4all/data/lib/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings } from '../../../settings';
import { useLastFilter } from '../table/hooks/use-last-filter';

export const useUserLastFilter = () => {
  useLastFilter(Entities.fullUser);

  const tableStateBag = useTableStateBag();
  const settingValue = useSetting(settings.lastFilter(Entities.fullUser));

  useEffect(() => {
    const userKindFilter = tableStateBag.tableState?.filters.find(
      (x) => x.id === 'userKind'
    );
    if (!userKindFilter || isEqual(settingValue.value, [userKindFilter]))
      return;
    settingValue.set([userKindFilter]);
  }, [tableStateBag.tableState?.filters, settingValue]);
};
