import styles from './AddressData.module.scss';

import { Box, Paper, RadioGroup, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CityPickerField } from '../../../../../../../../../../components/entity-picker/CityPickerField';
import { CountryPickerField } from '../../../../../../../../../../components/entity-picker/CountryPickerField';
import { SalutationPickerField } from '../../../../../../../../../../components/entity-picker/SalutationPickerField';
import { useCities as getCities } from '../../../../../../../../../../hooks';
import { accessorsOfNewbusinessPartnerTemplate } from '../../../../../../../../../files/search/places-utils';
import { PlaceSuggestions } from '../../../../../../../../../files/search/PlacesAutosuggestions';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

const COUNTRIES_THAT_HAVE_A_LIST_OF_CITIES = ['DE'];

export const AddressData: React.FC = () => {
  const { t } = useTranslation();

  const [showSuggestions, setShowSuggestions] = useState(false);

  const { register, watch, setValue, getValues, control } = useFormContextPlus<
    BPMaskFormValue & { nameEqualsStandard?: boolean }
  >();

  const mask = useMaskContext<
    BusinessPartners & { nameEqualsStandard?: boolean }
  >();

  const isPrivateCustomer = watch('isPrivateCustomer');

  const [angloSaxon, setAnglosaxon] = useState(false);

  const firmRef = useRef<HTMLInputElement>(null);

  const registerFirm1 = register('firm1');
  const firm1 = watch('firm1');
  const firm2 = watch('firm2');
  const firm3 = watch('firm3');
  const id = watch('id');

  const currentFirmString = [firm1, firm2, firm3]
    .filter((el) => ![undefined, ''].includes(el))
    .join('\n');

  const contact = getValues('contactList');
  const postOfficeBoxLocation = watch('postOfficeBoxLocation');

  const [currentPostalCode, setCurrentPostalCode] = useState<
    string | undefined
  >();

  const country = watch('country');
  const isCountryHaveAListOfCities =
    COUNTRIES_THAT_HAVE_A_LIST_OF_CITIES.includes(country);

  const cityResponse = getCities(
    {
      cityFilter: undefined,
      queryPage: 0,
      querySize: 100,
      postalCodeFilter: currentPostalCode
        ? currentPostalCode.toString()
        : undefined,
    },
    !isCountryHaveAListOfCities ||
      !currentPostalCode ||
      currentPostalCode.trim().length === 0
  );

  useEffect(() => {
    const res =
      cityResponse.data?.getCities?.data.length > 0
        ? cityResponse.data?.getCities?.data?.[0]
        : undefined;

    if (isCountryHaveAListOfCities && res) {
      setValue('city', res);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityResponse, setValue, isCountryHaveAListOfCities]);

  const [currentPostOfficeBoxPostalCode, setCurrentPostOfficeBoxPostalCode] =
    useState<string | undefined>(getValues().postOfficeBoxPostalCode);

  const cityPostOfficeBoxResponse = getCities({
    cityFilter: undefined,
    queryPage: 0,
    querySize: 100,
    postalCodeFilter: currentPostOfficeBoxPostalCode
      ? currentPostOfficeBoxPostalCode.toString()
      : undefined,
  });

  useEffect(() => {
    const res =
      cityPostOfficeBoxResponse.data?.getCities?.data?.length === 1
        ? cityPostOfficeBoxResponse.data?.getCities?.data?.[0]
        : undefined;

    if ((!postOfficeBoxLocation || postOfficeBoxLocation.length === 0) && res) {
      setValue('postOfficeBoxLocation', res);
    }
  }, [cityPostOfficeBoxResponse, postOfficeBoxLocation, setValue]);

  if (!mask.data) return <div></div>;

  return (
    <>
      <Collapse defaultOpen={true} title={t('MASK.ADDRESS_DATA')}>
        <div className={styles.wrapper}>
          <Stack direction="row" alignItems="center">
            <Box flex="1" pb="1.5rem">
              <ControllerPlus
                control={control}
                name="isPrivateCustomer"
                render={({ field }) => {
                  const val = field.value ? 'private' : 'company';

                  return (
                    <ControlWrapper
                      as={RadioGroup}
                      grid={false}
                      asProps={{
                        ...field,
                        value: val,
                        onChange: (e) => {
                          field.onChange(e.target.value === 'private');
                        },
                      }}
                    >
                      <CheckboxRadioItem
                        className={styles.inlineRadio}
                        label={t('MASK.COMPANY_ADDRESS')}
                        value="company"
                        control={'radio'}
                        horizontalPadding={false}
                      />
                      <CheckboxRadioItem
                        className={styles.inlineRadio}
                        horizontalPadding={false}
                        label={t('COMMON.PRIVATE_ADDRESS')}
                        value="private"
                        control={'radio'}
                        disabled={contact.length > 0 ? true : false}
                      />
                    </ControlWrapper>
                  );
                }}
              />
            </Box>
          </Stack>
          <Box position="relative">
            <Paper
              style={{
                position: 'absolute',
                zIndex: 100,
                top: '4rem',
                left: 0,
                maxWidth: '100%',
                paddingTop: '0.5rem',
              }}
            >
              <PlaceSuggestions
                entity={mask.entity as Entities.customer | Entities.supplier}
                disabled={Boolean(!showSuggestions || isPrivateCustomer || id)}
                query={firm1 || ''}
                showNoResultsText={false}
                onSelect={(template) => {
                  accessorsOfNewbusinessPartnerTemplate.forEach((key) => {
                    setValue(key, template[key]);
                  });
                  firmRef.current.value = template.firm1;
                  setValue('firm2', '');
                  setValue('firm3', '');
                  setShowSuggestions(false);
                }}
                showConfirmationDialog={false}
              />
            </Paper>
          </Box>
          <div className={styles.left}>
            <ControlWrapper>
              {!isPrivateCustomer && (
                <ControlWrapper grid={false} columns={0} paddingBottom={false}>
                  <LabeledInput
                    defaultValue={currentFirmString}
                    ref={firmRef}
                    style={{ height: '7rem' }}
                    label={t('INPUTS.FIRM')}
                    multiline={true}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    error={(registerFirm1 as any).error}
                    required={registerFirm1.required}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowSuggestions(false);
                      }, 500);
                    }}
                    onFocus={() => {
                      setShowSuggestions(true);
                    }}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const splits = val.split('\n');
                      const firm1 = splits[0] || '';
                      const firm2 = splits[1] || '';
                      const firm3 = splits.slice(2).join('\n') || '';
                      setValue('firm1', firm1, { shouldDirty: true });
                      setValue('firm2', firm2, { shouldDirty: true });
                      setValue('firm3', firm3, { shouldDirty: true });

                      if (firm2?.length > 0 || firm3?.length > 0) {
                        setShowSuggestions(false);
                      } else {
                        setShowSuggestions(true);
                      }
                    }}
                  />
                </ControlWrapper>
              )}
              {isPrivateCustomer && (
                <ControlWrapper columns={0} paddingBottom={false}>
                  <LabeledInput
                    {...register('firm2')}
                    label={t('INPUTS.NAME')}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const firm2 = val;
                      setValue('firm2', firm2, { shouldDirty: true });
                    }}
                  />
                  <LabeledInput
                    {...register('firm1')}
                    label={t('INPUTS.LAST_NAME')}
                    onChange={(e) => {
                      const val = e.currentTarget.value;
                      const firm1 = val;
                      setValue('firm1', firm1, { shouldDirty: true });
                    }}
                  />
                </ControlWrapper>
              )}
              <ControlWrapper columns={0} paddingBottom={false}>
                <div>
                  <LabeledInput
                    label={t('INPUTS.STREET_HOUSE_NUMBER')}
                    style={angloSaxon ? { height: '7rem' } : null}
                    {...register('street')}
                  />
                </div>
                {!angloSaxon && (
                  <ControllerPlus
                    name="postalCode"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <LabeledInput
                          label={t('INPUTS.POSTAL_CODE')}
                          {...field}
                          error={fieldState.error?.message}
                          onBlur={(e) => setCurrentPostalCode(e.target.value)}
                        />
                      );
                    }}
                  />
                )}
              </ControlWrapper>
              <ControlWrapper
                columns={angloSaxon ? 2 : 1}
                paddingBottom={false}
              >
                <ControllerPlus
                  name="country"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <CountryPickerField
                        {...field}
                        error={fieldState.error?.message}
                        value={field.value ? { shortName: field.value } : null}
                        onChange={(result) => {
                          setAnglosaxon(result?.anglosaxon);
                          field.onChange(result?.shortName || '');
                        }}
                      />
                    );
                  }}
                />
                {angloSaxon && (
                  <ControllerPlus
                    name="postalCode"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <LabeledInput
                          label={t('INPUTS.POSTAL_CODE')}
                          {...field}
                          error={fieldState.error?.message}
                          onBlur={(e) => setCurrentPostalCode(e.target.value)}
                        />
                      );
                    }}
                  />
                )}
                <div className={angloSaxon ? styles.gridControl : null}>
                  <ControllerPlus
                    name="city"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <CityPickerField
                          {...field}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      );
                    }}
                  />
                </div>
              </ControlWrapper>
            </ControlWrapper>
            {isPrivateCustomer && (
              <ControlWrapper paddingBottom={false}>
                <ControlWrapper>
                  <ControllerPlus
                    name="salutation"
                    control={control}
                    render={({ field }) => {
                      return <SalutationPickerField {...field} />;
                    }}
                  />
                </ControlWrapper>
                <ControlWrapper>
                  <LabeledInput
                    label={t('INPUTS.TITLE_EXTENSION')}
                    {...register('titleExtension')}
                  ></LabeledInput>
                </ControlWrapper>
                <DateTimeInputPicker
                  withTime={false}
                  required={false}
                  dateLabel={t('INPUTS.BIRTH_DATE')}
                  {...register('birthdayDate')}
                />
              </ControlWrapper>
            )}
            {isPrivateCustomer && (
              <ControlWrapper>
                <ControllerPlus
                  name="letterSalutation"
                  control={control}
                  render={({ field: salutation }) => {
                    return (
                      <LabeledInput
                        label={t('INPUTS.LETTER_SALUTATION')}
                        value={salutation.value}
                        onChange={(value) => {
                          salutation.onChange(value);
                        }}
                      ></LabeledInput>
                    );
                  }}
                />
              </ControlWrapper>
            )}
            <ControlWrapper>
              <LabeledInput
                label={t('COMMON.SHORT_NAME')}
                {...register('name')}
              ></LabeledInput>
            </ControlWrapper>
          </div>
        </div>
      </Collapse>
      <Collapse defaultOpen={false} title={t('INPUTS.POST_OFFICE_BOX')}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <ControlWrapper>
              <LabeledInput
                label={t('INPUTS.POST_OFFICE_BOX')}
                {...register('postOfficeBox')}
              />

              <ControllerPlus
                name="postOfficeBoxPostalCode"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <LabeledInput
                      label={t('INPUTS.POST_CODE_OF_POST_OFFICE_BOX')}
                      {...field}
                      error={fieldState.error?.message}
                      onBlur={(e) =>
                        setCurrentPostOfficeBoxPostalCode(e.target.value)
                      }
                    />
                  );
                }}
              />

              <ControllerPlus
                name="postOfficeBoxLocation"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <LabeledInput
                      {...field}
                      label={t('INPUTS.POST_OFFICE_BOX_LOCATION')}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </ControlWrapper>
          </div>
        </div>
      </Collapse>
    </>
  );
};
