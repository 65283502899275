// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

export function removeDotsInKeys(obj: AnyObject): AnyObject {
  const result: AnyObject = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check if the key contains a dot
      if (key.includes('.')) {
        const parts = key.split('.');
        let current = result;

        // Iterate through each part, creating nested objects as needed
        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];

          if (i === parts.length - 1) {
            // Last part, set the value
            current[part] =
              typeof value === 'object' && value !== null
                ? removeDotsInKeys(value)
                : value;
          } else {
            // Create nested object if it doesn't exist
            if (!current[part]) {
              current[part] = {};
            }
            current = current[part];
          }
        }
      } else {
        // No dot in the key, set it as is
        result[key] =
          typeof value === 'object' && value !== null
            ? removeDotsInKeys(value)
            : value;
      }
    }
  }

  return result;
}
