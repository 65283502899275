import { QuickColumnFilterPickerType } from '../../data-tables/table-quick-filters/quick-column-filter-picker/types';
import { OrganisationAreaSectionType } from '../types';

export const sectionToPickerType = (
  section: OrganisationAreaSectionType
): QuickColumnFilterPickerType => {
  if (section === 'groups') return 'group';
  if (section === 'categories') return 'category';
  if (section === 'types') return 'type';
};
