import { IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import React from 'react';

import {
  CustomToolbar,
  CustomToolbarAction,
  CustomToolbarMappedAction,
} from '../ToolbarTypes';

import { mapCustomActionsToComponent } from './mapCustomActionsToComponent';

interface UseCustomActionsOptions {
  skipMode?: boolean;
  selectedRowsIdsList: (string | number)[];
  selectedRowsData?: unknown[];
  custom?: CustomToolbar;
}

export const useToolbarCustomActions = (opt: UseCustomActionsOptions) => {
  const {
    selectedRowsIdsList,
    selectedRowsData = [],
    custom,
    skipMode = false,
  } = opt;
  const mapCustomActions = (
    action: CustomToolbarAction
  ): CustomToolbarMappedAction => {
    const handleProps = action.getHref
      ? {
          href:
            action.mode === 'Delete'
              ? action.getHref(selectedRowsIdsList)
              : action.getHref(selectedRowsIdsList[0]),
        }
      : {
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
            action.mode === 'Delete'
              ? action.handler(selectedRowsIdsList, e, selectedRowsData)
              : action.handler(selectedRowsIdsList[0], e, selectedRowsData[0]),
        };

    return { ...action, ...handleProps };
  };
  const isExactlyOneSelected = selectedRowsIdsList.length === 1;
  const isSomethingSelected = selectedRowsIdsList.length > 0;
  const filterEditModeActions = (x: CustomToolbarAction): boolean => {
    return (
      skipMode ||
      x.mode === 'Add' ||
      (x.mode === 'Edit' && isExactlyOneSelected) ||
      (x.mode === 'Delete' && isSomethingSelected)
    );
  };

  return {
    hideDivider: custom?.hideDivider,
    left: custom?.left?.filter(filterEditModeActions).map(mapCustomActions),
    right: custom?.right?.filter(filterEditModeActions).map(mapCustomActions),
  };
};

export const useToolbarCustomActionsComponents = (
  opt: UseCustomActionsOptions
) => {
  const actions = useToolbarCustomActions(opt);

  return {
    left: mapCustomActionsToComponent(
      actions.left?.map((x) => ({
        ...x,
        tooltip: x.title,
      })) ?? []
    ),
    right: mapCustomActionsToComponent(
      actions.right?.map((x) => ({
        ...x,
        tooltip: x.title,
      })) ?? []
    ),
  };
};

export const useMobileToolbarCustomActionsComponents = (
  opt: UseCustomActionsOptions
) => {
  const actions = useToolbarCustomActions(opt);

  const all = [...(actions.left ?? []), ...(actions.right ?? [])].filter(
    (action) => !action.sections || action.sections.includes('mobile')
  );
  return (
    <React.Fragment>
      {all.map((x) => (
        <Fab
          ref={x.rootRef}
          key={x.actionKey}
          size="medium"
          onClick={x.onClick}
          disabled={x.disabled}
          color={x.color}
        >
          {<x.IconComponent />}
        </Fab>
      ))}
    </React.Fragment>
  );
};

export const usePreviewToolbarCustomActionsComponents = (
  opt: UseCustomActionsOptions
) => {
  const actions = useToolbarCustomActions(opt);

  const all = [...(actions.left ?? []), ...(actions.right ?? [])].filter(
    (action) => !action.sections || action.sections.includes('preview')
  );

  return (
    <React.Fragment>
      {all.map((x) => (
        <IconButton
          ref={x.rootRef}
          key={x.actionKey}
          size="small"
          color="primary"
          onClick={x.onClick}
        >
          {<x.IconComponent />}
        </IconButton>
      ))}
    </React.Fragment>
  );
};
