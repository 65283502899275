import { useCallback } from 'react';

import { useModuleRights } from '../../use-module-rights';
import { checkModuleRight } from '../../useCanView';

import { CheckFunction, UseCheckModuleRight } from './type';

export const useCheckModuleRight: UseCheckModuleRight = () => {
  const { rights } = useModuleRights();

  const check = useCallback<CheckFunction>(
    (right) => checkModuleRight(rights, right),
    [rights]
  );

  return check;
};
