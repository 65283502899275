import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { createUseFormUpdate } from '../../utils/create-use-form-update';

import { EmailMaskFormValue } from './types';

export const useEmailFormUpdate = createUseFormUpdate<EmailMaskFormValue>(
  (form, mask) => {
    /**
     * Update the form's "to" field to the given contact's email address. Sets the
     * field to "" if the contact is `null` or does not have an email address.
     *
     * Will not do anything if the mask is in `"edit"` mode or if the field has been
     * manually changed before.
     */
    function maybeUpdateToField({
      contact,
      businessPartner,
    }: {
      contact: Contact;
      businessPartner: Customer | Supplier;
    }): void {
      if (
        mask.isEditMode ||
        form.getFieldState('toMailContactsInstance').isDirty
      ) {
        return;
      }

      function makeContactEmailFromBusinessPartner(): MailSearchContactsResultItem | null {
        if (businessPartner == null || !businessPartner.isPrivateCustomer) {
          return null;
        }

        const id = `${businessPartner.__typename}_${businessPartner.id}`;

        return {
          __typename: 'MailSearchContactsResultItem',
          id,
          contact: { ...businessPartner },
          mailAddress: businessPartner.eMail,
        };
      }

      function makeContactEmailFromContact(): MailSearchContactsResultItem | null {
        if (contact == null) {
          return null;
        }

        const id = `${businessPartner.__typename}Ap_${contact.id}`;

        return {
          __typename: 'MailSearchContactsResultItem',
          id,
          contact: {
            ...contact,
            businessPartner: {
              __typename: 'Geschaeftspartner',
              data: businessPartner,
            },
          },
          mailAddress: contact.eMail,
        };
      }

      const contactEmail = makeContactEmailFromContact();
      const businessPartnerEmail = makeContactEmailFromBusinessPartner();

      const email = contactEmail ?? businessPartnerEmail;
      const fieldValue = email ? [email] : null;

      // The form field will not be marked as dirty, but it should be fine in
      // "create" mask mode. We need to do this, so we can know if the user has
      // manually changed the "to" field and we shouldn't auto-update it anymore.

      form.setValue('toMailContactsInstance', fieldValue, {
        shouldDirty: false,
      });
    }

    return {
      'businessPartner.data'(businessPartner: Customer | Supplier) {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        if (businessPartner == null || businessPartner.isPrivateCustomer) {
          maybeUpdateToField({ contact: null, businessPartner });
        }

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact(contact: Contact) {
        const businessPartner = form.getValues('businessPartner.data');

        if (businessPartner && !businessPartner.isPrivateCustomer) {
          maybeUpdateToField({ contact, businessPartner });
        }

        const contactId = contact?.id ?? 0;
        return { contactId };
      },
      toMailContactsInstance(contacts: MailSearchContactsResultItem[]) {
        return updateContacts('to', contacts);
      },
      ccMailContactsInstance(contacts: MailSearchContactsResultItem[]) {
        return updateContacts('cc', contacts);
      },
      bccMailContactsInstance(contacts: MailSearchContactsResultItem[]) {
        return updateContacts('bcc', contacts);
      },
      project(project: Project) {
        const projectId = project?.id ?? 0;
        const businessPartnerField = form.getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId };
        }

        const businessPartner = project?.customer ?? project?.supplier ?? null;

        return {
          projectId,
          'businessPartner.data': businessPartner,
        };
      },
      signature(value: EMailSignature) {
        return {
          signatureId: value?.id || EMPTY_UID,
        };
      },
      senderMailbox(mailbox: MailboxConfiguration) {
        return {
          from: mailbox?.smtpAddress || '',
        };
      },
    };
  }
);

const updateContacts = (
  update: 'to' | 'cc' | 'bcc',
  contacts: MailSearchContactsResultItem[]
) => {
  const validContacts = contacts?.filter(
    (x) => x.mailAddress?.trim().length > 0
  );

  const shouldUpdate = validContacts?.length > 0;
  const newValue = validContacts?.map((el) => el.mailAddress).join(';');
  const newValueContacts = JSON.stringify(validContacts);

  return {
    [update]: shouldUpdate ? newValue : '',
    [`${update}MailContacts`]: shouldUpdate ? newValueContacts : '',
  };
};

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
