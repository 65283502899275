import { DateTime } from 'luxon';

import { getLocaleDateString } from './getLocaleDateStringPattern';

export const dateTimeFromString = (
  dateString: string,
  customFormat?: string
) => {
  return DateTime.fromFormat(dateString, customFormat || getLocaleDateString());
};
