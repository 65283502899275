import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import React from 'react';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { EditTableEntry, IdArray } from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';

interface ClonePositionButtonProps {
  onClonePosition: (copyIds: IdArray) => void;
  disabled: boolean;
  positions: EditTableEntry[];
}

export const ClonePositionButton = (props: ClonePositionButtonProps) => {
  const { onClonePosition, disabled, positions } = props;

  const { selectedPositionIds } = useSelectedPositionState();
  if (!(selectedPositionIds.length > 0)) return null;

  const disableClone = positions
    .filter((x) => {
      const firstElement = selectedPositionIds?.[0];
      if (typeof firstElement === 'string') {
        const localArray = selectedPositionIds as string[];
        return localArray.includes(x.localId);
      }
      return true;
    })
    .some((x) => x.posId || x.positionKind === ErpPositionsKind.STUECKLISTE);

  return (
    <IconButton
      disabled={disabled || disableClone}
      color="primary"
      onClick={() => onClonePosition(selectedPositionIds)}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};
