export enum TypeOfWorkTimeTracking {
  MANUALLY = 'MANUALLY',
  MANUALLY_OR_FINGERPRINT = 'MANUALLY_OR_FINGERPRINT',
  ONLY_FINGERPRINT = 'ONLY_FINGERPRINT',
  NO_TRACKING = 'NO_TRACKING',
  MANUALLY_OR_CARD = 'MANUALLY_OR_CARD',
  MANUALLY_OR_CARD_OR_FINGERPRINT = 'MANUALLY_OR_CARD_OR_FINGERPRINT',
  ONLY_CARD = 'ONLY_CARD',
  CARD_OR_FINGER_PRINT = 'CARD_OR_FINGER_PRINT',
}
