import { useApolloClient } from '@apollo/client';
import { compact, concat, set } from 'lodash';
import { useCallback } from 'react';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';
import { useBuildQuery } from '@work4all/data/lib/hooks/data-provider';

import { GroupQueryResult } from '@work4all/models/lib/Classes/GroupQueryResult.entity';
import { GroupQueryResultItem } from '@work4all/models/lib/Classes/GroupQueryResultItem.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { AggregationType } from '@work4all/models/lib/Enums/AggregationType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SortOrderStyle } from '@work4all/models/lib/GraphQL/globalTypes';
import { ObjectTypeByEntity } from '@work4all/models/lib/GraphQLEntities';

import { invariant } from '@work4all/utils';
import { parseUnformattedNum } from '@work4all/utils/lib/parseUnformattedNum';

import { DataType, FilterType } from '../../../types';
import { useTableStateBag } from '../../useTableStateBag';
import {
  FetchMoreOptions,
  GroupByQuery,
  GroupedItem,
  GroupFieldSortType,
  IGroupByResponse,
} from '../types';
import { generateRow } from '../utils/generateRow';
import { isNumber } from '../utils/isNumber';

import { translateField, translateFilter } from './translate-utils';

const VALUE_ALIAS = 'value';
const LABEL_ALIAS = 'label';

const GROUP_BY_FIELD: GroupQueryResult<EMode.query> = {
  totalCount: null,
  data: [{ data: [{ alias: null, value: null, aggregationType: null }] }],
};

const GROUP_BY_REQUEST_DATA = {
  entity: Entities.groupQueryResult,
  data: GROUP_BY_FIELD,
};

export const useFetchGroupBy = (
  requestData: DataRequest,
  pageSize: number,
  groupedBy: string[],
  getGroupKeyFields:
    | ((field: string) => {
        id: string;
        label: string;
      })
    | null,
  customFields?: ParsedCustomFieldConfig[]
) => {
  const client = useApolloClient();

  const { query: groupByQuery } = useBuildQuery(
    GROUP_BY_REQUEST_DATA,
    pageSize
  );
  const { columnsById } = useTableStateBag();

  const createFetchGroupBy = useCallback(
    (params: FetchMoreOptions) => {
      const { path, offset, filters } = params;

      const depthLevel = path.length;

      const groupKeyName = groupedBy[depthLevel];

      const combinedFilter = concat(...compact([requestData.filter, filters]));

      const fields = getGroupKeyFields
        ? getGroupKeyFields(groupKeyName)
        : { id: groupKeyName, label: null };

      const query: GroupByQuery = {
        entityType: ObjectTypeByEntity[requestData.entity],
        groupKeyFields: compact([
          {
            field: translateField(fields.id, requestData.entity, customFields),
            alias: VALUE_ALIAS,
            aggregations: [AggregationType.COUNT],
            sort:
              fields.label === null
                ? [
                    {
                      sortOrder: SortOrderStyle.ASCENDING,
                      sortType: GroupFieldSortType.BY_FIELD,
                    },
                  ]
                : null,
          },
          fields.label !== null
            ? {
                field: translateField(
                  fields.label,
                  requestData.entity,
                  customFields
                ),
                alias: LABEL_ALIAS,
                sort: [
                  {
                    sortOrder: SortOrderStyle.ASCENDING,
                    sortType: GroupFieldSortType.BY_FIELD,
                  },
                ],
              }
            : null,
        ]),
        filter: JSON.stringify(
          translateFilter(combinedFilter, requestData.entity, customFields)
        ),
      };

      const fetchData = async () => {
        const response = await client.query<IGroupByResponse>({
          fetchPolicy: 'no-cache',
          query: groupByQuery,
          variables: {
            query,
            page: Math.floor(offset / pageSize),
            size: pageSize,
          },
        });

        const reponse = response.data.groupBy;

        const total = reponse.totalCount;
        const items = reponse.data.map((aggregated) => {
          const items = aggregated.data;

          const isGroupKeyItem = (item: GroupQueryResultItem) => {
            return item.alias === VALUE_ALIAS && item.aggregationType === null;
          };

          const isGroupCountItem = (item: GroupQueryResultItem) => {
            return (
              item.alias === VALUE_ALIAS &&
              item.aggregationType === AggregationType.COUNT
            );
          };

          const isGroupLabelItem = (item: GroupQueryResultItem) => {
            return item.alias === LABEL_ALIAS && item.aggregationType === null;
          };

          const groupValue = items.find(isGroupKeyItem);
          const groupCount = items.find(isGroupCountItem);
          const groupLabel = items.find(isGroupLabelItem);

          invariant(groupValue, 'Group value item is not found.');
          invariant(groupCount, 'Group count item is not found.');

          const key = groupValue.value;
          let formattedKey = key;
          if (isNumber(columnsById[groupedBy[depthLevel]].dataType)) {
            // TODO I don't really understand how this can ever happen (I have
            // not seen any values returned by the API being formated in this
            // way), but keep it here for now just in case. Can, probably,
            // remove it later after making sure that the number format is
            // always the same.

            /**
             * key can be used to generate filters when data slice is requested.
             * numbers with "," are invalid when filtering. replace them with
             * "."
             */
            formattedKey = parseUnformattedNum(key).toString();
          }

          if (
            columnsById[groupedBy[depthLevel]].dataType === DataType.Checkbox &&
            columnsById[groupedBy[depthLevel]].filterType !==
              FilterType.BooleanNumber
          ) {
            formattedKey = key === '0' ? 'false' : 'true';
          }

          const subRowBasePath = path.concat(formattedKey);
          const count = parseInt(groupCount.value, 10);

          const row: GroupedItem = {
            isGrouped: true,
            meta: {
              path: subRowBasePath,
              groupByID: groupedBy[depthLevel],
              groupByLabel: groupLabel ? groupLabel.value : groupValue.value,
              groupByVal: formattedKey,
              groupByTotalCount: count,
            },
            subRows: Array.from({ length: Math.min(3, count) }, () => {
              return generateRow(subRowBasePath, true);
            }),
          };

          set(row, groupedBy[depthLevel], key);
          return row;
        });

        return { total, items };
      };

      return { fetchData };
    },
    [
      client,
      columnsById,
      customFields,
      getGroupKeyFields,
      groupByQuery,
      groupedBy,
      pageSize,
      requestData.entity,
      requestData.filter,
    ]
  );

  return createFetchGroupBy;
};
