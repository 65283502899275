import i18next from 'i18next';
import { DateTime, Duration } from 'luxon';

export enum DatePeriodPresets {
  DIFF = 'DIFF',
  PERIOD = 'PERIOD', //this does an autodetect of the period formats below
  PERIOD_MULTIPLEDAYS_ALLDAY = 'PERIOD_MULTIPLEDAYS_ALLDAY',
  PERIOD_SINGLEDAY_TIME = 'PERIOD_SINGLEDAY_TIME',
  PERIOD_MULTIPLEDAY_TIME = 'PERIOD_MULTIPLEDAY_TIME',
}

// date formats are specced in:
// https://work4all.sharepoint.com/:w:/s/WebSquad/EaJl2jaOABJIgDqhdoODeboBhz8ImmBRfn5s2nLbtoLAmg?e=qNjQL6
const DATE_DAY_MONTH: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
};
const DATE_SIMPLE_2YEAR: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

export const DateTimeCustom = {
  DATE_DAY_MONTH,
  DATE_SIMPLE_2YEAR,
};

const ensureDateTime = (dateString: string | Date) => {
  return typeof dateString === 'string'
    ? DateTime.fromISO(dateString)
    : DateTime.fromJSDate(dateString);
};

export const formatDateString = (
  dateString: string | Date,
  format: Intl.DateTimeFormatOptions | DatePeriodPresets | string
): string | null => {
  const startDate = ensureDateTime(dateString);
  if (!startDate.isValid || startDate.toMillis() <= 0) {
    return null;
  }

  if (typeof format === 'object' && format !== null) {
    return startDate.toLocaleString(format);
  }

  // format expression formatting
  return startDate.toFormat(format as string);
};

export function formatDatePeriod(
  format: DatePeriodPresets,
  startDate: string | Date,
  endDate?: string | Date,
  isWholeDay = false
) {
  const start = ensureDateTime(startDate);
  const end = ensureDateTime(endDate);

  if (!start.isValid || start.toMillis() <= 0) {
    return null;
  }
  if (!start.isValid && !end.isValid) {
    return '[invalid period]';
  }

  const isSameDay =
    start.hasSame(end, 'day') &&
    start.hasSame(end, 'year') &&
    start.hasSame(end, 'month');

  if (format === DatePeriodPresets.PERIOD) {
    if (isSameDay && isWholeDay) {
      return formatDateString(
        start.toJSDate(),
        DateTimeCustom.DATE_SIMPLE_2YEAR
      );
    }
    if (!isSameDay && isWholeDay) {
      return (
        start.toLocaleString(DateTimeCustom.DATE_SIMPLE_2YEAR) +
        ' - ' +
        end.toLocaleString(DateTimeCustom.DATE_SIMPLE_2YEAR)
      );
    }
    if (isSameDay && !isWholeDay) {
      return (
        start.toLocaleString(DateTime.DATETIME_SHORT) +
        ' - ' +
        end.toLocaleString(DateTime.TIME_SIMPLE)
      );
    }
    if (!isSameDay && !isWholeDay) {
      return (
        start.toLocaleString(DateTime.DATETIME_SHORT) +
        ' - ' +
        end.toLocaleString(DateTime.DATETIME_SHORT)
      );
    }
  }

  if (format === DatePeriodPresets.DIFF) {
    if (isWholeDay) return `1 ${i18next.t('COMMON.DAY')}`;

    const startDuration = Duration.fromMillis(start.toMillis());
    const endDuration = Duration.fromMillis(end.toMillis());
    const duration = endDuration.minus(startDuration);

    //if duration under 60000 millis return 0, the minimum unit is the minute
    if (duration.toMillis() < 60000) return '0';

    const durationObject = duration
      .shiftTo('minutes', 'hours', 'days')
      .toObject();
    const arrayValue = Object.keys(durationObject)
      .filter((key) => durationObject[key] > 0)
      .map(
        (key) =>
          `${durationObject[key]} ${i18next.t(
            `COMMON.${key.slice(0, -1).toUpperCase()}`,
            {
              count: durationObject[key],
            }
          )}`
      );

    return arrayValue.length === 1
      ? arrayValue[0]
      : `${arrayValue.slice(0, -1).join(', ')}, ${i18next.t(
          'COMMON.AND'
        )} ${arrayValue.slice(-1)}`;
  }
  return '[invalid period]';
}
