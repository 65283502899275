import { Group } from '@work4all/models/lib/Classes/Group.entity';

import { GroupsWithoutChildrenFunction } from './types';

export const groupsWithoutChildren: GroupsWithoutChildrenFunction = ({
  groups,
  maxResult,
}) => {
  const parentIds = groups.map((group) => group.parentId);
  const result: Group[] = [];

  for (const group of groups) {
    if (result.length === maxResult) {
      break;
    }

    if (parentIds.includes(group.id)) {
      continue;
    }

    result.push(group);
  }

  return result;
};
