import { useTranslation } from 'react-i18next';

import { GroupLabelProps } from './types';

type Props = Pick<GroupLabelProps, 'value' | 'translationPrefix'>;

export const EnumGroupLabel = ({ value, translationPrefix = '' }: Props) => {
  const { t } = useTranslation();

  return t(`${translationPrefix}.${value}`);
};
