import styles from './UserButton.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Badge,
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { useChangeTenant, useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import {
  MAX_DISPLAYED_NOTIFICATIONS,
  useUnreadNotifications,
} from '../../../../features/notifications';

interface UserButtonProps {
  onAvatarClick: () => void;
  standalone?: boolean;
}

export const UserButton: React.FC<UserButtonProps> = (props) => {
  const { onAvatarClick, standalone } = props;

  const user = useUser();
  const latestNotifications = useUnreadNotifications();

  const { activeTenantData, tenants } = useTenant();
  const tenantsDropdownItems = tenants.map((tenant) => ({
    title: tenant.name,
    id: tenant.id.toString(),
  }));

  const changeTenant = useChangeTenant();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeTenant = (tenantId: string) => {
    setAnchorEl(null);
    changeTenant(Number(tenantId));
  };

  return (
    <div
      className={clsx(styles.buttonWrapper, {
        [styles.buttonWrapperRounded]: standalone,
      })}
    >
      <Button
        onClick={handleButtonClick}
        endIcon={<ArrowDropDownIcon />}
        classes={{ endIcon: styles.endIcon }}
        className={styles.button}
      >
        <Typography variant="body1" color="inherit" noWrap>
          {activeTenantData.name ?? ''}
        </Typography>
      </Button>
      <IconButton
        onClick={onAvatarClick}
        size="small"
        disableRipple
        className={styles.iconButton}
      >
        <Badge
          badgeContent={latestNotifications.total}
          max={MAX_DISPLAYED_NOTIFICATIONS}
          color="error"
          overlap="circular"
        >
          <HookedUserIcon
            userId={user?.benutzerCode}
            className={styles.userIcon}
          />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            sx: {
              mt: 0.75,
            },
          },
        }}
      >
        {tenantsDropdownItems.map(({ id, title }) => (
          <MenuItem key={id} onClick={() => handleChangeTenant(id)}>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
