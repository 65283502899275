import { useDataMutation, useUser } from '@work4all/data';

import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PreviewInputsProvider } from '../../components/preview-input';
import { useEntityPreview } from '../../hooks/use-entity-preview';
import { EntityUntion } from '../types/EntityUntion';

interface DocumentEditablePreviewParams {
  onPopoverClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit: (field: any, otherArgs?: any) => Promise<void>;
}

interface DocumentEditablePreviewProps {
  entity: Entities;
  entries?: EntityUntion[];
  entityData?: EntityUntion;
  children: (props: DocumentEditablePreviewParams) => JSX.Element;
}

export const DocumentEditablePreview = (
  props: DocumentEditablePreviewProps
) => {
  const { entity, entries, entityData } = props;
  const user = useUser();

  const [mutate] = useDataMutation<EntityUntion, EMode.upsert>({
    entity: entity,
    mutationType: EMode.upsert,
    responseData: entityData,
  });

  const entityPreviewProps = useEntityPreview({
    user,
    subEntityType: entity,
    entries,
    mutate,
  });
  const { onPopoverClose, onEdit } = entityPreviewProps;

  return (
    <PreviewInputsProvider
      {...entityPreviewProps}
      numberOfSelectedRows={entries.length}
    >
      {props.children({ onPopoverClose, onEdit })}
    </PreviewInputsProvider>
  );
};
