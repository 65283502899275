import { GraphQLErrors } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import i18next from 'i18next';

import { translateInputField } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';

import { ApiValidationErrorType } from '@work4all/models/lib/Enums/ApiValidationErrorType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const IGNORED_CODES = ['WorkValidationError'];
// TODO: Ask api if those exist
export enum ApiErrorCodes {
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  USER_RIGHTS = 'USER_RIGHTS',
  SQL = 'SQL',
  ACCESS_DENIED = 'ACCESS_DENIED',
}

/**
 * Those are mostly component specific issues which are handled individually
 */
const SKIP_SENTRY_AND_SNACKBAR_CODES: string[] = [
  ApiValidationErrorType.BZ_OBJECT_NUMBER_ALREADY_EXIST,
  ApiValidationErrorType.TIME_TRACKING_SELECTED_POSITION_IS_SET_AS_COMPLETED,
  ApiErrorCodes.FILE_NOT_FOUND,
  'RE_NUMBER_ALREADY_EXISTS',
];

/**
 * Those are mostly component specific issues which are handled individually
 */
const SKIP_SNACKBAR_CODES: string[] = [
  ApiErrorCodes.SQL,
  ApiErrorCodes.ACCESS_DENIED,
];

/**
 * Replace camelCase or PascalCase transitions with an underscore and the letter
 */
export function toConstantCase(input: string) {
  if (!input) return input;
  return input.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
}

const translateError = (code: string) => {
  const constCode = toConstantCase(code);
  return i18next.t(`ERROR.${constCode}`);
};

const hasTranslation = (err: GraphQLError) => {
  const code = err.extensions?.code;
  const constCode = toConstantCase(code);
  return (
    // Check if there is translation
    i18next.t(`ERROR.${constCode}`) !== constCode &&
    // Check if key of translation even exist
    i18next.t(`ERROR.${constCode}`) !== `ERROR.${constCode}`
  );
};

const shouldCaptureError = (errors: GraphQLErrors) => {
  return errors
    .filter((x) => !IGNORED_CODES.includes(x.extensions?.code))
    .some((e) => {
      const code = e.extensions?.code;
      return (
        !SKIP_SENTRY_AND_SNACKBAR_CODES.includes(code) &&
        !SKIP_SENTRY_AND_SNACKBAR_CODES.includes(toConstantCase(code))
      );
    });
};

const shouldShowError = (errors: GraphQLErrors) => {
  return errors
    .filter((x) => !IGNORED_CODES.includes(x.extensions?.code))
    .some((e) => {
      const code = e.extensions?.code;
      return (
        !SKIP_SNACKBAR_CODES.includes(code) &&
        !SKIP_SNACKBAR_CODES.includes(toConstantCase(code))
      );
    });
};

const parseErrors = <T>(
  errors: GraphQLErrors,
  entity?: Entities
): ApiErrors<T> => {
  return errors.map((x) => {
    return {
      message: x.message,
      code: x.extensions.code,
      property: x.extensions.data?.info?.Property
        ? (translateInputField(
            x.extensions.data.info.Property,
            entity
          ) as keyof T)
        : undefined,
    };
  });
};

export const ValidationErrors = {
  hasTranslation,
  shouldCaptureError,
  parseErrors,
  translateError,
  shouldShowError,
};

export type ApiErrors<T = unknown> = {
  message: string;
  property: keyof T | undefined;
  code: string;
}[];
