import { useEffect, useRef } from 'react';

import { ColumnInstance, useTableStateBag } from '@work4all/components';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

interface UseDefaultTableFilter {
  columnId: string;
  value: unknown;
}

export const useDefaultTableFilter = (props: UseDefaultTableFilter) => {
  const { columnId, value } = props;
  const tableStateBag = useTableStateBag();
  const defaultFilterApplied = useRef(false);

  const memoValue = useDeepMemo(() => value, [value]);

  useEffect(() => {
    if (defaultFilterApplied.current) return;
    setTimeout(() => {
      if (!tableStateBag.tableInstance) return;
      defaultFilterApplied.current = true;
      const col = tableStateBag.tableInstance.allColumns.find(
        (x) => x.id === columnId
      ) as ColumnInstance;
      col.setFilter({
        filterType: col.filterType,
        value: memoValue,
      });
    }, 0);
  }, [tableStateBag.tableInstance, columnId]);
};
