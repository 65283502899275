import i18next from 'i18next';
import { DateTime } from 'luxon';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

export function formatRelativeDate(date: string): string {
  const givenDate = DateTime.fromISO(date);

  const refDate = DateTime.now();

  const todayDate = refDate.startOf('day');
  const futureDate = todayDate.plus({ days: 1 });
  const yesterdayDate = todayDate.minus({ days: 1 });

  if (givenDate.diff(futureDate).as('milliseconds') > 0) {
    return givenDate.toLocaleString(DateTime.DATETIME_SHORT);
  } else if (givenDate.diff(todayDate).as('milliseconds') > 0) {
    return givenDate.toLocaleString(DateTime.TIME_SIMPLE);
  } else if (givenDate.diff(yesterdayDate).as('milliseconds') > 0) {
    return i18next.t('DATE_TIME.yesterday');
  } else {
    return givenDate.toLocaleString(DateTimeCustom.DATE_SIMPLE_2YEAR);
  }
}
