import styles from './UserMenu.module.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { UserButton } from './components/UserButton/UserButton';
import { Profile } from './views/Profile/Profile';

const classes = { wrapper: styles.menu };

export const UserMenu: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();

  const [popoverState, setPopoverState] = useState<{
    open: boolean;
    initialView: IStackItem;
  }>({ open: false, initialView: null });

  const openPopover = () => {
    setPopoverState({
      open: true,
      initialView: {
        title: '',
        view: <Profile />,
        breadcrumbTitle: t('NAVIGATION.PROFILE'),
      },
    });
  };

  const closePopover = () => {
    setPopoverState((state) => {
      return { ...state, open: false };
    });
  };

  const togglePopover = () => {
    if (popoverState.open) {
      closePopover();
    } else {
      openPopover();
    }
  };

  return (
    <div {...props}>
      <NavigationOverlay
        classes={classes}
        open={popoverState.open}
        initialView={popoverState.initialView}
        withBreadcrumbs={true}
        close={() => {
          closePopover();
        }}
      />
      <UserButton onAvatarClick={togglePopover} />
    </div>
  );
};
