import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ToolbarDndWrapperDropZone = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: '42px',
        border: '2px dashed var(--brand01)',
        borderRadius: '4px',
        margin: '4px',
        textAlign: 'center',
        alignContent: 'center',
        color: 'var(--brand01)',
        background: 'rgba(73, 156, 224, 0.25)',
      }}
    >
      <Typography variant="caption" color="var(--brand01)">
        {t('TABLES.TOOLBAR_DROP_AREA_ZONE.GROUP_BY')}
      </Typography>
    </div>
  );
};
