import { ApolloError, gql, useApolloClient } from '@apollo/client';
import cryptoRandomString from 'crypto-random-string';
import { useCallback } from 'react';

import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import {
  EntitiyDefinition,
  FieldDefinitions,
  IFieldParam,
  UpsertMutationInput,
} from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { genFields } from '@work4all/utils/lib/graphql-query-generation/helpers/genFields';
import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { useApolloExtrasContext } from '../../apollo-extras/apollo-extras-context';
import { evictEntityCache } from '../../apollo-extras/evictEntityCache';
import { useEntityEventsContext } from '../../entity-events/entity-events-context';

import { fieldsToQueryFields } from './utils';

type Mutations = EMode.upsert;

export type MutationInput<
  TEntity,
  TMutation extends Mutations
> = TMutation extends EMode.upsert ? UpsertMutationInput<TEntity> : never;

export type UseDataMutationMutate<TEntity, TMutation extends Mutations> = {
  (input: MutationInput<TEntity, TMutation>): void;
};

export type UseDataMutation<TEntity, TMutation extends Mutations> = [
  mutate: UseDataMutationMutate<TEntity, TMutation>
];

export type UseDataMutationOptions<TEntity, TMutation extends Mutations> = {
  entity: Entities;
  resetStore?: boolean;
  mutationType: TMutation;
  /**
   * @param responseData define the shape of the response object
   */
  responseData: TEntity;
  onCompleted?: (data: TEntity) => void;
  onError?: (error: ApolloError) => void;
  inputName?: string;
  includeNonPrimitveValues?: boolean;
  wrapInput?: boolean;
  translateRelationInput?: boolean;
};

type SubType<T, C> = {
  [K1 in { [K2 in keyof T]: T[K2] extends C ? K2 : never }[keyof T]]: T[K1];
};
// type FilterFlags<Base, Condition> = {
//   [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
// };

// type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base];

// type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>;

export type EntityRelation<T> = {
  addModify?: Partial<SubType<T, string | number | boolean | null>>[] | null;
  add?: Partial<SubType<T, string | number | boolean | null>>[] | null;
  remove?: (string | number)[] | null;
  shouldTranslate?: boolean;
};

type EntityRelationsArg<T extends object> = {
  [Key in keyof SubType<T, unknown[]>]?: T[Key] extends (infer R)[]
    ? EntityRelation<R> | null
    : never;
};

export type MutationExtraArgs<
  T extends object,
  RelArg = Record<string, never>
> = {
  relations?: EntityRelationsArg<T> | RelArg | null;
  /**
   * Used when the backend defines properties outside the `relations`,
   * because using `relations` has a whole different process in the backend.
   */
  arguments?: EntityRelationsArg<T> | RelArg | null;
};

const UPSERT_MUTATION_INPUT_NAME = 'input';

export function useDataMutation<
  TEntity,
  TMutation extends Mutations,
  RelArg = Record<string, never>
>({
  entity,
  mutationType,
  resetStore = true,
  responseData,
  onCompleted,
  onError,
  inputName = UPSERT_MUTATION_INPUT_NAME,
  includeNonPrimitveValues = false,
  wrapInput = true,
  translateRelationInput = true,
}: UseDataMutationOptions<TEntity, TMutation>) {
  const client = useApolloClient();

  const latestOnCompleted = useLatest(onCompleted);
  const latestOnError = useLatest(onError);

  const { isAutoInvalidationDisabled } = useApolloExtrasContext();
  const isAutoInvalidationDisabledRef = useLatest(isAutoInvalidationDisabled);

  const { emit: emitEntityEvent } = useEntityEventsContext();

  const getMutationDefinition = useCallback(() => {
    const definition = entityDefinition[entity] as EntitiyDefinition<unknown>;

    if (!definition) {
      throw new Error(`Could not find definition for entity "${entity}"`);
    }

    const mutationDefinition = definition.remote.mutations?.[mutationType];

    if (!mutationDefinition) {
      console.warn(
        `Could not find definition for mutation "${mutationType}" for entity "${entity}"`
      );
      return;
    }

    return mutationDefinition;
  }, [entity, mutationType]);

  const mutate = useCallback(
    function mutate<TInput extends object>(
      input: TInput,
      extraArgs: MutationExtraArgs<TInput, RelArg> = {},
      options?: {
        skipOnComplete?: boolean;
      }
    ) {
      const mutationDefinition = getMutationDefinition();
      if (!mutationDefinition) return console.error('mutation not implemented');

      const mutationString = generateGraphQLMutation({
        entity,
        mutationName: mutationDefinition.mutationName,
        inputName,
        response: responseData,
        args: mutationDefinition.args,
      });
      return client
        .mutate<TEntity>({
          mutation: gql(mutationString),
          variables: wrapInput
            ? {
                input: translateInput(entity, input, includeNonPrimitveValues),
                ...translateExtraArgs(
                  entity,
                  extraArgs,
                  translateRelationInput
                ),
              }
            : input,
        })
        .then(
          (data) => {
            if (!options?.skipOnComplete) {
              latestOnCompleted.current?.(
                data.data[mutationDefinition.mutationName]
              );
            }

            emitEntityEvent({ type: 'upsert', entity, data });

            evictEntityCache(
              client,
              entity,
              resetStore && !isAutoInvalidationDisabledRef?.current
            );

            return data.data[mutationDefinition.mutationName] as TEntity;
          },
          (error) => {
            if (latestOnError.current) {
              latestOnError.current(error);
            } else {
              console.error(error);
            }
          }
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getMutationDefinition,
      entity,
      inputName,
      responseData,
      client,
      includeNonPrimitveValues,
      latestOnCompleted,
      resetStore,
      isAutoInvalidationDisabledRef,
      latestOnError,
      wrapInput,
    ]
  );

  return [mutate] as const;
}

export function translateInput<Input>(
  entity: Entities,
  input: Input,
  includeNonPrimitveValues = false
) {
  const fieldDefinitions = entityDefinition[entity]
    .fieldDefinitions as FieldDefinitions<Input>;

  const allowedFields = Object.entries(input).filter(([key, value]) => {
    if (value === undefined) return false;

    const field = fieldDefinitions[key as keyof Input];
    if (!field) return false;

    if (key === 'customFieldList') return true;

    // Not a primitive field
    if (field.entity !== undefined && includeNonPrimitveValues !== true) {
      return false;
    }

    return true;
  }) as [keyof Input, unknown][];

  const translatedFields = allowedFields.map(([key, value]) => {
    const field = fieldDefinitions[key];
    return [field.alias, value] as const;
  });

  return Object.fromEntries(translatedFields);
}

export function prepareResponse(entity: Entities, data: unknown) {
  const { str: responseFields } = genFields(fieldsToQueryFields(data, entity));
  return responseFields;
}

type IGenerateGraphQLMutationOptions<IResponse> = {
  entity: Entities;
  name?: string;
  mutationName: string;
  inputName: string;
  response: IResponse;
  args?: IFieldParam[];
};

function generateMutationName() {
  return `Mutation${cryptoRandomString({ length: 32, type: 'alphanumeric' })}`;
}

function generateGraphQLMutation<IResponse>({
  entity,
  name = generateMutationName(),
  mutationName,
  response,
  args = [],
}: IGenerateGraphQLMutationOptions<IResponse>) {
  function varName(name: string): string {
    return `$${name}`;
  }

  const additionalArgsVarsString = args
    .map(({ name, type }) => `${varName(name)}: ${type}`)
    .join(' ');

  const additionalArgsString = args
    .map(({ name }) => `${name}: ${varName(name)}`)
    .join(' ');
  const responseString = prepareResponse(entity, response);

  return `mutation ${name}(${additionalArgsVarsString}) { ${mutationName}(${additionalArgsString}) { ${responseString} } }`;
}

function translateExtraArgs<T extends object, TArg>(
  entity: Entities,
  extraArgs: MutationExtraArgs<T, TArg>,
  translateRelationInput: boolean
) {
  if (Object.keys(extraArgs).length === 0) {
    return null;
  }

  let args = {};

  if (extraArgs?.relations) {
    args = {
      relations: translateRelationsArg(
        entity,
        extraArgs.relations,
        translateRelationInput
      ),
    };
  }

  if (extraArgs?.arguments) {
    args = {
      ...args,
      ...translateRelationsArg(
        entity,
        extraArgs.arguments,
        translateRelationInput
      ),
    };
  }

  return args;
}

function translateRelationsArg<T extends object, TArg>(
  entity: Entities,
  relations: TArg | EntityRelationsArg<T>,
  translateRelationInput: boolean
) {
  if (!relations) {
    return null;
  }

  const fields = entityDefinition[entity]
    .fieldDefinitions as FieldDefinitions<T>;

  return Object.fromEntries(
    Object.entries(relations)
      .map(([key, value]) => {
        const field = fields[key as keyof T];

        //no translation
        if (!field) return [key, value];

        // Is not a relation. Likely a malformed input.
        if (!field.entity) {
          return null;
        }

        // TODO Handle union relations.

        const relationEntity = Array.isArray(field.entity)
          ? field.entity[0]
          : field.entity;

        return [
          field.alias,
          translateRelation(relationEntity, value, translateRelationInput),
        ] as const;
      })
      .filter(Boolean)
  );
}

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

function translateRelation<T extends object>(
  entity: Entities,
  relation: EntityRelation<T>,
  translateRelationInput: boolean
) {
  if (!relation) {
    return null;
  }
  const shouldTranslate =
    relation.shouldTranslate === undefined ? true : relation.shouldTranslate;

  // PostIt and SalesOpportunityRatingStatus entities are special cases and can't be handled by this hook.
  if (entity === Entities.postIt) return relation;
  if (entity === Entities.salesOpportunityRating) return relation;

  const translate = (add: ArrayElement<EntityRelation<T>['add']>) => {
    if (typeof add === 'object' && translateRelationInput && shouldTranslate) {
      return translateInput(entity, add);
    }

    return add;
  };

  if (relation.shouldTranslate !== undefined) delete relation.shouldTranslate;
  return {
    ...relation,
    add:
      relation.add?.map(translate) ?? (relation.addModify ? undefined : null),
    addModify: relation.addModify?.map(translate) ?? undefined,
    remove: relation.remove,
  };
}
