import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';

import { usePaymentMask } from '../../../hooks/use-payment-mask';
import { useBottomViews } from '../../bottom-views';
import { AccountingLockCell } from '../AccountingLockCell';
import { DueDateClassifiedCell } from '../DueDateClassifiedCell';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { InvoiceFormCell } from '../InvoiceFormCell';
import { PaymentStatusCell } from '../PaymentStatusCell';
import { RaAccountingStatusCell } from '../RaAccountingStatusCell';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';

import schema from './../schemata/raViewModel-table-schema.json';

const defaultSort = [
  { field: 'invoiceDate', direction: SortDirection.DESCENDING },
];

const DELETABLE_STATUS = [
  PaymentStatus.ZERO_INVOICE,
  PaymentStatus.OPEN_CREDIT,
  PaymentStatus.OPEN,
  PaymentStatus.DUE,
];

export const RaTable = React.forwardRef<TableInstance, IEntityTable>(
  function RaTable(_props, ref) {
    const dataTable = useEntityDataTable<RaViewModel, never>({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
      cells: {
        PaymentStatus: PaymentStatusCell,
        DueDateClassified: DueDateClassifiedCell,
        InvoiceForm: InvoiceFormCell,
        RaAccountingStatus: RaAccountingStatusCell,
        AccountingLock: AccountingLockCell,
      },
      forceRequestFields: {
        accountingHandoverDate: null,
      } as RaViewModel,
    });

    const template = useErpHandlersTemplate('supplier.id', Entities.supplier);

    const { t } = useTranslation();
    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.ra,
    });

    const openPaymentMaskAction = usePaymentMask(
      Entities.raViewModel,
      dataTable.refetch
    );

    const prefilter = [{ id: { $eq: dataTable.selectedEntity?.id } }];
    const bottomArea = useBottomViews({
      skip: !dataTable.selectedEntity,
      views: [
        {
          type: 'table',
          entity: Entities.raLedgerAccountSplit,
          titleTranslationKey: 'COMMON.BOOKINGS',
          options: {
            prefilter,
          },
        },
        {
          type: 'table',
          entity: Entities.raPayment,
          titleTranslationKey: 'COMMON.PAYMENTS',
          options: {
            prefilter,
          },
        },
      ],
    });

    return (
      <EntityTable
        displayFooter
        ref={ref}
        template={template}
        {...dataTable}
        actions={{
          add: {
            visible: false,
            disabled: true,
            handler: () => null,
          },
          edit: {
            visible: false,
            disabled: true,
            handler: () => null,
          },
          custom: { left: [openPaymentMaskAction] },
          remove: {
            ...deleteConfig,
            handler: async (e) => {
              await deleteConfig.handler(e);
              dataTable.refetch();
            },
            canDeleteEntity: (id) => {
              const entity = dataTable.selectedEntities.find(
                (item) => item.id === Number(id)
              );
              const canDelete =
                DELETABLE_STATUS.includes(entity.paymentStatus) &&
                !entity.accountingHandoverDate
                  ? true
                  : false;

              const preventMessage = !DELETABLE_STATUS.includes(
                entity.paymentStatus
              )
                ? t('ALERTS.OUTGOING_INVOICE_PAYMENTS_EXIST', {
                    number: entity?.invoiceNumber,
                  })
                : entity.accountingHandoverDate
                ? t('ALERTS.OUTGOING_INVOICE_HANDOVER', {
                    number: entity?.invoiceNumber,
                  })
                : null;

              return {
                value: canDelete,
                preventMessage,
              };
            },
          },
        }}
        areas={{
          bottom: bottomArea,
        }}
      />
    );
  }
);
