import { useUser } from '../../useUser';

import {
  CanAddPermissionFunction,
  CanConvertPermissionFunction,
  CanDeletePermissionFunction,
  CanEditPermissionFunction,
  CanPreviewPermissionFunction,
  UsePermissionsReturn,
} from './types';
import { usePermissionsFunction } from './use-permission-function';
import { usePermissionsRights } from './use-permissions-rights';

export const usePermissions = (): UsePermissionsReturn => {
  const user = useUser();
  const rights = usePermissionsRights();

  const canPreview = usePermissionsFunction<CanPreviewPermissionFunction>({
    user,
    rights,
    permission: 'canPreview',
  });

  const canAdd = usePermissionsFunction<CanAddPermissionFunction>({
    user,
    rights,
    permission: 'canAdd',
  });

  const canEdit = usePermissionsFunction<CanEditPermissionFunction>({
    user,
    rights,
    permission: 'canEdit',
  });

  const canConvert = usePermissionsFunction<CanConvertPermissionFunction>({
    user,
    rights,
    permission: 'canConvert',
  });

  const canDelete = usePermissionsFunction<CanDeletePermissionFunction>({
    user,
    rights,
    permission: 'canDelete',
  });

  return {
    canPreview,
    canAdd,
    canEdit,
    canConvert,
    canDelete,
  };
};
