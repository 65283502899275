import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerPopover } from '../../entity-picker/components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { InfoCard } from './info-card/InfoCard';
import { InfoCards } from './info-cards/InfoCards';
import { BeforeContentElement } from './preview-input/components/before-content-element/BeforeContentElement';
import { PreviewInputsProvider } from './preview-input/providers/preview-inputs-provider/preview-inputs-provider';

export interface PreviewContentField<T> {
  key: Extract<keyof T, string>;
  label: string | ((entry: T) => string);
  entity?: Entities;
  disabled?: boolean;
  truncate?: boolean;
  formatter?: (field: T[keyof T], entry: T) => string;
  getPicker?: (props: GetPickerProps<T>) => JSX.Element;
  getData?: (entry: T) => object;
  isCommon?: (commonFields: string[]) => boolean;
}

export interface PreviewContentProps<T> {
  entity: Entities;
  entries: T[];
  fields: PreviewContentField<T>[];
  style?: React.CSSProperties;
  mutate?: <TInput extends object>(input: TInput, otherArgs) => Promise<void>;
}

export function PreviewContent<T>(props: PreviewContentProps<T>) {
  const { entity, fields, entries, style, mutate: mutateAll } = props;

  const user = useUser();

  const [mutate] = useDataMutation<T, EMode.upsert>({
    entity,
    mutationType: EMode.upsert,
    responseData: { id: null } as unknown as T,
  });

  // TODO: is it even needed?
  const openPicker = useCallback(() => {}, []);
  const entityPreviewResult = useEntityPreview({
    user,
    subEntityType: entity,
    entries,
    openPicker,
    mutate,
    mutateAll,
  });
  const { isInaccessible } = useInaccessibleFields();

  const cards = entries[0]
    ? fields
        .filter((field) => !isInaccessible(entity, field.key))
        .map((field) => {
          return (
            <PickerCardInfo
              key={field.key}
              entity={entity}
              field={field}
              entry={entries[0]}
              entityPreviewResult={entityPreviewResult}
            />
          );
        })
    : null;

  return (
    <PreviewInputsProvider
      {...entityPreviewResult}
      numberOfSelectedRows={entries.length}
      entity={entity}
    >
      <InfoCards style={style}>{cards}</InfoCards>
    </PreviewInputsProvider>
  );
}

// TODO: hopefully this is only
interface GetPickerProps<T> {
  isCommon: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit: (field: any, otherArgs?: any) => Promise<void>;
  entry: T;
}

interface PickerCardInfo<T> {
  field: PreviewContentField<T>;
  entityPreviewResult: ReturnType<typeof useEntityPreview>;
  entry: T;
  entity: Entities;
}

function PickerCardInfo<T>(props: PickerCardInfo<T>) {
  const { field, entityPreviewResult, entry, entity } = props;
  const { handleLock, commonFields, isMultiselect, onPopoverClose, onEdit } =
    entityPreviewResult;
  const {
    getPicker,
    disabled = true,
    truncate = true,
    formatter,
    key,
    label,
    isCommon: isCommonFunc,
  } = field;

  const isCommon = isCommonFunc
    ? isCommonFunc(commonFields)
    : commonFields.includes(key);

  const { t } = useTranslation();
  const popoverRef = useRef();

  const cardInfoComponent = (
    <InfoCard
      key={key}
      disabled={disabled}
      truncate={truncate}
      label={typeof label === 'function' ? label(entry) : label}
      beforeContentElement={
        !isMultiselect && field.entity && field.getData ? (
          <BeforeContentElement
            previewEntity={entity}
            inputEntity={field.entity}
            accessor=""
            value={field.getData(entry)}
          />
        ) : undefined
      }
    >
      {isMultiselect && !isCommon
        ? `(${t('COMMON.MULTIPLE')})`
        : formatter
        ? formatter(entry[key], entry)
        : (entry[key] as string)}
    </InfoCard>
  );

  if (!getPicker) return cardInfoComponent;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      disabled={disabled}
      onTargetClick={() => {
        handleLock(key);
      }}
      onClose={onPopoverClose}
      // TODO: better centrilize place for pickers like we have get controller / table
      picker={getPicker({ isCommon, onEdit, entry })}
    >
      {cardInfoComponent}
    </EntityPickerPopover>
  );
}
