import { useEventCallback } from '@mui/material/utils';
import { throttle } from 'lodash';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData, FileType } from '@work4all/models/lib/File';

export function useSearchedFiles(
  ids: string[],
  fType: FileType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalFields?: any
) {
  if (!additionalFields) {
    additionalFields = {};
  }
  const requestData = useMemo<DataRequest>(() => {
    const filter = [
      {
        id: {
          $in: [...ids],
        },
      },
    ];

    return {
      entity: Entities[fType],
      data: { ...FILE_FIELDS, ...additionalFields },
      filter,
    };
  }, [fType, additionalFields, ids]);

  const resData = useDataProvider<FileData>(requestData, ids.length === 0);

  const refetchFilesData = useEventCallback(resData.refetch);
  const throttledFilesData = useMemo(
    () => throttle(refetchFilesData, 500),
    [refetchFilesData]
  );
  useEntityChanges({
    entity: Entities.salesOpportunities,
    onEntityChanged: throttledFilesData,
  });
  return { resData: { ...resData, data: ids.length > 0 ? resData.data : [] } };
}

const FILE_FIELDS: FileData = {
  id: null,
  name: null,
  number: null,
};
