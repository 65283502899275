import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { ErpDialog } from '../ErpDialog';
import { CloneConvertDialogProps, ErpObject } from '../types';

import { createDuplicateName } from './utils';

export const CopyErpDialog = (props: CloneConvertDialogProps) => {
  const { sourceEntity, data } = props;
  const { t } = useTranslation();

  const searchTerm = useMemo(
    () => data[0]?.note?.replace(/\(\d+\)$/, '').trimEnd(),
    [data]
  );
  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: sourceEntity,
      data: {
        id: null,
        note: null,
      },
      filter: [
        {
          note: { $eq: `%${searchTerm}%` },
        },
      ],
    };
  }, [sourceEntity, searchTerm]);

  const { data: result } = useDataProvider<ErpObject>(
    requestData,
    data.length !== 1
  );
  const copiedNote = data[0]?.note
    ? createDuplicateName(
        searchTerm,
        result.map((x) => x.note)
      )
    : undefined;

  if (!sourceEntity || !data) return null;

  return (
    <ErpDialog
      {...props}
      title={`${t(`COMMON.${sourceEntity.toUpperCase()}`)} ${t(
        'COMMON.DOUBLE'
      )}`}
      description={copiedNote}
    />
  );
};
