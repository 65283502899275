import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { MfaMode } from '../Enums/MfaMode.enum';
import { TypeOfWorkTimeTracking } from '../Enums/TypeOfWorkTimeTracking.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputFullUser<T extends EMode = EMode.entity> {
  /** Alias: abteilung */
  abteilung?: any;
  /** Alias: accountname */
  accountname?: any;
  /** Alias: adAnzeigeName */
  adAnzeigeName?: any;
  /** Alias: adGuid */
  adGuid?: any;
  /** Alias: alternativeAnrede */
  alternativeAnrede?: any;
  /** Alias: anredeCode */
  anredeCode?: any;
  /** Alias: anspruch */
  anspruch?: any;
  /** Alias: ausgeschieden */
  ausgeschieden?: any;
  /** Alias: austrittsdatum */
  austrittsdatum?: any;
  /** Alias: autoAnmeldung */
  autoAnmeldung?: any;
  /** Alias: autoAnmeldungBeiEinemProfil */
  autoAnmeldungBeiEinemProfil?: any;
  /** Alias: automatischesUpdate */
  automatischesUpdate?: any;
  /** Alias: bankverbindung */
  bankverbindung?: any;
  /** Alias: benutzerprofilChanged */
  benutzerprofilChanged?: any;
  /** Alias: benutzerprofilCode */
  benutzerprofilCode?: any;
  /** Alias: benutzerrechteChanged */
  benutzerrechteChanged?: any;
  /** Alias: beschart */
  beschart?: any;
  /** Alias: bIC */
  bIC?: any;
  /** Alias: bilddateiname */
  bilddateiname?: any;
  /** Alias: bisjetzt */
  bisjetzt?: any;
  /** Alias: bLZ */
  bLZ?: any;
  /** Alias: briefAnrede */
  briefAnrede?: any;
  /** Alias: bruttogehalt */
  bruttogehalt?: any;
  /** Alias: bString */
  bString?: any;
  /** Alias: changeable */
  changeable?: any;
  /** Alias: code */
  code?: any;
  /** Alias: dMSPasswort */
  dMSPasswort?: any;
  /** Alias: dMSUsername */
  dMSUsername?: any;
  /** Alias: easyMailPass */
  easyMailPass?: any;
  /** Alias: easyMailUser */
  easyMailUser?: any;
  /** Alias: eintrittsdatum */
  eintrittsdatum?: any;
  /** Alias: eMail */
  eMail?: any;
  /** Alias: eMailPrivat */
  eMailPrivat?: any;
  /** Alias: enabled */
  enabled?: any;
  /** Alias: exchangeConnector */
  exchangeConnector?: any;
  /** Alias: exchangeSettings */
  exchangeSettings?: any;
  /** Alias: feiertageAlsGeleisteteArbeitszeit */
  feiertageAlsGeleisteteArbeitszeit?: any;
  /** Alias: fingerabdruckBild */
  fingerabdruckBild?: any;
  /** Alias: fingerabdruckLaenge */
  fingerabdruckLaenge?: any;
  /** Alias: frei1 */
  frei1?: any;
  /** Alias: frei2 */
  frei2?: any;
  /** Alias: frei3 */
  frei3?: any;
  /** Alias: frei4 */
  frei4?: any;
  /** Alias: frei5 */
  frei5?: any;
  /** Alias: funktion */
  funktion?: any;
  /** Alias: funktion2 */
  funktion2?: any;
  /** Alias: geburtsdatum */
  geburtsdatum?: any;
  /** Alias: geburtsort */
  geburtsort?: any;
  /** Alias: generalPChanged */
  generalPChanged?: any;
  /** Alias: gesetzlichePausenregel */
  gesetzlichePausenregel?: any;
  /** Alias: grCode */
  grCode?: any;
  /** Alias: gUID */
  gUID?: any;
  /** Alias: hTMLMailVorlage */
  hTMLMailVorlage?: any;
  /** Alias: iA */
  iA?: any;
  /** Alias: iBAN */
  iBAN?: any;
  /** Alias: info */
  info?: any;
  /** Alias: infofensterprofil */
  infofensterprofil?: any;
  /** Alias: iV */
  iV?: any;
  /** Alias: keineRessource */
  keineRessource?: any;
  /** Alias: keinUpdate */
  keinUpdate?: any;
  /** Alias: kontonummer */
  kontonummer?: any;
  /** Alias: kostenstelle */
  kostenstelle?: any;
  /** Alias: krankenkasse */
  krankenkasse?: any;
  /** Alias: lieferantenCode */
  lieferantenCode?: any;
  /** Alias: listFlag */
  listFlag?: any;
  /** Alias: lohnsatz1 */
  lohnsatz1?: any;
  /** Alias: lohnsatz2 */
  lohnsatz2?: any;
  /** Alias: lohnsatz3 */
  lohnsatz3?: any;
  /** Alias: lookupCode */
  lookupCode?: any;
  /** Alias: mark */
  mark?: any;
  /** Alias: maschine */
  maschine?: any;
  /** Alias: master */
  master?: any;
  /** Alias: mobil */
  mobil?: any;
  /** Alias: mobilPrivat */
  mobilPrivat?: any;
  /** Alias: modificationDate */
  modificationDate?: any;
  /** Alias: modulRechte */
  modulRechte?: any;
  /** Alias: nachname */
  nachname?: any;
  /** Alias: name */
  name?: any;
  /** Alias: niederlassungsCode */
  niederlassungsCode?: any;
  /** Alias: notiz */
  notiz?: any;
  /** Alias: notizRTF */
  notizRTF?: any;
  /** Alias: nummer */
  nummer?: any;
  /** Alias: nurMitarbeiter */
  nurMitarbeiter?: any;
  /** Alias: nurRessource */
  nurRessource?: any;
  /** Alias: nurZeichen */
  nurZeichen?: any;
  /** Alias: nurZeiterfassung */
  nurZeiterfassung?: any;
  /** Alias: objGrCode */
  objGrCode?: any;
  /** Alias: ort */
  ort?: any;
  /** Alias: pass1 */
  pass1?: any;
  /** Alias: pass2 */
  pass2?: any;
  /** Alias: passwortDatum */
  passwortDatum?: any;
  /** Alias: passwortHash */
  passwortHash?: any;
  /** Alias: pausenCode */
  pausenCode?: any;
  /** Alias: persoenlicheKontakteAutomatischErmitteln */
  persoenlicheKontakteAutomatischErmitteln?: any;
  /** Alias: plz */
  plz?: any;
  /** Alias: pLZ2 */
  pLZ2?: any;
  /** Alias: pName */
  pName?: any;
  /** Alias: postfach */
  postfach?: any;
  /** Alias: praefix */
  praefix?: any;
  /** Alias: pRECHTE_0 */
  pRECHTE_0?: any;
  /** Alias: pRECHTE_1 */
  pRECHTE_1?: any;
  /** Alias: pRECHTE_10 */
  pRECHTE_10?: any;
  /** Alias: pRECHTE_2 */
  pRECHTE_2?: any;
  /** Alias: pRECHTE_3 */
  pRECHTE_3?: any;
  /** Alias: pRECHTE_4 */
  pRECHTE_4?: any;
  /** Alias: pRECHTE_5 */
  pRECHTE_5?: any;
  /** Alias: pRECHTE_6 */
  pRECHTE_6?: any;
  /** Alias: pRECHTE_7 */
  pRECHTE_7?: any;
  /** Alias: pRECHTE_8 */
  pRECHTE_8?: any;
  /** Alias: pRECHTE_9 */
  pRECHTE_9?: any;
  /** Alias: privatBriefPath */
  privatBriefPath?: any;
  /** Alias: profilNeu */
  profilNeu?: any;
  /** Alias: rAMaximized */
  rAMaximized?: any;
  /** Alias: reportRights */
  reportRights?: any;
  /** Alias: rundungAbUhrzeit */
  rundungAbUhrzeit?: any;
  /** Alias: senderName */
  senderName?: any;
  /** Alias: signatur */
  signatur?: any;
  /** Alias: signaturHTML */
  signaturHTML?: any;
  /** Alias: signaturHtml2 */
  signaturHtml2?: any;
  /** Alias: signaturRTF */
  signaturRTF?: any;
  /** Alias: slackId */
  slackId?: any;
  /** Alias: sMTPAddress */
  sMTPAddress?: any;
  /** Alias: sMTPPassword */
  sMTPPassword?: any;
  /** Alias: sMTPPort */
  sMTPPort?: any;
  /** Alias: sMTPReplyAddress */
  sMTPReplyAddress?: any;
  /** Alias: sMTPSavePassword */
  sMTPSavePassword?: any;
  /** Alias: sMTPUsername */
  sMTPUsername?: any;
  /** Alias: sMTPUseSSL */
  sMTPUseSSL?: any;
  /** Alias: sozialversicherungsnummer */
  sozialversicherungsnummer?: any;
  /** Alias: sprachCode */
  sprachCode?: any;
  /** Alias: staat */
  staat?: any;
  /** Alias: staatsangehoerigkeit */
  staatsangehoerigkeit?: any;
  /** Alias: standardGruppe */
  standardGruppe?: any;
  /** Alias: standardLohnsatz */
  standardLohnsatz?: any;
  /** Alias: startVerhalten */
  startVerhalten?: any;
  /** Alias: strasse */
  strasse?: any;
  /** Alias: sUSER_SNAME */
  sUSER_SNAME?: any;
  /** Alias: taktung */
  taktung?: any;
  /** Alias: teilzeitfaktor */
  teilzeitfaktor?: any;
  /** Alias: telefax */
  telefax?: any;
  /** Alias: telefaxPrivat */
  telefaxPrivat?: any;
  /** Alias: telefon */
  telefon?: any;
  /** Alias: telefonPrivat */
  telefonPrivat?: any;
  /** Alias: titel */
  titel?: any;
  /** Alias: tokenstring */
  tokenstring?: any;
  /** Alias: urlaubswert */
  urlaubswert?: any;
  /** Alias: userProfileCode */
  userProfileCode?: any;
  /** Alias: userrights */
  userrights?: any;
  /** Alias: instanzCode */
  instanzCode?: any;
  /** Alias: kommtGehtMinimalePause */
  kommtGehtMinimalePause?: any;
  /** Alias: passwortAenderungBenoetigt */
  passwortAenderungBenoetigt?: any;
  /** Alias: mfaMode */
  mfaMode?: MfaMode;
  /** Alias: mfaSecret */
  mfaSecret?: any;
  /** Alias: allowWork4allWeb */
  allowWork4allWeb?: any;
  /** Alias: userRights_Sicherung */
  userRights_Sicherung?: any;
  /** Alias: userRightsIndexAktiviert */
  userRightsIndexAktiviert?: any;
  /** Alias: userrightsIndividuell */
  userrightsIndividuell?: any;
  /** Alias: vorjahr */
  vorjahr?: any;
  /** Alias: vorname */
  vorname?: any;
  /** Alias: vorwahl */
  vorwahl?: any;
  /** Alias: watchHeartbeat */
  watchHeartbeat?: any;
  /** Alias: whattodosanzeigenBString */
  whattodosanzeigenBString?: any;
  /** Alias: windowsAnmeldename */
  windowsAnmeldename?: any;
  /** Alias: wWW */
  wWW?: any;
  /** Alias: zeichen */
  zeichen?: any;
  /** Alias: zeigenAlsKontakt */
  zeigenAlsKontakt?: any;
  /** Alias: zeitstempelErfassungsvariante */
  zeitstempelErfassungsvariante?: TypeOfWorkTimeTracking;
  /** Alias: zeitstempelInkludierteMinutenProTag */
  zeitstempelInkludierteMinutenProTag?: any;
  /** Alias: zeitstempelInkludierteMinutenProMonat */
  zeitstempelInkludierteMinutenProMonat?: any;
  /** Alias: zeitstempelMaxMinutenProJahr */
  zeitstempelMaxMinutenProJahr?: any;
  /** Alias: zeitstempelMaxMinutenProMonat */
  zeitstempelMaxMinutenProMonat?: any;
  /** Alias: zeitstempelMaxMinutenProTag */
  zeitstempelMaxMinutenProTag?: any;
  /** Alias: zeitstempelPausenMinuten */
  zeitstempelPausenMinuten?: any;
  /** Alias: zeitstempelRundungAbends */
  zeitstempelRundungAbends?: any;
  /** Alias: zeitstempelRundungAbUhrzeit */
  zeitstempelRundungAbUhrzeit?: any;
  /** Alias: zeitstempelRundungMorgens */
  zeitstempelRundungMorgens?: any;
  /** Alias: zeitstempelSollMinutenProTag */
  zeitstempelSollMinutenProTag?: any;
  /** Alias: vorgesetzterCode */
  vorgesetzterCode?: any;
  /** Alias: urlaubsgenehmigerCode */
  urlaubsgenehmigerCode?: any;
  /** Alias: kommtGehtProfilId */
  kommtGehtProfilId?: any;
  /** Alias: kommtGehtMinimaleStartZeit */
  kommtGehtMinimaleStartZeit?: any;
  /** Alias: kommtGehtInklWoche */
  kommtGehtInklWoche?: any;
  /** Alias: verguetungsart */
  verguetungsart?: any;
  /** Alias: projectAccessRightsLimited */
  projectAccessRightsLimited?: any;
  /** Alias: maximaleArbeitsstunden */
  maximaleArbeitsstunden?: any;
  /** Alias: maximaleArbeitsstundenMo */
  maximaleArbeitsstundenMo?: any;
  /** Alias: maximaleArbeitsstundenDi */
  maximaleArbeitsstundenDi?: any;
  /** Alias: maximaleArbeitsstundenMi */
  maximaleArbeitsstundenMi?: any;
  /** Alias: maximaleArbeitsstundenDo */
  maximaleArbeitsstundenDo?: any;
  /** Alias: maximaleArbeitsstundenFr */
  maximaleArbeitsstundenFr?: any;
  /** Alias: maximaleArbeitsstundenSa */
  maximaleArbeitsstundenSa?: any;
  /** Alias: maximaleArbeitsstundenSo */
  maximaleArbeitsstundenSo?: any;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputFullUser> = {
  abteilung: {
    alias: 'abteilung',
  },
  accountname: {
    alias: 'accountname',
  },
  adAnzeigeName: {
    alias: 'adAnzeigeName',
  },
  adGuid: {
    alias: 'adGuid',
  },
  alternativeAnrede: {
    alias: 'alternativeAnrede',
  },
  anredeCode: {
    alias: 'anredeCode',
  },
  anspruch: {
    alias: 'anspruch',
  },
  ausgeschieden: {
    alias: 'ausgeschieden',
  },
  austrittsdatum: {
    alias: 'austrittsdatum',
  },
  autoAnmeldung: {
    alias: 'autoAnmeldung',
  },
  autoAnmeldungBeiEinemProfil: {
    alias: 'autoAnmeldungBeiEinemProfil',
  },
  automatischesUpdate: {
    alias: 'automatischesUpdate',
  },
  bankverbindung: {
    alias: 'bankverbindung',
  },
  benutzerprofilChanged: {
    alias: 'benutzerprofilChanged',
  },
  benutzerprofilCode: {
    alias: 'benutzerprofilCode',
  },
  benutzerrechteChanged: {
    alias: 'benutzerrechteChanged',
  },
  beschart: {
    alias: 'beschart',
  },
  bIC: {
    alias: 'bIC',
  },
  bilddateiname: {
    alias: 'bilddateiname',
  },
  bisjetzt: {
    alias: 'bisjetzt',
  },
  bLZ: {
    alias: 'bLZ',
  },
  briefAnrede: {
    alias: 'briefAnrede',
  },
  bruttogehalt: {
    alias: 'bruttogehalt',
  },
  bString: {
    alias: 'bString',
  },
  changeable: {
    alias: 'changeable',
  },
  code: {
    alias: 'code',
  },
  dMSPasswort: {
    alias: 'dMSPasswort',
  },
  dMSUsername: {
    alias: 'dMSUsername',
  },
  easyMailPass: {
    alias: 'easyMailPass',
  },
  easyMailUser: {
    alias: 'easyMailUser',
  },
  eintrittsdatum: {
    alias: 'eintrittsdatum',
  },
  eMail: {
    alias: 'eMail',
  },
  eMailPrivat: {
    alias: 'eMailPrivat',
  },
  enabled: {
    alias: 'enabled',
  },
  exchangeConnector: {
    alias: 'exchangeConnector',
  },
  exchangeSettings: {
    alias: 'exchangeSettings',
  },
  feiertageAlsGeleisteteArbeitszeit: {
    alias: 'feiertageAlsGeleisteteArbeitszeit',
  },
  fingerabdruckBild: {
    alias: 'fingerabdruckBild',
  },
  fingerabdruckLaenge: {
    alias: 'fingerabdruckLaenge',
  },
  frei1: {
    alias: 'frei1',
  },
  frei2: {
    alias: 'frei2',
  },
  frei3: {
    alias: 'frei3',
  },
  frei4: {
    alias: 'frei4',
  },
  frei5: {
    alias: 'frei5',
  },
  funktion: {
    alias: 'funktion',
  },
  funktion2: {
    alias: 'funktion2',
  },
  geburtsdatum: {
    alias: 'geburtsdatum',
  },
  geburtsort: {
    alias: 'geburtsort',
  },
  generalPChanged: {
    alias: 'generalPChanged',
  },
  gesetzlichePausenregel: {
    alias: 'gesetzlichePausenregel',
  },
  grCode: {
    alias: 'grCode',
  },
  gUID: {
    alias: 'gUID',
  },
  hTMLMailVorlage: {
    alias: 'hTMLMailVorlage',
  },
  iA: {
    alias: 'iA',
  },
  iBAN: {
    alias: 'iBAN',
  },
  info: {
    alias: 'info',
  },
  infofensterprofil: {
    alias: 'infofensterprofil',
  },
  iV: {
    alias: 'iV',
  },
  keineRessource: {
    alias: 'keineRessource',
  },
  keinUpdate: {
    alias: 'keinUpdate',
  },
  kontonummer: {
    alias: 'kontonummer',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  krankenkasse: {
    alias: 'krankenkasse',
  },
  lieferantenCode: {
    alias: 'lieferantenCode',
  },
  listFlag: {
    alias: 'listFlag',
  },
  lohnsatz1: {
    alias: 'lohnsatz1',
  },
  lohnsatz2: {
    alias: 'lohnsatz2',
  },
  lohnsatz3: {
    alias: 'lohnsatz3',
  },
  lookupCode: {
    alias: 'lookupCode',
  },
  mark: {
    alias: 'mark',
  },
  maschine: {
    alias: 'maschine',
  },
  master: {
    alias: 'master',
  },
  mobil: {
    alias: 'mobil',
  },
  mobilPrivat: {
    alias: 'mobilPrivat',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  modulRechte: {
    alias: 'modulRechte',
  },
  nachname: {
    alias: 'nachname',
  },
  name: {
    alias: 'name',
  },
  niederlassungsCode: {
    alias: 'niederlassungsCode',
  },
  notiz: {
    alias: 'notiz',
  },
  notizRTF: {
    alias: 'notizRTF',
  },
  nummer: {
    alias: 'nummer',
  },
  nurMitarbeiter: {
    alias: 'nurMitarbeiter',
  },
  nurRessource: {
    alias: 'nurRessource',
  },
  nurZeichen: {
    alias: 'nurZeichen',
  },
  nurZeiterfassung: {
    alias: 'nurZeiterfassung',
  },
  objGrCode: {
    alias: 'objGrCode',
  },
  ort: {
    alias: 'ort',
  },
  pass1: {
    alias: 'pass1',
  },
  pass2: {
    alias: 'pass2',
  },
  passwortDatum: {
    alias: 'passwortDatum',
  },
  passwortHash: {
    alias: 'passwortHash',
  },
  pausenCode: {
    alias: 'pausenCode',
  },
  persoenlicheKontakteAutomatischErmitteln: {
    alias: 'persoenlicheKontakteAutomatischErmitteln',
  },
  plz: {
    alias: 'plz',
  },
  pLZ2: {
    alias: 'pLZ2',
  },
  pName: {
    alias: 'pName',
  },
  postfach: {
    alias: 'postfach',
  },
  praefix: {
    alias: 'praefix',
  },
  pRECHTE_0: {
    alias: 'pRECHTE_0',
  },
  pRECHTE_1: {
    alias: 'pRECHTE_1',
  },
  pRECHTE_10: {
    alias: 'pRECHTE_10',
  },
  pRECHTE_2: {
    alias: 'pRECHTE_2',
  },
  pRECHTE_3: {
    alias: 'pRECHTE_3',
  },
  pRECHTE_4: {
    alias: 'pRECHTE_4',
  },
  pRECHTE_5: {
    alias: 'pRECHTE_5',
  },
  pRECHTE_6: {
    alias: 'pRECHTE_6',
  },
  pRECHTE_7: {
    alias: 'pRECHTE_7',
  },
  pRECHTE_8: {
    alias: 'pRECHTE_8',
  },
  pRECHTE_9: {
    alias: 'pRECHTE_9',
  },
  privatBriefPath: {
    alias: 'privatBriefPath',
  },
  profilNeu: {
    alias: 'profilNeu',
  },
  rAMaximized: {
    alias: 'rAMaximized',
  },
  reportRights: {
    alias: 'reportRights',
  },
  rundungAbUhrzeit: {
    alias: 'rundungAbUhrzeit',
  },
  senderName: {
    alias: 'senderName',
  },
  signatur: {
    alias: 'signatur',
  },
  signaturHTML: {
    alias: 'signaturHTML',
  },
  signaturHtml2: {
    alias: 'signaturHtml2',
  },
  signaturRTF: {
    alias: 'signaturRTF',
  },
  slackId: {
    alias: 'slackId',
  },
  sMTPAddress: {
    alias: 'sMTPAddress',
  },
  sMTPPassword: {
    alias: 'sMTPPassword',
  },
  sMTPPort: {
    alias: 'sMTPPort',
  },
  sMTPReplyAddress: {
    alias: 'sMTPReplyAddress',
  },
  sMTPSavePassword: {
    alias: 'sMTPSavePassword',
  },
  sMTPUsername: {
    alias: 'sMTPUsername',
  },
  sMTPUseSSL: {
    alias: 'sMTPUseSSL',
  },
  sozialversicherungsnummer: {
    alias: 'sozialversicherungsnummer',
  },
  sprachCode: {
    alias: 'sprachCode',
  },
  staat: {
    alias: 'staat',
  },
  staatsangehoerigkeit: {
    alias: 'staatsangehoerigkeit',
  },
  standardGruppe: {
    alias: 'standardGruppe',
  },
  standardLohnsatz: {
    alias: 'standardLohnsatz',
  },
  startVerhalten: {
    alias: 'startVerhalten',
  },
  strasse: {
    alias: 'strasse',
  },
  sUSER_SNAME: {
    alias: 'sUSER_SNAME',
  },
  taktung: {
    alias: 'taktung',
  },
  teilzeitfaktor: {
    alias: 'teilzeitfaktor',
  },
  telefax: {
    alias: 'telefax',
  },
  telefaxPrivat: {
    alias: 'telefaxPrivat',
  },
  telefon: {
    alias: 'telefon',
  },
  telefonPrivat: {
    alias: 'telefonPrivat',
  },
  titel: {
    alias: 'titel',
  },
  tokenstring: {
    alias: 'tokenstring',
  },
  urlaubswert: {
    alias: 'urlaubswert',
  },
  userProfileCode: {
    alias: 'userProfileCode',
  },
  userrights: {
    alias: 'userrights',
  },
  instanzCode: {
    alias: 'instanzCode',
  },
  kommtGehtMinimalePause: {
    alias: 'kommtGehtMinimalePause',
  },
  passwortAenderungBenoetigt: {
    alias: 'passwortAenderungBenoetigt',
  },
  mfaMode: {
    alias: 'mfaMode',
  },
  mfaSecret: {
    alias: 'mfaSecret',
  },
  allowWork4allWeb: {
    alias: 'allowWork4allWeb',
  },
  userRights_Sicherung: {
    alias: 'userRights_Sicherung',
  },
  userRightsIndexAktiviert: {
    alias: 'userRightsIndexAktiviert',
  },
  userrightsIndividuell: {
    alias: 'userrightsIndividuell',
  },
  vorjahr: {
    alias: 'vorjahr',
  },
  vorname: {
    alias: 'vorname',
  },
  vorwahl: {
    alias: 'vorwahl',
  },
  watchHeartbeat: {
    alias: 'watchHeartbeat',
  },
  whattodosanzeigenBString: {
    alias: 'whattodosanzeigenBString',
  },
  windowsAnmeldename: {
    alias: 'windowsAnmeldename',
  },
  wWW: {
    alias: 'wWW',
  },
  zeichen: {
    alias: 'zeichen',
  },
  zeigenAlsKontakt: {
    alias: 'zeigenAlsKontakt',
  },
  zeitstempelErfassungsvariante: {
    alias: 'zeitstempelErfassungsvariante',
  },
  zeitstempelInkludierteMinutenProTag: {
    alias: 'zeitstempelInkludierteMinutenProTag',
  },
  zeitstempelInkludierteMinutenProMonat: {
    alias: 'zeitstempelInkludierteMinutenProMonat',
  },
  zeitstempelMaxMinutenProJahr: {
    alias: 'zeitstempelMaxMinutenProJahr',
  },
  zeitstempelMaxMinutenProMonat: {
    alias: 'zeitstempelMaxMinutenProMonat',
  },
  zeitstempelMaxMinutenProTag: {
    alias: 'zeitstempelMaxMinutenProTag',
  },
  zeitstempelPausenMinuten: {
    alias: 'zeitstempelPausenMinuten',
  },
  zeitstempelRundungAbends: {
    alias: 'zeitstempelRundungAbends',
  },
  zeitstempelRundungAbUhrzeit: {
    alias: 'zeitstempelRundungAbUhrzeit',
  },
  zeitstempelRundungMorgens: {
    alias: 'zeitstempelRundungMorgens',
  },
  zeitstempelSollMinutenProTag: {
    alias: 'zeitstempelSollMinutenProTag',
  },
  vorgesetzterCode: {
    alias: 'vorgesetzterCode',
  },
  urlaubsgenehmigerCode: {
    alias: 'urlaubsgenehmigerCode',
  },
  kommtGehtProfilId: {
    alias: 'kommtGehtProfilId',
  },
  kommtGehtMinimaleStartZeit: {
    alias: 'kommtGehtMinimaleStartZeit',
  },
  kommtGehtInklWoche: {
    alias: 'kommtGehtInklWoche',
  },
  verguetungsart: {
    alias: 'verguetungsart',
  },
  projectAccessRightsLimited: {
    alias: 'projectAccessRightsLimited',
  },
  maximaleArbeitsstunden: {
    alias: 'maximaleArbeitsstunden',
  },
  maximaleArbeitsstundenMo: {
    alias: 'maximaleArbeitsstundenMo',
  },
  maximaleArbeitsstundenDi: {
    alias: 'maximaleArbeitsstundenDi',
  },
  maximaleArbeitsstundenMi: {
    alias: 'maximaleArbeitsstundenMi',
  },
  maximaleArbeitsstundenDo: {
    alias: 'maximaleArbeitsstundenDo',
  },
  maximaleArbeitsstundenFr: {
    alias: 'maximaleArbeitsstundenFr',
  },
  maximaleArbeitsstundenSa: {
    alias: 'maximaleArbeitsstundenSa',
  },
  maximaleArbeitsstundenSo: {
    alias: 'maximaleArbeitsstundenSo',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputFullUserEntityDefinition: EntitiyDefinition<InputFullUser> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputFullUser',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
