import { useCallback } from 'react';

import { useDialogs } from '@work4all/components';

import { PasswordChangeDialog } from './PasswordChangeDialog';

export const usePasswordChangeDialog = () => {
  const dialogs = useDialogs();

  return useCallback(
    () =>
      new Promise<string>((resolve) => {
        const onClose = () => {
          resolve('');
          dialogs.close(id);
        };

        const onConfirm = (input: string) => {
          resolve(input);
          dialogs.close(id);
        };

        const { id } = dialogs.open(PasswordChangeDialog, {
          onConfirm,
          onClose,
        });
      }),
    [dialogs]
  );
};
