import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Path, PathValue, UnpackNestedValue } from 'react-hook-form';

import { useFormContextPlus } from '../../../../../../../../../form-plus/use-form-context-plus';
import { EditTableEntry } from '../types';

import {
  BaseStateContext,
  EditStateContext,
  useEditableState,
  UseEditableStateProps,
  UseEditableStateResult,
} from './use-editable-state';

interface UseEditableStateFormProps<T, TEntity> {
  mapInitialize: (input: T) => T;
  /**
   * Specify positions property.
   */
  property: Path<TEntity>;
}

export function useEditableStateForm<
  T extends EditTableEntry,
  TEntity,
  TContext extends BaseStateContext = EditStateContext
>(
  props: Omit<UseEditableStateProps<T, TContext>, 'positions'> &
    UseEditableStateFormProps<T, TEntity>
): UseEditableStateResult<T, TContext> {
  const { property, mapInitialize, ...editStateProps } = props;
  const form = useFormContextPlus<TEntity>();
  const propertyToUpdate = form.watch(property) as T[];

  // Initialize & sync with form
  const positions = useMemo(() => {
    return (propertyToUpdate || []).map(mapInitialize);
  }, [propertyToUpdate, mapInitialize]);

  const shouldUpdate = useRef<boolean>(false);

  const updateForm = useCallback(
    (positions: T[]) => {
      form.setValue(
        property,
        positions as UnpackNestedValue<PathValue<TEntity, Path<TEntity>>>,
        {
          shouldDirty: true,
        }
      );
    },
    [property, form]
  );

  const useHandler = (handler) => {
    return useCallback(
      (...args) => {
        shouldUpdate.current = true;
        return handler?.(...args);
      },
      [handler]
    );
  };

  const onAddPosition = useHandler(editStateProps.onAddPosition);
  const onRemovePosition = useHandler(editStateProps.onRemovePosition);
  const onEditPosition = useHandler(editStateProps.onEditPosition);
  const onMovePosition = useHandler(editStateProps.onMovePosition);

  const editState = useEditableState<T, TContext>({
    positions,
    ...editStateProps,
    onAddPosition,
    onRemovePosition,
    onEditPosition,
    onMovePosition,
  });

  // save updates
  useEffect(() => {
    if (shouldUpdate.current) {
      updateForm(editState.positions);
      shouldUpdate.current = false;
    }
  }, [editState.positions]);

  return editState;
}
