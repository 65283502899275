import styles from './../../MaskOverlay.module.scss';

import { memo, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { useFormPlus, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { InputArticleRelation } from '@work4all/models/lib/Classes/InputArticleRelation.entity';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddArticle,
  canDeleteArticle,
  canEditArticle,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { AccountingTabPanel } from './tabs/accounting/AccountingTabPanel';
import { ComponentsTabPanel } from './tabs/components/ComponentsTabPanel';
import { GeneralTabPanel } from './tabs/general/GeneralTabPanel';
import { MiscellaneousTabPanel } from './tabs/miscellaneous/MiscellaneousTabPanel';
import { PricesTabPanel } from './tabs/prices/PricesTabPanel';
import { ArticleMaskFormValue } from './types';
import { useArticleFormUpdate } from './use-article-form-update';
import { useArticleRequest } from './use-article-request';
import { useNewArticleData } from './use-new-article-data';
import { mapRelations } from './utils/map-relations';

export const ArticleOverlayController = (props: MaskControllerProps) => {
  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const request = useArticleRequest({ id: mask.id });
  const initialFormValue = useInitialFormValue<ArticleMaskFormValue>(
    request,
    mask.isCreateMode
  );

  const user = useUser();

  const presetFields = useMemo(() => {
    return mask.params?.presetFields
      ? JSON.parse(mask.params.presetFields)
      : undefined;
  }, [mask?.params?.presetFields]);

  const newEntityData = useNewArticleData({
    enabled: mask.isCreateMode,
    presetFields,
  });

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const defaultValues = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(dataRaw), customFields);
  }, [dataRaw, customFields]);

  const attachments = useMemo(() => {
    const documents = defaultValues.articleDocumentList ?? [];

    return documents.map((doc) => {
      const { name, ...rest } = doc;
      return { fileName: name, ...rest };
    });
  }, [defaultValues.articleDocumentList]);

  const tfm = useTempFileManager(attachments);

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema);

  const form = useFormPlus<ArticleMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: defaultValues,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  useEffect(() => form.reset(defaultValues), [form, defaultValues]);

  useLock(
    {
      subEntityType: mask.entity,
      subEntityIds: defaultValues.id ? [defaultValues.id.toString()] : [],
    },
    [defaultValues]
  );

  const isDirty = form.formState.isDirty || tfm.fileListDirty;

  useConfirmBeforeCloseMask(isDirty);

  const [mutate] = useDataMutation<
    ArticleMaskFormValue,
    EMode.upsert,
    InputArticleRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
  });

  useArticleFormUpdate(form, mask);

  const entityName = t(`COMMON.ARTICLE`);
  const maskTitle = formatPageTitle([entityName, initialFormValue.value?.name]);
  usePageTitle(maskTitle, { priority: 1 });

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(props);

  const handleSubmit = async (values: ArticleMaskFormValue) => {
    const updateRaw = mask.isCreateMode
      ? values
      : pickUpdateFields(values, form.formState.dirtyFields);

    const updateMapped = prepareInputWithCustomFields(updateRaw);
    const relations = mapRelations(defaultValues, values);

    await mutate(updateMapped, { relations });
  };

  const entityRights = useMemo(() => {
    return {
      create: canAddArticle(user),
      read: true,
      update: canEditArticle(user, defaultValues),
      delete: canDeleteArticle(user, defaultValues),
    };
  }, [defaultValues, user]);

  const maskContext = useMaskContextValue({
    ...mask,
    data: defaultValues,
    customFields,
  });

  const isAllowedToSave = mask.isEditMode
    ? entityRights.update
    : entityRights.create;

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  const submitButtonProps = {
    disabled: !isDirty || !isAllowedToSave,
    disableReason: !isAllowedToSave ? t('RIGHTS.MISSING') : null,
    loading: form.formState.isSubmitting,
  };

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <TempFileManagerContext.Provider value={tfm}>
          <FormProvider {...form}>
            <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
              <Form
                className={styles.maskForm}
                onSubmit={form.handleSubmit(handleSubmit)}
              >
                <MaskOverlayHeader
                  title={entityName}
                  subTitle={defaultValues.name}
                  actions={
                    <>
                      <MaskOverlaySubmitButton {...submitButtonProps} />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            disabled={!entityRights.delete || mask.wip}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                  tabs={
                    <ArticleMaskTabs
                      isCreateMode={mask.isCreateMode}
                      renderIndividualTab={shouldRenderIndividualTab}
                    />
                  }
                />

                <ArticleMaskContent
                  renderIndividualTab={shouldRenderIndividualTab}
                />
              </Form>
            </MaskTabContext>
          </FormProvider>
        </TempFileManagerContext.Provider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const ArticleMaskTabs = memo(function CrmTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();

  const showComponentsTab =
    form.watch('articleKind') === ArticleKind.STUECKLISTE;

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')} />
      <MaskTab value="misc" label={t('MASK.MISCELLANEOUS')} />

      {showComponentsTab && (
        <MaskTab value="components" label={t('COMMON.ERP.COMPONENTS')} />
      )}

      <MaskTab value="prices" label={t('COMMON.ERP.PRICING')} />
      <MaskTab value="accounting" label={t('COMMON.ERP.BOOKING')} />
      <MaskTab
        disabled
        value="attachments"
        label={t('COMMON.MASK.DOCUMENTS')}
      />

      {renderIndividualTab && (
        <MaskTab value="individual" label={t('MASK.INDIVIDUAL')} />
      )}
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      />
    </MaskTabs>
  );
});

const ArticleMaskContent = memo(function CrmTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="misc">
        <MiscellaneousTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="components">
        <ComponentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="prices">
        <PricesTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="accounting">
        <AccountingTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value="individual">
          <IndividualTabPanel />
        </MaskTabPanel>
      )}

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});
