import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';

import { EditTableEntry } from '../../../erp/components/tab-panels/positions/components/edit-table/types';

export const DEFAULT_VISIBLE_BOM_COLUMNS = [
  'name',
  'article.articleKind',
  'amount',
  'article.number',
  'article.entryPrice',
  'article.unit.name',
  'article.netPrice',
  'article.netPrice2',
  'article.netPrice3',
];

export type BOMComponentPosition = BOMComponent &
  EditTableEntry & {
    'article.articleKind'?: string;
    'article.number'?: number;
    'article.entryPrice'?: number;
    'article.unit.name'?: string;
    'article.netPrice'?: string;
    'article.netPrice2'?: string;
    'article.netPrice3'?: string;
    prurchasePrice?: number;
  };

export const DEFAULT_BOM_COLUMNS = {
  visibility: DEFAULT_VISIBLE_BOM_COLUMNS,
  order: [],
  width: {},
};
