import styles from './Table.module.scss';

import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import i18next from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BooleanPicker } from '@work4all/components/lib/components/entity-picker/boolean-picker/BooleanPicker';
import { ClosedStatusPicker } from '@work4all/components/lib/components/entity-picker/ClosedStatusPicker/ClosedStatusPicker';
import { IFilterPickerConfig } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';
import { DueDateClassifiedPicker } from '@work4all/components/lib/components/entity-picker/due-date-classified-picker/DueDateClassifiedPicker';
import { EInvoiceFormatPicker } from '@work4all/components/lib/components/entity-picker/e-invoice-format-picker/EInvoiceFormatPicker';
import { EmailKindPicker } from '@work4all/components/lib/components/entity-picker/email-kind-picker/EmailKindPicker';
import { InvoiceFormPicker } from '@work4all/components/lib/components/entity-picker/invoice-form-picker/InvoiceFormPicker';
import { InvoiceKindPicker } from '@work4all/components/lib/components/entity-picker/invoice-kind-picker/InvoiceKindPicker';
import { ObjectTypePicker } from '@work4all/components/lib/components/entity-picker/object-type-picker/ObjectTypePicker';
import { PaymentStatusPicker } from '@work4all/components/lib/components/entity-picker/payment-status-picker/PaymentStatusPicker';
import { PermitStatusPicker } from '@work4all/components/lib/components/entity-picker/permit-status-picker/PermitStatusPicker';
import { RaAccountingStatusPicker } from '@work4all/components/lib/components/entity-picker/ra-accounting-status-picker/RaAccountingStatusPicker';
import { ReAccountingStatusPicker } from '@work4all/components/lib/components/entity-picker/re-accounting-status-picker/ReAccountingStatusPicker';
import { SalesOpportunitiesGroupPicker } from '@work4all/components/lib/components/entity-picker/sales-opportunities-group-picker/SalesOpportunitiesGroupPicker';
import { SalesOpportunitiesStatusPicker } from '@work4all/components/lib/components/entity-picker/sales-opportunities-status-picker/SalesOpportunitiesStatusPicker';
import { TaskStatusPicker } from '@work4all/components/lib/components/entity-picker/task-status-picker/TaskStatusPicker';
import { TicketStatusPicker } from '@work4all/components/lib/components/entity-picker/ticket-status-picker/TicketStatusPicker';
import { TravelReceiptsStatusPicker } from '@work4all/components/lib/components/entity-picker/travel-receipts-status-picker/TravelReceiptsStatusPicker';
import { VacationKindPicker } from '@work4all/components/lib/components/entity-picker/vacation-kind-picker/VacationKindPicker';
import {
  ColumnInstance,
  FilterType,
} from '@work4all/components/lib/dataDisplay/basic-table';
import {
  DateRangePicker,
  getTimeSpanUnit,
} from '@work4all/components/lib/dataDisplay/basic-table/components/date-range-picker/DateRangePicker';
import { NumberRangePicker } from '@work4all/components/lib/dataDisplay/basic-table/components/number-range-picker/NumberRangePicker';
import { RequiredTimeRangePicker } from '@work4all/components/lib/dataDisplay/basic-table/components/required-time-range-picker/RequiredTimeRangePicker';
import { SearchInput } from '@work4all/components/lib/dataDisplay/basic-table/components/search-input/SearchInput';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { ListPageContext } from '../../../containers/file-entities-lists/list-page-context';
import { settings, useSetting } from '../../../settings';
import { PopoverFilterWrap } from '../../filters/popover-filter-wrap/PopoverFilterWrap';

import { ChronoContactPicker } from './components/chrono-contact-picker/ChronoContactPicker';
import { getConditionalPicker } from './getConditionalPicker';
import { StateFullPickerWrap } from './StateFullPickerWrap';

function renderPicker(filterType: FilterType, column) {
  let Picker = null;
  switch (filterType) {
    case FilterType.EmailKind:
      Picker = EmailKindPicker;
      break;
    case FilterType.TicketStatus:
      Picker = TicketStatusPicker;
      break;
    case FilterType.TaskStatus:
      Picker = TaskStatusPicker;
      break;
    case FilterType.EInvoiceFormat:
      Picker = EInvoiceFormatPicker;
      break;
    case FilterType.VacationKind:
      Picker = VacationKindPicker;
      break;
    case FilterType.ObjectType:
      Picker = ObjectTypePicker;
      break;
    case FilterType.ChronoContactPicker:
      Picker = ChronoContactPicker;
      break;
    case FilterType.SalesOpportunitiesGroupPicker:
      Picker = SalesOpportunitiesGroupPicker;
      break;
    case FilterType.SalesOpportunitiesStatusPicker:
      Picker = SalesOpportunitiesStatusPicker;
      break;
    case FilterType.TravelReceiptStatus:
      Picker = TravelReceiptsStatusPicker;
      break;
    case FilterType.PaymentStatus:
      Picker = PaymentStatusPicker;
      break;
    case FilterType.InvoiceForm:
      Picker = InvoiceFormPicker;
      break;
    case FilterType.ReAccountingStatus:
      Picker = ReAccountingStatusPicker;
      break;
    case FilterType.RaAccountingStatus:
      Picker = RaAccountingStatusPicker;
      break;
    case FilterType.PermitStatus:
      Picker = PermitStatusPicker;
      break;
    case FilterType.InvoiceKind:
      Picker = InvoiceKindPicker;
      break;
    case FilterType.DueDateClassified:
      Picker = DueDateClassifiedPicker;
      break;
    default:
      break;
  }
  return !Picker ? null : (
    <Picker
      multiple={true}
      value={column.filterValue?.value}
      onChange={(value) => {
        if (value === null || (Array.isArray(value) && value.length === 0)) {
          column.setFilter(null);
        } else {
          column.setFilter({
            value: value,
            filterType: column.filterType,
          });
        }
      }}
    />
  );
}
interface Props {
  column: ColumnInstance;
  children?: JSX.Element;
  listEntityType?: Entities;
}

export const TableFilter: React.FC<Props> = (props) => {
  const { column, children, listEntityType } = props;
  const { t } = useTranslation();

  const listPageContext = useContext(ListPageContext);
  const entityType = listEntityType ?? listPageContext?.entityType;

  const closedEntitiesSetting = useSetting(
    settings.hideClosedEntities({ entityType })
  );

  const setHideClosedEntitiesSetting = useEventCallback((value: boolean) => {
    closedEntitiesSetting.set(value);
  });

  let comp;

  switch (column.filterType) {
    case FilterType.Date:
      comp = (
        <DateRangePicker
          startDate={
            column.filterValue?.value?.startDate
              ? column.filterValue?.value.startDate
              : null
          }
          endDate={
            column.filterValue?.value?.endDate
              ? column.filterValue?.value.endDate
              : null
          }
          onChange={(startDate, endDate) => {
            if (startDate === null && endDate === null) {
              column.setFilter(null);
            }
            column.setFilter({
              value: {
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
                span: getTimeSpanUnit(startDate, endDate),
              },
              filterType: column.filterType,
            });
            return;
          }}
        />
      );
      break;

    case FilterType.Number:
      comp = (
        <NumberRangePicker
          from={
            column.filterValue?.value?.from
              ? column.filterValue?.value.from
              : null
          }
          to={
            column.filterValue?.value?.to ? column.filterValue?.value.to : null
          }
          onChange={(from, to) => {
            if (from === null && to === null) {
              column.setFilter(null);
            }
            column.setFilter({
              value: {
                from: from ? from : null,
                to: to ? to : null,
              },
              filterType: column.filterType,
            });
            return;
          }}
        />
      );
      break;

    case FilterType.ClosedStatus:
      comp = (
        <ClosedStatusPicker
          trueText={t(`${entityType?.toUpperCase()}.CLOSED_STATUS.HIDE_CLOSED`)}
          falseText={t(`${entityType?.toUpperCase()}.CLOSED_STATUS.SHOW_ALL`)}
          value={column.filterValue?.value ?? false}
          onChange={(value) => {
            column.setFilter({
              value,
              filterType: column.filterType,
            });

            setHideClosedEntitiesSetting(value);
          }}
        />
      );
      break;
    case FilterType.BooleanNumber:
    case FilterType.Boolean:
      comp = (
        <BooleanPicker
          multiple={true}
          value={column.filterValue?.value}
          onChange={(value) => {
            if (
              value === null ||
              (Array.isArray(value) && value.length === 0)
            ) {
              column.setFilter(null);
            } else {
              column.setFilter({
                value: value,
                filterType: column.filterType,
              });
            }
          }}
        />
      );
      break;

    case FilterType.Search:
      comp = (
        <SearchInput
          value={column.filterValue?.value ?? ''}
          onChange={(value) => {
            const normalizedValue = value.trim().length > 0 ? value : null;

            // If the actual search string didn't change, don't update the
            // filter to avoid unnesessary re-renders.
            if (
              (column.filterValue == null && normalizedValue === null) ||
              column.filterValue?.value === normalizedValue
            ) {
              return;
            }

            column.setFilter(
              normalizedValue !== null
                ? {
                    value,
                    filterType: column.filterType,
                  }
                : null
            );
          }}
        />
      );
      break;
    case FilterType.Picker: {
      const Picker = getConditionalPicker(
        column.filterParams?.entity,
        column?.cellParams?.fieldType
      );

      if (!Picker) {
        comp = null;
        break;
      }

      const isCustomField =
        column?.filterParams?.entity === Entities.customField;
      const filterPickerConfig: IFilterPickerConfig | null =
        (!listEntityType && listPageContext === null) || isCustomField
          ? null
          : {
              parentEntityType: listEntityType ?? listPageContext.entityType,
              field: column.id,
              filter: null,
            };

      comp =
        Picker === null ? null : (
          <StateFullPickerWrap
            stateLessCfg={filterPickerConfig}
            render={(filterPickerConfig: IFilterPickerConfig) => {
              return (
                <Picker
                  {...(column.filterParams?.entity === Entities.lookUp
                    ? { lookupType: column.filterParams.lookupType }
                    : undefined)}
                  {...(isCustomField &&
                  column.cellParams.fieldType ===
                    IndividualFieldControlType.COMBO
                    ? {
                        fieldOptions: column.cellParams.fieldOptions,
                      }
                    : undefined)}
                  layout="advanced"
                  userClasses={[
                    UserClass.BENUTZER,
                    UserClass.MITARBEITER,
                    UserClass.RESSOURCE,
                  ]}
                  multiple={column?.filterParams?.multiple ?? true}
                  value={column.filterValue?.value}
                  filterConfig={getFilterConfig(filterPickerConfig)}
                  favorites={false}
                  onChange={(value) => {
                    if (
                      value === null ||
                      (Array.isArray(value) && value.length === 0)
                    ) {
                      column.setFilter(null);
                    } else {
                      column.setFilter({
                        value: value,
                        filterType: column.filterType,
                      });
                    }
                  }}
                />
              );
            }}
          ></StateFullPickerWrap>
        );
      break;
    }
    case FilterType.Check:
      comp = (
        <div style={{ padding: '0.5rem' }}>
          <CheckboxRadioItem
            label={i18next.t('FILTERS.CHECKED_ITEMS_ONLY')}
            checked={column.filterValue?.value === 'true'}
            onChange={(e) => {
              if (e.target.checked) {
                column.setFilter({
                  value: 'true',
                  filterType: column.filterType,
                });
              } else {
                column.setFilter(null);
              }
            }}
          />
        </div>
      );
      break;
    case FilterType.RequiredTime: {
      comp = (
        <RequiredTimeRangePicker
          from={column.filterValue?.value?.from ?? null}
          to={column.filterValue?.value?.to ?? null}
          onChange={(from, to) => {
            if (from === null && to === null) {
              column.setFilter(null);
            } else {
              column.setFilter({
                value: { from: from, to: to },
                filterType: column.filterType,
              });
            }
          }}
          convertTo={column.cellParams?.convertTo}
        />
      );
      break;
    }
    case FilterType.TravelReceiptStatus:
    case FilterType.EmailKind:
    case FilterType.VacationKind:
    case FilterType.EInvoiceFormat:
    case FilterType.TicketStatus:
    case FilterType.ObjectType:
    case FilterType.ChronoContactPicker:
    case FilterType.SalesOpportunitiesGroupPicker:
    case FilterType.SalesOpportunitiesStatusPicker:
    case FilterType.PaymentStatus:
    case FilterType.InvoiceForm:
    case FilterType.ReAccountingStatus:
    case FilterType.RaAccountingStatus:
    case FilterType.PermitStatus:
    case FilterType.InvoiceKind:
    case FilterType.DueDateClassified:
    case FilterType.TaskStatus: {
      comp = renderPicker(column.filterType, column);
      break;
    }
  }

  if (!comp && children) return children;

  return (
    comp && (
      <PopoverFilterWrap
        disabled={column.filterValue?.readOnly}
        filterButtonClass={clsx(styles.filterButtonClass, {
          [styles.activeFilter]: column.filterValue,
          [styles.readOnly]: column.filterValue?.readOnly,
        })}
        title={column.Header as string}
        customComponent={children}
      >
        <div className={styles['filter-wrapper']}>{comp}</div>
      </PopoverFilterWrap>
    )
  );
};

export function getFilterConfig(filterPickerConfig: IFilterPickerConfig) {
  if (!filterPickerConfig) return filterPickerConfig;
  // This list contains longer nested filters implemented by API
  const exeptionList = [
    'businessPartner.data.id',
    'businessPartnerContactCombined.contact.id',
    'businessPartnerContactCombined.businessPartner.data.id',
    'additionalAddress1.businessPartner.data.id',
    'additionalAddress1.contact.id',
    'additionalAddress2.businessPartner.data.id',
    'additionalAddress2.contact.id',
    'additionalAddress3.businessPartner.data.id',
    'additionalAddress3.contact.id',
  ];
  const result =
    filterPickerConfig.field.split('.').length <= 2 ||
    exeptionList.includes(filterPickerConfig.field)
      ? filterPickerConfig
      : undefined;

  if (!result)
    console.warn(
      `There is missing implementation for filter ${filterPickerConfig.field}`
    );
  return result;
}
