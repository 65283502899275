import styles from './TimeCardPreview.module.scss';

import { Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BanderoleInfo } from '@work4all/components/lib/components/banderole/BanderoleInfo';

import { useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { WorkingTimeTimeCardDayInfo } from '@work4all/models/lib/Classes/WorkingTimeTimeCardDayInfo.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TableRightArea } from '../../../../components/data-tables/table/components/table-right-area/TableRightArea';

import { DetailsHeading } from './DetailsHeading';
import {
  TimeCardDetailsProvider,
  useTimeCardDetails,
} from './hooks/useTimeCardDetails';
import { TimeCardPreviewBlock } from './TimeCardPreviewBlock';
import TimeCardPreviewButtons from './TimeCardPreviewButtons';
import { calculateActionDependsOnBlock } from './utils';

export interface ITimeCardPreviewProps {
  value?: WorkingTimeTimeCardDayInfo;
  user: User;
  close: () => void;
  refetch: () => Promise<void>;
  lastInterimBalanceDate: string;
}

export interface ITimeCardPreviewContentProps {}

const TimeCardPreviewContent: React.FC<ITimeCardPreviewContentProps> = () => {
  const { disabled, items, disabledReason } = useTimeCardDetails();
  const renderComponents = () => {
    if (!items.length) return <></>;
    return items.map((block, idx) => {
      const separator = idx < items.length - 1;
      return (
        <TimeCardPreviewBlock
          key={`${block.start}_block`}
          block={block}
          separator={separator}
        />
      );
    });
  };

  const lastBlock = items ? items[items.length - 1] : null;
  const actions = calculateActionDependsOnBlock(lastBlock);

  return (
    <div className={styles['time-preview__content']}>
      {renderComponents()}
      <TimeCardPreviewButtons actions={actions} />

      <Tooltip
        title={disabledReason}
        placement="bottom-start"
        open={disabled && Boolean(disabledReason)}
        classes={{
          tooltipPlacementBottom: styles['time-preview__tooltip'],
        }}
      >
        <div className={styles['time-preview__tooltip-constraint']} />
      </Tooltip>
    </div>
  );
};

export const TimeCardPreview: React.FC<ITimeCardPreviewProps> = (
  props: ITimeCardPreviewProps
) => {
  const { t } = useTranslation();
  const { value, user, close, lastInterimBalanceDate } = props;

  const currentUser = useUser();

  const canSetPause = checkUserRight(
    UserRights.PausenStempeln,
    currentUser.benutzerRechte
  );

  const canEditOwnTime = checkUserRight(
    UserRights.KommtGehtZeitenAendernEigene,
    currentUser.benutzerRechte
  );

  const canEditAll = checkUserRight(
    UserRights.KommtGehtZeitenAendernFremde,
    currentUser.benutzerRechte
  );

  const isCurrentUser = user.id === currentUser.benutzerCode;
  const canEdit = isCurrentUser ? canEditOwnTime : canEditAll;

  const content = useMemo(() => <TimeCardPreviewContent />, []);

  const archived = useMemo(() => {
    return (
      DateTime.fromISO(value.date) <= DateTime.fromISO(lastInterimBalanceDate)
    );
  }, [value.date, lastInterimBalanceDate]);

  return (
    <TimeCardDetailsProvider
      date={value.date}
      items={value.presenceList ?? []}
      disabled={!canEdit || archived}
      disabledReason={
        archived
          ? undefined
          : isCurrentUser
          ? t('STATISTIC.TIMECARD.NO_EDIT_RIGHTS_OWN_TIME')
          : t('STATISTIC.TIMECARD.NO_EDIT_RIGHTS_OTHER_TIME')
      }
      disabledPause={!canSetPause || !canEdit || archived}
      userId={user.id}
      isCurrentUser={isCurrentUser}
      refetch={props.refetch}
    >
      <div className={styles['time-preview']}>
        <TableRightArea
          entityType={Entities.workingTimeTimeCardDayInfo}
          className={styles['time-preview__resizable_area']}
          rightArea={{
            content: (
              <div className={styles['time-preview__root']}>
                <BanderoleInfo
                  visible={archived}
                  text={t('STATISTIC.TIMECARD.ARCHIVED')}
                />
                <DetailsHeading
                  close={close}
                  title={t('STATISTIC.TIMECARD.WORKINGHOURS')}
                  subTitle={DateTime.fromISO(value.date).toLocaleString(
                    DateTime.DATE_SHORT
                  )}
                />
                {content}
              </div>
            ),
          }}
        />
      </div>
    </TimeCardDetailsProvider>
  );
};
