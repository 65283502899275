import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const RequestedEmailData: EMail<EMode.query> = {
  id: null,
  subject: null,
  user: {
    id: null,
    displayName: null,
  },
  date: null,
  to: null,
  kind: null,
  from: null,
  cc: null,
  body: null,
  bodyHtml: null,
  rewrittenBodyHtml: null,
  projectId: null,
  businessPartnerId: null,
  businessPartnerType: null,
  contactId: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayFilename: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
    },
  ],
  saveSendMailJob: {
    id: null,
    errorMessages: null,
  },
};
