import styles from './LabeledDateInput.module.scss';

import { ButtonBase } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from '@work4all/assets/icons/outline-calendar.svg';

import { dateTimeFromString } from '@work4all/utils/lib/date-utils/dateTimeFromString';

import { useMobiscrollLanguage } from '../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { DatePicker } from '../date-picker';
import { validateDateString } from '../date-time-input-picker';
import { withDropdown } from '../labeled-input';
import { ILabeledInput } from '../labeled-input/LabeledInput';
import { SectionLabeledInput } from '../section-labeled-input/SectionLabeledInput';
import {
  withDateTimeMask,
  WithDateTimeMaskProps,
} from '../section-labeled-input/withDateTimeMask';

import { dateModifier } from './date-modifier';

const DateMaskLabeledInput =
  withDateTimeMask<WithDateTimeMaskProps>(SectionLabeledInput);

interface IFilterDateProps
  extends Omit<ILabeledInput, 'iconRight' | 'classes' | 'value'> {
  onDateSelect?: (date: DateTime) => void;
  value?: string;
  onIconClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const LabeledDateInput = React.forwardRef<
  HTMLInputElement,
  IFilterDateProps
>((props, ref) => {
  // exclude onDateSelect  from "rest"
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { onIconClick, value, onDateSelect: _onDateSelect, ...rest } = props;

  const { t } = useTranslation();

  const onClick = onIconClick
    ? onIconClick
    : (props.onClick as unknown as React.MouseEventHandler<HTMLButtonElement>);

  return (
    <DateMaskLabeledInput
      ref={ref}
      value={value}
      classes={{ input: styles.input }}
      iconRight={
        <ButtonBase disabled={props.disabled} onClick={onClick}>
          <CalendarIcon />
        </ButtonBase>
      }
      {...rest}
      mask={t('DATE.DATE_SIMPLE_4YEAR_MASK')}
      splitter={t('DATE.DATE_SEPARATOR')}
      modify={dateModifier}
      isValid={(input: string) => {
        return !input.includes('_') && !!validateDateString(input);
      }}
    />
  );
});

export const LabeledDateInputWithDropdown = withDropdown(
  LabeledDateInput,
  (props) => {
    if (props.disabled) {
      return null;
    }
    const language = useMobiscrollLanguage();

    return (
      <DatePicker
        locale={language}
        controls={['calendar']}
        defaultValue={props.value ? dateTimeFromString(props.value) : undefined}
        onChange={(e) => {
          props.onDateSelect(DateTime.fromJSDate(e.value as Date));
          props.onHideDropdown();
        }}
      />
    );
  }
);
