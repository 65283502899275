import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FileDocIcon } from '@work4all/assets/icons/file-types/doc.svg';
import { ReactComponent as FileJpgIcon } from '@work4all/assets/icons/file-types/jpg.svg';
import { ReactComponent as FilePdfIcon } from '@work4all/assets/icons/file-types/pdf.svg';

import { IAttachmentEntity } from '@work4all/models/lib/GraphQLEntities';

import { components } from '../../../apiModels';
import { useAuthHeaders } from '../../auth/use-auth-headers';
import { HttpClientResponse } from '../../http-client';
import { HttpClient } from '../../http-client/classes/HttpClient';
import { useHttpClient } from '../../http-client/hooks';
import { UploadFileParams } from '../../interfaces';
import { downloadAuthed } from '../../utils/authedFileDownload';

import { useUser } from '..';

export async function uploadTempFile(
  file: File,
  httpClient: HttpClient,
  baseUrl: string
): Promise<HttpClientResponse<components['schemas']['UploadResult']>> {
  const formData = new FormData();
  formData.append('myFile', file, truncateFilename({ value: file.name }));
  try {
    const data = await httpClient.post<
      components['schemas']['UploadResult'],
      FormData,
      UploadFileParams
    >({
      url: `${baseUrl}/api/file`,
      body: formData,
      params: {
        type: 'TempDatei',
      },
    });

    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
}

export enum FileType {
  TicketAnhang = 'TicketAnhang',
  EditorDatei = 'EditorDatei',
  ArtikelDokument = 'ArtikelDokument',
  ArtikelStandardBild = 'ArtikelStandardBild',
  Dokument = 'Dokument',
  Brief = 'Brief',
  TempDatei = 'TempDatei',
  WikiInlineImage = 'WikiInlineImage',
  ErpAnhang = 'ErpAnhang',
  LieferscheinSignature = 'LieferscheinSignature',
  ArchivPdf = 'ArchivPdf',
  EMailAnhang = 'EMailAnhang',
  CrmAnhangTelefonat = 'CrmAnhangTelefonat',
  CrmAnhangNotiz = 'CrmAnhangNotiz',
  CrmAnhangTermin = 'CrmAnhangTermin',
  CrmAnhangAufgabe = 'CrmAnhangAufgabe',
  Besuchsbericht = 'Besuchsbericht',
  ConvertedFile = 'ConvertedFile',
  EmbededAttachement = 'EmbededAttachement',
  WordLetterTemplate = 'WordLetterTemplate',
  WordDocumentTemplate = 'WordDocumentTemplate',
  StammdatenAttachementFileEntity = 'StammdatenAttachementFileEntity',
  StammdatenAttachementPictureEntity = 'StammdatenAttachementPictureEntity',
  Datei = 'Datei',
  EMailVorlagenAnhang = 'EMailVorlagenAnhang',
  Thumbnail = 'Thumbnail',
  Report = 'Report',
  SalesOpportunityAttachment = 'SalesOpportunityAttachment',
  CommentInlineImage = 'CommentInlineImage',
}

export async function updateFile(
  httpClient: HttpClient,
  baseUrl: string,
  fileType: FileType,
  sourceTempFileId: string,
  code?: number
): Promise<HttpClientResponse<components['schemas']['UploadResult']>> {
  try {
    const data = await httpClient.put({
      url: `${baseUrl}/api/file/updatefile`,
      params: {
        fileType,
        sourceTempFileId,
        code,
      },
    });

    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
}

export const TempFileManagerContext =
  React.createContext<ITempFileManagerContext | null>(null);

export interface ITempFileManagerContext {
  fileList: IAttachmentEntity[];
  fileListDirty: boolean;
  resetChanges: () => void;
  uploadFiles: (uploadItemList: File[], compress?: boolean) => void;
  downloadFiles: (ids: string[]) => void;
  markFilesToDelete: (ids: string[]) => void;
  markFilesToUpdate: (item: IAttachmentEntity) => void;
  fileIdsToDelete: Array<string | number>;
  filesToUpdate: IAttachmentEntity[];
  temporaryFileUploads: IAttachmentEntity[];
  currentUploadingFiles: number;
  setTemporaryFileUploads: Dispatch<SetStateAction<IAttachmentEntity[]>>;
}

export type MimeTypes =
  | 'image/jpeg'
  | 'image/png'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const fileIconByMimeType: Record<
  MimeTypes,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  'image/jpeg': FileJpgIcon,
  'image/png': FileJpgIcon, // FIXME: need png icon
  'application/pdf': FilePdfIcon,
  'application/msword': FileDocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileDocIcon,
};

interface TempFileOpts {
  maxAttachmentTotalSize?: number;
}

export function truncateFilename(props: {
  value: string;
  maxLength?: number;
}): string {
  //temp file names can't exceed 100 characters
  const { value, maxLength = 100 } = props;
  if (value.length <= maxLength) {
    return value;
  }

  const dotIdx = value.lastIndexOf('.');
  const extension = value.substring(dotIdx, value.length);
  const shortenedName = value.substring(0, maxLength - extension.length);

  return shortenedName + extension;
}

export function useTempFileManager(
  knownPersitedData: IAttachmentEntity[],
  opts: TempFileOpts = {}
): ITempFileManagerContext {
  const { maxAttachmentTotalSize } = opts;

  const user = useUser();
  const { t } = useTranslation();

  const [alreadyPersistantFiles, setAlreadyPersistantFiles] = useState<
    IAttachmentEntity[]
  >([]);
  const [temporaryFileUploads, setTemporaryFileUploads] = useState<
    IAttachmentEntity[]
  >([]);
  const [filesToUpdate, setFilesToUpdate] = useState<IAttachmentEntity[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<(string | number)[]>(
    []
  );
  const [fileListDirty, setFileListDirty] = useState(false);
  const [currentUploadingFiles, setCurrentUploadingFiles] = useState(0);

  const snackbar = useSnackbar();
  const uploadQueueSize = useRef<number>(0);

  useEffect(() => {
    setAlreadyPersistantFiles([...(knownPersitedData || [])]);
  }, [setAlreadyPersistantFiles, knownPersitedData]);

  const findFile = (list: IAttachmentEntity[], fileId: number | string) => {
    if (isNaN(Number(fileId))) {
      return list.findIndex((x) => x.id === fileId);
    } else {
      return list.findIndex((x) => x.id === Number(fileId));
    }
  };

  const httpHeaders = useAuthHeaders();

  const fitsMaxAttachmentTotalSize = useCallback(
    (size: number) => {
      uploadQueueSize.current += size;

      const persitant = alreadyPersistantFiles.map((x) => x.fileInfos.fileSize);

      const deleted = alreadyPersistantFiles
        .filter((x) => fileIdsToDelete.includes(x.id.toString()))
        .map((x) => x.fileInfos.fileSize);

      const temp = temporaryFileUploads.map((x) => x.fileInfos.fileSize);

      let totalSize: number = persitant.length
        ? persitant.reduce((a, b) => a + b)
        : 0;
      totalSize -= deleted.length ? deleted.reduce((a, b) => a + b) : 0;
      totalSize += temp.length ? temp.reduce((a, b) => a + b) : 0;
      totalSize += uploadQueueSize.current;
      return totalSize < maxAttachmentTotalSize;
    },
    [
      alreadyPersistantFiles,
      fileIdsToDelete,
      maxAttachmentTotalSize,
      temporaryFileUploads,
    ]
  );

  const httpClient = useHttpClient();

  const uploadFiles = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (
      uploadItemListNotCompressed: File[],
      compress?: boolean
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any[]> => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const uploadPromises: Promise<any>[] = [];
      uploadQueueSize.current = 0;

      let uploadItemList = uploadItemListNotCompressed;
      if (compress)
        uploadItemList = await Promise.all(
          uploadItemListNotCompressed.map((file) => compressImageFile(file))
        );

      uploadItemList.forEach((uploadItem) => {
        if (!maxAttachmentTotalSize) {
          uploadPromises.push(
            uploadTempFile(uploadItem, httpClient, user.baseUrl)
          );
        } else {
          if (fitsMaxAttachmentTotalSize(uploadItem.size)) {
            uploadPromises.push(
              uploadTempFile(uploadItem, httpClient, user.baseUrl)
            );
          } else {
            snackbar.enqueueSnackbar(t('MASK.ATTACHMENT_MAX_SIZE'), {
              variant: 'error',
            });
          }
        }
      });

      if (uploadPromises.length === 0) {
        return;
      }

      setCurrentUploadingFiles((current) => {
        return current + uploadPromises.length;
      });
      const results = await Promise.all(uploadPromises);
      setCurrentUploadingFiles((current) => {
        return current - uploadPromises.length;
      });

      setTemporaryFileUploads((temporaryFileUploads) => [
        ...temporaryFileUploads,
        ...results.map((data, idx) => {
          const file = uploadItemList[idx];
          const truncatedFilename = truncateFilename({ value: file.name });
          return {
            fileName: truncatedFilename,
            fileInfos: {
              downloadUrl: data?.data?.downloadUrl,
              downloadMimeType: file.type,
              previewMimeType: file.type,
              previewUrl: data?.data?.downloadUrlForPreview,
              fileSize: file.size,
              fileEntityFilename: truncatedFilename,
            },
            type: file.type,
            id: data.data.generatedObject,
            date: DateTime.now().toISO(),
          };
        }),
      ]);
      setFileListDirty(true);

      return;
    },
    [
      user.baseUrl,
      maxAttachmentTotalSize,
      fitsMaxAttachmentTotalSize,
      snackbar,
      t,
      httpClient,
    ]
  );

  const markFilesToUpdate = useCallback(
    (item: IAttachmentEntity) => {
      const temporaryFileIndex = temporaryFileUploads.findIndex(
        (x) => x.id === item.id
      );
      if (temporaryFileIndex !== -1) {
        const newTemporaryAttachmentList = [...temporaryFileUploads];
        newTemporaryAttachmentList[temporaryFileIndex] = item;
        setTemporaryFileUploads(newTemporaryAttachmentList);
        return;
      }

      const attachmentToUpdateIndex = filesToUpdate.findIndex(
        (x) => x.id === item.id
      );
      if (attachmentToUpdateIndex !== -1) {
        const newAttachmentsToUpdateList = [...filesToUpdate];
        newAttachmentsToUpdateList[attachmentToUpdateIndex] = item;
        setFilesToUpdate(newAttachmentsToUpdateList);
        return;
      } else {
        setFilesToUpdate([...filesToUpdate, item]);
      }

      setFileListDirty(true);
    },
    [temporaryFileUploads, filesToUpdate]
  );

  const markFilesToDelete = useCallback(
    (ids: string[]) => {
      //the ids that are not in our tempfiels are serverside known files and need to be deleted over there
      const serverIds: (number | string)[] = ids
        .filter((id) => !temporaryFileUploads.find((el) => id === el.id))
        .map((id) => (isNaN(Number(id)) ? id : Number(id)));

      //clean temp attachment list - as we havent uploaded it yet we need to delete it only locally
      const newTemporaryAttachmentList = [...temporaryFileUploads].filter(
        (x) => ids.indexOf(x.id + '') === -1
      );
      setTemporaryFileUploads(newTemporaryAttachmentList);

      setFileIdsToDelete([...fileIdsToDelete, ...serverIds]);
      setFileListDirty(true);
    },
    [fileIdsToDelete, temporaryFileUploads]
  );

  const fileList: IAttachmentEntity[] = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const files: any[] = [...alreadyPersistantFiles];

    filesToUpdate.forEach((updatedItem) => {
      const idx = files.findIndex((x) => x.id === updatedItem.id);
      if (idx !== -1) {
        files[idx] = updatedItem;
      }
    });

    return [
      ...files.filter((x) => fileIdsToDelete.indexOf(x.id) === -1),
      ...temporaryFileUploads,
    ];
  }, [
    fileIdsToDelete,
    filesToUpdate,
    alreadyPersistantFiles,
    temporaryFileUploads,
  ]);

  const downloadFiles = useCallback(
    async (ids: string[]) => {
      for (const id of ids) {
        const index = findFile(fileList, id);
        const item = fileList[index];
        if (item)
          await downloadAuthed(
            `${item.fileInfos.downloadUrl}`,
            item.fileName,
            httpHeaders
          );
      }
    },
    [fileList, httpHeaders]
  );
  const resetChanges = useCallback(() => {
    setFileIdsToDelete([]);
    setFilesToUpdate([]);
    setTemporaryFileUploads([]);
    setFileListDirty(false);
  }, []);

  return useMemo(() => {
    return {
      fileList,
      fileListDirty,
      resetChanges,
      uploadFiles,
      downloadFiles,
      markFilesToDelete,
      markFilesToUpdate,
      fileIdsToDelete,
      filesToUpdate,
      temporaryFileUploads,
      currentUploadingFiles,
      setTemporaryFileUploads,
    };
  }, [
    currentUploadingFiles,
    downloadFiles,
    fileIdsToDelete,
    fileList,
    fileListDirty,
    filesToUpdate,
    markFilesToDelete,
    markFilesToUpdate,
    resetChanges,
    temporaryFileUploads,
    uploadFiles,
  ]);
}

const compressImageFile = async (element: File, quality: number = 0.5) => {
  if (!element.type.startsWith('image')) return element;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const file: any = await compressImage(element, quality, 'image/jpeg');
  file.name = element.name;
  return file as File;
};

const compressImage = async (
  file: File,
  quality: number = 1,
  type: string = file.type
) => {
  // Get as image data
  const imageBitmap = await createImageBitmap(file);

  // Draw to canvas
  const canvas = document.createElement('canvas');
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageBitmap, 0, 0);

  // Turn into Blob
  return await new Promise((resolve) => canvas.toBlob(resolve, type, quality));
};
