import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOpenMask } from '../../../../../mask-overlays/mask-overlay/hooks/use-open-mask';
import { useSupplier } from '../../queries/use-supplier';
import { FilePreviewPanelBaseProps } from '../FilePreviewPanel';
import { SupplierFileInfoPanel } from '../SupplierFileInfoPanel';

export default interface SupplierFileInfoPanelContainerProps
  extends Pick<
    FilePreviewPanelBaseProps,
    'onCloseClick' | 'onEdit' | 'onVisibilityToggle'
  > {
  ids: number[];
}

export const SupplierFileInfoPanelContainer = (
  props: SupplierFileInfoPanelContainerProps
) => {
  const { ids } = props;
  const id = ids[0];
  const {
    supplier: fullyLoadedSupplier,
    refetch,
    pending,
  } = useSupplier(id.toString());

  useEntityEvents((event) => {
    if (event.entity === Entities.contact) {
      refetch();
    }
  });

  const onOpenMask = useOpenMask();

  return (
    <SupplierFileInfoPanel
      supplier={fullyLoadedSupplier}
      loading={pending}
      onAddContact={() => {
        onOpenMask().handler({
          entity: Entities.contact,
          template: {
            entity: Entities.supplier,
            id: String(id),
          },
        });
      }}
      onEditContact={(contactId) => {
        onOpenMask().handler({
          entity: Entities.contact,
          id: String(contactId),
          template: {
            entity: Entities.supplier,
            id: String(id),
          },
        });
      }}
      {...props}
    />
  );
};
