import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TimeTrackerMenuTabs } from './TimeTrackerMenuTabs';

export function useTimeTrackerTab(options: {
  workTimeDisabled: boolean;
  projectTimeDisabled: boolean;
}) {
  const { workTimeDisabled, projectTimeDisabled } = options;
  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = (tab: TimeTrackerMenuTabs) => {
    setSearchParams({ tab }, { replace: true });
  };

  // Only shows tabs to which the user has access.
  const tab = useMemo(() => {
    const defaultValue = searchParams.get('tab') as TimeTrackerMenuTabs;
    const allowed: TimeTrackerMenuTabs[] = [
      workTimeDisabled ? null : TimeTrackerMenuTabs.workingTime,
      projectTimeDisabled ? null : TimeTrackerMenuTabs.projectTime,
    ].filter(Boolean);

    if (allowed.includes(defaultValue)) {
      return defaultValue;
    }

    return allowed[0] ?? null;
  }, [workTimeDisabled, projectTimeDisabled, searchParams]);

  return [tab, setTab] as const;
}
