import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import {
  parseTableSettings,
  stringifyTableSettings,
} from '../../components/data-tables/make-table-settings';

export const useApendCustomListSettings = (entityType: Entities) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const settingsParam = searchParams.get('settings');
  const currentFilters = parseTableSettings(settingsParam);

  useEffect(() => {
    let settings = '';
    switch (entityType) {
      case Entities.mailboxContent: {
        const newFilters = [
          ...(currentFilters?.filter ?? []),
          {
            id: 'status',
            value: [
              {
                id: MailAssignStatus.NOT_YET_ASSIGNED,
                name: t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED'),
              },
            ],
          },
        ].filter(Boolean);
        settings = stringifyTableSettings({
          sort: currentFilters?.sort,
          filter: Array.from(
            new Map(newFilters.map((item) => [item.id, item])).values()
          ),
        });
        break;
      }

      // add another default prefilters for other entities
      default:
        return;
    }
    navigate(
      {
        pathname: `/more/entity/${entityType}`,
        search: `settings=${settings}`,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
