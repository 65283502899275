import { useBottomViewsContext } from '../hooks';

import { BottomViewsTableView } from './BottomViewsTableView';

export const BottomViewsTabContent = () => {
  const { activeView } = useBottomViewsContext();

  if (activeView.type === 'table') {
    return (
      <BottomViewsTableView
        entity={activeView.entity}
        prefilter={activeView?.options?.prefilter}
      />
    );
  }

  if (activeView.type === 'custom') {
    return activeView.content;
  }

  return null;
};
