import styles from './YellowNote.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Divider,
  IconButton,
  Stack,
  TextareaAutosize,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { clamp } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { useAdjustedYellowNotePosition } from './use-adjusted-yellow-note-position';
import { useYellowNoteContext } from './yellow-note-context';
import { YellowNoteCornerGraphic } from './YellowNoteCornerGraphic';

export function YellowNote() {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const context = useYellowNoteContext();

  const {
    data: note,
    updateYellowNote,
    deleteYellowNote,
    isOpen,
    setOpen,
  } = context;

  const dialogs = useDialogs();

  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement>(null);
  const [draggableEl, setDraggableEl] = useState<HTMLDivElement>(null);

  const [isDragging, setDragging] = useState(false);

  const [position, setPosition] = useAdjustedYellowNotePosition({
    wrapperEl,
    draggableEl,
  });

  const onDragStart = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isDesktop || isDragging) {
      return;
    }

    const root = wrapperEl;
    const draggable = draggableEl;

    setDragging(true);

    const x0 = draggable.offsetLeft;
    const y0 = draggable.offsetTop;

    setPosition({ x: x0, y: y0 });

    const isTouch = event.type === 'touchstart';

    const startX = isTouch
      ? (event as React.TouchEvent).touches[0].clientX
      : (event as React.MouseEvent).clientX;

    const startY = isTouch
      ? (event as React.TouchEvent).touches[0].clientY
      : (event as React.MouseEvent).clientY;

    const handleMove = ({ x, y }: { x: number; y: number }) => {
      const dx = x - startX;
      const dy = y - startY;

      setPosition({
        x: clamp(x0 + dx, 0, root.clientWidth - draggable.clientWidth),
        y: clamp(y0 + dy, 0, root.clientHeight - draggable.clientHeight),
      });
    };

    const handleUp = () => {
      setDragging(false);
    };

    const onMouseMove = (e: MouseEvent | PointerEvent) => {
      handleMove({ x: e.clientX, y: e.clientY });
    };

    const onTouchMove = (e: TouchEvent) => {
      handleMove({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    };

    const onMouseUp = () => {
      handleUp();

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const onTouchEnd = () => {
      handleUp();

      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };

    if (isTouch) {
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    } else {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };

  const [isEditing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (isOpen && note == null) {
      setInputValue(note?.body ?? '');
      setEditing(true);
    }
  }, [isOpen, note]);

  if (!isOpen) {
    return null;
  }

  function renderButtons() {
    return (
      <Stack direction="row">
        {!isEditing && (
          <IconButton
            onClick={() => {
              setInputValue(note?.body ?? '');
              setEditing(true);
            }}
          >
            <EditIcon />
          </IconButton>
        )}

        {!isEditing && (
          <IconButton
            onClick={async () => {
              const confirmed = await dialogs.confirm({
                title: t('FILE.YELLOW_NOTE.DELETE_TITLE'),
                description: t('FILE.YELLOW_NOTE.DELETE_DESCRIPTION'),
                confirmLabel: t('ALERTS.BTN_DELETE'),
                cancelLabel: t('ALERTS.BTN_ABORT'),
              });

              if (confirmed) {
                await deleteYellowNote();
                setEditing(false);
                setOpen(false);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}

        {isEditing && (
          <IconButton
            onClick={async () => {
              await updateYellowNote(inputValue);
              setEditing(false);
            }}
          >
            <SaveIcon />
          </IconButton>
        )}

        <IconButton
          onClick={() => {
            setEditing(false);
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    );
  }

  return (
    <div ref={setWrapperEl} className={styles.wrapper}>
      <div
        ref={setDraggableEl}
        className={clsx(styles.draggable, isDragging && styles.dragging)}
        style={
          isDesktop
            ? position
              ? { left: position.x, top: position.y }
              : {
                  left: 'calc(50% - 16rem)',
                  top: 'calc(50% - 10rem)',
                }
            : null
        }
      >
        <div className={styles.header}>
          <div
            className={styles.titleWrapper}
            onMouseDown={onDragStart}
            onTouchStart={onDragStart}
          >
            <Typography className={styles.title} variant="h4">
              {t('FILE.YELLOW_NOTE.TITLE')}
            </Typography>
          </div>

          {renderButtons()}
        </div>

        <Divider className={styles.divider} />

        {isEditing ? (
          <TextareaAutosize
            className={clsx(styles.textarea, 'custom-scrollbar')}
            autoFocus
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.currentTarget.value);
            }}
            minRows={8}
            maxRows={16}
          />
        ) : (
          <div className={clsx(styles.body, 'custom-scrollbar')}>
            {note?.body ?? ''}
          </div>
        )}

        <div className={styles.footer}>
          <div className={styles.footerContent}>
            <Typography className={styles.footerTextWrapper} variant="caption">
              {note?.date ? <DateRangeIcon /> : null}
              <span className={styles.footerText}>
                {note?.date
                  ? DateTime.fromISO(note.date).toLocaleString(
                      DateTime.DATE_SHORT
                    )
                  : null}
                {note?.date && note.user ? <span> | </span> : null}
                {note?.user ? note.user.displayName : null}
              </span>
            </Typography>
          </div>

          <div className={styles.footerCorner}>
            <div className={styles.cornerTop} />
            <div className={styles.cornerBottom}>
              <YellowNoteCornerGraphic className={styles.cornerGraphic} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
