import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IUser, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { CRMTypes } from '@work4all/models/lib/Classes/CRMTypes.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/InputTypes/InputCrmAnhangAttachementsRelation';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import {
  formatTopicMarkName,
  titleWithTopicMark,
} from '../../utils/titleWithTopicMark';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { CrmMaskFormValue } from './types';
import { useCrmRequestData as useCrmRequest } from './use-crm-request';

export type CRMTypesIntersection = Omit<
  Note<EMode.query> | Task<EMode.query> | CallMemo<EMode.query>,
  'status'
>;

export const CRMOverlayController = (props: MaskControllerProps) => {
  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const request = useCrmRequest(props);

  const template = useAssignableTemplateEntity(mask);
  const user = useUser();
  const { presetFields } = props.params ?? {};
  const newEntityData = useMemo(() => {
    const userPartial = {
      benutzerCode: user.benutzerCode,
      displayName: user.displayName,
    };
    const data = createCRMDefaultData({
      template,
      entity: mask.entity,
      user: userPartial,
      presetFieldsJSON: presetFields,
    });

    return data;
  }, [
    user.benutzerCode,
    user.displayName,
    template,
    mask.entity,
    presetFields,
  ]);

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [mutate] = useDataMutation<
    CRMTypes,
    EMode.upsert,
    {
      attachements: InputCrmAnhangAttachementsRelation;
    }
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data as unknown as CRMTypes<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave(data);
    },
  });

  const getTempFileInitialData = useCallback((data: CrmMaskFormValue) => {
    return data?.attachmentList;
  }, []);

  const overlay = useMaskOverlay<CrmMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customFields,
    getSubTitle: (x) => x.title,
    getTempFileInitialData,
  });

  const { data, form, tempFileManager } = overlay;
  const { getValues, getFieldState, watch, formState } = form;

  const usedTopicMark = useRef<string>();
  useEffect(() => {
    usedTopicMark.current = data?.topicMarkList?.[0]
      ? formatTopicMarkName(data.topicMarkList[0].name)
      : undefined;
  }, [data]);

  const skipUpdatingDate = useEventCallback(
    (key: 'date' | 'endDate', value: string) => {
      return (
        mask.entity !== Entities.task ||
        (mask.isEditMode && !data.id) ||
        data?.[key] === value
      );
    }
  );

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            'businessPartner.data': businessPartner,
          };
        }
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
      topicMarkList: (topicMarkList: TopicSimple[]) => {
        const { title, topicMark } = titleWithTopicMark(
          getValues('title'),
          topicMarkList,
          usedTopicMark.current,
          !getFieldState('topicMarkList').isDirty
        );

        usedTopicMark.current = topicMark;

        if (title) return { title };
      },
      date: (date: string) => {
        if (skipUpdatingDate('date', date)) return;

        const todaysDate = Date.now();
        const endDate = getValues('endDate');
        if (
          Date.parse(date) > todaysDate &&
          (!endDate || Date.parse(endDate) < Date.parse(date))
        ) {
          return {
            endDate: date,
          };
        }
      },
      endDate: (endDate: string) => {
        if (skipUpdatingDate('endDate', endDate)) return;

        const startDate = getValues('date');

        if (Date.parse(endDate) < Date.parse(startDate) && endDate) {
          return {
            date: endDate,
          };
        }
      },
    },
    form
  );

  const currentTopicMarkList = watch('topicMarkList');

  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.inputCrmAnhangAttachementModify,
      'code'
    );

  const relations = useMemo(() => {
    const topic: InputTopicMarkRelation = {
      set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
    };

    const result =
      attachementsRelation || currentTopicMarkList
        ? { attachements: attachementsRelation?.attachements, topic }
        : undefined;

    return result;
  }, [attachementsRelation, currentTopicMarkList]);

  const handleSubmit = async (data: CrmMaskFormValue) => {
    const updateRaw = mask.isCreateMode
      ? data
      : pickUpdateFields(data, formState.dirtyFields);

    const updateMapped = prepareInputWithCustomFields(updateRaw);

    await mutate(updateMapped, relations ? { relations } : undefined);
  };

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <OverlayController<CrmMaskFormValue>
      {...overlay}
      onSubmit={handleSubmit}
      tabs={
        <CrmTabs
          isCreateMode={mask.isCreateMode}
          renderIndividualTab={shouldRenderIndividualTab}
        />
      }
    >
      <Content renderIndividualTab={shouldRenderIndividualTab} />
    </OverlayController>
  );
};

const CrmTabs = memo(function CrmTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="attachments" label={t('MASK.ATTACHMENTS')}></MaskTab>
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value="individual" label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function CrmTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value="individual">
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

type ICreateCRMDefaultParams = {
  template: AssignableEntityResult;
  entity: Entities;
  user: Pick<IUser, 'displayName' | 'benutzerCode'>;
  presetFieldsJSON: string;
};

function createCRMDefaultData(
  params: ICreateCRMDefaultParams
): Note | Task | CallMemo {
  const { entity, template, user, presetFieldsJSON = '{}' } = params;

  const common: Note | Task | CallMemo = {
    attachmentList: [],
    title: '',
    note: '',
    date: DateTime.now().toISO(),
    user: {
      displayName: user.displayName,
      id: user.benutzerCode,
    },
    userId: user.benutzerCode,
    topicMarkList: null,
  };

  if (entity === Entities.task) {
    common.status = TaskStatus.OFFEN;
    (common as unknown as Task).priority = 1;
  }

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const contact =
      template.data.contact || template.data.businessPartner?.data?.mainContact;
    const data: Note | Task | CallMemo = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartner?.businessPartnerType ??
        template.data.businessPartnerType ??
        SdObjType.LIEFERANT,
      contact: contact ?? null,
      contactId: contact?.id ?? 0,
      project: template.data.project ?? null,
      projectId: template.data.project?.id ?? 0,
      projectProcess: template.data.projectProcess ?? null,
      projectProcessId: template.data.projectProcess?.id ?? 0,
      topicMarkList: template.data.topicMarkList,
    };

    return data;
  }

  const presetFields = JSON.parse(presetFieldsJSON);

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    businessPartnerType: SdObjType.LIEFERANT,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
    ...presetFields,
  };
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    type GraphQLEntity = { __typename: string };

    const typename = (businessPartner as GraphQLEntity).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
