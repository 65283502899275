import styles from './HtmlInputField.module.scss';

import { useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import { TextmarkType } from '@work4all/components/lib/components/entity-picker/textmark-picker';
import { TextmarkPickerButton } from '@work4all/components/lib/components/entity-preview/template-preview/TextmarkPickerButton';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { GetEditorResult } from '@work4all/components/lib/input/format-text/TextEditor/types';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { LabeledInput } from '../../../../../locked-inputs';

export interface HtmlInputFieldProps {
  label: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  textmarkType?: TextmarkType;
}

export const HtmlInputField = (props: HtmlInputFieldProps) => {
  const { label, value, onChange, error, textmarkType } = props;

  const [editor, setEditor] = useState<GetEditorResult>();

  return (
    <LabeledInput
      onClear={null}
      classes={{
        actions: styles['html-input-label-actions'],
      }}
      error={error}
      value={
        <div className={styles.inputWrapper}>
          <TextEditor
            value={value}
            onInitialized={(editor: FroalaEditor) => {
              setEditor(editor.getEditor());
            }}
            hideToolbar
            enableTextmarks
            onChange={onChange}
            canAddOrInsertImage={false}
            config={{
              height: remToPx(1),
              heightMax: remToPx(6),
              heightMin: remToPx(1),
              placeholderText: '',
              useClasses: true,
            }}
          />
        </div>
      }
      label={label}
      iconRight={
        <TextmarkPickerButton
          onChange={({ newEditorValue }) => {
            onChange(newEditorValue);
          }}
          editor={editor}
          type={textmarkType}
          iconSize="small"
        />
      }
    />
  );
};
