import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPreviewProps } from '../types';

import { TextBuildingBlockPreview } from './TextBuildingBlockPreview';

type TextBuildingBlockPreviewContainerProps = EntityPreviewProps;
export const PREVIEW_DATA: TextBuildingBlock<EMode.query> = {
  id: null,
  name: null,
  text: null,
};

export function TextBuildingBlockPreviewContainer(
  props: TextBuildingBlockPreviewContainerProps
) {
  const { ids, onCloseClick, onEditClicked, onVisibilityToggle } = props;
  const textBuildingBlockId = ids[0];
  const requestData = useMemo<DataRequest>(() => {
    return {
      completeDataResponse: false,
      entity: Entities.textBuildingBlock,
      data: PREVIEW_DATA,
      vars: {
        codes: [textBuildingBlockId],
      },
    };
  }, [textBuildingBlockId]);

  const { data } = useDataProvider<TextBuildingBlock>(requestData);

  const found = useMemo(() => {
    return data.find(
      (textBuildingBlock) => textBuildingBlock.id === textBuildingBlockId
    );
  }, [data, textBuildingBlockId]);

  if (!found) {
    return null;
  }

  return (
    <TextBuildingBlockPreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      textBuildingBlock={found}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
