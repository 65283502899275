import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { DocumentFileFragment } from './DocumentFileFragment';

export const RequestedDocumentData: Document<EMode.query> = {
  id: null,
  filePath: null,
  note: null,
  userId: null,
  ...DocumentFileFragment,
  childDocuments: [
    {
      id: null,
      ...DocumentFileFragment,
    },
  ],
};
