import styles from './TimeFrameSwitch.module.scss';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, IconButton, Popover } from '@mui/material';
import clsx from 'clsx';
import { DateTime, DurationObjectUnits } from 'luxon';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../input/date-picker';
import { Tooltip } from '../tooltip/Tooltip';

export const TIME_UNIT_TYPES = ['day', 'week', 'month', 'year'] as const;

export type TimeUnitType = (typeof TIME_UNIT_TYPES)[number];

export function isTimeUnitType(value: string): value is TimeUnitType {
  return (TIME_UNIT_TYPES as readonly string[]).includes(value);
}
export interface ITimeFrameSwitchProps {
  initialTime?: Date;
  timeUnit: TimeUnitType;
  lightMode?: boolean;
  onChange?: (startTime: Date, endTime: Date) => void;
  showActions?: boolean;
  backwardAction?: boolean;
  forwardAction?: boolean;
  todayAction?: boolean;
  showTitle?: boolean;
  classes?: string;
}

export const TimeFrameSwitch: React.FC<ITimeFrameSwitchProps> = (props) => {
  const {
    initialTime,
    onChange,
    timeUnit,
    lightMode,
    backwardAction = true,
    forwardAction = true,
    todayAction = true,
    showTitle = true,
    showActions = true,
  } = props;
  const unit: keyof DurationObjectUnits = timeUnit ? timeUnit : 'day';
  const { t } = useTranslation();

  const forward = useCallback(() => {
    const newTime = DateTime.fromJSDate(initialTime).plus({ [unit + 's']: 1 });
    if (onChange) {
      onChange(
        newTime.startOf(unit).toJSDate(),
        newTime.endOf(unit).toJSDate()
      );
    }
  }, [initialTime, unit, onChange]);

  const today = useCallback(() => {
    const newTime = DateTime.now();
    if (onChange) {
      onChange(
        newTime.startOf(unit).toJSDate(),
        newTime.endOf(unit).toJSDate()
      );
    }
  }, [onChange, unit]);

  const backward = useCallback(() => {
    const newTime = DateTime.fromJSDate(initialTime).minus({ [unit + 's']: 1 });
    if (onChange) {
      onChange(
        newTime.startOf(unit).toJSDate(),
        newTime.endOf(unit).toJSDate()
      );
    }
  }, [initialTime, unit, onChange]);

  const formattedTime = useMemo(() => {
    if (unit === 'day') {
      return (
        <span className={styles.inlineBlock}>
          {DateTime.fromJSDate(initialTime).toLocaleString(DateTime.DATE_FULL)}
        </span>
      );
    }

    if (unit === 'week') {
      const weekName =
        t('COMMON.CALENDAR_WEEK_SHORT') +
        ' ' +
        DateTime.fromJSDate(initialTime).weekNumber;

      const start = DateTime.fromJSDate(initialTime).startOf('week');
      const end = DateTime.fromJSDate(initialTime).endOf('week');

      const startDay = start.toFormat('d');
      const endDay = end.toFormat('d');
      const startMonth = start.toFormat('MMM');
      const endMonth = end.toFormat('MMM');
      const startYear = start.toFormat('yy');
      const endYear = end.toFormat('yy');

      return (
        <>
          {weekName.length > 0 && (
            <span className={styles.inlineBlock}>{weekName}</span>
          )}
          <span>
            &nbsp;|&nbsp;{startDay}. {startMonth !== endMonth ? startMonth : ''}{' '}
            {startYear !== endYear ? startYear : ''} - {endDay}. {endMonth}{' '}
            {endYear}
          </span>
        </>
      );
    }

    if (unit === 'month') {
      return (
        <span className={styles.inlineBlock}>
          {DateTime.fromJSDate(initialTime).toFormat('MMM yyyy')}
        </span>
      );
    }

    if (unit === 'year') {
      return (
        <span className={styles.inlineBlock}>
          {DateTime.fromJSDate(initialTime).toFormat('yyyy')}
        </span>
      );
    }
  }, [unit, initialTime, t]);

  const TodayButton = useMemo(() => {
    const disabled = DateTime.now()
      .startOf(timeUnit)
      .equals(DateTime.fromJSDate(initialTime).startOf(timeUnit));

    return (
      <Button
        onClick={today}
        color="primary"
        disabled={disabled}
        style={{ fontWeight: '500' }}
      >
        {t('COMMON.TODAY')}
      </Button>
    );
  }, [timeUnit, initialTime, today, t]);

  const datePicker = useRef();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const renderFormattedTime = useCallback(() => {
    return (
      <Button
        ref={datePicker}
        className={clsx(
          { [styles.inverse]: lightMode },
          styles.bold,
          styles.alignCenter,
          styles.labelButton,
          props?.classes
        )}
        onClick={() => setIsDatePickerOpen(true)}
      >
        {formattedTime}
      </Button>
    );
  }, [formattedTime, lightMode, props?.classes]);

  const tooltipText = useMemo(() => {
    switch (unit) {
      case 'day':
        return t('COMMON.SWITCH_TO_CURRENT_DAY');
      case 'week':
        return t('COMMON.SWITCH_TO_CURRENT_WEEK');
      case 'month':
        return t('COMMON.SWITCH_TO_CURRENT_MONTH');
      case 'year':
        return t('COMMON.SWITCH_TO_CURRENT_YEAR');
    }
  }, [unit, t]);

  return (
    <div className={clsx(styles.wrap, lightMode && styles.inverse)}>
      {showActions && (
        <div className={styles.switchers}>
          {todayAction && (
            <Tooltip title={tooltipText}>
              <span>{TodayButton}</span>
            </Tooltip>
          )}
          {backwardAction && (
            <IconButton onClick={backward} size="small">
              <ChevronLeft className={clsx(lightMode && styles.inverse)} />
            </IconButton>
          )}
          {forwardAction && (
            <IconButton onClick={forward} size="small">
              <ChevronRight className={clsx(lightMode && styles.inverse)} />
            </IconButton>
          )}
        </div>
      )}

      {showTitle && (
        <span
          className={clsx(
            lightMode && styles.inverse,
            styles.bold,
            styles.alignCenter
          )}
        >
          {renderFormattedTime()}
        </span>
      )}

      <Popover
        open={isDatePickerOpen}
        anchorEl={datePicker.current}
        onClose={() => setIsDatePickerOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DatePicker
          onChange={(e) => {
            const newTime = DateTime.fromJSDate(e.value as Date);
            if (onChange) {
              onChange(
                newTime.startOf(unit).toJSDate(),
                newTime.endOf(unit).toJSDate()
              );
            }
            setIsDatePickerOpen(false);
          }}
          defaultValue={initialTime}
        />
      </Popover>
    </div>
  );
};
