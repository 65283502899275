import { CheckSharp } from '@mui/icons-material';

export type ICheckboxCellProps = {
  value: string | boolean;
};

export const CheckboxCell = (props: ICheckboxCellProps) => {
  const { value } = props;

  return (
    Boolean(value) && (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CheckSharp />
      </div>
    )
  );
};
