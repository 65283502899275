/* eslint-disable no-console */
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { StatisticCustomerDatasheet } from '@work4all/models/lib/Classes/StatisticCustomerDatasheet.entity';
import { StatisticCustomerDatasheetColumn } from '@work4all/models/lib/Classes/StatisticCustomerDatasheetColumn.entity';

import { formatNumberAsCurrency } from '@work4all/utils/lib/formatNumberAsCurrency';

import { SimpleTable } from '../../../simple-table/SimpleTable';
import { getStatisticFormatedDate } from '../getStatisticFormatedDate';

import { Stats, useCustomerSheetData } from './use-customer-sheet-data';

interface StatisticCustomerSheetTableProps {
  data: StatisticCustomerDatasheet;
  selectedYear: Date;
  selectedCustomer: Customer;
  borders?: { vertical?: boolean; horizontal?: boolean };
  startBusinessYearSetting?: number;
  onDateChange?: (date: Date) => void;
}

const filterDataByStatisticName = (
  data: StatisticCustomerDatasheet,
  statisticName: string
): StatisticCustomerDatasheetColumn[] =>
  data[0]?.columns
    ?.map((item) => ({
      ...item,
      statistics: item.statistics.filter(
        (stat) => stat.statisticName === statisticName
      ),
    }))
    .filter((item) => item.statistics.length > 0) || [];

export const StatisticCustomerSheetTable: React.FC<
  StatisticCustomerSheetTableProps
> = (props) => {
  const {
    data,
    selectedYear,
    selectedCustomer,
    borders,
    startBusinessYearSetting,
    onDateChange,
  } = props;
  const { t } = useTranslation();

  const [filteredDataSales, filteredDataArticle, filteredDataCostcenter] =
    useMemo(
      () => [
        filterDataByStatisticName(data, 'Umsätze'),
        filterDataByStatisticName(data, 'Artikel'),
        filterDataByStatisticName(data, 'Kostenstellen'),
      ],
      [data]
    );

  const fiscalYears = useMemo(() => {
    return Array.from(
      new Set(
        filteredDataSales.map((item) => {
          const date = DateTime.fromFormat(item.month, 'yyyy-MM-dd');
          const year = date.year;
          const month = date.month;
          return month < startBusinessYearSetting ? year - 1 : year;
        })
      )
    );
  }, [filteredDataSales, startBusinessYearSetting]);

  const preparedCustomerSheetData = useCustomerSheetData(
    data,
    selectedYear,
    startBusinessYearSetting,
    fiscalYears,
    filteredDataSales,
    filteredDataArticle,
    filteredDataCostcenter
  );

  function displayMonthlySum(monthlySum: number) {
    return (
      <td
        style={{
          minWidth: 100,
          width: 100,
          color: monthlySum < 0 ? 'red' : undefined,
          textAlign: 'right',
        }}
      >
        {monthlySum !== 0 && monthlySum != null
          ? formatNumberAsCurrency(monthlySum, {
              maximum: 0,
              minimum: 0,
            })
          : ''}
      </td>
    );
  }

  function renderHeaderRow(newData: Stats[]) {
    return (
      <tr style={{ height: 43.5 }}>
        <th style={{ borderTop: 0, borderRight: 0 }}></th>
        <th style={{ borderTop: 0 }}></th>
        <th>{t('COMMON.SUM')}</th>
        {newData[0].stat[0]?.months.map((months) => {
          return (
            <th>
              {DateTime.fromJSDate(new Date(months.date)).toFormat('MMM')}
            </th>
          );
        })}
      </tr>
    );
  }

  function renderSeparatorRow() {
    return (
      <tr>
        <td colSpan={15}>
          <div style={{ height: 45 }}></div>
        </td>
      </tr>
    );
  }

  function renderTitleRowSalesDevelopment(newData: Stats[]) {
    return (
      <tr style={{ background: 'var(--ui03)' }}>
        <td style={{ minWidth: 300 }} colSpan={2}>
          <strong>{t('STATISTIC.SALES_DEVELOPMENT')}</strong>
        </td>
        {(() => {
          const totalSum = newData[0].stat.reduce(
            (totalSum, item) => totalSum + item.sum,
            0
          );

          return displayMonthlySum(totalSum);
        })()}

        {Array.from({ length: 12 }, (_, monthIndex) => {
          const monthlySum = newData[0].stat.reduce(
            (sum, item) => sum + (item.months[monthIndex]?.value || 0),
            0
          );

          return displayMonthlySum(monthlySum);
        })}
      </tr>
    );
  }

  function renderRowsSalesDevelopment(
    newData: Stats[],
    onDateChange: (date: Date) => void,
    startBusinessYearSetting: number
  ) {
    return newData[0].stat.map((data) => {
      return (
        <tr
          onClick={() => {
            onDateChange(new Date(data.year));
          }}
          style={{ cursor: 'pointer' }}
        >
          <td colSpan={2}>
            {startBusinessYearSetting !== 1
              ? (() => {
                  const date = new Date(data.year);
                  date.setMonth(startBusinessYearSetting - 1);
                  const date2 = new Date(data.year);
                  date2.setMonth(startBusinessYearSetting + 11 - 1);
                  return (
                    getStatisticFormatedDate(date, 'MM.yyyy') +
                    ' - ' +
                    getStatisticFormatedDate(date2, 'MM.yyyy')
                  );
                })()
              : getStatisticFormatedDate(new Date(data.year), 'yyyy')}
          </td>
          {displayMonthlySum(data.sum)}

          {data.months.map((months) => {
            return displayMonthlySum(months.value);
          })}
        </tr>
      );
    });
  }

  function renderTitleRowArtikleData(newData: Stats[]) {
    return (
      <tr style={{ background: 'var(--ui03)' }}>
        <td colSpan={2}>
          <strong>{t('STATISTIC.ARTICLE_SELECTED_BUISNESS_YEAR')}</strong>
        </td>
        {(() => {
          const totalSum = newData[0].artikelData.reduce(
            (totalSum, item) => totalSum + item.totalValue,
            0
          );

          return displayMonthlySum(totalSum);
        })()}

        {Array.from({ length: 12 }, (_, monthIndex) => {
          const monthlySum = newData[0].artikelData.reduce(
            (sum, item) => sum + (item.monthlyData[monthIndex]?.value || 0),
            0
          );

          return displayMonthlySum(monthlySum);
        })}
      </tr>
    );
  }

  function renderRowsArticleData(newData: Stats[]) {
    return newData[0].artikelData.map((artikel) => {
      return (
        <tr>
          <td>{artikel.name}</td>
          <td>{artikel.number}</td>
          {displayMonthlySum(artikel.totalValue)}
          {artikel.monthlyData.map((months) => {
            return displayMonthlySum(months.value);
          })}
        </tr>
      );
    });
  }

  function renderTitleRowCostCenterData(newData: Stats[]) {
    return (
      <tr style={{ background: 'var(--ui03)' }}>
        <td colSpan={2}>
          <strong>{t('STATISTIC.COSTCENTER_SELECTED_BUISNESS_YEAR')}</strong>
        </td>
        {(() => {
          const totalSum = newData[0].costCenterData.reduce(
            (totalSum, item) => totalSum + item.totalValue,
            0
          );

          return displayMonthlySum(totalSum);
        })()}

        {Array.from({ length: 12 }, (_, monthIndex) => {
          const monthlySum = newData[0].costCenterData.reduce(
            (sum, item) => sum + (item.monthlyData[monthIndex]?.value || 0),
            0
          );

          return displayMonthlySum(monthlySum);
        })}
      </tr>
    );
  }

  function renderRowsCostCenterData(newData: Stats[]) {
    return newData[0].costCenterData.map((costCenter) => {
      return (
        <tr>
          <td>{costCenter.name}</td>
          <td>{costCenter.number}</td>
          {displayMonthlySum(costCenter.totalValue)}
          {costCenter.monthlyData.map((months) => {
            return displayMonthlySum(months.value);
          })}
        </tr>
      );
    });
  }

  if (preparedCustomerSheetData[0] === undefined) {
    if (selectedCustomer === null) {
      return (
        <Typography variant="h3" style={{ textAlign: 'center' }}>
          {' '}
          {t('STATISTIC.PLEASE_SELECT_CUSTOMER')}
        </Typography>
      );
    } else {
      return [];
    }
  } else {
    if (preparedCustomerSheetData[0].stat.length === 0) {
      return (
        <Typography variant="h3" style={{ textAlign: 'center' }}>
          {' '}
          {t('STATISTIC.NO_DATA_FOR_SELECTED_CUSTOMER')}
        </Typography>
      );
    } else {
      return (
        <SimpleTable borders={borders}>
          {renderHeaderRow(preparedCustomerSheetData)}
          {renderTitleRowSalesDevelopment(preparedCustomerSheetData)}
          {renderRowsSalesDevelopment(
            preparedCustomerSheetData,
            onDateChange,
            startBusinessYearSetting
          )}

          {renderSeparatorRow()}
          {renderTitleRowArtikleData(preparedCustomerSheetData)}
          {renderRowsArticleData(preparedCustomerSheetData)}

          {renderSeparatorRow()}
          {renderTitleRowCostCenterData(preparedCustomerSheetData)}
          {renderRowsCostCenterData(preparedCustomerSheetData)}
        </SimpleTable>
      );
    }
  }
};
