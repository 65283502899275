import { Entities } from '../Enums/Entities.enum';

import { EntityRelations, inferRelations } from './infer-relations';

const cache = new Map<Entities, EntityRelations | null>();

export function getEntityRelations(
  entityType: Entities
): EntityRelations | null {
  if (cache.has(entityType)) {
    return cache.get(entityType)!;
  }

  const relations = inferRelations(entityType);

  cache.set(entityType, relations);

  return relations;
}
