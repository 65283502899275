import Add from '@mui/icons-material/Add';
import List from '@mui/icons-material/List';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EntityPickerActionBar,
  EntityPickerActionButton,
} from '@work4all/components/lib/components/entity-picker/components';
import { FooterPickerAction } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';
import { TableInitialState } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useTableStateBag';

import { usePermissions } from '@work4all/data';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { MaskModal } from './MaskModal';
import { PickFromListModal } from './PickFromListModal';

interface SelectedItem {
  id: number | string;
  number: number | string;
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasRequiredProperties = (obj: any): obj is SelectedItem => {
  return obj && 'id' in obj && 'number' in obj && 'name' in obj;
};

export type IEntityPickerActionsProps<T> = {
  disableAddButton?: boolean;
  hideAddButton?: boolean;
  disableListButton?: boolean;
  hideListButton?: boolean;
  entity: Entities;
  prefilter?: unknown[];
  multiple: boolean;
  onChange: (value: T | T[]) => void;
  data?: T;
  initialState?: TableInitialState;
  template?: {
    id: number | string;
    entity: Entities;
  };
};

export function EntityPickerActions<T>(props: IEntityPickerActionsProps<T>) {
  const {
    disableAddButton,
    hideAddButton,
    disableListButton,
    hideListButton,
    entity,
    multiple,
    onChange,
    prefilter,
    data,
    initialState,
  } = props;

  const { t } = useTranslation();
  const { canAdd } = usePermissions();
  const { saveSearchItem } = useSearchHistory();

  const [modal, setModal] = useState<'create' | 'list' | null>(null);

  const isListModalOpen = modal === 'list';
  const isCreateModalOpen = modal === 'create';

  const closeModal = () => {
    setModal(null);
  };

  const handleConfirm = (selected: T[]) => {
    if (props.multiple) {
      onChange(selected);
    } else {
      onChange(selected[0] ?? null);
      if (selected[0] && hasRequiredProperties(selected[0])) {
        saveSearchItem(entity, {
          id: selected[0].id.toString(),
          number: selected[0].number.toString(),
          name: selected[0].name,
        });
      }
    }
    closeModal();
  };

  const hideAdd =
    hideAddButton || !canAdd({ entity, context: props.template?.entity });

  if (hideAdd && hideListButton) return null;
  return (
    <>
      <EntityPickerActionBar>
        {!hideAdd && (
          <EntityPickerActionButton
            startIcon={<Add />}
            disabled={disableAddButton}
            onClick={() => {
              setModal('create');
            }}
          >
            {t('COMMON.NEW')}
          </EntityPickerActionButton>
        )}

        {!hideListButton && (
          <EntityPickerActionButton
            startIcon={<List />}
            disabled={disableListButton}
            onClick={() => setModal('list')}
          >
            {t('COMMON.LIST')}
          </EntityPickerActionButton>
        )}
      </EntityPickerActionBar>

      <PickFromListModal
        open={isListModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        entity={entity}
        data={data}
        multiple={multiple}
        prefilter={prefilter}
        initialState={initialState}
      />

      <MaskModal
        open={isCreateModalOpen}
        entity={entity}
        onClose={closeModal}
        onAfterSave={(data: T) => {
          handleConfirm([data]);
        }}
        template={props?.template}
      />
    </>
  );
}

export function DefaultPickerAction<TValue>(props: FooterPickerAction<TValue>) {
  const { onChange, data, entity, popoverRef } = props;
  return (
    <EntityPickerActions
      {...props}
      entity={entity}
      multiple={false}
      data={data}
      onChange={(value) => {
        onChange?.(value);
        popoverRef.current?.close();
      }}
    />
  );
}
