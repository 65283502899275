import { useObservableState } from 'observable-hooks';
import React, { useMemo } from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { useDataProvider } from '@work4all/data';

import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  SuggestionPickerField,
  SuggestionPickerFieldProps,
} from './SuggestionPickerField';

interface EntitySuggestionPickerField
  extends Omit<SuggestionPickerFieldProps, 'data'> {
  field: string;
  entity: Entities;
}

export const EntitySuggestionPickerField = React.forwardRef<
  HTMLDivElement,
  EntitySuggestionPickerField
>(function EntitySuggestionPickerField(props, ref) {
  const { onChange, ...rest } = props;

  const { field, entity } = props;

  const [searchTerm, setSearchTerm] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: [
        { [field]: { $eq: searchTerm + '%' } },
        { [field]: { $ne: '' } },
        { [field]: { $ne: ' ' } },
      ],
      entity: entity,
      data: [field],
      sort: [{ field, direction: SortDirection.ASCENDING }],
    };
  }, [entity, field, searchTerm]);

  const response = useDataProvider(
    requestData,
    !searchTerm || searchTerm?.trim().length === 0
  );

  const data = useMemo(() => {
    const result: string[] = [...new Set(response.data.map((x) => x[field]))];
    return result;
  }, [field, response.data]);

  return (
    <SuggestionPickerField
      {...rest}
      ref={ref}
      onChange={(value) => {
        setSearchTerm(value ?? '');
        onChange(value);
      }}
      data={data}
    />
  );
});
