import styles from './GeneralTabPanel.module.scss';

import { Box, Divider, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { Card } from '@work4all/components/lib/dataDisplay/card';

import { ArticleGroupPickerField } from '../../../../../../../components/entity-picker/ArticleGroupPickerField';
import { ArticleKindPickerField } from '../../../../../../../components/entity-picker/ArticleKindPickerField';
import { UnitPickerField } from '../../../../../../../components/entity-picker/UnitPickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { CheckboxRadioItem, LabeledInput } from '../../../../../locked-inputs';
import { ArticleMaskFormValue } from '../../types';

import { ArticleTextInputs } from './components/ArticleTextInputs';

const collapseClasses = {
  root: styles.collapseRoot,
  wrapperInner: styles.collapseWrapperInner,
};

export function GeneralTabPanel() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();
  const bomComponents = form.watch('bomComponents');

  return (
    <Box className={styles.panel}>
      <Stack
        direction="column"
        sx={{ width: '100%', height: '100%', py: '1rem' }}
      >
        <Collapse
          className={styles.collapse}
          classes={collapseClasses}
          title={t('COMMON.DESCRIPTION')}
          defaultOpen
          bottomIndent="lg"
        >
          <Card className={styles.card}>
            <Stack
              direction="column"
              spacing="1rem"
              sx={{ width: '100%', height: '100%' }}
            >
              <ArticleTextInputs />

              <Divider orientation="horizontal" />

              <Box>
                <Grid container spacing="1rem">
                  <Grid item xs={4}>
                    <ControllerPlus
                      control={form.control}
                      name="number"
                      render={({ field }) => {
                        const label = t('MASK.ARTICLE.FIELDS.number');
                        return <LabeledInput label={label} {...field} />;
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ControllerPlus
                      control={form.control}
                      name="unit"
                      render={({ field }) => {
                        const label = t('MASK.ARTICLE.FIELDS.unit');
                        return <UnitPickerField label={label} {...field} />;
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <LabeledInput
                      label={t('MASK.ARTICLE.FIELDS.factor')}
                      {...form.register('factor')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Card>
        </Collapse>
      </Stack>

      <Divider orientation="vertical" />

      <Stack
        direction="column"
        sx={{ width: '100%', height: '100%', py: '1rem' }}
      >
        <Collapse title={t('COMMON.TYPE')} defaultOpen bottomIndent="lg">
          <Box>
            <Grid container columns={2}>
              <Grid item xs={2}>
                <ControllerPlus
                  control={form.control}
                  name="articleKind"
                  render={({ field }) => {
                    const label = t('MASK.ARTICLE.FIELDS.articleKind');
                    return (
                      <ArticleKindPickerField
                        disabled={!!bomComponents?.length}
                        label={label}
                        clearable={false}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="isCharge"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.isCharge')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="isOutsideService"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.isOutsideService')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="isRentalArticle"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.isRentalArticle')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="serialNumberInbound"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.serialNumberInbound')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="isShutDown"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.isShutDown')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>

        <Collapse
          title={t('MASK.ORGANIZATION')}
          defaultOpen={true}
          bottomIndent="lg"
        >
          <Box>
            <Grid container columns={1} spacing="1rem">
              <Grid item xs={1}>
                <LabeledInput
                  disabled
                  label={t('MASK.ARTICLE.FIELDS.CATEGORIES')}
                  value=""
                />
              </Grid>

              <Grid item xs={1}>
                <LabeledInput
                  disabled
                  label={t('MASK.ARTICLE.FIELDS.ACCESSORY_CATEGORIES')}
                  value=""
                />
              </Grid>

              <Grid item xs={1}>
                <Divider orientation="horizontal" />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="articleGroup"
                  render={({ field }) => {
                    const label = t('MASK.ARTICLE.FIELDS.articleGroup');
                    return (
                      <ArticleGroupPickerField
                        label={label}
                        value={field.value ? [field.value] : null}
                        onChange={(groups) => {
                          if (groups && groups.length > 0) {
                            field.onChange(groups[0]);
                          } else {
                            field.onChange(null);
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Stack>
    </Box>
  );
}
