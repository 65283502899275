import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { PauseRule } from '@work4all/models/lib/Classes/PauseRule.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type PauseRulePickerProps = IPickerProps<PauseRule, false>;

export function PauseRulePicker(props: PauseRulePickerProps) {
  const { data, ...rest } = props;
  const renderItem = (item: PauseRule) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      multiple={false}
      entity={Entities.pauseRule}
      data={PAUSE_RULE_DATA}
      filterBy={['name']}
      sortBy="name"
      completeDataResponse
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const PAUSE_RULE_DATA: PauseRule = {
  id: null,
  name: null,
};
