import styles from './ResourceUtilizationInfoDialog.module.scss';

import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { Link } from '@work4all/data';

import { ResourceUtilizationInfo } from '@work4all/models/lib/Classes/ResourceUtilizationInfo.entity';

export const ResourceUtilizationInfoDialog = (props: {
  data: ResourceUtilizationInfo;
  open: boolean;
  onClose: (event, reason: 'backdropClick' | 'escapeKeyDown') => void;
}) => {
  const { data, open, onClose } = props;
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <Dialog
      title={DateTime.fromISO(data?.date).toFormat('DDD ')}
      open={open}
      onClose={onClose}
      classes={{
        dialog: {
          paper: styles.paper,
        },
      }}
    >
      {data.absenceList.length > 0 && (
        <div>
          <Box p="0 1rem">
            {' '}
            <Typography>{t('COMMON.ABSENT')}</Typography>
          </Box>
        </div>
      )}

      {data.appointmentList.length > 0 && (
        <>
          <Divider
            title={t('COMMON.APPOINTMENT', { count: 2 })}
            horizontalPadding
          />
          <List disablePadding>
            {data.appointmentList.map((appointment) => {
              return (
                <ListItem key={appointment.id}>
                  <Stack
                    direction="row"
                    gap="1rem"
                    width="100%"
                    overflow="hidden"
                    alignItems="center"
                  >
                    <Box flexGrow={1} overflow="hidden">
                      <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {appointment.title}
                      </Typography>
                    </Box>
                    {appointment.isWholeDay ? (
                      <Box>
                        <Typography color="var(--text03)">
                          {t('COMMON.WHOLEDAY')}
                        </Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography color="var(--text03)" whiteSpace="nowrap">
                          {DateTime.fromISO(appointment.startDate).toFormat(
                            'HH:mm'
                          )}{' '}
                          -{' '}
                          {DateTime.fromISO(appointment.endDate).toFormat(
                            'HH:mm'
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box>
                      <Link
                        to={`/more/entity/appointment/details/${appointment.id}`}
                        target="_blank"
                        style={{ width: '100%' }}
                      >
                        <IconButton size="small">
                          <OpenInNew fontSize="small" color="primary" />
                        </IconButton>
                      </Link>
                    </Box>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Dialog>
  );
};
