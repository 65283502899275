import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';

import { UseGroupingToolbarActions } from './types';

export const useGroupingToolbarActions: UseGroupingToolbarActions = () => {
  const { t } = useTranslation();
  const { tableState, tableInstance } = useTableStateBag();

  const isSomeRowsExpanded = useMemo(() => {
    return tableInstance?.rows?.some((row) => row.isExpanded);
  }, [tableInstance?.rows]);

  return useMemo(() => {
    if (!tableState || tableState?.groupBy?.length === 0) {
      return [];
    }

    return [
      {
        actionKey: 'expand-all-grouped-row',
        mode: 'Add',
        sections: ['toolbar'],
        IconComponent: KeyboardArrowDownIcon,
        tooltip: t('GROUPING_TOOLBAR_ACTIONS.EXPAND_ALL'),
        handler: () => {
          /*
           * We should pass `true` to force expand all closed rows,
           * if we didn't do that and there are some rows expanded
           * the behavior will be closing the expand rows not
           * expanding the closed ones.
           */
          tableInstance.toggleAllRowsExpanded(true);
        },
        disabled: tableInstance.isAllRowsExpanded,
      },
      {
        actionKey: 'close-all-grouped-row',
        mode: 'Add',
        sections: ['toolbar'],
        IconComponent: KeyboardArrowRightIcon,
        tooltip: t('GROUPING_TOOLBAR_ACTIONS.CLOSE_ALL'),
        handler: () => {
          /*
           * We should pass `false` to force close all expanded rows,
           * if we didn't do that and there are some rows closed
           * the behavior will be opening the closed rows not
           * closing the opened ones.
           */
          tableInstance.toggleAllRowsExpanded(false);
        },
        disabled: !isSomeRowsExpanded,
      },
    ];
  }, [isSomeRowsExpanded, t, tableInstance, tableState]);
};
