import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class UserDailyTarget<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: gueltigAb */
  validFrom?: string;
  /** Alias: tagessoll */
  dailytarget?: number;
  /** Alias: wochentag */
  weekday?: number;

  __typename?: string;
}

const fields: FieldDefinitions<UserDailyTarget> = {
  id: {
    alias: 'code',
  },
  userId: {
    alias: 'benutzerCode',
  },
  validFrom: {
    alias: 'gueltigAb',
  },
  dailytarget: {
    alias: 'tagessoll',
  },
  weekday: {
    alias: 'wochentag',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userDailyTargetEntityDefinition: EntitiyDefinition<UserDailyTarget> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BenutzerTagessoll',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
