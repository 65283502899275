import styles from './Attachments.module.scss';

import { gql, useMutation } from '@apollo/client';
import {
  CircularProgress,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as ChevronDownIcon } from '@work4all/assets/icons/chevron-down.svg';

import { useDataMutation, useUser } from '@work4all/data';
import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { IAttachmentEntity } from '@work4all/models';
import { ConvertTempFileResult } from '@work4all/models/lib/Classes/ConvertTempFileResult.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { InputDokumentRelation } from '@work4all/models/lib/Classes/InputDokumentRelation.entity';
import { TempFile } from '@work4all/models/lib/Classes/TempFile.entity';
import { ConvertTempfileTarget } from '@work4all/models/lib/Enums/ConvertTempfileTarget.enum';
import { CreateTempFileOriginType } from '@work4all/models/lib/Enums/CreateTempFileOriginType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';

import { ActionsBar } from '../../dataDisplay/actions-bar/ActionsBar';
import { useDialogs } from '../../dialog-manager';
import { useLock } from '../../hooks/object-lock/useLock';
import { ActionButton } from '../../input/action-button/ActionButton';
import { IStackItem } from '../../navigation/history-stack';
import { NavigationPopover } from '../../navigation/navigation-popover';
import { FileIcon } from '../file-icon/FileIcon';
import { ResizableArea } from '../ResizableArea/ResizableArea';
import { Table, TableLayout } from '../table';

import {
  FileIconCell,
  IBasicTableProps,
} from './../../dataDisplay/basic-table';
import { useRowSelection } from './../../dataDisplay/basic-table/hooks/useRowSelection';
import {
  CellEditHandler,
  EditModeTableInstance,
} from './../../dataDisplay/basic-table/plugins/useEditMode';
import { MIME_TYPES } from './../../preview/Preview';
import { Body1 } from './../../typography/body1/Body1';
import { DocumentPreview } from './../entity-preview/document-preview';
import { AttachmentsDropZone } from './AttachmentsDropZone';
import { mapAttachmentData } from './map-attachment-data';

const CREATE_TEMP_FILE = gql`
  mutation CreateTempFile(
    $type: CreateTempFileOriginType!
    $createTempFileId: String!
  ) {
    createTempFile(type: $type, id: $createTempFileId) {
      id
    }
  }
`;

const CONVERT_TEMP_FILE = gql`
  mutation ConvertTempFile(
    $tempFileIds: [ID]!
    $targetType: ConvertTempfileTarget!
    $targetId: ID
    $targetCode: Int
  ) {
    convertTempFile(
      tempFileIds: $tempFileIds
      targetType: $targetType
      targetId: $targetId
      targetCode: $targetCode
    ) {
      ok
      targetCode
      targetId
    }
  }
`;

type AllowedTempfileTargetToCreateEntityFrom = ConvertTempfileTarget.DOKUMENT;
const EntityByTempfileTarget: {
  [key in AllowedTempfileTargetToCreateEntityFrom]: Entities;
} = {
  [ConvertTempfileTarget.DOKUMENT]: Entities.document,
};

export interface AttachmentProps {
  layout?: 'list' | 'compact';
  disableAddAction?: boolean;
  disableEditAction?: boolean;
  disableRemoveAction?: boolean;
  disableDownloadAction?: boolean;
  convertTempfileTarget?: ConvertTempfileTarget;
  createTempFileOriginType?: CreateTempFileOriginType;
  convertFileAdditionalInfo?: Document;
  fullHeight?: boolean;
  dropZone?: boolean;
  tableLayout?: TableLayout;
  /**
   * If `layout` value is `compact` show a specific number of attachments, and hide the rest.
   */
  truncate?: {
    /**
     * Hide any attachment after the specific number.
     */
    after: number;
    /**
     * Define the behavior when clicking on the number of hiding attachments.
     */
    onMoreClick: () => void;
  };
  /**
   * If `true` the newest added attachments will be shown first.
   */
  newestAttachmentsFirst?: boolean;
  /**
   * Limit the attachments to be displayed
   */
  visibleAttachmentIds?: (string | number)[];
  disableConvertingAttachments?: boolean;
}

export const Attachments: React.FC<AttachmentProps> = (props) => {
  const {
    layout = 'list',
    disableAddAction = false,
    disableEditAction = false,
    disableRemoveAction = false,
    disableDownloadAction = false,
    convertTempfileTarget,
    createTempFileOriginType,
    convertFileAdditionalInfo,
    dropZone = true,
    tableLayout = 'table',
    truncate,
    newestAttachmentsFirst = true,
    visibleAttachmentIds,
    disableConvertingAttachments = false,
  } = props;

  const attachmentsContext = useContext(TempFileManagerContext);
  const {
    fileList: attachmentList,
    markFilesToDelete: markAttachmentsToDelete,
    markFilesToUpdate: markAttachmentToUpdate,
    currentUploadingFiles,
    temporaryFileUploads,
  } = attachmentsContext;

  const lock = useLock();
  const { t } = useTranslation();

  const { selectedRows, onSelectedRowsChange } = useRowSelection();
  const tableRef = useRef<TableInstance & EditModeTableInstance>(null);
  const [hidePreview, setHidePreview] = useState(false);

  const isUploadActionAllowed = !disableAddAction && !lock?.locked;
  const isEditActionAllowed =
    !disableEditAction &&
    attachmentList?.length > 0 &&
    !lock?.locked &&
    selectedRows?.length === 1;
  const isRemoveActionAllowed =
    !disableRemoveAction &&
    attachmentList?.length > 0 &&
    !lock?.locked &&
    selectedRows?.length > 0;
  const isDownloadActionAllowed =
    !disableDownloadAction &&
    attachmentList?.length > 0 &&
    selectedRows?.length > 0;

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const cardConfig: CardConfig = useMemo(
    () => ({
      icon: 'type',
      title: 'filename',
      content: null,
      secondaryIcons: [],
      virtualColumns: [],
      info: [
        {
          key: 'contactInfo1',
          title: 'COMMON.FILE_SIZE',
          columns: ['filesize'],
          defaultHidden: false,
        },
      ],
    }),
    []
  );

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: '',
        accessor: 'type',
        Cell: FileIconCell,
        width: 60,
        id: 'type',
      },
      {
        Header: t('COMMON.FILE_NAME'),
        accessor: 'filename',
        width: layout === 'compact' ? 200 : 300,
      },
    ];
    if (layout !== 'compact') {
      columns.push({
        Header: t('COMMON.FILE_SIZE'),
        accessor: 'filesize',
        width: 100,
        alignment: 'right',
      });
      columns.push({
        Header: t('COMMON.CREATOR'),
        accessor: 'userName',
        width: 200,
        alignment: 'right',
      });
      columns.push({
        Header: t('COMMON.DATE_AND_TIME'),
        accessor: 'date',
        width: 200,
        alignment: 'right',
      });
    }
    return columns;
  }, [t, layout]);

  const user = useUser();
  const displayData = useMemo(() => {
    if (attachmentList) {
      let data = [...attachmentList];

      if (visibleAttachmentIds) {
        data = data.filter((attachment) =>
          visibleAttachmentIds.includes(attachment.id?.toString())
        );
      }

      if (newestAttachmentsFirst) {
        data.sort(
          (a, b) =>
            DateTime.fromISO(b.date ?? b.lastModificationDate).toMillis() -
            DateTime.fromISO(a.date ?? a.lastModificationDate).toMillis()
        );
      }

      return data.map((item) =>
        mapAttachmentData({ ...item }, user.displayName)
      );
    } else {
      return [];
    }
  }, [
    attachmentList,
    newestAttachmentsFirst,
    user.displayName,
    visibleAttachmentIds,
  ]);

  const sortedAttachmentList = useMemo(() => {
    const data = [...attachmentList];
    if (newestAttachmentsFirst) {
      data.sort(
        (a, b) =>
          DateTime.fromISO(b.date ?? b.lastModificationDate).toMillis() -
          DateTime.fromISO(a.date ?? a.lastModificationDate).toMillis()
      );
    }
    return data;
  }, [attachmentList, newestAttachmentsFirst]);

  const onCellEdit = useCallback<CellEditHandler>(
    ({ cell, value }) => {
      const updateItem: IAttachmentEntity = {
        ...sortedAttachmentList[cell.row.index],
      };

      if (typeof value === 'string' && value.trim().length !== 0) {
        updateItem.fileName =
          value + '.' + updateItem.fileName.split('.')?.pop();
      }

      markAttachmentToUpdate(updateItem);
      attachmentList[cell.row.index] = updateItem;
      setHidePreview(false);
    },
    [attachmentList, markAttachmentToUpdate, sortedAttachmentList]
  );

  const onUploadAttachment = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      attachmentsContext.uploadFiles(Array.from(e.target.files));
    },
    [attachmentsContext]
  );

  const onEditAttachment = useCallback(() => {
    const table = tableRef.current;
    table.setEditModeConfig({
      row: selectedRows[0],
      columns: ['filename'],
      autoFocus: 'filename',
    });
  }, [selectedRows]);

  const dialogs = useDialogs();

  const onRemoveAttachment = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('DELETE.ATTACHMENT.CONFIRMATION.TITLE'),
      description: t('DELETE.ATTACHMENT.CONFIRMATION.DESCRIPTION'),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });
    if (confirmed) {
      markAttachmentsToDelete(selectedRows.map((attachmentId) => attachmentId));
    }
    tableRef.current.toggleAllRowsSelected(false);
  }, [dialogs, markAttachmentsToDelete, selectedRows, t]);

  const [downloadState, setDownloadState] = useState<Record<string, boolean>>(
    {}
  );

  const handleDeleteEmailAttachment = useCallback(
    async (ids: string) => {
      const confirmed = await dialogs.confirm({
        title: t('DELETE.ATTACHMENT.CONFIRMATION.TITLE'),
        description: t('DELETE.ATTACHMENT.CONFIRMATION.DESCRIPTION'),
        confirmLabel: t('ALERTS.BTN_DELETE'),
        cancelLabel: t('ALERTS.BTN_ABORT'),
      });
      if (confirmed) {
        markAttachmentsToDelete([ids]);
      }
      handleAttachmentMenuClose();
    },
    [dialogs, markAttachmentsToDelete, t]
  );

  const onDownloadAttachment = useCallback(
    async (ids?: string[]) => {
      try {
        const idObj = {};
        for (const id of ids) {
          idObj[id] = true;
        }
        setDownloadState({ ...downloadState, ...idObj });
        await attachmentsContext.downloadFiles(ids.length ? ids : selectedRows);
        for (const id of ids) {
          idObj[id] = false;
        }
        setDownloadState({ ...downloadState, ...idObj });
      } catch (err) {
        console.error('failed download ', err);
      }
    },
    [attachmentsContext, downloadState, selectedRows]
  );

  const progressbar = useMemo(() => {
    return (
      <div className={styles.progressbarContainer}>
        {currentUploadingFiles > 0 && <LinearProgress></LinearProgress>}
      </div>
    );
  }, [currentUploadingFiles]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [initialView, setInitialView] = useState<IStackItem | null>(null);
  const open = Boolean(anchorEl);

  const [createNewDocument] = useDataMutation<
    Document,
    EMode.upsert,
    InputDokumentRelation
  >({
    entity: Entities.document,
    mutationType: EMode.upsert,
    responseData: { id: null },
  });

  const [createTempFile] = useMutation<
    { createTempFile: TempFile },
    { createTempFileId: string; type: CreateTempFileOriginType }
  >(CREATE_TEMP_FILE);

  const [convertTempFile] = useMutation<
    { convertTempFile: ConvertTempFileResult },
    {
      tempFileIds: string[];
      targetType: ConvertTempfileTarget;
      targetId?: string;
      targetCode?: number;
    }
  >(CONVERT_TEMP_FILE);

  const tenant = useTenant();

  const { enqueueSnackbar } = useSnackbar();

  const createEntityFromAttachment = useCallback(
    async (id: string) => {
      setDownloadState({ ...downloadState, [id]: true });

      const entity = EntityByTempfileTarget[convertTempfileTarget] as Entities;
      const attachment = displayData.find(
        (x) => x.id.toString() === id.toString()
      );

      const tempFile = await createTempFile({
        variables: {
          type: createTempFileOriginType,
          createTempFileId: id,
        },
      });

      let createdEntityId;
      switch (entity) {
        case Entities.document: {
          const createdEntity = (await createNewDocument(
            {
              note: attachment.filename,
              ...convertFileAdditionalInfo,
            },
            {
              relations: {
                fileContent: {
                  tempFileId: tempFile.data.createTempFile.id,
                },
              },
            }
          )) as Document;

          createdEntityId = createdEntity?.id;
          break;
        }
        default:
          break;
      }

      if (createdEntityId) {
        const url = `/t-${tenant.activeTenant}/more/entity/${entity}/details/${createdEntityId}`;
        enqueueSnackbar(t(`ATTACHMENTS.${entity.toUpperCase()}_CONVERTED`), {
          action: () => (
            <Link href={url} target="_blank">
              {t('ATTACHMENTS.OPEN')}
            </Link>
          ),
          autoHideDuration: 6000,
        });
      }

      setDownloadState({ ...downloadState, [id]: false });
    },
    [
      convertFileAdditionalInfo,
      convertTempFile,
      convertTempfileTarget,
      createNewDocument,
      createTempFile,
      createTempFileOriginType,
      displayData,
      downloadState,
      enqueueSnackbar,
      t,
      tenant.activeTenant,
    ]
  );

  const handleAttachmentMenuClick = useCallback(
    (event, id: string | number) => {
      const isTemporaryFile = temporaryFileUploads.find(
        (file) => file.id === id
      );

      setInitialView({
        view: (
          <List>
            <ListItem className={styles.listItem}>
              <ListItemText
                onClick={() => {
                  setChosenAtt(attachmentList.find((x) => x.id === id));
                  handleAttachmentMenuClose();
                }}
                className={clsx(styles.itemText, {
                  [styles.disabled]: downloadState[id],
                })}
              >
                {t('ATTACHMENTS.OPEN')}
              </ListItemText>
            </ListItem>

            {!disableDownloadAction && (
              <ListItem className={styles.listItem}>
                <ListItemText
                  onClick={() => {
                    !downloadState[id] && onDownloadAttachment([id.toString()]);
                    handleAttachmentMenuClose();
                  }}
                  className={clsx(styles.itemText, {
                    [styles.disabled]: downloadState[id],
                  })}
                >
                  {t('INPUTS.DOWNLOAD')}
                </ListItemText>
              </ListItem>
            )}

            {!disableRemoveAction && (
              <ListItem
                onClick={() => handleDeleteEmailAttachment(id.toString())}
                className={styles.listItem}
              >
                <ListItemText className={styles.itemText}>
                  {t('ATTACHMENTS.REMOVE')}
                </ListItemText>
              </ListItem>
            )}

            {!disableConvertingAttachments &&
              convertTempfileTarget &&
              createTempFileOriginType &&
              !isTemporaryFile && (
                <ListItem
                  onClick={() => {
                    createEntityFromAttachment(id.toString());
                    handleAttachmentMenuClose();
                  }}
                  className={styles.listItem}
                >
                  <ListItemText className={styles.itemText}>
                    {t('MASK.CONVERT_TO_DOCUMENT')}
                  </ListItemText>
                </ListItem>
              )}
          </List>
        ),
      });
      setAnchorEl(event.currentTarget);
    },
    [
      downloadState,
      t,
      disableDownloadAction,
      disableRemoveAction,
      convertTempfileTarget,
      createTempFileOriginType,
      attachmentList,
      onDownloadAttachment,
      handleDeleteEmailAttachment,
      createEntityFromAttachment,
    ]
  );

  const handleAttachmentMenuClose = () => {
    setAnchorEl(null);
  };

  const [chosenAtt, setChosenAtt] = useState<IAttachmentEntity>(null);

  const compactView = useMemo(() => {
    const numberOfDisplayedAttachments = truncate?.after ?? displayData.length;
    const numberOfHiddenAttachments =
      displayData.length - numberOfDisplayedAttachments;

    return (
      <div className={styles.compactViewWrap}>
        <NavigationPopover
          anchorEl={anchorEl}
          onClose={handleAttachmentMenuClose}
          open={open}
          initialView={initialView}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        />

        <div className={styles.attachmentBadgeWrap}>
          {displayData.slice(0, numberOfDisplayedAttachments).map((att) => (
            <div key={att.id} className={styles.attachmentBadge}>
              <Tooltip title={att.filename}>
                <div
                  className={styles.content}
                  onClick={() => {
                    setChosenAtt(attachmentList.find((x) => x.id === att.id));
                  }}
                >
                  {downloadState[att.id] ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <FileIcon fileType={att.type} />
                  )}
                  <Body1
                    component="div"
                    className={clsx(styles.text, {
                      [styles.disabled]: downloadState[att.id],
                    })}
                  >
                    {att.filename}
                  </Body1>
                </div>
              </Tooltip>

              <div className={styles.dividerVert}></div>
              <ActionButton
                className={styles.attButton}
                icon={<ChevronDownIcon className={styles.chevronDown} />}
                onClick={(e) => handleAttachmentMenuClick(e, att.id)}
              />
            </div>
          ))}
        </div>

        {numberOfHiddenAttachments > 0 && (
          <Link
            component="button"
            variant="body2"
            onClick={truncate.onMoreClick}
            underline="none"
            className={styles.moreLink}
          >
            {t('COMMON.COUNT_MORE', { count: numberOfHiddenAttachments })}
          </Link>
        )}

        {chosenAtt !== null ? (
          <div style={{ position: 'absolute' }}>
            <DocumentPreview
              title={chosenAtt?.fileName}
              url={chosenAtt?.fileInfos?.previewUrl}
              mimeType={chosenAtt?.fileInfos?.previewMimeType as MIME_TYPES}
              noPreviewUrl={
                chosenAtt?.fileInfos?.fileServiceProviderInfos?.fspUrl ||
                chosenAtt?.fileInfos?.downloadUrl
              }
              downloadUrl={chosenAtt?.fileInfos?.downloadUrl}
              onCloseClick={() => {
                setChosenAtt(null);
              }}
              fspUrl={chosenAtt?.fileInfos?.fileServiceProviderInfos?.fspUrl}
              openInFullscreen={true}
              onEditClicked={isEditActionAllowed ? onEditAttachment : undefined}
              onDeleteClicked={
                isRemoveActionAllowed
                  ? () => {
                      markAttachmentsToDelete([chosenAtt.id.toString()]);
                    }
                  : undefined
              }
              selectedRowsIdsList={selectedRows}
              filePath={chosenAtt?.fileName}
            />
          </div>
        ) : null}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    anchorEl,
    attachmentList,
    chosenAtt,
    disableDownloadAction,
    disableRemoveAction,
    displayData,
    downloadState,
    handleAttachmentMenuClick,
    initialView,
    isEditActionAllowed,
    isRemoveActionAllowed,
    markAttachmentsToDelete,
    onEditAttachment,
    open,
    truncate,
  ]);

  const selectedAttachment = useMemo<IAttachmentEntity>(() => {
    if (selectedRows.length === 0) {
      return null;
    }
    return attachmentList.find((att) => att.id.toString() === selectedRows[0]);
  }, [selectedRows, attachmentList]);

  const [previewWidth, setPreviewWidth] = useState(500);

  const attachmentTable = useMemo(
    () => (
      <div className={styles.tableWrapper}>
        <div
          className={styles.tableContent}
          hidden={isViewportDownMd && selectedRows.length === 1}
        >
          <Table
            ref={tableRef}
            allItemsCount={displayData.length}
            columns={columns}
            cardConfig={cardConfig}
            cardsView={tableLayout === 'cards'}
            mode="client"
            data={displayData}
            onSelectedRowsChange={onSelectedRowsChange}
            onCellEdit={onCellEdit}
            resizableColumns={true}
            className={clsx(styles.attachmentTable)}
            onRowDoubleClick={(id) => onDownloadAttachment([id])}
          />
        </div>
        {selectedRows.length === 1 && (!isViewportDownMd || !hidePreview) && (
          <ResizableArea
            handles="left"
            minWidth={500}
            maxWidth={1000}
            size={{ width: previewWidth }}
            className={styles.previewWrapper}
            onResize={(size) => setPreviewWidth(size.width)}
          >
            <DocumentPreview
              title={selectedAttachment?.fileName}
              url={selectedAttachment?.fileInfos?.previewUrl}
              mimeType={
                selectedAttachment?.fileInfos?.previewMimeType as MIME_TYPES
              }
              noPreviewUrl={
                selectedAttachment?.fileInfos?.fileServiceProviderInfos
                  ?.fspUrl || selectedAttachment?.fileInfos?.downloadUrl
              }
              downloadUrl={selectedAttachment?.fileInfos?.downloadUrl}
              onCloseClick={() => {
                tableRef.current.toggleAllRowsSelected(false);
                setHidePreview(false);
              }}
              fspUrl={
                selectedAttachment?.fileInfos?.fileServiceProviderInfos?.fspUrl
              }
              openInFullscreen={isViewportDownMd}
              onEditClicked={
                isEditActionAllowed && !isViewportDownMd
                  ? () => {
                      onEditAttachment();
                      setHidePreview(true);
                    }
                  : undefined
              }
              onDeleteClicked={
                isRemoveActionAllowed
                  ? () => {
                      markAttachmentsToDelete([
                        selectedAttachment?.id.toString(),
                      ]);
                    }
                  : undefined
              }
              selectedRowsIdsList={selectedRows}
              filePath={selectedAttachment?.fileName}
            />
          </ResizableArea>
        )}
      </div>
    ),
    [
      isViewportDownMd,
      selectedRows,
      displayData,
      columns,
      cardConfig,
      tableLayout,
      onSelectedRowsChange,
      onCellEdit,
      hidePreview,
      selectedAttachment?.fileName,
      selectedAttachment?.fileInfos?.previewUrl,
      selectedAttachment?.fileInfos?.previewMimeType,
      selectedAttachment?.fileInfos?.fileServiceProviderInfos?.fspUrl,
      selectedAttachment?.fileInfos?.downloadUrl,
      selectedAttachment?.id,
      isEditActionAllowed,
      isRemoveActionAllowed,
      onDownloadAttachment,
      previewWidth,
      onEditAttachment,
      markAttachmentsToDelete,
    ]
  );

  return (
    <AttachmentsDropZone disableAddAction={!dropZone}>
      <div className={clsx({ [styles.attachments]: displayData.length })}>
        {layout === 'list' && (
          <div className={styles.actionBarWrapper}>
            <ActionsBar
              upload={isUploadActionAllowed && { onChange: onUploadAttachment }}
              edit={isEditActionAllowed && { onClick: onEditAttachment }}
              remove={isRemoveActionAllowed && { onClick: onRemoveAttachment }}
              hideMoreButton
              isGrouped={false}
              download={
                isDownloadActionAllowed && {
                  onClick: () => onDownloadAttachment(selectedRows),
                  disabled: false,
                }
              }
            />
          </div>
        )}
        {layout === 'compact' ? (
          compactView
        ) : (
          <>
            {progressbar}
            {attachmentTable}
          </>
        )}
      </div>
    </AttachmentsDropZone>
  );
};
