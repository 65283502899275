import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { EntityPreviewProps } from '@work4all/components/lib/components/entity-preview/types';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { SalesOpportunitiesPreview } from './SalesOpportunitiesPreview';

type SalesOpportunitiesContainerProps = EntityPreviewProps;
export const SalesOpportunitiesFields: SalesOpportunities<EMode.query> = {
  id: null,
  name: null,
  description: null,
  note: null,
  dateCreated: null,
  dateNextReview: null,
  decisionDate: null,
  groupId: null,
  probabilityPercent: null,
  creator: {
    id: null,
  },
  group: {
    id: null,
    name: null,
  },
  assignmentType: null,
  value: null,
  expectedCosts: null,
  orderValue: null,
  projectId: null,
  status: null,
  ratings: [
    {
      id: null,
      comment: null,
      value: null,
      status: null,
      ratingPercent: null,
      ratingDate: null,
    },
  ],
  ratingStatus: {
    id: null,
    name: null,
    ratingValue: null,
  },
  ratingStatusId: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        number: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        number: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  currency: {
    id: null,
    name: null,
  },
  kind: null,
};

export function SalesOpportunitiesContainer(
  props: SalesOpportunitiesContainerProps
) {
  const { ids, ...handlers } = props;
  const entries = useEntityPreviewData<SalesOpportunities>(
    ids,
    Entities.salesOpportunities,
    SalesOpportunitiesFields
  );

  if (!entries) {
    return null;
  }

  return <SalesOpportunitiesPreview {...handlers} entries={entries} />;
}
