import { MutableRefObject, useCallback } from 'react';
import { uuid } from 'short-uuid';

import { Article } from '@work4all/models/lib/Classes/Article.entity';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useMaskContext } from '../../../../../hooks/mask-context';
import { useEditableStateForm } from '../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state-form';
import { IEditTable } from '../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { BOMComponentPosition } from '../types';

const mapInitialize = (x: BOMComponentPosition) => ({
  ...x,
  prurchasePrice:
    x.article?.articlePrices?.purchasePriceList?.[0]?.purchasePrice,
});

interface UseBomStateProps {
  editTableRef?: MutableRefObject<IEditTable>;
}

export const useBomState = (props: UseBomStateProps) => {
  const { editTableRef } = props;
  const mapAddLocal = useCallback(
    (context: BOMComponentPosition): BOMComponentPosition => {
      setTimeout(() => {
        editTableRef?.current?.scrollTo();
      }, 100);
      return {
        name: context?.article?.longtext,
        ...context,
        id: -1 * Math.random(),
        localId: context.localId ?? uuid(),
        amount: 1,
      };
    },
    []
  );

  const editState = useEditableStateForm<
    BOMComponentPosition,
    PathsOf<Article, 1>
  >({
    mapAddLocal,
    // specify property to sync form
    property: 'bomComponents',
    mapInitialize,
  });

  const { id } = useMaskContext();
  const onAddPosition = useCallback(
    (context) => {
      if (!context.article) return editState.onAddPosition(context);
      if (`${context.article.id}` !== `${id}`)
        return editState.onAddPosition(context);
    },
    [editState.onAddPosition, id]
  );

  return { ...editState, onAddPosition };
};
