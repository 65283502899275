import React from 'react';
import { TableInstance } from 'react-table';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEmailActions } from '../../containers/mask-overlays/mask-overlay/views/email/EmailActions';

import { AttachmentCell } from './AttachmentCell';
import { EmailKindCell } from './EmailKindCell';
import { EmailStatusCell } from './EmailStatusCell';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/eMail-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export type IEmailKindCellConfig = {
  type: 'EmailKind';
};

export type IAttachmentCellConfig = {
  type: 'EmailAttachment';
};

export type IEmailStatusCellConfig = {
  type: 'EmailStatus';
};

type IEmailTableCustomColumns = {
  EmailKind?: IEmailKindCellConfig;
  Attachment?: IAttachmentCellConfig;
  EmailStatus?: IEmailStatusCellConfig;
};

export const EmailsTable = React.forwardRef<TableInstance, IEntityTable>(
  function EmailsTable(_props, ref) {
    const { canAdd } = usePermissions();

    const dataTable = useEntityDataTable<EMail, IEmailTableCustomColumns>({
      schema: schema as never,
      cells: {
        EmailKind: EmailKindCell,
        Attachment: AttachmentCell,
        EmailStatus: EmailStatusCell,
      },
      cellProcessors: {
        EmailStatus: (config) => {
          return [config.accessor, 'saveSendMailJob.errorMessages'];
        },
      },
      defaultSort,
    });

    useSyncEmailStatus({
      shouldSync({ id }) {
        const emails = dataTable.data as EMail[];
        const found = emails.find((email) => email.id === id);
        return found !== undefined;
      },
    });

    const { emailActionDefinitions } = useEmailActions({
      hasRight: canAdd({ entity: Entities.eMail }),
      outbound: dataTable.selectedEntity?.kind === 'AUSGEHEND' ? true : false,
    });

    useEntityEvents((event) => {
      if (event.entity === Entities.eMail) {
        dataTable.refetch();
      }
    });

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        actions={{
          custom: {
            left: emailActionDefinitions,
          },
        }}
      />
    );
  }
);
