import { DateTime } from 'luxon';

import { TextmarkContext } from '@work4all/components/lib/components/entity-picker/textmark-picker';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { participantToEmailContact } from '../../email/utils/participantToEmailContact';

export const mapAppointmentToEmailParams = (
  appointment: Appointment,
  translations: Parameters<typeof mapAppointmentEmailTextFieldCustom>['1']
) => {
  const participants = appointment.appointmentAttendeeList;
  return {
    receivers: JSON.stringify(
      participants.map(participantToEmailContact).filter((x) => x)
    ),
    processedMailTemplateArgs: JSON.stringify({
      noDirectContact: !appointment.contact,
      textFieldCustom: [
        {
          key: 'appointment' satisfies TextmarkContext,
          value: JSON.stringify(
            mapAppointmentEmailTextFieldCustom(appointment, translations)
          ),
        },
      ],
    }),
  };
};

export const mapAppointmentEmailTextFieldCustom = (
  appointment: Appointment,
  translations: {
    hourKey?: string;
    wholeDay?: string;
  } = {}
) => {
  const { hourKey, wholeDay } = translations;
  const start = DateTime.fromISO(appointment.startDate);
  const end = DateTime.fromISO(appointment.endDate);
  let date = appointment.isWholeDay
    ? start.toFormat('dd.MM.yyyy') + `, ${wholeDay}`
    : start.toFormat('dd.MM.yyyy, H:mm');

  if (!appointment.isWholeDay) {
    if (start.toFormat('dd.MM.yyyy') === end.toFormat('dd.MM.yyyy')) {
      date += ` - ${end.toFormat('H:mm')}`;
    } else if (start.isValid && end.isValid) {
      date += ` - ${end.toFormat('dd.MM.yyyy, H:mm')}`;
    }

    if (hourKey) date += ` ${hourKey}`;
  }

  let resources = '';
  let participants = '';
  let extern = '';
  if (appointment.appointmentAttendeeList) {
    const formatParticipant = (key: 'ressource' | 'user' | 'contact') =>
      appointment.appointmentAttendeeList
        .map((x) => x[key])
        .filter(Boolean)
        .map((x) => {
          if (x.displayName) return x.displayName;
          if ('loginName' in x) return x.loginName;
          return '';
        })
        .reduce((p, c) => (p ? `${p}, ${c}` : c), '');

    resources = formatParticipant('ressource');
    participants = formatParticipant('user');
    extern = formatParticipant('contact');
  }

  return {
    date,
    city: appointment.city,
    title: appointment.title,
    note: appointment.note,
    participants,
    extern,
    resources,
    contractNumber: appointment.contract?.contractNumber,
    deliveryNoteNumber: appointment.deliveryNote?.number,
  };
};
