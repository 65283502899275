import styles from './ChronoFileList.module.scss';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Row, TableInstance } from 'react-table';

import { useLocation, useNavigate, useWidgetsDataBag } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';

import { ChronoFileItem } from '@work4all/models/lib/Classes/ChronoFileItem.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';
import { entityTypeLookup } from '@work4all/models/lib/table-schema/entityTypeLookup';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { mutationEntityToViewEntity } from '@work4all/utils';

import { Table } from '../../../../../components/data-tables/table/Table';
import { SelectedItemContext } from '../card-widgets/selected-item-context';

import { IAggregatedList, useChronoFileList } from './hooks/useChronoFileList';

export interface ChronoFileListProps extends IAggregatedList {
  id?: string | number;
  type?: FileType;
  schema: ITableSchema<never>;
}

export const ChronoFileList = React.forwardRef<
  TableInstance,
  ChronoFileListProps
>(function ChronoFileList(props, ref) {
  const layout = 'table';

  const {
    cardConfig,
    userConfig,
    prepareRowDisplayModifiers,
    data,
    fetchMore,
    refetch,
    total,
    initialSortBy,
    pending,
    userConfigMethods,
  } = useChronoFileList({ layout, ...props });

  const { canEdit } = usePermissions();

  const { setItem } = useContext(SelectedItemContext);
  const { widgetsDefinitions } = useWidgetsDataBag();

  const location = useLocation();
  const navigate = useNavigate();

  const findEntity = useCallback(
    (id: string) => {
      const dataItem = data.find(
        (x) => x.primaryKey === parseInt(id) || x.primaryKey === id
      );
      if (!dataItem?.fileItem?.__typename) return;
      const mapping = Object.entries(entityTypeLookup).find(
        (x) => x[1] === dataItem.fileItem.__typename
      );
      return mapping;
    },
    [data]
  );

  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (!selected) return;
    const mapping = findEntity(selected);
    if (!mapping) return;
    if (!widgetsDefinitions?.definition) return;
    const widget = widgetsDefinitions.definition
      .flatMap((x) => x.widgets)
      .find((x) =>
        x.config.entities.some((x) => x.entityTypeName === mapping[1])
      );
    if (!widget) return;

    setItem({
      type: mapping[0] as Entities,
      id: Number(selected) || selected,
      widgetId: widget.id,
    });
  }, [selected, widgetsDefinitions, findEntity, setItem]);

  const editAction = useMemo(() => {
    return {
      handler: (id: string) => {
        /**
         * todo differentiate erp and erp_lightcase here too
         * 1. edit can be always clicked as the contextmenu is for the table not one row currently
         * 2. differentiate between double click and single click to open normal preview, or preview in fullscreenMode
         */
        const mapping = findEntity(id);
        const entityType = mutationEntityToViewEntity(mapping?.[0] as Entities);
        if (!entityType || !canEdit({ entity: entityType, record: null }))
          return;
        const href = `${location.pathname}/details/${entityType}/${id}`;
        navigate(href);
      },
      getHref: (id: string) => {
        const mapping = findEntity(id);
        const entityType = mutationEntityToViewEntity(mapping?.[0] as Entities);
        if (!entityType || !canEdit({ entity: entityType, record: null }))
          return;
        return `${location.pathname}/details/${mapping[0]}/${id}`;
      },
    };
  }, [findEntity, canEdit, location.pathname, navigate]);

  useEntityEvents(() => {
    refetch();
  });

  if (!userConfig) return null;

  return (
    <div className={styles.main}>
      <Table
        ref={ref}
        actions={{
          edit: editAction,
          resetColumns: userConfigMethods.remove,
        }}
        pending={pending}
        layout={layout}
        selectableMultiple={false}
        cardConfig={cardConfig}
        columnConfigs={userConfig}
        initialSortBy={initialSortBy}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        onSelectedRowsChange={(selected: Row<ChronoFileItem>[]) => {
          if (!(selected.length === 1)) return;
          setSelected(selected[0].id);
        }}
        data={data}
        total={total}
        noLockableEntity={true}
        hideToolbar={true}
        classes={{ root: styles.root }}
      />
    </div>
  );
});
