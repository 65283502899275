import { TextmarksList } from '../../types';

export const DOCUMENT_TEXTMARKS: TextmarksList = {
  letter: {
    id: 'TEXTMARK.LETTER',
    name: 'TEXTMARK.LETTER',
    subItems: [
      {
        id: 'TEXTMARK.LETTER_SUBJECT',
        name: 'TEXTMARK.LETTER_SUBJECT',
        accessor: 'Betreff',
      },
      {
        id: 'TEXTMARK.LETTER_NOTE',
        name: 'TEXTMARK.LETTER_NOTE',
        accessor: 'Notiz',
      },
      {
        id: 'TEXTMARK.LETTER_DATE_HEAD',
        name: 'TEXTMARK.LETTER_DATE',
        subItems: [
          {
            id: 'TEXTMARK.LETTER_DATE',
            name: 'TEXTMARK.LETTER_DATE',
            accessor: 'Datum',
          },
          {
            id: 'TEXTMARK.LETTER_DATE_LONG',
            name: 'TEXTMARK.LETTER_DATE_LONG',
            accessor: 'DatumLang',
          },
        ],
      },
      {
        id: 'TEXTMARK.LETTER_USER_SIGN',
        name: 'TEXTMARK.LETTER_USER_SIGN',
        accessor: 'Zeichen',
      },
    ],
  },
  project: {
    id: 'TEXTMARK.PROJECT',
    name: 'TEXTMARK.PROJECT',
    subItems: [
      {
        id: 'TEXTMARK.PROJECT_NAME',
        name: 'TEXTMARK.PROJECT_NAME',
        accessor: 'Projektname',
      },
      {
        id: 'TEXTMARK.PROJECT_NUMBER',
        name: 'TEXTMARK.PROJECT_NUMBER',
        accessor: 'Projektnummer',
      },
      {
        id: 'TEXTMARK.PROJECT_NOTE',
        name: 'TEXTMARK.PROJECT_NOTE',
        accessor: 'projektnotiz',
      },
      {
        id: 'TEXTMARK.PROJECT_RESPONSIBLE_EXTERNAL',
        name: 'TEXTMARK.PROJECT_RESPONSIBLE_EXTERNAL',
        accessor: 'ProjectResponsibleExtern',
      },
      {
        id: 'TEXTMARK.PROJECT_DATE',
        name: 'TEXTMARK.PROJECT_DATE',
        subItems: [
          {
            id: 'TEXTMARK.PROJECT_START_DATE_INNER',
            name: 'TEXTMARK.PROJECT_START_DATE_INNER',
            accessor: 'ProjektAnfangDatum',
          },
          {
            id: 'TEXTMARK.PROJECT_END_DATE_INNER',
            name: 'TEXTMARK.PROJECT_END_DATE_INNER',
            accessor: 'ProjektEndeDatum',
          },
          {
            id: 'TEXTMARK.PROJECT_START_DATE_OUTER',
            name: 'TEXTMARK.PROJECT_START_DATE_OUTER',
            accessor: 'ProjektDatum',
          },
          {
            id: 'TEXTMARK.PROJECT_END_DATE_OUTER',
            name: 'TEXTMARK.PROJECT_END_DATE_OUTER',
            accessor: 'ProjektBisDatum',
          },
          {
            id: 'TEXTMARK.PROJECT_START_DATE_INNER_YEAR',
            name: 'TEXTMARK.PROJECT_START_DATE_INNER_YEAR',
            accessor: 'ProjektAnfangDatumJahr',
          },
          {
            id: 'TEXTMARK.PROJECT_END_DATE_INNER_YEAR',
            name: 'TEXTMARK.PROJECT_END_DATE_INNER_YEAR',
            accessor: 'ProjektEndeDatumJahr',
          },
          {
            id: 'TEXTMARK.PROJECT_START_DATE_OUTER_YEAR',
            name: 'TEXTMARK.PROJECT_START_DATE_OUTER_YEAR',
            accessor: 'ProjektDatumJahr',
          },
          {
            id: 'TEXTMARK.PROJECT_END_DATE_OUTER_YEAR',
            name: 'TEXTMARK.PROJECT_END_DATE_OUTER_YEAR',
            accessor: 'ProjektBisDatumJahr',
          },
        ],
      },

      {
        id: 'TEXTMARK.PROJECT_LEADER_INTERNAL',
        name: 'TEXTMARK.PROJECT_LEADER_INTERNAL',
        accessor: 'ProjektLeiterIntern',
      },
      {
        id: 'TEXTMARK.PROJECT_LEADER_EXTERNAL',
        name: 'TEXTMARK.PROJECT_LEADER_EXTERNAL',
        accessor: 'ProjektLeiterExtern',
      },
      {
        id: 'TEXTMARK.PROJECT_RESPONSIBLE_INTERNAL',
        name: 'TEXTMARK.PROJECT_RESPONSIBLE_INTERNAL',
        accessor: 'ProjectResponsibleIntern',
      },
      {
        id: 'TEXTMARK.PROJECT_CUSTOMER',
        name: 'TEXTMARK.PROJECT_CUSTOMER',
        subItems: [
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_NAME',
            name: 'TEXTMARK.PROJECT_CUSTOMER_NAME',
            accessor: 'Projektkundenname',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_NUMBER',
            name: 'TEXTMARK.PROJECT_CUSTOMER_NUMBER',
            accessor: 'Projektkundennummer',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_CITY',
            name: 'TEXTMARK.PROJECT_CUSTOMER_CITY',
            accessor: 'Projektkundenort',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_STREET',
            name: 'TEXTMARK.PROJECT_CUSTOMER_STREET',
            accessor: 'projektkundenstraße',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_PLZ',
            name: 'TEXTMARK.PROJECT_CUSTOMER_PLZ',
            accessor: 'projektkundenplz',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX',
            name: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX',
            accessor: 'projektkundenpostfach',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX_CITY',
            name: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX_CITY',
            accessor: 'projektkundenpostfachort',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX_PLZ',
            name: 'TEXTMARK.PROJECT_CUSTOMER_POSTAL_BOX_PLZ',
            accessor: 'projektkundenpostfachplz',
          },
          {
            id: 'TEXTMARK.PROJECT_CUSTOMER_GROUP',
            name: 'TEXTMARK.PROJECT_CUSTOMER_GROUP',
            accessor: 'Projektkundengruppe',
          },
        ],
      },

      {
        id: 'TEXTMARK.PROJECT_COST_CENTER',
        name: 'TEXTMARK.PROJECT_COST_CENTER',
        subItems: [
          {
            id: 'TEXTMARK.PROJECT_COST_CENTER_NAME',
            name: 'TEXTMARK.PROJECT_COST_CENTER_NAME',
            accessor: 'Projektkostenstellenname',
          },
          {
            id: 'TEXTMARK.PROJECT_COST_CENTER_NUMBER',
            name: 'TEXTMARK.PROJECT_COST_CENTER_NUMBER',
            accessor: 'Projektkostenstellennummer',
          },
        ],
      },
    ],
  },
  businessPartner: {
    id: 'TEXTMARK.BUSINESSPARTNER',
    name: 'TEXTMARK.BUSINESSPARTNER',
    subItems: [
      {
        id: 'TEXTMARK.BUSINESSPARTNER_VAT_ID_NUMBER',
        name: 'TEXTMARK.BUSINESSPARTNER_VAT_ID_NUMBER',
        accessor: 'USTIdNr',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_NUMBER',
        name: 'TEXTMARK.BUSINESSPARTNER_NUMBER',
        accessor: 'Nummer',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_ADDRESS_HEAD',
        name: 'TEXTMARK.BUSINESSPARTNER_ADDRESS',
        subItems: [
          {
            id: 'TEXTMARK.BUSINESSPARTNER_STREET',
            name: 'TEXTMARK.BUSINESSPARTNER_STREET',
            accessor: 'Straße',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_1',
            name: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_1',
            accessor: 'Firma_Zeile1',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_2',
            name: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_2',
            accessor: 'Firma_Zeile2',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_3',
            name: 'TEXTMARK.BUSINESSPARTNER_NAME_ROW_3',
            accessor: 'Firma_Zeile3',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_ADDRESS',
            name: 'TEXTMARK.BUSINESSPARTNER_ADDRESS',
            accessor: 'Adresse',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_COUNTRY_SHORT',
            name: 'TEXTMARK.BUSINESSPARTNER_COUNTRY_SHORT',
            accessor: 'Staat',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_ADDRESS_WITHOUT_COUNTRY_AP',
            name: 'TEXTMARK.BUSINESSPARTNER_ADDRESS_WITHOUT_COUNTRY_AP',
            accessor: 'AdresseohneLandohneAnsprechpartner',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_ADDRESS_WITHOUT_COUNTRY',
            name: 'TEXTMARK.BUSINESSPARTNER_ADDRESS_WITHOUT_COUNTRY',
            accessor: 'AdresseAdresseohneLandohneLandohneAnsprechpartner',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CITY',
            name: 'TEXTMARK.BUSINESSPARTNER_CITY',
            accessor: 'Ort',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PLZ',
            name: 'TEXTMARK.BUSINESSPARTNER_PLZ',
            accessor: 'PLZ',
          },
        ],
      },

      {
        id: 'TEXTMARK.BUSINESSPARTNER_COMMUNICATION',
        name: 'TEXTMARK.BUSINESSPARTNER_COMMUNICATION',
        subItems: [
          {
            id: 'TEXTMARK.BUSINESSPARTNER_FAX',
            name: 'TEXTMARK.BUSINESSPARTNER_FAX',
            accessor: 'Telefax',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PHONE',
            name: 'TEXTMARK.BUSINESSPARTNER_PHONE',
            accessor: 'Telefon',
          },
        ],
      },

      {
        id: 'TEXTMARK.BUSINESSPARTNER_NOTE',
        name: 'TEXTMARK.BUSINESSPARTNER_NOTE',
        accessor: 'KundenNotiz',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_NAME',
        name: 'TEXTMARK.BUSINESSPARTNER_NAME',
        accessor: 'Kurzname',
      },

      {
        id: 'TEXTMARK.BUSINESSPARTNER_FIRST_CONTACT',
        name: 'TEXTMARK.BUSINESSPARTNER_FIRST_CONTACT',
        accessor: 'Erstkontakt',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_LAST_CONTACT',
        name: 'TEXTMARK.BUSINESSPARTNER_LAST_CONTACT',
        accessor: 'Letztkontakt',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_GROUP',
        name: 'TEXTMARK.BUSINESSPARTNER_GROUP',
        accessor: 'Gruppe',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_BANK',
        name: 'TEXTMARK.BUSINESSPARTNER_BANK',
        subItems: [
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_BIC',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_BIC',
            accessor: 'Bankverbindung_BIC',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_BLZ',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_BLZ',
            accessor: 'Bankverbindung_BLZ',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_IBAN',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_IBAN',
            accessor: 'Bankverbindung_IBAN',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_OWNER',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_OWNER',
            accessor: 'Bankverbindung_Kontoinhaber',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_ACC_NUMBER',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_ACC_NUMBER',
            accessor: 'Bankverbindung_Kto',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_BANK_NAME',
            name: 'TEXTMARK.BUSINESSPARTNER_BANK_NAME',
            accessor: 'Bankverbindung_Bank',
          },
        ],
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON',
        name: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON',
        subItems: [
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION',
            name: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION',
            accessor: 'Anrede',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION_LOWER_CASE',
            name: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION_LOWER_CASE',
            accessor: 'Anrede_Kleinbuchstabe',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION_NO_COMMA',
            name: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_SALUTATION_NO_COMMA',
            accessor: 'Anrede_OhneKomma',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_EMAIL',
            name: 'TEXTMARK.BUSINESSPARTNER_PRIVATE_PERSON_EMAIL',
            accessor: 'Email',
          },
        ],
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON',
        name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON',
        subItems: [
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION',
            accessor: 'Anrede',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION_LOWER_CASE',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION_LOWER_CASE',
            accessor: 'Anrede_Kleinbuchstabe',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION_NO_COMMA',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_SALUTATION_NO_COMMA',
            accessor: 'Anrede_OhneKomma',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE',
            accessor: 'AnsprechpartnerTelefon',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_FAX',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_FAX',
            accessor: 'AnsprechpartnerTelefax',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE_EXTENSION',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE_EXTENSION',
            accessor: 'AnsprechpartnerDurchwahl',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_NOTE',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_NOTE',
            accessor: 'AnsprechpartnerNotiz',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE_MOBILE',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_PHONE_MOBILE',
            accessor: 'AnsprechPartnerMobil',
          },
          {
            id: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_EMAIL',
            name: 'TEXTMARK.BUSINESSPARTNER_CONTACT_PERSON_EMAIL',
            accessor: 'AnsprechPartnerEmail',
          },
        ],
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_EMAIL',
        name: 'TEXTMARK.BUSINESSPARTNER_EMAIL',
        accessor: 'Email',
      },
      {
        id: 'TEXTMARK.BUSINESSPARTNER_WEBSITE',
        name: 'TEXTMARK.BUSINESSPARTNER_WEBSITE',
        accessor: 'Internet',
      },
    ],
  },
  editor: {
    id: 'TEXTMARK.EDITOR',
    name: 'TEXTMARK.EDITOR',
    subItems: [
      {
        id: 'TEXTMARK.EDITOR_NAME',
        name: 'TEXTMARK.EDITOR_NAME',
        accessor: 'Schreibender',
      },
      {
        id: 'TEXTMARK.EDITOR_ROLE',
        name: 'TEXTMARK.EDITOR_ROLE',
        accessor: 'FunktionSchreibender',
      },
      {
        id: 'TEXTMARK.EDITOR_DEPARTMENT',
        name: 'TEXTMARK.EDITOR_DEPARTMENT',
        accessor: 'VertreterAbteilung',
      },
    ],
  },
  user: {
    id: 'TEXTMARK.USER',
    name: 'TEXTMARK.USER',
    subItems: [
      {
        id: 'TEXTMARK.USER_NAME',
        name: 'TEXTMARK.USER_NAME',
        accessor: 'BenutzerLang',
      },
      {
        id: 'TEXTMARK.USER_SIGN',
        name: 'TEXTMARK.USER_SIGN',
        accessor: 'UnserZeichen',
      },
      {
        id: 'TEXTMARK.USER_DEPARTMENT',
        name: 'TEXTMARK.USER_DEPARTMENT',
        accessor: 'MyAbteilung',
      },
      {
        id: 'TEXTMARK.USER_EMAIL',
        name: 'TEXTMARK.USER_EMAIL',
        accessor: 'MyEmail',
      },
      {
        id: 'TEXTMARK.USER_ROLE',
        name: 'TEXTMARK.USER_ROLE',
        accessor: 'MyFunktion',
      },
      {
        id: 'TEXTMARK.USER_TITLE',
        name: 'TEXTMARK.USER_TITLE',
        accessor: 'MyTitel',
      },
      {
        id: 'TEXTMARK.USER_FAX',
        name: 'TEXTMARK.USER_FAX',
        accessor: 'MyTelefax',
      },
      {
        id: 'TEXTMARK.USER_PHONE',
        name: 'TEXTMARK.USER_PHONE',
        accessor: 'MyTelefon',
      },
      {
        id: 'TEXTMARK.USER_MOBILE_PHONE',
        name: 'TEXTMARK.USER_MOBILE_PHONE',
        accessor: 'MyMobileNumber',
      },
    ],
  },
};
