import {
  PreviewPickerInput,
  withExtras,
} from '@work4all/components/lib/components/entity-preview/components/preview-input';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { SalesOpportunitiesEvaluationPicker } from './sales-opportunities-evaluation-picker/SalesOpportunitiesEvaluationPicker';

export const SalesOpportunitiesEvaluationPreviewPickerInput =
  withExtras<SalesOpportunities>(({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<SalesOpportunities>
        {...rest}
        value={value}
        formattedValue={`${value?.probabilityPercent ?? 100} %`}
        picker={
          <SalesOpportunitiesEvaluationPicker
            salesOpportunity={value}
            value={value.probabilityPercent}
            multiple={false}
            onChange={(value) => {
              if (value) onChange(value);
            }}
          />
        }
        entity={Entities.salesOpportunities}
      />
    );
  });
