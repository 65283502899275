import { entityDefinition } from '../Classes/entityDefinitions';
import type { EntitiyDefinition } from '../DataProvider';
import { Entities } from '../Enums/Entities.enum';

export type EntityRelations = {
  byId: Record<string, string>;
  byObject: Record<string, string>;
};

// The user field is required in many entities. But this field is not present in
// most masks, and it is configured manually in code (but only userId). So
// making the user required just creates a bunch of errors.

// But in other masks the user field does exist, and it would be nice to
// actually validate it.

// We should either update the masks and add this field where it's missing, or
// add exceptions based on entity type to only ignore it in specific masks. For
// now, just ignore it everywhere to keep the current behavior unchanged.
//
// TODO https://work4all.atlassian.net/browse/WW-5126
const IGNORED_FIELD_TYPES = new Set([Entities.user]);

function inferRelationsFromEntityDefinition(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  definition: EntitiyDefinition<any>
): EntityRelations {
  const fieldDefinitions = definition.fieldDefinitions;
  const relations: EntityRelations = { byId: {}, byObject: {} };

  for (const property in fieldDefinitions) {
    const definition = fieldDefinitions[property];

    const fieldTypes = Array.isArray(definition.entity)
      ? definition.entity
      : [definition.entity];

    if (fieldTypes.some((entityType) => IGNORED_FIELD_TYPES.has(entityType))) {
      continue;
    }

    const idProperty = `${property}Id`;
    if (idProperty in fieldDefinitions) {
      relations.byId[idProperty] = property;
      relations.byObject[property] = idProperty;
    }
  }

  return relations;
}

export function inferRelations(entityType: Entities): EntityRelations {
  const definition = entityDefinition[entityType];

  if (!definition) {
    return null;
  }

  return inferRelationsFromEntityDefinition(definition);
}
