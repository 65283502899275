import { useMemo } from 'react';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { SalesOpportunitiesFields } from '../../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-preview/SalesOpportunitiesContainer';
import { CUSTOM_FIELDS_DATA } from '../../../components/custom-fields/custom-fields-data';

export const useSalesOpportunitiesRequestData = (id: string | number) => {
  return useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: id } }];
    const entity = Entities.salesOpportunities;
    const data: SalesOpportunities<EMode.query> = {
      ...SalesOpportunitiesFields,
      status: null,
      customFieldList: [CUSTOM_FIELDS_DATA],
      competitors: null,
      creatorUserId: null,
      user: {
        id: null,
        displayName: null,
      },
      user2: {
        id: null,
        displayName: null,
      },
      userId: null,
      user2Id: null,
      contactId: null,
      attachementList: [
        {
          id: null,
          fileKey: null,
          fileDisplayname: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          salesOpportunityCode: null,
          insertTime: null,
          updateTime: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
    };
    return { filter, entity, data };
  }, [id]);
};
