import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { escapeCharactersRegex } from './utils';
import { Section, SectionChangeEvent, WithSectionProps } from './withSection';

interface MaskState {
  step: number;
  index: number;
  value: string;
}

function standardSplit(value: string, index: number) {
  return {
    value,
    index,
    step: 0,
  };
}
function replaceAt(input: string, index: number, replacement: string) {
  return (
    input.substring(0, index) +
    replacement +
    input.substring(index + replacement.length)
  );
}

export interface WithDateTimeMaskProps extends WithSectionProps {
  isValid?: (input: string) => boolean;
}

export function withDateTimeMask<T extends WithDateTimeMaskProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const Component = forwardRef<unknown, T>((props: T, ref) => {
    const { mask, splitter, modify } = props;
    const separator = useMemo(
      () => escapeCharactersRegex(splitter),
      [splitter]
    );

    const stringToMask = useCallback(
      (input: string): MaskState[] => {
        return input
          .split(separator)
          .filter((x) => x)
          .map(standardSplit);
      },
      [separator]
    );

    const [maskState, setMaskState] = useState<MaskState[]>(
      props.value ? stringToMask(props.value.toString()) : []
    );
    const [section, setSection] = useState<{ value: number }>();

    const notValidKey = mask?.[0];

    const localIsValid = () => true;
    const isValid = props.isValid || localIsValid;

    function stateToValue(state: MaskState[]) {
      const value = state.map((section, id) =>
        section.value.includes(notValidKey)
          ? section.value
          : modify(section.value, id, 'none')
      );

      return value.join(splitter);
    }

    const handleChange = (event: SectionChangeEvent) => {
      let newMaskState = stringToMask(event.target.value);
      let dirty = false;

      if (event?.section?.event === 'digit') {
        const copy = [...maskState];
        const currentSection = copy[event.section.index];
        const originalMaskState = stringToMask(mask);
        const maskSection = originalMaskState[event.section.index];
        // TODO: if value > max use max - is it needed?
        if (maskSection.value.length === 2) {
          if (currentSection.step === 0) {
            currentSection.value = `0${event.section.originalValue}`;
          } else {
            currentSection.value = modify(
              `${currentSection.value[1]}${event.section.originalValue}`,
              event.section.index,
              'none'
            );
          }
          dirty = currentSection.step !== maskSection.value.length - 1;
          currentSection.step =
            (currentSection.step + 1) % maskSection.value.length;
        } else if (maskSection.value.length === 4) {
          if (
            currentSection.step === maskSection.value.length ||
            isValid(currentSection.value)
          ) {
            currentSection.value = replaceAt(
              maskSection.value,
              0,
              event.section.originalValue
            );
            currentSection.step = 1;
          } else {
            currentSection.value = replaceAt(
              currentSection.value,
              currentSection.step,
              event.section.originalValue
            );
            currentSection.step++;
          }
          dirty = currentSection.step !== maskSection.value.length;
        }

        if (currentSection.index !== originalMaskState.length - 1 && !dirty) {
          setSection({ value: event.section.index + 1 });
        }
        newMaskState = copy;
        event.target.value = stateToValue(newMaskState);
      }

      if (isValid(event.target.value) && !dirty) {
        props.onChange?.(event);
      } else {
        setMaskState(newMaskState);
      }
    };

    useEffect(() => {
      if (typeof props.value !== 'string')
        throw new Error('This hook pass only string values');
      const newMaskState = stringToMask(props.value);
      setMaskState(newMaskState);
    }, [props.value, stringToMask]);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      props.onFocus?.(event);
      if (!maskState.length) {
        setMaskState(stringToMask(mask));
      }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(event);
      if (!isValid(event.target.value)) {
        if (typeof props.value !== 'string')
          throw new Error('This hook pass only string values');
        const newMaskState = stringToMask(props.value);
        setMaskState(newMaskState);
      }
    };

    const handleSectionChange = (section: Section) => {
      if (section.event === 'remove') {
        const newMaskState = [...maskState];
        const maskSection = newMaskState[section.index];
        maskSection.value = stringToMask(mask)[section.index].value;
        maskSection.step = 0;
        setMaskState(newMaskState);
      } else {
        const newMaskState = [...maskState];
        newMaskState
          .filter((x) => x.value.length === 2)
          .forEach((x) => (x.step = 0));
        setMaskState(newMaskState);
      }
    };

    return (
      <WrappedComponent
        ref={ref}
        {...(props as T)}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onSectionChange={handleSectionChange}
        section={section}
        value={stateToValue(maskState)}
      />
    );
  });

  Component.displayName = `withDateTimeMask(${displayName})`;
  return Component;
}
