import { DateTime } from 'luxon';

export type IHoursCellProps = {
  value: string;
};

export const HoursCell = (props: IHoursCellProps) => {
  const { value } = props;

  return value
    ? DateTime.fromISO(value).toLocaleString(DateTime.TIME_SIMPLE)
    : '';
};
