import styles from './Organization.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CategoryClassPicker } from '@work4all/components/lib/components/entity-picker/category-class-picker/CategoryClassPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { DepartmentPickerField } from '../../../../../../../../../../components/entity-picker/DepartmentPickerField';
import { EntitySuggestionPickerField } from '../../../../../../../../../../components/entity-picker/EntitySuggestionPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  Chip,
  LabeledInput,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ContactMaskFormValue } from '../../../../../types';

const collapseClasses = {
  container: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Organization: React.FC = () => {
  const { register, control, watch } =
    useFormContextPlus<ContactMaskFormValue>();

  const bpType = watch('businessPartnerType');

  const mask = useMaskContext();

  const { t } = useTranslation();

  const categoryFieldRef = useRef<HTMLDivElement>();
  const mobile = useMediaQuery<Theme>('(max-width:960px)');

  return (
    <Collapse
      className={styles.collapse}
      classes={collapseClasses}
      defaultOpen={!mobile}
      title={t('MASK.ORGANIZATION')}
    >
      <ControlWrapper className={styles.mobileVert}>
        <ControllerPlus
          name={'role'}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <EntitySuggestionPickerField
                label={t('INPUTS.FUNCTION')}
                {...field}
                error={fieldState.error?.message}
                entity={Entities.contact}
                field="role"
              />
            );
          }}
        />

        <ControllerPlus
          name={'department'}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <DepartmentPickerField
                ofType={mask.template?.entity as Entities}
                ofId={mask.template?.id.toString()}
                {...field}
                error={fieldState.error?.message}
              />
            );
          }}
        />
      </ControlWrapper>

      <ControlWrapper ref={categoryFieldRef}>
        <ControllerPlus
          name="categoryAssignmentList"
          control={control}
          render={({ field }) => {
            const value = Array.isArray(field.value) ? field.value : [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let prefilter: any = [
              { hide: { $eq: false } },
              { isPersonCategory: { $eq: true } },
            ];
            if (bpType === SdObjType.KUNDE) {
              prefilter = prefilter.concat([
                { isCustomerCategory: { $eq: true } },
              ]);
            } else {
              prefilter = prefilter.concat([
                { isSupplierCategory: { $eq: true } },
              ]);
            }
            return (
              <EntityPickerPopover
                anchorEl={categoryFieldRef}
                disabled={!bpType}
                picker={
                  <CategoryClassPicker
                    filterToContactCategories={true}
                    multiple
                    value={value.map((categoryAssignment) => ({
                      id: categoryAssignment?.categoryId,
                    }))}
                    prefilter={prefilter}
                    onChange={(value) => {
                      const newValue: CategoryAssignment[] = value?.map(
                        (category) => {
                          return {
                            categoryId: category.id,
                            categoryName: category.name,
                            categoryKindName: category?.categoryClass?.name,
                          };
                        }
                      );

                      field.onChange(newValue);
                    }}
                  />
                }
              >
                <PickerTargetButton
                  onClear={null}
                  value={
                    value.length > 0 ? (
                      <ChipList>
                        {value.map((categoryAssignment, idx) => {
                          return (
                            <Chip
                              key={idx}
                              maxWidth={18}
                              label={`${categoryAssignment.categoryKindName} / ${categoryAssignment.categoryName}`}
                              handleDelete={() => {
                                const newValue = field?.value?.filter(
                                  (x) =>
                                    x.categoryId !==
                                    categoryAssignment.categoryId
                                );
                                field.onChange(newValue);
                              }}
                            />
                          );
                        })}
                      </ChipList>
                    ) : (
                      ''
                    )
                  }
                  label={t('INPUTS.CATEGORIES')}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <LabeledInput
          label={t('INPUTS.NOTE')}
          {...register('note')}
          multiline={true}
        />
      </ControlWrapper>
    </Collapse>
  );
};
