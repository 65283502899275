import { IdArray } from '../types';

export function getIdsIncomingKeys<T>(
  positions: { id?: T; localId?: string }[],
  array: IdArray
): T[] {
  const firstElement = array?.[0];
  if (typeof firstElement === 'string') {
    const localArray = array as string[];
    return positions
      .filter(
        (p) =>
          localArray.includes(p.localId) || localArray.includes(p.id as string)
      )
      .map((x) => x.id);
  }

  // IdArray can be either number[] or string[] so if one element is correct all are correct.
  return array as T[];
}
