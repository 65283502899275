import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditModeTableInstance } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { ArticlePickerField } from '../../../../../../../../../../components/entity-picker/ArticlePickerField';
import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api';
import { ARTICLE_POSITIONS_DATA } from '../constants';

export interface ArticlePickerCellProps {
  cell: {
    row: {
      index: number;
    };
  };
  isEditMode: boolean;
  onAddPosition?: (props: ShadowObjectAddPositionArgs) => void;
  tableInstance: EditModeTableInstance;
  data?: Article;
  label?: string;
}

export const ArticlePickerCell = (props: ArticlePickerCellProps) => {
  const { cell, isEditMode, tableInstance, onAddPosition, data, label } = props;

  const { t } = useTranslation();
  const dataResult = useDeepMemo(
    () => ({ ...ARTICLE_POSITIONS_DATA, ...data }),
    [data]
  );
  return (
    <ArticlePickerField
      value={undefined}
      data={dataResult}
      showFunctions
      autoFocus={isEditMode && !!tableInstance?.state?.editModeConfig}
      label={label ?? t('ERP.ADD_POSITION')}
      input={
        <Typography variant="body2" color="var(--text03)" flex="1">
          {label ?? t('ERP.ADD_POSITION')}
        </Typography>
      }
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (value === ErpPositionsKind.STANDARD) {
          onAddPosition({
            positionType: value as ErpPositionsKind,
            index: cell.row.index,
            article: {
              id: 0,
            },
          });
        } else if ((value as ErpPositionsKind) in ErpPositionsKind) {
          onAddPosition({
            positionType: value as ErpPositionsKind,
            index: cell.row.index,
          });
        } else {
          const article = value as Article;
          onAddPosition({
            positionType: ErpPositionsKind.STANDARD,
            article: {
              ...article,
              id: article.id || 0,
            } as Article,
            defaultText: article.id ? undefined : article?.name,
            index: cell.row.index,
          });
        }
      }}
    />
  );
};
