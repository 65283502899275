import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const FileInfoFragment: FileEntity<EMode.query> = {
  previewUrl: null,
  downloadUrl: null,
  fileRequestType: null,
  previewMimeType: null,
  downloadMimeType: null,
  fileServiceProviderInfos: {
    id: null,
    exists: null,
    fileName: null,
    mimeType: null,
    fspUrl: null,
  },

  // As the FileInfo type doesn't have an ID field, we need to also request
  // properties that are likely to be fetched by other components (the table)
  // so the Apollo cache is not invalidated.
  fileEntityFilename: null,
};
