import i18next from 'i18next';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { DefaultErpText } from '@work4all/models/lib/Classes/DefaultErpText.entity';
import { DefaultErpTextType } from '@work4all/models/lib/Enums/DefaultErpTextType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useMaskContext } from '../../../../../hooks/mask-context';

const getInitialValues = (entity: Entities) => {
  let internalTabs: { label: string; value: string }[] = [];
  if (entity === Entities.customer)
    internalTabs = [
      {
        label: i18next.t('COMMON.OFFER'),
        value: 'OFFER',
      },
      {
        label: i18next.t('COMMON.DELIVERYNOTE'),
        value: 'DELIVERY_NOTE',
      },
      {
        label: i18next.t('COMMON.INVOICE'),
        value: 'INVOICE',
      },
      {
        label: i18next.t('COMMON.CONTRACT'),
        value: 'ORDER',
      },
    ];
  else
    internalTabs = [
      {
        label: i18next.t('COMMON.DELIVERYNOTE'),
        value: 'DELIVERY_NOTE',
      },
      {
        label: i18next.t('COMMON.ORDER'),
        value: 'BOOKING',
      },
      {
        label: i18next.t('COMMON.DEMAND'),
        value: 'DEMAND',
      },
    ];

  return orderBy(internalTabs, 'label');
};

export const useTextTabPanel = () => {
  const mask = useMaskContext();

  const tabs = useMemo(() => getInitialValues(mask.entity), [mask.entity]);

  const [tab, setTab] = useState<string>(tabs[0].value);

  const handleTabChange = (_: unknown, tab: string) => {
    setTab(tab);
  };

  return { tab, tabs, handleTabChange };
};

interface UseEnsureErpTextsInitializedOptions {
  isDataFetched: boolean;
  defaultErpTexts?: DefaultErpText[];
  setValue: (erp: DefaultErpText[]) => void;
  entity: Entities;
}

export const getInitialErpTexts = (entity: Entities): DefaultErpText[] => {
  const initalValues = getInitialValues(entity);

  const mapToEntity = (type: string): DefaultErpText[] =>
    initalValues.map((x) => ({
      textType: `${x.value}_${type}` as DefaultErpTextType,
    }));

  return [...mapToEntity('CLOSING'), ...mapToEntity('HEADER')];
};

export const useEnsureErpTextsInitialized = (
  options: UseEnsureErpTextsInitializedOptions
) => {
  const { isDataFetched, defaultErpTexts, setValue, entity } = options;

  useEffect(() => {
    if (isDataFetched && defaultErpTexts) {
      const initialDefaultErpTexts: DefaultErpText[] =
        getInitialErpTexts(entity);

      let needUpdate = false;
      const combined = initialDefaultErpTexts.map((initial) => {
        const exist = defaultErpTexts.find(
          (y) => y.textType === initial.textType
        );
        if (exist) return exist;
        needUpdate = true;
        return initial;
      });

      if (needUpdate) {
        setValue(combined);
      }
    }
  }, [isDataFetched, defaultErpTexts, setValue, entity]);
};
