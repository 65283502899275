import { useEffect, useRef } from 'react';

import { ColumnInstance, useTableStateBag } from '@work4all/components';

import { useSetting } from '@work4all/data/lib/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings } from '../../../../settings';

export const useLastFilter = (entity: Entities) => {
  const tableStateBag = useTableStateBag();
  const defaultFilterApplied = useRef(false);

  const settingValue = useSetting(settings.lastFilter(entity));

  useEffect(() => {
    if (!settingValue?.value || defaultFilterApplied.current) return;

    setTimeout(() => {
      if (!tableStateBag.tableInstance) return;
      defaultFilterApplied.current = true;
      settingValue.value.forEach((filter) => {
        const col = tableStateBag.tableInstance.allColumns.find(
          (x) => x.id === filter.id
        ) as ColumnInstance;
        col?.setFilter(filter.value);
      });
    }, 0);
  }, [tableStateBag.tableInstance, settingValue]);
};
