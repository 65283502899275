import { PermissionsConfigsByEntity } from '../types';

import { CRM_PERMISSIONS_CONFIGS } from './crm-permissions-configs';
import { DMS_PERMISSIONS_CONFIGS } from './dms-permissions-configs';
import { ERP_PERMISSIONS_CONFIGS } from './erp-permissions-configs';
import { GENERAL_PERMISSIONS_CONFIGS } from './general-permissions-configs';

export const PERMISSIONS_CONFIGS_BY_ENTITY: PermissionsConfigsByEntity = {
  ...CRM_PERMISSIONS_CONFIGS,
  ...DMS_PERMISSIONS_CONFIGS,
  ...ERP_PERMISSIONS_CONFIGS,
  ...GENERAL_PERMISSIONS_CONFIGS,
};
