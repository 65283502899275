import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  canAddAppointment,
  canAddCallMemo,
  canAddChecklist,
  canAddNote,
  canAddSalesOpportunities,
  canAddTask,
  canAddTicket,
  canDeleteAppointment,
  canDeleteCallMemo,
  canDeleteChecklist,
  canDeleteNote,
  canDeleteSalesOpportunities,
  canDeleteTask,
  canDeleteTicket,
  canEditAppointment,
  canEditCallMemo,
  canEditChecklist,
  canEditNote,
  canEditSalesOpportunities,
  canEditTask,
  canEditTicket,
} from '@work4all/utils/lib/permissions';

import { ALL_PERMISSIONS_TYPES } from '../../constants';
import { PermissionsConfigsByEntity } from '../types';

export const CRM_PERMISSIONS_CONFIGS: PermissionsConfigsByEntity = {
  [Entities.task]: {
    permissionsFunctions: {
      canAdd: canAddTask,
      canEdit: canEditTask,
      canDelete: canDeleteTask,
    },
  },
  [Entities.checkList]: {
    permissionsFunctions: {
      canAdd: canAddChecklist,
      canEdit: canEditChecklist,
      canDelete: canDeleteChecklist,
    },
  },
  [Entities.callMemo]: {
    permissionsFunctions: {
      canAdd: canAddCallMemo,
      canEdit: canEditCallMemo,
      canDelete: canDeleteCallMemo,
    },
  },
  [Entities.ticket]: {
    permissionsFunctions: {
      canAdd: canAddTicket,
      canEdit: canEditTicket,
      canDelete: canDeleteTicket,
    },
    denyPermissionRules: {
      canAccessTicket: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
    },
  },
  [Entities.salesOpportunities]: {
    permissionsFunctions: {
      canAdd: canAddSalesOpportunities,
      canEdit: canEditSalesOpportunities,
      canDelete: canDeleteSalesOpportunities,
    },
  },
  [Entities.appointment]: {
    permissionsFunctions: {
      canAdd: canAddAppointment,
      canEdit: canEditAppointment,
      canDelete: canDeleteAppointment,
    },
  },
  [Entities.note]: {
    permissionsFunctions: {
      canAdd: canAddNote,
      canEdit: canEditNote,
      canDelete: canDeleteNote,
    },
  },
};
