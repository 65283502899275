import { GetEntityPermissionsConfigsFunction } from '../types';

import { PERMISSIONS_CONFIGS_BY_ENTITY } from './permissions-configs-by-entity';

export const getEntityPermissionsConfigs: GetEntityPermissionsConfigsFunction =
  ({ entity, context }) => {
    let configs = PERMISSIONS_CONFIGS_BY_ENTITY[entity];
    let missingConfigsWarnMessage = `Permissions configs aren't defined for entity ${entity}`;

    if (context) {
      configs = configs?.[context];
      missingConfigsWarnMessage = `${missingConfigsWarnMessage} and context ${context}`;
    }

    const isConfigsMissing = !configs || Object.keys(configs).length === 0;

    if (isConfigsMissing) {
      console.warn(missingConfigsWarnMessage);
    }

    return { isConfigsMissing, ...configs };
  };
