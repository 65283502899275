import styles from './ErpBookingsTable.module.scss';

import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CURRENCY_PARAMS, NumberCell } from '@work4all/components';

import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';
import { TaxKey } from '@work4all/models/lib/Classes/TaxKey.entity';

import { formatNumberAsCurrency } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { TaxKeyPickerField } from '../../../../../../../../../../../../components/entity-picker/TaxKeyPickerField';
import {
  settings,
  useSetting,
} from '../../../../../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../../../../../form-plus/use-form-context-plus';
import { CostCenterCell } from '../../../../../../../../components/table-cells/CostCenterCell';
import { CostGroupCell } from '../../../../../../../../components/table-cells/CostGroupCell';
import { LedgerAccountCell } from '../../../../../../../../components/table-cells/LedgerAccountCell';
import { ProjectCell } from '../../../../../../../../components/table-cells/ProjectCell';
import { EditTableColumns } from '../../../../../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { ReViewModelFormValue } from '../../../../../../../types';

interface UseErpBookingsColumnsProps {
  bookings: RELedgerAccountSplit[];
  disabled: boolean;
  handleEdit: (
    original: RELedgerAccountSplit,
    changes: RELedgerAccountSplit
  ) => void;
}

const pickerStyles = {
  margin: '-0.25rem 0 0 0',
};

export const useErpBookingsColumns = (props: UseErpBookingsColumnsProps) => {
  const { bookings, disabled, handleEdit } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const columnSettings = useSetting(settings.incomingInvoicePositionsColumn());
  const width = useDeepMemo(
    () => columnSettings.value.width,
    [columnSettings.value.width]
  );

  const { watch } = useFormContextPlus<ReViewModelFormValue>();
  const supplierId = watch('supplier.id');

  const totals = useMemo(() => {
    const { net, vat } = (bookings ?? []).reduce<{ net: number; vat: number }>(
      (acc, cur) => {
        acc.net += cur.valueNet ?? 0;
        acc.vat += cur.vatAmount ?? 0;

        return acc;
      },
      { net: 0, vat: 0 }
    );

    const gross = net + vat;

    return { net, vat, gross };
  }, [bookings]);

  const columns = useMemo(() => {
    const columns: EditTableColumns<RELedgerAccountSplit>[] = [
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT'),
        accessor: 'konto',
        type: 'picker',
        width: 180,
        Cell: (cell) => {
          return (
            <LedgerAccountCell
              autoFocus={cell.autoFocus}
              disabled={disabled}
              value={cell.value}
              data={{ taxKeyValue: null }}
              style={pickerStyles}
              onChange={(ledgerAccount) => {
                cell.onSelectionChange(0, 0);
                handleEdit(cell.row.original, {
                  konto: ledgerAccount,
                });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_CENTER'),
        accessor: 'costCenter',
        type: 'picker',
        width: 130,
        Cell: (cell) => {
          return (
            <CostCenterCell
              autoFocus={cell.autoFocus}
              disabled={disabled}
              value={cell.value}
              style={pickerStyles}
              onChange={(costCenter) => {
                cell.onSelectionChange(0, 0);
                handleEdit(cell.row.original, { costCenter });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.PROJECT'),
        accessor: 'project',
        type: 'picker',
        width: 140,
        Cell: (cell) => {
          const supplierIdFilter = supplierId
            ? [
                {
                  supplierId: {
                    $eq: supplierId,
                  },
                },
              ]
            : [];

          return (
            <ProjectCell
              autoFocus={cell.autoFocus}
              disabled={disabled}
              value={cell.value}
              style={pickerStyles}
              onChange={(project) => {
                if (project?.lockExternalServices) {
                  enqueueSnackbar(
                    t(
                      'VALIDATION.INBOUND_INVOICES.PROJECT_LOCKED_EXTERNAL_SERVICES'
                    )
                  );
                  return;
                }
                if (project?.projectStatus?.closedStatus) {
                  enqueueSnackbar(
                    t('VALIDATION.INBOUND_INVOICES.PROJECT_CLOSED')
                  );
                  return;
                }

                cell.onSelectionChange(0, 0);
                handleEdit(cell.row.original, { project });
              }}
              prefilter={[
                {
                  $or: [
                    {
                      projectStatusId: { $eq: 0 },
                    },
                    {
                      projectStatusId: { $eq: null },
                    },
                    {
                      'projectStatus.closedStatus': { $eq: 0 },
                    },
                    {
                      'projectStatus.closedStatus': { $eq: null },
                    },
                  ],
                },
                ...supplierIdFilter,
              ]}
              useSearchHistory={false}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_GROUP'),
        accessor: 'costGroup',
        type: 'picker',
        width: 140,
        Cell: (cell) => {
          return (
            <CostGroupCell
              autoFocus={cell.autoFocus}
              disabled={disabled}
              value={cell.value}
              style={pickerStyles}
              onChange={(costGroup) => {
                cell.onSelectionChange(0, 0);
                handleEdit(cell.row.original, { costGroup });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.NOTE'),
        accessor: 'note',
        width: 140,
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
        accessor: 'taxKey',
        type: 'picker',
        width: 50,
        align: 'right',
        Cell: (cell) => {
          const value = cell.value ?? {
            id: cell.row.original.taxKeyValue,
            taxKeyValue: cell.row.original.taxKeyValue,
          };
          return (
            <TaxKeyPickerField
              value={value}
              onChange={(value: TaxKey) => {
                handleEdit(cell.row.original, {
                  taxKeyValue: value ? parseInt(`${value.taxKeyValue}`) : null,
                });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.VAT'),
        accessor: 'vat',
        type: 'number',
        max: 29,
        transform: (input: number) => Math.min(Math.max(input, 0), 29),
        width: 60,
        align: 'right',
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
        accessor: 'valueNet',
        type: 'number',
        width: 80,
        align: 'right',
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.net)}
          </div>
        ),
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
        accessor: 'vatAmount',
        type: 'number',
        width: 80,
        align: 'right',
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.vat)}
          </div>
        ),
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
        accessor: 'proportionDM',
        type: 'number',
        width: 80,
        align: 'right',
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.gross)}
          </div>
        ),
      },
    ];
    Object.entries(width).forEach((colWidth) => {
      const [prop, value] = colWidth;
      const colIdx = columns.findIndex((col) => col.accessor === prop);
      if (columns[colIdx]) {
        columns[colIdx].width = value;
      }
    });

    return columns;
  }, [
    t,
    totals.net,
    totals.vat,
    totals.gross,
    width,
    disabled,
    handleEdit,
    enqueueSnackbar,
    supplierId,
  ]);

  return columns;
};
