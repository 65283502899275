import styles from '../EntityPicker.module.scss';

import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export interface ITicketPickerProps<TMultiple extends boolean>
  extends IPickerProps<Ticket, TMultiple> {
  strikeThroughIfClosed?: boolean;
  renderItemContent?: (ticket: Ticket) => React.ReactNode;
}

const DEFAULT_SORT_BY = 'title';
const DEFAULT_SORT_BY_DIRECTION = SortDirection.ASCENDING;

export function TicketPicker<TMultiple extends boolean>(
  props: ITicketPickerProps<TMultiple>
) {
  const {
    data,
    strikeThroughIfClosed,
    sortBy = DEFAULT_SORT_BY,
    sortByDirection = DEFAULT_SORT_BY_DIRECTION,
    renderItemContent,
    ...rest
  } = props;

  const allFields = useMemo(() => {
    return { ...TICKET_FIELDS, ...data };
  }, [data]);

  return (
    <ListEntityPicker
      entity={Entities.ticket}
      data={allFields}
      filterBy={['title', 'number']}
      sortBy={sortBy}
      sortByDirection={sortByDirection}
      {...rest}
      renderItemContent={(ticket: Ticket) => {
        if (renderItemContent) {
          return renderItemContent(ticket);
        }

        const isClosed = ticket.status1 === TicketStatus.ERLEDIGT;

        return (
          <Tooltip title={ticket.title}>
            <Typography
              variant="body2"
              noWrap
              sx={{
                textDecoration:
                  strikeThroughIfClosed && isClosed ? 'line-through' : null,
              }}
            >
              <span className={styles.secondaryInfo}>{ticket.number} | </span>
              {ticket.title}
            </Typography>
          </Tooltip>
        );
      }}
    />
  );
}

const TICKET_FIELDS: Ticket<EMode.query> = {
  id: null,
  title: null,
  number: null,
  status1: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        number: null,
      },
    },
  },
  project: {
    id: null,
    name: null,
    number: null,
    projectStatus: { id: null, closedStatus: null },
  },
  projectProcess: {
    id: null,
    process: null,
  },
  contract: {
    id: null,
    number: null,
    contractNumber: null,
    note: null,
    positionList: [{ id: null, number: null, shortText: null }],
  },
  position: { id: null, number: null, shortText: null },
};
