import styles from './DetailsHeading.module.scss';

import { Divider, IconButton, Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/cross.svg';

import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { Heading4 } from '../../../files/search/components/heading4/Heading4';

interface Props {
  title: string;
  subTitle: string;
  close: () => void;
}

export function DetailsHeading(props: PropsWithChildren<Props>) {
  const { title, subTitle, close } = props;
  return (
    <Stack className={styles['details-header__root']}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles['details-header']}
      >
        <Stack direction="row">
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
          <Heading4 className={styles['details-header__title']}>
            {title}
          </Heading4>
        </Stack>
        <Body1 className={styles['details-header__body']}>{subTitle}</Body1>
      </Stack>
      <Divider />
    </Stack>
  );
}
