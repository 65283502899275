import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOpenMask } from '../../../../../mask-overlays/mask-overlay/hooks/use-open-mask';
import { useCustomer } from '../../queries/use-customer';
import { useSalesOpportunities } from '../../queries/use-sales-opportunities';
import { useServiceContracts } from '../../queries/use-service-contracts';
import { CustomerFileInfoPanel } from '../CustomerFileInfoPanel';
import { FilePreviewPanelBaseProps } from '../FilePreviewPanel';

export default interface CustomerFileInfoPanelContainerProps
  extends Pick<
    FilePreviewPanelBaseProps,
    'onCloseClick' | 'onEdit' | 'onVisibilityToggle'
  > {
  ids: number[];
}

export const CustomerFileInfoPanelContainer = (
  props: CustomerFileInfoPanelContainerProps
) => {
  const { ids, onVisibilityToggle } = props;
  const id = ids[0];
  const {
    customer: fullyLoadedCustomer,
    refetch,
    pending,
  } = useCustomer(id.toString());
  const { salesOpportunities } = useSalesOpportunities(id.toString());
  const { serviceContracts } = useServiceContracts(id.toString());

  useEntityEvents((event) => {
    if (event.entity === Entities.contact) {
      refetch();
    }
  });
  const onOpenMask = useOpenMask();

  return (
    <CustomerFileInfoPanel
      openSalesOpportunityList={salesOpportunities}
      loading={pending}
      activeServiceContractList={serviceContracts}
      customer={fullyLoadedCustomer}
      onVisibilityToggle={onVisibilityToggle}
      onAddContact={() => {
        onOpenMask().handler({
          entity: Entities.contact,
          template: {
            entity: Entities.customer,
            id: String(id),
          },
        });
      }}
      onEditContact={(contactId) => {
        onOpenMask().handler({
          entity: Entities.contact,
          id: String(contactId),
          template: {
            entity: Entities.customer,
            id: String(id),
          },
        });
      }}
      {...props}
    />
  );
};
