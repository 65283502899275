import { useMemo, useState } from 'react';

import { BottomViewsContext, BottomViewsContextValue } from '../contexts';

import { BottomViewsProviderProps } from './types';

export const BottomViewsProvider = ({
  children,
  views,
  isCollapsed,
  setIsCollapsed,
}: BottomViewsProviderProps) => {
  const [activeViewId, setActiveViewId] = useState<string>(views[0].id);

  const activeView = useMemo(
    () => views.find((view) => view.id === activeViewId),
    [activeViewId, views]
  );

  const value: BottomViewsContextValue = useMemo(() => {
    return {
      views,
      isCollapsed,
      toggleCollapsibility: () => setIsCollapsed(!isCollapsed),
      activeView,
      setActiveViewId,
    };
  }, [activeView, isCollapsed, setIsCollapsed, views]);

  return (
    <BottomViewsContext.Provider value={value}>
      {children}
    </BottomViewsContext.Provider>
  );
};
