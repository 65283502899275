import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';

import { useBottomViewsContext } from '../hooks';

export const BottomViewsToggleCollapsibilityAction = () => {
  const { isCollapsed, toggleCollapsibility } = useBottomViewsContext();

  const renderIcon = useCallback(() => {
    if (isCollapsed) {
      return <KeyboardArrowUpIcon />;
    }

    return <KeyboardArrowDownIcon />;
  }, [isCollapsed]);

  return <IconButton onClick={toggleCollapsibility}>{renderIcon()}</IconButton>;
};
