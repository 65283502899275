import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import {
  LABEL_VISIBILITY,
  MaskActionButton,
} from '@work4all/components/lib/components/MaskActionButton';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';
import ProjectPlanningIcon from '@work4all/components/lib/icons/ProjectPlanningIcon';

import { useNavigate } from '@work4all/data/lib/hooks';

import { ProjectPlanningPageSearchParamKeys } from '../../containers/mask-overlays/mask-overlay/views/project-planning/ProjectPlanningPage';

export enum PROJECTPLANNINGACTIONS {
  open = 'open',
}

export const useProjectPlanningActions = () => {
  const tableStateBag = useTableStateBag();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedFlatRows = tableStateBag.tableInstance
    ?.selectedFlatRows as unknown as Row<{ id: string }>[];

  const data = tableStateBag.tableInstance?.data as unknown as Row<{
    id: number;
  }>[];

  const open = useCallback(
    (selectedIds: string[]) => {
      const layout = selectedIds.length > 1 ? 'stacked' : 'gantt';
      const pathname = '/more/project-planning';
      const search = new URLSearchParams({
        [ProjectPlanningPageSearchParamKeys.layout]: layout,
        [ProjectPlanningPageSearchParamKeys.projectIds]: selectedIds.join(','),
      }).toString();

      navigate({ pathname, search });
    },
    [navigate]
  );

  const handleOpenClick = useCallback(() => {
    if (selectedFlatRows.length) {
      const selectedIds = getSelectedRowIds(selectedFlatRows);
      open(selectedIds);
    } else {
      const allIds = data.map((x) => x.id);
      open(allIds);
    }
  }, [data, open, selectedFlatRows]);

  const planCalendarActionDefinitions: CustomToolbarAction[] =
    useMemo((): CustomToolbarAction[] => {
      return [
        {
          actionKey: PROJECTPLANNINGACTIONS.open,
          IconComponent: ProjectPlanningIcon,
          title: t(
            selectedFlatRows?.length === 1
              ? 'PROJECT_PLANNING.LAYOUT.gantt'
              : 'COMMON.PROJECTPROCESS_plural'
          ),
          disabled: false,
          handler: () => {
            handleOpenClick();
          },
          mode: 'Add',
        },
      ];
    }, [t, selectedFlatRows?.length, handleOpenClick]);
  return { open, planCalendarActionDefinitions };
};

export const ProjectPlanningActions = () => {
  const { planCalendarActionDefinitions } = useProjectPlanningActions();
  return (
    <>
      {planCalendarActionDefinitions.map((x) => (
        <MaskActionButton
          key={x.actionKey}
          labelVisibility={LABEL_VISIBILITY.HIDE}
          label={x.title}
          icon={<x.IconComponent />}
          disabledReason={x.disableReason}
          disabled={x.disabled}
          onClick={() => {
            x.handler(null);
          }}
        />
      ))}
    </>
  );
};

function getSelectedRowIds(rows: Row<{ id: string }>[]): string[] {
  return rows.filter((row) => !row.isGrouped).map((row) => row.original?.id);
}
