import { useMemo, useState } from 'react';

import { BottomViews } from '../components';

import { UseBottomViewProps, UseBottomViewReturn } from './types';

export const useBottomViews = ({
  views,
  skip,
  size,
}: UseBottomViewProps): UseBottomViewReturn => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const viewsWithIndexAsAnId = useMemo(() => {
    return views.map((view, id) => {
      return { ...view, id: `${id}` };
    });
  }, [views]);

  return useMemo(() => {
    if (skip) return undefined;

    return {
      content: (
        <BottomViews
          views={viewsWithIndexAsAnId}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      ),
      resizable: !isCollapsed,
      size,
    };
  }, [skip, viewsWithIndexAsAnId, isCollapsed, size]);
};
