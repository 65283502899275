import styles from './OverviewCard.module.scss';

import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import clsx from 'clsx';
import { concat } from 'lodash';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import {
  TimeFrameSwitch,
  TimeUnitType,
} from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import {
  dateToQuery,
  searchToQuery,
} from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { PopoverList } from '@work4all/components/lib/input/filter-components/popover-list';
import {
  IStackItem,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';

import { useDataProvider, useModuleRights, useUser } from '@work4all/data';
import { nameof } from '@work4all/data/lib/helper/nameof';
import { useDocumentClasses } from '@work4all/data/lib/hooks/document-classes';
import { useHiddenEntities } from '@work4all/data/lib/hooks/use-hidden-entities';

import { getDefaultPrefilter } from '@work4all/models';
import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import {
  makeTableSettings,
  stringifyTableSettings,
} from '../../../../../components/data-tables/make-table-settings';
import { settings, useSetting } from '../../../../../settings';
import { CollapsibleCard } from '../../CollapsibleCard/CollapsibleCard';
import { CardWidgetProps } from '../types';

import { IOverviewItemProps } from './components/overview-item/OverviewItem';
import { OverviewSection } from './components/overview-section/OverviewSection';

export type IOverViewItemConf = {
  [key in Entities]?: boolean;
};

export enum PersonFilterType {
  all = 'all',
  department = 'department',
  me = 'mine',
}

const OverviewPageContext = React.createContext({
  timeUnit: 'day',
  setTimeUnit: undefined,
  personFilter: PersonFilterType.all,
  setPersonFilter: undefined,
  timeSpan: {
    start: new Date(),
    end: new Date(),
  },
});

export const useOverviewConfig = () => useContext(OverviewPageContext);

export interface TimeSettings {
  date: string;
  timeUnit: TimeUnitType;
  lastSettingDate: string;
}

interface OverviewCardProps extends CardWidgetProps {
  onActiveRowsChange?: (items: number) => void;
}

export const OverviewCard: React.FC<OverviewCardProps> = ({
  onActiveRowsChange,
  ...props
}) => {
  const user = useUser();
  const { t } = useTranslation();

  const { set: setWidgetConfig, value: widgetConfig } = useSetting(
    settings.overviewWidgetConfig()
  );

  const { value: overviewTimeUnitConfig } = useSetting(
    settings.overviewTimeUnitConfig()
  );
  const { value: overviewPersonFilterConfig } = useSetting(
    settings.overviewPersonFilterConfig()
  );

  const { loading: moduleRightsLoading } = useModuleRights();
  const { isHidden } = useHiddenEntities();

  useEffect(() => {
    const deleteCurrentTimeSettings = () => {
      sessionStorage.removeItem('currentTimeSettings');
    };

    window.addEventListener('beforeunload', deleteCurrentTimeSettings);
    return () => {
      window.removeEventListener('beforeunload', deleteCurrentTimeSettings);
    };
  }, []);

  const defaultTimeSpan = useMemo(() => {
    const currentTimeSettingsJson = sessionStorage.getItem(
      'currentTimeSettings'
    );
    let result = {
      start: DateTime.now().startOf('day').toJSDate(),
      end: DateTime.now().endOf('day').toJSDate(),
    };

    if (currentTimeSettingsJson) {
      const currentTimeSettings: TimeSettings = JSON.parse(
        currentTimeSettingsJson
      );
      if (
        DateTime.fromISO(currentTimeSettings.lastSettingDate).hasSame(
          DateTime.now(),
          'day'
        )
      ) {
        result = {
          start: DateTime.fromISO(currentTimeSettings.date).toJSDate(),
          end: DateTime.fromISO(currentTimeSettings.date)
            .endOf(currentTimeSettings.timeUnit)
            .toJSDate(),
        };
      }
    }
    return result;
  }, []);

  const [timeSpan, setTimeSpan] = useState(defaultTimeSpan);

  const saveAndSetTimeSpan = useCallback(
    (props: { start: Date; end: Date }) => {
      const { start, end } = props;
      const settings: TimeSettings = {
        date: DateTime.fromJSDate(start).toISO(),
        timeUnit: overviewTimeUnitConfig,
        lastSettingDate: DateTime.now().toISO(),
      };
      sessionStorage.setItem('currentTimeSettings', JSON.stringify(settings));
      setTimeSpan({ start, end });
    },
    [overviewTimeUnitConfig]
  );

  const [crmItems, setCrmItems] = useState<IOverviewItemProps[]>([]);
  const [erpItems, setErpItems] = useState<IOverviewItemProps[]>([]);
  const [dmsItems, setDmsItems] = useState<IOverviewItemProps[]>([]);

  const departmentUsersProviderData = useMemo(() => {
    const userData: User = {
      displayName: null,
      lastName: null,
      id: null,
      departmentName: null,
    };
    return {
      entity: Entities.user,
      data: userData,
      filter: [
        {
          departmentName: user.departmentName
            ? searchToQuery(user.departmentName)
            : { $eq: '' },
        },
        {
          hasLeft: { $eq: false },
        },
        {
          userKind: { $ne: UserClass.RESSOURCE },
        },
      ],
    };
  }, [user]);

  const departmentUsersData = useDataProvider<User>(
    departmentUsersProviderData,
    !user.departmentName
  );

  const getFilterParams = useCallback(
    (
      entityType: Entities,
      dateField: string,
      userField: string
    ): { filter; deeplink } => {
      // TODO Move property lookups to a single place (`dateField`,
      // `userField`).
      //
      // There are two different places that map filters to column names: here
      // and in the `makeTableSettings` function, which is used to create URL
      // params for list pages. We should move them to a single place to avoid
      // duplication and reduce risk of errors caused by mismatches.
      //
      // Right now the `makeTableSettings` function is using nested id
      // properties (eg. "user.id" instead of "userId") due to the limitations
      // of picker filters, but this will be fixed later. At that point we can
      // move all property lookup logic to one place.

      const filter: unknown[] = [
        {
          [dateField]: dateToQuery(timeSpan.start, timeSpan.end),
        },
      ];

      const departmentUsers =
        departmentUsersData.data.length === 0
          ? [{ id: user.benutzerCode }]
          : departmentUsersData.data;

      if (overviewPersonFilterConfig === PersonFilterType.me) {
        filter.push({
          [userField]: searchToQuery(user.benutzerCode),
        });
      }

      if (overviewPersonFilterConfig === PersonFilterType.department) {
        filter.push({
          [userField]: { $in: departmentUsers.map((a) => a.id) },
        });
      }

      let relativeDateFilter: RelativeDateFilter | null = null;

      if (
        DateTime.fromJSDate(timeSpan.start)
          .startOf('day')
          .equals(DateTime.now().startOf('day')) &&
        DateTime.fromJSDate(timeSpan.end)
          .endOf('day')
          .equals(DateTime.now().endOf('day'))
      ) {
        relativeDateFilter = RelativeDateFilter.today;
      } else if (
        DateTime.fromJSDate(timeSpan.start)
          .startOf('week')
          .equals(DateTime.now().startOf('week')) &&
        DateTime.fromJSDate(timeSpan.end)
          .endOf('week')
          .equals(DateTime.now().endOf('week'))
      ) {
        relativeDateFilter = RelativeDateFilter.currentWeek;
      } else if (
        DateTime.fromJSDate(timeSpan.start)
          .startOf('month')
          .equals(DateTime.now().startOf('month')) &&
        DateTime.fromJSDate(timeSpan.end)
          .endOf('month')
          .equals(DateTime.now().endOf('month'))
      ) {
        relativeDateFilter = RelativeDateFilter.currentMonth;
      }

      const deeplink = stringifyTableSettings(
        makeTableSettings(entityType, {
          date: {
            value: {
              startDate: relativeDateFilter || timeSpan.start.toISOString(),
              endDate: relativeDateFilter ? null : timeSpan.end.toISOString(),
            },
          },
          user:
            overviewPersonFilterConfig === PersonFilterType.me
              ? {
                  value: [
                    {
                      id: String(user.benutzerCode),
                      displayName: user.displayName,
                    },
                  ],
                }
              : overviewPersonFilterConfig === PersonFilterType.department
              ? {
                  value: departmentUsers.map((user) => {
                    return {
                      id: String(user.id),
                      displayName: user.displayName,
                    };
                  }),
                }
              : null,
        })
      );

      return { filter, deeplink };
    },
    [user, overviewPersonFilterConfig, departmentUsersData, timeSpan]
  );

  const documentClasses = useDocumentClasses();

  const createWidgetConf = useCallback(() => {
    const newCrmItems: IOverviewItemProps[] = [
      {
        title: t('COMMON.TASK_plural'),
        entity: Entities.task,
        ...getFilterParams(
          Entities.task,
          nameof<Task>('date'),
          nameof<Task>('userId')
        ),
      },
      {
        title: t('COMMON.CHECKLIST_plural'),
        entity: Entities.checkList,
        ...getFilterParams(
          Entities.checkList,
          nameof<CheckList>('date'),
          nameof<CheckList>('userId')
        ),
      },

      {
        title: t('COMMON.CALLMEMO_plural'),
        entity: Entities.callMemo,
        ...getFilterParams(
          Entities.callMemo,
          nameof<CallMemo>('date'),
          nameof<CallMemo>('userId')
        ),
      },
      {
        title: t('COMMON.NOTE_plural'),
        entity: Entities.note,
        ...getFilterParams(
          Entities.note,
          nameof<Note>('date'),
          nameof<Note>('userId')
        ),
      },
      {
        title: t('COMMON.APPOINTMENT_plural'),
        entity: Entities.appointment,
        ...getFilterParams(
          Entities.appointment,
          nameof<Appointment>('startDate'),
          nameof<Appointment>('userId')
        ),
      },
      {
        title: t('COMMON.TICKET_plural'),

        entity: Entities.ticket,
        ...getFilterParams(
          Entities.ticket,
          nameof<Ticket>('followUpDate'),
          nameof<Ticket>('editor1Id')
        ),
      },
    ]
      .filter((el) => !isHidden(el.entity))
      .sort((a, b) => a.title.localeCompare(b.title));

    const uniqueDocumentClassNames = [
      ...new Set(documentClasses.map((dc) => dc.name)),
    ];

    let newDmsItems: IOverviewItemProps[] = [
      {
        title: t('COMMON.LETTER_plural'),
        entity: Entities.letter,
        ...getFilterParams(
          Entities.letter,
          nameof<Letter>('date'),
          nameof<Letter>('userId')
        ),
      },
      {
        title: t('COMMON.EMAIL_plural'),
        entity: Entities.eMail,
        ...getFilterParams(
          Entities.eMail,
          nameof<EMail>('date'),
          nameof<EMail>('userId')
        ),
      },
      {
        title: t('COMMON.VISITORLOG_plural'),
        entity: Entities.visitationReport,
        ...getFilterParams(
          Entities.visitationReport,
          nameof<VisitationReport>('startTime'),
          nameof<VisitationReport>('userId')
        ),
      },
      ((): IOverviewItemProps => {
        const params = getFilterParams(
          Entities.document,
          nameof<Document>('date'),
          nameof<Document>('userId')
        );

        return {
          title: t('COMMON.DOCUMENT_plural'),
          entity: Entities.document,
          filter: concat(
            ...[
              ...getDefaultPrefilter(Entities.document),
              params.filter,
            ].filter(Boolean)
          ),
          deeplink: params.deeplink,
        };
      })(),
      ...uniqueDocumentClassNames.map<IOverviewItemProps>(
        (documentClassName) => {
          const params = getFilterParams(
            Entities.document,
            nameof<Document>('date'),
            nameof<Document>('userId')
          );

          return {
            title: documentClassName,
            entity: Entities.document,
            entityVariant: documentClassName,
            filter: concat(
              ...[
                ...getDefaultPrefilter(Entities.document, documentClassName),
                params.filter,
              ].filter(Boolean)
            ),
            deeplink: params.deeplink,
          };
        }
      ),
    ];

    newDmsItems = newDmsItems
      .filter((el) => {
        return !isHidden({
          entityType: el.entity,
          entityVariant: el.entityVariant,
        });
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    const newErpItems: IOverviewItemProps[] = [
      {
        title: t('COMMON.OFFER_plural'),
        entity: Entities.offer,
        ...getFilterParams(
          Entities.offer,
          nameof<Offer>('date'),
          nameof<Offer>('userId')
        ),
      },
      {
        title: t('COMMON.CONTRACT_plural'),
        entity: Entities.contract,
        ...getFilterParams(
          Entities.contract,
          nameof<Contract>('contractDate'),
          nameof<Contract>('userId')
        ),
      },
      {
        title: t('COMMON.REQUISITION_plural'),
        entity: Entities.demand,
        ...getFilterParams(
          Entities.demand,
          nameof<Demand>('date'),
          nameof<Demand>('userId')
        ),
      },
      {
        title: t('COMMON.ORDER_plural'),
        entity: Entities.order,
        ...getFilterParams(
          Entities.order,
          nameof<Order>('date'),
          nameof<Order>('userId')
        ),
      },
      {
        title: t('COMMON.INBOUNDDELIVERYNOTE_plural'),
        entity: Entities.inboundDeliveryNote,
        ...getFilterParams(
          Entities.inboundDeliveryNote,
          nameof<InboundDeliveryNote>('date'),
          nameof<InboundDeliveryNote>('userId')
        ),
      },
      {
        title: t('COMMON.INBOUNDINVOICE_plural'),
        entity: Entities.reViewModel,
        ...getFilterParams(
          Entities.reViewModel,
          nameof<ReViewModel>('invoiceDate'),
          nameof<ReViewModel>('userId')
        ),
      },
      {
        title: t('COMMON.CALCULATION_plural'),
        entity: Entities.calculation,
        ...getFilterParams(
          Entities.calculation,
          nameof<Calculation>('date'),
          nameof<Calculation>('userId')
        ),
      },
      {
        title: t('COMMON.DELIVERYNOTE_plural'),
        entity: Entities.deliveryNote,
        ...getFilterParams(
          Entities.deliveryNote,
          nameof<DeliveryNote>('date'),
          nameof<DeliveryNote>('userId')
        ),
      },
      {
        title: t('COMMON.INVOICE_plural'),
        entity: Entities.invoice,
        ...getFilterParams(
          Entities.invoice,
          nameof<Invoice>('date'),
          nameof<Invoice>('userId')
        ),
      },
    ]
      .filter((el) => !isHidden(el.entity))
      .sort((a, b) => a.title.localeCompare(b.title));

    newCrmItems.forEach((item) => {
      if (
        widgetConfig !== undefined &&
        widgetConfig[item.entity] !== undefined
      ) {
        item.active = widgetConfig[item.entity];
      } else {
        item.active = true;
      }
    });

    newErpItems.forEach((item) => {
      if (
        widgetConfig !== undefined &&
        widgetConfig[item.entity] !== undefined
      ) {
        item.active = widgetConfig[item.entity];
      } else {
        item.active = true;
      }
    });

    newDmsItems.forEach((item) => {
      const savedKey =
        item.entity === Entities.document && item.entityVariant
          ? `${Entities.document}(${item.entityVariant})`
          : item.entity;
      if (widgetConfig !== undefined && widgetConfig[savedKey] !== undefined) {
        item.active = widgetConfig[savedKey];
      } else {
        item.active = true;
      }
    });

    setCrmItems(newCrmItems);
    setErpItems(newErpItems);
    setDmsItems(newDmsItems);
  }, [t, getFilterParams, documentClasses, widgetConfig, isHidden]);

  const updateWidgetConf = (
    entity: Entities,
    value: boolean,
    entityVariant
  ) => {
    const savedConf = { ...widgetConfig };

    const saveKey = entityVariant ? `${entity}(${entityVariant})` : entity;
    savedConf[saveKey] = value;
    setWidgetConfig(savedConf);
  };

  useEffect(() => {
    createWidgetConf();
  }, [widgetConfig, createWidgetConf, timeSpan, moduleRightsLoading]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [initialView, setInitialView] = useState<IStackItem | null>(null);
  const [widgetEditMode, setWidgetEditMode] = useState(false);
  const handleSettingsClick = (event) => {
    setInitialView({ title: t('COMMON.SETTINGS'), view: <SettingsMenu /> });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const visibleCrmItems: number = useMemo(() => {
    return crmItems.filter((x) => {
      return widgetEditMode || x.active;
    }).length;
  }, [widgetEditMode, crmItems]);

  const visibleErpItems: number = useMemo(() => {
    return erpItems.filter((x) => {
      return widgetEditMode || x.active;
    }).length;
  }, [widgetEditMode, erpItems]);

  const visibleDmsItems: number = useMemo(() => {
    return dmsItems.filter((x) => {
      return widgetEditMode || x.active;
    }).length;
  }, [widgetEditMode, dmsItems]);

  const SettingsMenu: React.FC = () => {
    const { onClose } = useHistoryStack();
    const { set: setOverviewTimeUnitConfig, value: overviewTimeUnitConfig } =
      useSetting(settings.overviewTimeUnitConfig());
    const {
      set: setOverviewPersonFilterConfig,
      value: overviewPersonFilterConfig,
    } = useSetting(settings.overviewPersonFilterConfig());

    return (
      <div className={styles.optionsWrap}>
        <Divider title={t('COMMON.TIME_UNIT')}></Divider>
        <RadioGroup
          value={overviewTimeUnitConfig}
          name=""
          onChange={(e) => {
            const unit = e.target.defaultValue as 'day' | 'week' | 'month';

            setOverviewTimeUnitConfig(unit);

            const start = DateTime.now().startOf(unit).toJSDate();
            const end = DateTime.now().endOf(unit).toJSDate();

            saveAndSetTimeSpan({ start, end });
          }}
        >
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={overviewTimeUnitConfig === 'day'}
              />
            }
            value="day"
            label={t('COMMON.DAY')}
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={overviewTimeUnitConfig === 'week'}
              />
            }
            value="week"
            label={t('COMMON.WEEK')}
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={overviewTimeUnitConfig === 'month'}
              />
            }
            value="month"
            label={t('COMMON.MONTH')}
          />
        </RadioGroup>
        <Divider title={t('COMMON.PERSON_FILTER')}></Divider>
        <RadioGroup
          value={overviewTimeUnitConfig}
          name=""
          onChange={(e) => {
            setOverviewPersonFilterConfig(
              e.target.defaultValue as PersonFilterType
            );
          }}
        >
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={overviewPersonFilterConfig === PersonFilterType.me}
              />
            }
            value={PersonFilterType.me}
            label={t('COMMON.ME')}
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={
                  overviewPersonFilterConfig === PersonFilterType.department
                }
              />
            }
            value={PersonFilterType.department}
            label={t('COMMON.MY_TEAM')}
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={overviewPersonFilterConfig === PersonFilterType.all}
              />
            }
            value={PersonFilterType.all}
            label={t('COMMON.ALL')}
          />
        </RadioGroup>
        <Divider title={t('COMMON.MORE_OPTIONS')}></Divider>
        <PopoverList
          items={[{ id: 'fields', label: t('COMMON.CONFIGURE_FIELDS') }]}
          handleClick={(item) => {
            switch (item.id) {
              case 'fields':
                setWidgetEditMode(true);
                onClose();
                break;

              default:
                break;
            }
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    const maxRows = 10; // item rows + section label rows
    if (onActiveRowsChange) {
      // rows (3 colums each) + section label row
      const crmRows =
        visibleCrmItems === 0 ? 0 : Math.ceil(visibleCrmItems / 3) + 1;
      const erpRows =
        visibleErpItems === 0 ? 0 : Math.ceil(visibleErpItems / 3) + 1;
      const dmsRows =
        visibleDmsItems === 0 ? 0 : Math.ceil(visibleDmsItems / 3) + 1;
      onActiveRowsChange(
        widgetEditMode ? maxRows : crmRows + erpRows + dmsRows
      );
    }
  }, [
    onActiveRowsChange,
    visibleCrmItems,
    visibleDmsItems,
    visibleErpItems,
    widgetEditMode,
  ]);

  useEffect(() => {
    const newTime = DateTime.fromJSDate(timeSpan.start);

    const newStart = newTime.startOf(overviewTimeUnitConfig).toJSDate();
    const newEnd = newTime.endOf(overviewTimeUnitConfig).toJSDate();
    if (
      newStart.getTime() !== timeSpan.start.getTime() ||
      newEnd.getTime() !== timeSpan.end.getTime()
    ) {
      saveAndSetTimeSpan({ start: newStart, end: newEnd });
    }
  }, [
    saveAndSetTimeSpan,
    timeSpan.end,
    timeSpan.start,
    overviewTimeUnitConfig,
  ]);

  return (
    <CollapsibleCard
      title={
        !widgetEditMode && (
          <Box
            display="flex"
            gap="0"
            flexDirection="column"
            pt={props.collapsed ? '0rem' : '1.25rem'}
          >
            {t('NAVIGATION.OVERVIEW')}
            {!props.collapsed && (
              <TimeFrameSwitch
                timeUnit={overviewTimeUnitConfig}
                initialTime={timeSpan.start}
                onChange={(start, end) => {
                  saveAndSetTimeSpan({ start, end });
                  createWidgetConf();
                }}
                backwardAction={false}
                forwardAction={false}
                todayAction={false}
                classes={styles.timeFrameSwitch}
              />
            )}
          </Box>
        )
      }
      headerContent={
        <div className={clsx(styles.timeFrameSwitchWrapper)}>
          {widgetEditMode && (
            <Button
              size="medium"
              className={clsx(styles.applyButton)}
              onClick={() => {
                setWidgetEditMode(false);
              }}
            >
              {t('COMMON.APPLY')}
            </Button>
          )}
          {!widgetEditMode && (
            <div style={{ display: 'flex' }}>
              <TimeFrameSwitch
                timeUnit={overviewTimeUnitConfig}
                initialTime={timeSpan.start}
                onChange={(start, end) => {
                  saveAndSetTimeSpan({ start, end });
                  createWidgetConf();
                }}
                showTitle={false}
                todayAction={false}
              />
              <IconButton
                onClick={handleSettingsClick}
                size="medium"
                className={clsx(styles.settingsButton)}
              >
                <SettingsIcon />
              </IconButton>
            </div>
          )}
          <NavigationPopover
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            initialView={initialView}
          ></NavigationPopover>
        </div>
      }
      {...props}
    >
      <Box pt={'1rem'}>
        {visibleCrmItems > 0 && (
          <OverviewSection
            title={t('COMMON.CRMOBJECTS')}
            items={crmItems}
            editMode={widgetEditMode}
            onItemActiveChange={updateWidgetConf}
          ></OverviewSection>
        )}
        {visibleDmsItems > 0 && (
          <OverviewSection
            title={t('COMMON.DMSOBJECTS')}
            items={dmsItems}
            editMode={widgetEditMode}
            onItemActiveChange={updateWidgetConf}
          ></OverviewSection>
        )}
        {visibleErpItems > 0 && (
          <OverviewSection
            title={t('COMMON.ERPOBJECTS')}
            items={erpItems}
            editMode={widgetEditMode}
            onItemActiveChange={updateWidgetConf}
          ></OverviewSection>
        )}
      </Box>
    </CollapsibleCard>
  );
};
