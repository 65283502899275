import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationArea } from '../../organisation-area';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/fullUser-table-schema.json';
import { useDefaultTableFilter } from '../table/hooks/use-default-table-filter';

import { useUserLastFilter } from './use-user-last-filter';

const defaultSort = [
  { field: 'displayName', direction: SortDirection.ASCENDING },
];

const forceRequestFields: FullUser = {
  groupId: null,
};

export const UsersTable = React.forwardRef<TableInstance, IEntityTable>(
  function UsersTable(_props, ref) {
    const dataTable = useEntityDataTable<FullUser, never>({
      schema: schema as never,
      defaultSort,
      forceRequestFields,
    });
    const { t } = useTranslation();

    useDefaultTableFilter({
      columnId: 'hasLeft',
      value: [
        {
          id: false,
          name: t('BOOLEAN_FILTER.false'),
        },
      ],
    });

    useUserLastFilter();

    return (
      <EntityTable
        ref={ref}
        areas={{
          left: {
            title: t('COMMON.ORGANISATION'),
            content: <OrganisationArea entity={Entities.fullUser} />,
            resizable: true,
            collapsible: true,
            custom: true,
          },
        }}
        {...dataTable}
      />
    );
  }
);
