import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IMailboxPickerProps<TMultiple extends boolean> = IPickerProps<
  MailboxConfiguration,
  TMultiple
>;

export function MailboxPicker<TMultiple extends boolean>(
  props: IMailboxPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...MAILBOX_FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.mailboxConfiguration,
      data: allFields,
      completeDataResponse: true,
    };
  }, [allFields]);

  const response = useDataProvider(requestData);

  const dataset = useMemo<IResponse<MailboxConfiguration>>(() => {
    return {
      ...response,
      total: response.data.length,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.mailboxConfiguration}
      data={allFields}
      filterBy={['mailboxPrimaryAddress']}
      sortBy="mailboxPrimaryAddress"
      useSearchHistory={false}
      fixedDataSet={dataset}
      favorites={false}
      {...rest}
      renderItemContent={(mailboxConfiguration: MailboxConfiguration) => (
        <Tooltip title={mailboxConfiguration.smtpAddress}>
          <Typography variant="body2" noWrap>
            {mailboxConfiguration.smtpAddress}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const MAILBOX_FIELDS: MailboxConfiguration = {
  id: null,
  smtpAddress: null,
};
