import styles from './styles.module.scss';

import clsx from 'clsx';
import { CSSProperties, PropsWithChildren } from 'react';

interface SimpleTableProps extends PropsWithChildren {
  style?: CSSProperties;
  borders?: { vertical?: boolean; horizontal?: boolean };
}

export const SimpleTable = ({
  children,
  borders,
  ...rest
}: SimpleTableProps) => {
  return (
    <table
      className={clsx(styles.table, {
        [styles.borderHorizontal]: borders?.horizontal,
        [styles.borderVertical]: borders?.vertical,
      })}
      {...rest}
    >
      {children}
    </table>
  );
};
