import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ContactsPopoverData } from '../containers/mask-overlays/mask-overlay/views/ticket/components/contacts-popover/ContactsPopover';

export const useTicketContactsPopoverData = (
  id: string | undefined
): ContactsPopoverData | null => {
  const requestData = useMemo(
    () => ({
      entity: Entities.ticket,
      filter: [{ id: { $eq: id } }],
      data: {
        id: null,
        businessPartner: {
          id: null,
          businessPartnerType: null,
          data: {
            customer: {
              id: null,
              contactList: [
                {
                  id: null,
                  displayName: null,
                  layedOff: null,
                },
              ],
            },
          },
        },
        creator: {
          id: null,
          displayName: null,
        },
        editor1: {
          id: null,
          displayName: null,
        },
        editor2: {
          id: null,
          displayName: null,
        },
        contact: {
          id: null,
          displayName: null,
        },
      },
    }),
    [id]
  );

  const { data, loading } = useDataProvider<Ticket>(requestData, !id);

  return useMemo(() => {
    if (loading || !id || !data[0]) return null;

    return {
      businessPartner: data[0].businessPartner,
      creator: data[0].creator,
      editor1: data[0].editor1,
      editor2: data[0].editor2,
      highlightedExternalContact: data[0].contact,
    };
  }, [data, id, loading]);
};
