import style from './ErpDialog.module.scss';

import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import clsx from 'clsx';
import i18next from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { Dialog, useDialogs } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';

import { ErpObjectConversionRequest } from '@work4all/models/lib/Classes/ErpObjectConversionRequest.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { canSeeSellingPrices } from '@work4all/utils/lib/permissions';

import {
  BaseActionButton,
  Chip,
  LabeledInput,
} from '../../../containers/mask-overlays/locked-inputs';
import { ControlWrapper } from '../../../containers/mask-overlays/mask-overlay/components';
import { LockOverride } from '../../../containers/mask-overlays/mask-overlay/components/LockOverride';
import { getRequiredEntity } from '../../../containers/mask-overlays/mask-overlay/views/erp/getRequiredEntity';
import { settings, useSetting } from '../../../settings';
import { PartnerPickerByEntity } from '../../entity-picker/PartnerPickerByEntity';

import { ErpDialogArticleGroupedPositions } from './components/ErpDialogArticleGroupedPositions/ErpDialogArticleGroupedPositions';
import {
  ErpDialogPositions,
  OnItemsChangedOptions,
} from './components/ErpDialogPositions';
import { ErpDialogSupplierGroupedPositions } from './components/ErpDialogSupplierGroupedPositions/ErpDialogSupplierGroupedPositions';
import { ErpConversionGroupByType, ErpObject, SelectedPosition } from './types';
import { evaluateErpNumber } from './utils';

const de = i18next.getFixedT('de');

export interface ErpDialogConfirm
  extends Pick<
    ErpObjectConversionRequest,
    'options' | 'positionDetails' | 'targetNewName'
  > {}

export interface ErpDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (confirm: ErpDialogConfirm) => void;
  sourceEntity: Entities;
  targetEntity: Entities;
  title: string;
  data: ErpObject[];
  isBusinessPartnerEditAllowed?: boolean;
  description?: string;
}

export const ErpDialog = (props: ErpDialogProps) => {
  const {
    open,
    onClose,
    onConfirm,
    targetEntity,
    title,
    data,
    sourceEntity,
    isBusinessPartnerEditAllowed = false,
    description: inDescription,
  } = props;

  const { t } = useTranslation();
  const dialogs = useDialogs();

  const initialBusinessPartner = data.length
    ? data[0]?.businessPartnerContactCombined?.businessPartner?.data
    : undefined;

  const [businessPartner, setBusinessPartner] = useState(
    initialBusinessPartner
  );
  const bpEntity = getRequiredEntity(targetEntity);

  const commonCollapseProps = {
    headerClassName: style.header,
    classes: {
      root: style.root,
    },
  };

  const number =
    data.length === 1
      ? `${evaluateErpNumber(sourceEntity, data[0])}`
      : data
          .slice(1, 5)
          .reduce(
            (prev, entry) =>
              `${prev}, ${evaluateErpNumber(sourceEntity, entry)}`,
            `${evaluateErpNumber(sourceEntity, data[0])}`
          ) + (data.length > 5 ? '...' : '');

  const note = data.length === 1 ? data[0]?.note : '';

  const convertDialogPositionMaximized = useSetting(
    settings.convertDialogPositionMaximized()
  );

  const singleBp =
    new Set(
      data
        .map((x) => x?.businessPartnerContactCombined?.businessPartner)
        .filter((x) => x)
        .map((x) => x.id)
    ).size <= 1;

  const [intoSingle, setIntoSingle] = useState(singleBp);
  const [historicalItems, setHistoricalItems] = useState(true);
  const [maximized, setMaximized] = useState<boolean>(
    data.length === 1 ? convertDialogPositionMaximized.value : false
  );
  const lastMaximized = useRef<boolean>(false);

  const handleMaximized = useCallback(
    (input: boolean) => {
      if (data.length === 1) lastMaximized.current = input;
      if (input) setMaximized(true);
    },
    [data.length]
  );

  const handleClose = () => {
    if (data.length === 1)
      convertDialogPositionMaximized.set(lastMaximized.current);
    onClose();
  };

  const erpQuantitySuggestion = useSetting(
    settings.erpConvertQuantitySuggestion()
  );

  // calculate description
  const erpConvertionOriginalName = useSetting(
    settings.erpConvertionOriginalName()
  );
  const [description, setDescription] = useState(note);
  useEffect(() => {
    if (inDescription) {
      setDescription(inDescription);
    } else if (erpConvertionOriginalName.value) {
      setDescription(
        `${de(
          `COMMON.ERP.ABBREVIATION.${sourceEntity.toUpperCase()}`
        )}${number} | ${note?.split('\n')?.[0]}`
      );
    }
  }, [
    erpConvertionOriginalName.value,
    inDescription,
    note,
    number,
    sourceEntity,
    t,
  ]);

  const originalName = description?.startsWith(
    `${de(`COMMON.ERP.ABBREVIATION.${sourceEntity.toUpperCase()}`)}${number} | `
  );

  const [disabled, setDisabled] = useState(false);
  const positions = useRef<Record<number, SelectedPosition[]>>({});
  const onItemsChanged = useCallback(
    (id: number, items: SelectedPosition[], options: OnItemsChangedOptions) => {
      positions.current[id] = items;
      const RELEVANT_FOR_DISABLED: ErpPositionsKind[] = [
        ErpPositionsKind.STANDARD,
        ErpPositionsKind.STUECKLISTE,
      ];
      const notSelectedAtall = Object.values(positions.current)
        ?.flatMap((x) => x)
        .filter((x) => RELEVANT_FOR_DISABLED.includes(x.position.positionKind))
        .every((x) => !x.selected);

      setDisabled(notSelectedAtall && !options.forceEnabled);
    },
    []
  );

  // special cases
  const contractToDeliveryNote =
    (sourceEntity === Entities.order &&
      targetEntity === Entities.inboundDeliveryNote) ||
    (sourceEntity === Entities.contract &&
      targetEntity === Entities.deliveryNote);

  const user = useUser();

  const groupingEnabled =
    sourceEntity === Entities.contract && targetEntity === Entities.order;
  const [groupByType, setGroupByType] = useState<ErpConversionGroupByType>(
    groupingEnabled ? 'ARTICLE' : 'NONE'
  );

  const renderPositions = () => {
    switch (groupByType) {
      case 'ARTICLE':
        return (
          <ErpDialogArticleGroupedPositions
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            onItemsChanged={(pos) => {
              // onItemsChanged(entry.id, pos)
            }}
            data={data}
            onToggled={handleMaximized}
            title={t('COMMON.ERP.GROUP_BY_ARTICLE_TITLE')}
            {...commonCollapseProps}
            defaultOpen={false}
          />
        );
      case 'SUPPLIER':
        return (
          <ErpDialogSupplierGroupedPositions
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            onItemsChanged={(pos) => {
              // onItemsChanged(entry.id, pos)
            }}
            data={data}
            onToggled={handleMaximized}
            {...commonCollapseProps}
            defaultOpen={false}
          />
        );
      case 'NONE':
      default:
        return data.map((entry, idx) => (
          <ErpDialogPositions
            autoOpen={data.length === 1}
            showRowSize={idx === 0}
            maximizedMask={maximized}
            key={entry.id}
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            onItemsChanged={(pos, opt) => onItemsChanged(entry.id, pos, opt)}
            entry={entry}
            onToggled={handleMaximized}
            title={`${t('MASK.POSITIONS')} ${
              data.length > 1
                ? ` |  ${t(`COMMON.${sourceEntity.toUpperCase()}`)} ` +
                  evaluateErpNumber(sourceEntity, entry)
                : ''
            } ${
              entry?.businessPartnerContactCombined?.businessPartner?.data
                ?.name ?? ''
            }`}
            {...commonCollapseProps}
            defaultOpen={false}
            historicalItems={historicalItems}
          />
        ));
    }
  };

  return (
    <Dialog
      open={open}
      title={title}
      onClose={handleClose}
      icon={<ConvertIcon />}
      classes={{
        dialog: {
          paper: clsx(style.minified, {
            [style.maximized]: maximized,
            [style.contractToDeliveryNote]: contractToDeliveryNote && maximized,
            [style.forbiddenSeeSellingPrices]:
              contractToDeliveryNote && maximized && !canSeeSellingPrices(user),
            [style.groupByArticle]: groupByType !== 'NONE',
          }),
        },
      }}
    >
      <Box padding="0 1rem 1rem;">
        <Collapse
          {...commonCollapseProps}
          defaultOpen
          title={t('COMMON.GENERAL_plural')}
          headerBar={
            groupingEnabled && (
              <div className={style.collapse}>
                <div className={style.collapseInner}>
                  <BaseActionButton
                    title={t('COMMON.ERP.GROUP_BY_SUPPLIER')}
                    color={'primary'}
                    onClick={() => setGroupByType('SUPPLIER')}
                  />
                  <BaseActionButton
                    title={
                      data.length > 1
                        ? t('COMMON.ERP.GROUP_BY_ARTICLE')
                        : t('COMMON.ERP.GROUP_BY_ORIGINAL')
                    }
                    color={'primary'}
                    onClick={() => setGroupByType('ARTICLE')}
                  />
                </div>
              </div>
            )
          }
        >
          <Box
            display="flex"
            flexDirection={maximized ? 'row' : 'column'}
            gap="0.5rem"
          >
            {data.length === 1 && (
              <LabeledInput
                disabled
                value={data[0]?.note}
                label={`${t(`COMMON.${sourceEntity.toUpperCase()}`)} ${number}`}
              />
            )}
            {data.length > 1 && (
              <LabeledInput
                disabled
                value={
                  <ChipList>
                    {data.map((item, idx) => {
                      return (
                        <Chip
                          key={idx}
                          withSeperator={false}
                          maxWidth={false}
                          label={`${evaluateErpNumber(sourceEntity, item)}`}
                          disabled
                        />
                      );
                    })}
                  </ChipList>
                }
                label={t(`COMMON.${sourceEntity.toUpperCase()}_plural`)}
              />
            )}

            {!groupingEnabled && singleBp && (
              <LockOverride forceLock={!isBusinessPartnerEditAllowed}>
                <PartnerPickerByEntity
                  entity={bpEntity}
                  businessPartner={businessPartner}
                  onChange={(array) => {
                    setBusinessPartner(array[0]);
                  }}
                />
              </LockOverride>
            )}
          </Box>
        </Collapse>
        {!groupingEnabled && data?.length > 1 && (
          <Collapse
            {...commonCollapseProps}
            defaultOpen
            title={t('COMMON.ERP.CONVERT.TYPE')}
          >
            <RadioGroup
              defaultValue="1"
              value={intoSingle}
              onChange={(_event, value) => {
                setIntoSingle(value === 'true');
              }}
            >
              <FormControlLabel
                value={true}
                disabled={!singleBp}
                control={<Radio />}
                label={t(
                  `COMMON.ERP.CONVERT.ONE.${targetEntity.toUpperCase()}`
                )}
              />
              <FormControlLabel
                value={false}
                disabled={!singleBp}
                control={<Radio />}
                label={t('COMMON.ERP.CONVERT.MULTI', {
                  object: t(`COMMON.${targetEntity.toUpperCase()}_plural`),
                })}
              />
            </RadioGroup>
          </Collapse>
        )}

        <Collapse
          defaultOpen
          {...commonCollapseProps}
          title={t('COMMON.SETTINGS')}
        >
          {(data.length === 1 || intoSingle) && (
            <ControlWrapper>
              <LabeledInput
                label={t('INPUTS.DESCRIPTION')}
                value={description}
                onChange={(e) => {
                  setDescription(e.currentTarget.value);
                }}
              />
            </ControlWrapper>
          )}

          {!props.description && (
            <CheckboxRadioItem
              label={t('COMMON.ERP.CONVERT.ORIGINAL_OBJECT_IN_NAME')}
              control="checkbox"
              checked={originalName}
              // @ts-expect-error The type for this component's props is wrong
              onChange={(_event: unknown, value: boolean) => {
                if (value) {
                  setDescription(
                    `${de(
                      `COMMON.ERP.ABBREVIATION.${sourceEntity.toUpperCase()}`
                    )}${number} | ${description}`
                  );
                } else {
                  setDescription(
                    description.replace(
                      `${de(
                        `COMMON.ERP.ABBREVIATION.${sourceEntity.toUpperCase()}`
                      )}${number} | `,
                      ''
                    )
                  );
                }
              }}
            />
          )}
          <CheckboxRadioItem
            label={t('COMMON.ERP.CONVERT.HISTORICAL_ITEMS')}
            control="checkbox"
            checked={historicalItems}
            // @ts-expect-error The type for this component's props is wrong
            onChange={(_event: unknown, value: boolean) => {
              setHistoricalItems(value);
            }}
          />
        </Collapse>
        {renderPositions()}
        <Box display="flex" justifyContent="space-around" paddingTop="1rem">
          <Button
            sx={{
              flex: 1,
            }}
            onClick={handleClose}
          >
            {t('ALERTS.BTN_ABORT')}
          </Button>
          <Button
            disabled={groupingEnabled || disabled}
            sx={{
              flex: 1,
            }}
            onClick={async () => {
              setDisabled(true);

              if (data.length === 1)
                convertDialogPositionMaximized.set(lastMaximized.current);
              const submitData = {
                targetNewName: description,
                positionDetails: Object.values(positions.current)
                  .flatMap((x) => x)
                  .filter((x) => x.newAmount !== x.oldAmount || !x.selected)
                  .map((x) => ({
                    positionCode: x.position.id,
                    doNotConvert: !x.selected,
                    newAmount: x.newAmount,
                  })),
                options: {
                  createHistoryPosition: historicalItems,
                  createMultipleObjects: !intoSingle,
                },
              };

              erpConvertionOriginalName.set(originalName);
              if (data.length === 1 || intoSingle) {
                onConfirm(submitData);
                return;
              }

              const confirmed = await dialogs.confirm({
                title: t('COMMON.ERP.CONVERSION_CONFIRM_TITLE'),
                description: t('COMMON.ERP.CONVERSION_CONFIRM_MESSAGE'),
              });
              if (confirmed) onConfirm(submitData);
              else setDisabled(false);
            }}
          >
            {t('COMMON.OK')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
