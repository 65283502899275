import { DoesUserHavePermissionFunction } from './types';

export const doesUserHavePermission: DoesUserHavePermissionFunction = ({
  user,
  entity,
  record,
  permission,
  permissionsFunctions,
}) => {
  if (!permissionsFunctions) {
    console.warn(`Permission functions aren't defined for entity ${entity}`);
    return false;
  }

  const permissionFunction = permissionsFunctions[permission];

  if (!permissionFunction) {
    console.warn(
      `${permission} permission function isn't defined for entity ${entity}`
    );
    return false;
  }

  return permissionFunction(
    {
      benutzerCode: user.benutzerCode,
      benutzerRechte: user.benutzerRechte,
    },
    record
  );
};
