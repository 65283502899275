import styles from './../MaskOverlay.module.scss';

import { PropsWithChildren } from 'react';
import { FormProvider, UnpackNestedValue } from 'react-hook-form';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { MaskTabContext } from '../../mask-tabs';
import {
  Form,
  MaskOverlayHeader,
  MaskOverlayStandardSubmitButton,
} from '../components';
import { MaskOverlayDeleteMenuItem } from '../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayMenuWrapper } from '../components/MaskOverlayMenuWrapper';
import { MaskContextProvider } from '../hooks/mask-context';
import { EntityRightsContext } from '../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../hooks/use-standard-delete-entity-handler';
import { MaskControllerProps } from '../types';

import { useMaskOverlay } from './use-mask-overlay';

interface OverlayControllerProps<T>
  extends ReturnType<typeof useMaskOverlay<T>>,
    MaskControllerProps {
  onSubmit: (values: UnpackNestedValue<T>) => Promise<void>;
  tabs?: JSX.Element;
  actions?: JSX.Element;
}

export function OverlayController<T>(
  props: PropsWithChildren<OverlayControllerProps<T>>
) {
  const {
    mask,
    onSubmit,
    tabs,
    children,
    actions,
    form,
    maskContext,
    title,
    subTitle,
    submitButtonProps,
    entityRights,
    tempFileManager,
  } = props;

  // delete props
  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(props);

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <FormProvider {...form}>
          <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
            <Form
              className={styles.maskForm}
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <TempFileManagerContext.Provider value={tempFileManager}>
                <MaskOverlayHeader
                  title={title}
                  subTitle={subTitle}
                  actions={
                    <>
                      {actions}
                      <MaskOverlayStandardSubmitButton {...submitButtonProps} />
                      <MaskOverlayFullscreenToggleButton />
                      {mask.isEditMode && (
                        <MaskOverlayMenuWrapper>
                          <MaskOverlayDeleteMenuItem
                            disabled={!entityRights.delete || mask.wip}
                            onClick={handleDeleteEntitiesClick}
                          />
                        </MaskOverlayMenuWrapper>
                      )}
                    </>
                  }
                  tabs={tabs}
                />
                {children}
              </TempFileManagerContext.Provider>
            </Form>
          </MaskTabContext>
        </FormProvider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
}
