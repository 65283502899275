import styles from './../../MaskOverlay.module.scss';

import ShareIcon from '@mui/icons-material/Share';
import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentRenameContext } from '@work4all/components/lib/components/entity-preview/FileListPreview/attachment-rename-context';
import { useAttachmentRenameContextState } from '@work4all/components/lib/components/entity-preview/FileListPreview/use-attachment-rename-context-state';

import { IUser, useUser } from '@work4all/data';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { parseNameAndExtension } from '@work4all/data/lib/utils';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { InputBriefRelation } from '@work4all/models/lib/Classes/InputBriefRelation.entity';
import { InputDokumentRelation } from '@work4all/models/lib/Classes/InputDokumentRelation.entity';
import { InputLetterDocumentFileRelation } from '@work4all/models/lib/Classes/InputLetterDocumentFileRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useBinaryTransfer } from '../../../../../hooks/useBinaryTransfer';
import { settings, useSetting } from '../../../../../settings';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContext,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { DocumentMaskContext } from './context';
import { CreateDocumentEntry, DocumentInit } from './CreateDocumentEntry';
import { useDocumentsRequestData } from './hooks/use-documents-request-data';
import { DocumentMaskFormValue, DocumentsTypesIntersection } from './types';
import { useMutateDocumentFiles } from './use-mutate-child-documents';

export const considerExternalSourcesRegExp = /^(ftp|http(s)|ssh|wss):\/\/(.*)/i;
//structure of the entity that can be edited
export enum DocumentsGroups {
  general = 'general',
}

export const DocumentsOverlayController = (props: MaskControllerProps) => {
  const { t, i18n } = useTranslation();

  const mask = useMaskConfig(props);
  const { params } = mask;
  const { basedon, customizedTitle } = params;

  let maskName = mask.entityVariant || t(`COMMON.${mask.entity.toUpperCase()}`);
  if (customizedTitle) {
    maskName = i18n.exists(customizedTitle)
      ? t(customizedTitle)
      : customizedTitle;
  }

  const bt = useBinaryTransfer();

  const [init, setInit] = useState<DocumentInit | null>(null);

  const maskContext = useMaskContextValue({ ...mask, data: {} });

  useEffect(() => {
    // The mask can only be initialized once. If init is not null, we don't need
    // to do anything.
    if (init !== null) {
      return;
    }

    if (basedon === 'transfer' && bt.transferData) {
      const file = bt.transferData[0];

      if (!file) {
        console.warn('No file was found in the BinaryTransfer');
        return;
      }

      setInit({
        type: 'file',
        content: [file],
      });
    }
  }, []);

  if (mask.mode === 'create' && init === null) {
    return (
      <MaskContextProvider value={maskContext}>
        <Box display="flex" flexDirection="column" height={'100%'}>
          <MaskOverlayHeader title={maskName} tabs={null} />
          <div className={styles.tabsWrapper}>
            <CreateDocumentEntry
              multiple={mask.entity === Entities.document}
              onInitReady={setInit}
            />
          </div>
        </Box>
      </MaskContextProvider>
    );
  } else {
    return <DocumentsForm {...props} init={init} />;
  }
};

interface DocumentsFormProps extends MaskControllerProps {
  init: DocumentInit | null;
}

const DocumentsForm = (props: DocumentsFormProps) => {
  const { t, i18n } = useTranslation();
  const mask = useMaskConfig(props);

  const { init } = props;
  const { params } = mask;
  const { customizedTitle, presetFields = '{}' } = params;

  let maskName = mask.entityVariant || t(`COMMON.${mask.entity.toUpperCase()}`);
  if (customizedTitle) {
    maskName = i18n.exists(customizedTitle)
      ? t(customizedTitle)
      : customizedTitle;
  }

  const presetData = JSON.parse(presetFields);
  const { value: defaultTemplate, set: saveAsDefaultTemplate } = useSetting(
    settings.lastUsedDocTemplate({
      entity: mask.entity,
      docType: mask.entityVariant || 'genericdoc',
    })
  );

  const user = useUser();

  const request = useDocumentsRequestData(mask.entity, mask.id);

  const template = useAssignableTemplateEntity(mask);

  const newEntityData = useMemo(() => {
    const fileName = init ? getNameFromInit(init) : '';

    const data = createDefaultDocumentData({
      filePath: fileName,
      note:
        init?.type === 'link' ? fileName : parseNameAndExtension(fileName).name,
      template,
      user,
    });

    return {
      ...data,
      ...presetData,
      basedOnTemplate: defaultTemplate,
      infoWindowName: mask.entityVariant ?? '',
    };

    //we only try to use the defaultTemplate initally, not after we have changed it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, template, user, presetFields]);

  const customRules = useCallback(
    (data) => {
      if (init?.type === 'template' && !data.basedOnTemplate) {
        return {
          basedOnTemplate: {
            message: t('ERROR.REQUIRED_TEMPLATE'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [init?.type, t]
  );

  const overlay = useMaskOverlay<DocumentMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customRules,
    getSubTitle: (x) => x.note,
  });

  const { form, tempFileManager, dataRaw, initialFormValue } = overlay;
  const { formState, getValues } = form;

  useEffect(() => {
    if (mask.mode === 'create') {
      if (!init) {
        throw new Error('No init data was provided for document mask');
      }

      if (init.type === 'file') {
        tempFileManager.resetChanges();
        tempFileManager.uploadFiles(init.content);
      }
    }
    // The TempFileManager is not needed in the dependencies array as it
    // changing by itself should not trigger anything.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mask.mode, init]);

  const basedOnTemplate = form.watch('basedOnTemplate');

  useEffect(() => {
    if (basedOnTemplate) {
      saveAsDefaultTemplate(basedOnTemplate);
    }
  }, [basedOnTemplate, saveAsDefaultTemplate]);

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const [mutate] = useDataMutation<
    DocumentsTypesIntersection<EMode.entity>,
    EMode.upsert,
    InputDokumentRelation | InputBriefRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData:
      request.data as unknown as DocumentsTypesIntersection<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data as Document | Letter);
      }
      props.onAfterSave(data);
    },
  });

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },

      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const projectSubDirectories = project?.projectSubDirectories ?? [];

        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return {
            projectId,
            assignedToFolder: null,
            projectSubDirectories,
          };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectSubDirectories,
            'businessPartner.data': businessPartner,
          };
        }
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
    },
    form
  );

  const attachmentRenameContextState = useAttachmentRenameContextState({
    nameDisabled: mask.isEditMode,
    noteDisabled: false,
  });

  const { mutateDocumentFiles } = useMutateDocumentFiles({
    childDocumentList: [dataRaw, ...(dataRaw.childDocuments || [])],
    entity: mask.entity,
    getNote: attachmentRenameContextState.getNote,
  });

  const submitForm = async (data) => {
    const assignedToFolder = getValues('assignedToFolder');
    const currentTopicMarkList = getValues('topicMarkList');
    const filePath = getValues('filePath');
    const basedOnTemplate = getValues('basedOnTemplate');

    function makeRelations() {
      let topic: InputTopicMarkRelation = undefined;

      if (currentTopicMarkList) {
        topic = {
          set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
        };
      }

      let fileContent: InputLetterDocumentFileRelation = undefined;
      let fileContents: InputLetterDocumentFileRelation[] = undefined;

      if (mask.mode === 'create') {
        if (init.type === 'template') {
          if (basedOnTemplate) {
            fileContent = {
              subDirectory: assignedToFolder?.name,
              targetfileName: filePath,
              templateCode: basedOnTemplate.id,
            };
          }
        }

        if (init.type === 'file') {
          const files = tempFileManager.temporaryFileUploads;

          if (mask.entity === Entities.document) {
            if (files.length > 0) {
              fileContents = tempFileManager.temporaryFileUploads.map(
                (attachment) => {
                  const attachmentName =
                    attachmentRenameContextState.getName(attachment.id) ??
                    attachment.fileName;

                  return {
                    subDirectory: assignedToFolder?.name,
                    targetfileName: attachmentName,
                    tempFileId: attachment.id as string,
                    bodyHtml:
                      attachmentRenameContextState.getNote(attachment.id) ||
                      ' ',
                  };
                }
              );
            }
          } else {
            const firstFile = files[0];

            fileContent = {
              subDirectory: assignedToFolder?.name,
              targetfileName: firstFile.fileName,
              tempFileId: firstFile.id as string,
            };
          }
        }
      }

      const result =
        topic || fileContent || fileContents
          ? { topic, fileContent, fileContents }
          : undefined;
      return result;
    }

    const relations = makeRelations();

    if (mask.mode === 'edit') {
      const keysToMutate = { id: data.id };
      Object.keys(formState.dirtyFields).forEach((key) => {
        keysToMutate[key] = data[key];
      });
      await mutateDocumentFiles();
      await mutate(keysToMutate, relations ? { relations } : undefined);
    } else {
      await mutate(data, relations ? { relations } : undefined);
    }
  };

  let documentType = init?.type;
  if (mask.isEditMode) {
    documentType = 'file';
    if (considerExternalSourcesRegExp.exec(initialFormValue.value?.filePath)) {
      documentType = 'link';
    }
  }

  const maskExtras = useMemo(() => {
    const uploadFiles = tempFileManager.uploadFiles;
    const markFilesToDelete = tempFileManager.markFilesToDelete;
    const resetChanges = tempFileManager.resetChanges;

    return {
      type: documentType,
      addFiles(files: File[]): void {
        uploadFiles(files);
      },
      removeFile(id: string): void {
        markFilesToDelete([id]);
      },
      setFiles(files: File[]): void {
        resetChanges();
        uploadFiles(files);

        const file = files[0];

        //eslint-disable-next-line
        //@ts-ignore
        form.setValue('filePath', file.name);

        if (!form.getFieldState('note').isTouched) {
          form.setValue('note', file.name);
        }
      },
    };
  }, [
    tempFileManager.uploadFiles,
    tempFileManager.markFilesToDelete,
    tempFileManager.resetChanges,
    documentType,
    form,
  ]);

  overlay.submitButtonProps.disabled =
    overlay.submitButtonProps.disabled && !attachmentRenameContextState.isDirty;
  return (
    <DocumentMaskContext.Provider value={maskExtras}>
      <OverlayController<DocumentMaskFormValue>
        {...overlay}
        onSubmit={submitForm}
        actions={
          mask.isEditMode ? (
            <Button disabled size="large" startIcon={<ShareIcon />}>
              {t('INPUTS.SHARE')}
            </Button>
          ) : undefined
        }
        tabs={<DocumentTabs isCreateMode={mask.isCreateMode} />}
      >
        <AttachmentRenameContext.Provider value={attachmentRenameContextState}>
          <Content />
        </AttachmentRenameContext.Provider>
      </OverlayController>
    </DocumentMaskContext.Provider>
  );
};

const DocumentTabs = memo(function DocumentTabs({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function DocumentTabPanels() {
  const mask = useMaskContext();
  const { setNote } = useContext(AttachmentRenameContext);
  const { watch } = useFormContextPlus<DocumentMaskFormValue>();

  const { id, childDocuments, noteHtml } = watch();

  useEffect(() => {
    if (mask.entity !== Entities.document || !id) return;

    setNote(id.toString(), noteHtml);
    childDocuments.forEach((doc) => {
      const id = (doc as Document)?.id;
      setNote(id, doc?.noteHtml);
    });
  }, [id, noteHtml, setNote, childDocuments, mask.entity]);

  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});

type ICreateDocumentDefaultParams = {
  note?: string;
  template: AssignableEntityResult;
  user: IUser;
  filePath?: string;
};

function createDefaultDocumentData(
  params: ICreateDocumentDefaultParams
): DocumentsTypesIntersection<EMode.entity> {
  const { note = '', template, user, filePath = '' } = params;

  const common: DocumentsTypesIntersection<EMode.entity> = {
    note: note,
    filePath,
    date: DateTime.now().toISO(),
    user: {
      displayName: `${user.firstName} ${user.lastName}`,
      id: user.benutzerCode,
    },
    userId: user.benutzerCode,
    creatorUserId: user.benutzerCode,
    creator: {
      displayName: `${user.firstName} ${user.lastName}`,
      id: user.benutzerCode,
    },
    topicMarkList: null,
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const data: DocumentsTypesIntersection<EMode.entity> = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartner?.businessPartnerType ??
        template.data.businessPartnerType ??
        SdObjType.LIEFERANT,
      contact: template.data.contact,
      contactId: template.data.contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id || 0,
      topicMarkList: template.data.topicMarkList,
    };
    return data;
  }

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    businessPartnerType: SdObjType.LIEFERANT,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
  };
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = (
      businessPartner as unknown as Record<string, string>
    ).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

const getNameFromInit = (init: DocumentInit) => {
  switch (init.type) {
    case 'file':
      if (init.content.length === 1) {
        return init.content[0].name;
      } else {
        return '';
      }
    case 'link':
      return init.content;
    default:
      return '';
  }
};
