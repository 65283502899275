import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

export const defaultErpGetRelation = (position: Position) => {
  if (position.posId) return 'child';
  if (
    position.positionKind === ErpPositionsKind.STUECKLISTE ||
    ('collapsed' in position && position.collapsed)
  ) {
    return 'parent';
  }
  return 'normal';
};
