import { gql, useApolloClient } from '@apollo/client';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMAIL_SIGNATURE_PICKER_DATA,
  EmailSignaturePicker,
  EmailSignaturePickerProps,
} from '@work4all/components/lib/components/entity-picker/EmailSignaturePicker';

import { useUser } from '@work4all/data';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canAddEmailSignature } from '@work4all/utils/lib/permissions';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface EmailSignaturePickerFieldProps extends EmailSignaturePickerProps {
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const EmailSignaturePickerField = React.forwardRef<
  HTMLDivElement,
  EmailSignaturePickerFieldProps
>(function EmailSignaturePickerField(props, ref) {
  const { error, disabled = false, label, ...pickerProps } = props;
  const { value, onChange, data, clearable = true } = pickerProps;

  const { t } = useTranslation();
  const user = useUser();

  const client = useApolloClient();

  const popoverRef = useRef<EntityPickerPopover>(null);

  const allFields = useMemo(() => {
    return { ...EMAIL_SIGNATURE_PICKER_DATA, ...data };
  }, [data]);

  const handleSignatureListSelection = async (signature: EMailSignature) => {
    const emailSignatures = await client.query({
      fetchPolicy: 'no-cache',
      query: GET_EMAIL_SIGNATURES,
      variables: {
        filter: JSON.stringify([{ id: { $eq: signature.id } }]),
        ...EMAIL_SIGNATURE_KEYS_ARGUMENTS.body,
        querySize: 100,
      },
    });

    onChange(emailSignatures?.data?.getEMailSignaturen2.data[0]);
    popoverRef.current?.close();
  };

  const field = (
    <PickerTargetButton
      ref={ref}
      value={value ? value.name : ''}
      label={label ?? t('INPUTS.SIGNATURE')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<EmailSignaturePicker {...pickerProps} />}
      footer={
        <EntityPickerActions
          disableAddButton={!canAddEmailSignature(user)}
          entity={Entities.eMailSignature}
          multiple={false}
          data={allFields}
          onChange={handleSignatureListSelection}
        />
      }
    >
      {field}
    </EntityPickerPopover>
  );
});

const GET_EMAIL_SIGNATURES = gql`
  query getEMailSignaturen2(
    $querySize: Int
    $filter: String
    $resolveInlineImages: Boolean
    $resolveTextmarken: Boolean
  ) {
    getEMailSignaturen2(querySize: $querySize, filter: $filter) {
      total
      size
      page
      data {
        body: body(
          resolveInlineImages: $resolveInlineImages
          resolveTextmarken: $resolveTextmarken
        )
        id
        name
      }
    }
  }
`;
