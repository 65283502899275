import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Article } from './Article.entity';

export class BOMComponent<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: komponenteArtikelCode */
  componentArticleId?: number;
  /** Alias: index */
  index?: number;
  /** Alias: anzahl */
  amount?: number;
  /** Alias: name */
  name?: string;
  /** Alias: stuecklisteArtikelCode */
  partslistArticleId?: number;
  /** Alias: nurEinkauf */
  onlyShopping?: boolean;
  /** Alias: nichtBerechnen */
  notCalculate?: boolean;
  /** Alias: laenge */
  length?: number;
  /** Alias: breite */
  width?: number;
  /** Alias: kalkulationsLogik */
  calculationLogic?: number;
  /** Alias: kalkulationslogikFaktor */
  calculationLogicFactor?: number;
  /** Alias: article */
  article?: Article<T>;
  /** Alias: notiz */
  note?: string;

  __typename?: string;
}

const fields: FieldDefinitions<BOMComponent> = {
  id: {
    alias: 'code',
  },
  componentArticleId: {
    alias: 'komponenteArtikelCode',
  },
  index: {
    alias: 'index',
  },
  amount: {
    alias: 'anzahl',
  },
  name: {
    alias: 'name',
  },
  partslistArticleId: {
    alias: 'stuecklisteArtikelCode',
  },
  onlyShopping: {
    alias: 'nurEinkauf',
  },
  notCalculate: {
    alias: 'nichtBerechnen',
  },
  length: {
    alias: 'laenge',
  },
  width: {
    alias: 'breite',
  },
  calculationLogic: {
    alias: 'kalkulationsLogik',
  },
  calculationLogicFactor: {
    alias: 'kalkulationslogikFaktor',
  },
  article: {
    alias: 'article',
    entity: Entities.article,
  },
  note: {
    alias: 'notiz',
  },
  __typename: {
    alias: '__typename',
  },
};

export const bOMComponentEntityDefinition: EntitiyDefinition<BOMComponent> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Komponente',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
