import styles from './ControlWrapper.module.scss';

import clsx from 'clsx';
import React, { CSSProperties, ReactNode } from 'react';

interface IControlWrapperProps {
  className?: string;
  paddingBottom?: boolean | 'lg' | 'md';
  paddingTop?: boolean | 'lg' | 'md';
  children?: ReactNode;
  columns?: number;
  grid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: any; //TODO: type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asProps?: any; //TODO: type
  gridColumn?: string;
  fullWidth?: boolean;
  gridTemplateColumns?: string;
  gap?: string;
}

export const ControlWrapper = React.forwardRef<
  HTMLElement,
  IControlWrapperProps
>(function ControlWrapper(props, ref) {
  const {
    className,
    paddingBottom = true,
    paddingTop = false,
    columns,
    grid = true,
    gridColumn,
    asProps = {},
    fullWidth = true,
    gridTemplateColumns,
    gap,
  } = props;

  let style: CSSProperties = null;
  if (gridTemplateColumns) {
    style = { gridTemplateColumns };
  } else if (columns) {
    style = { gridTemplateColumns: `repeat(${columns}, 1fr)` };
  }

  if (gridColumn) {
    style = Object.assign({}, style, { gridColumn });
  }

  if (gap) {
    style.gap = gap;
  }

  const Component = props.as ? props.as : 'div';

  return (
    <Component
      ref={ref}
      style={style}
      className={clsx(
        styles.wrapper,
        {
          [styles.grid]: grid,
          [styles.outerSize]: !fullWidth,
          [styles.columns]: grid && typeof columns === 'undefined',
          [styles.flex]: !grid,
          [styles.flexColumn]: !grid && columns <= 1,
          [styles.paddingTop]: paddingTop,
          [styles[`paddingTop--${paddingTop}`]]: typeof paddingTop === 'string',
          [styles.paddingBottom]: paddingBottom,
          [styles[`paddingBottom--${paddingBottom}`]]:
            typeof paddingBottom === 'string',
        },
        className
      )}
      {...asProps}
    >
      {props.children}
    </Component>
  );
});
