import styles from './TimeCardPreview.module.scss';

import { DateTime } from 'luxon';
import React from 'react';

import { TimeInputPicker } from '@work4all/components/lib/input/time-input-picker';

import { UserPresenceTimeInfoItem } from '@work4all/models/lib/Classes/UserPresenceTimeInfoItem.entity';

import { useTimeEntryMutation } from './hooks/useTimeEntryMutation';

interface Props extends UserPresenceTimeInfoItem {
  icon: React.ReactNode;
  label: string;
  disabled: boolean;
  onClear: () => void;
}

export const TimeCardPreviewTimeStamp: React.FC<Props> = (props: Props) => {
  const { id, label, timestamp, icon, disabled, onClear } = props;

  const { update, onBlur } = useTimeEntryMutation();

  return (
    <div key={`stamp_${id}`} className={styles['time-preview__row']}>
      <div className={styles['time-preview__icon']}>{icon}</div>
      <div className={styles['time-preview__item']}>
        <TimeInputPicker
          onChange={(e) => {
            update(id, e.currentTarget?.value);
          }}
          onDestroyPopover={onBlur}
          disabled={disabled}
          label={label}
          value={DateTime.fromISO(timestamp)}
          onClear={onClear}
        />
      </div>
    </div>
  );
};
