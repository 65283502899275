import styles from './TimeTrackerMenu.module.scss';

import { ChevronRight } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList } from '@mui/lab';
import { IconButton, Tab, Theme, useMediaQuery } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useCanGoBack } from '@work4all/components/lib/hooks';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';
import {
  IStackItem,
  StackProvider,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import {
  AppParts,
  IUser,
  useCanView,
  useNavigate,
  useUser,
} from '@work4all/data';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TimeTrackingKind } from '@work4all/models/lib/Enums/TimeTrackingKind.enum';

import { canEditTimeTrackingForOthers } from '@work4all/utils/lib/permissions';

import { useSuggestionsContext } from '../../contexts/SuggestionsContext';
import { usePageTitle } from '../../hooks';
import { settings, useSetting } from '../../settings';
import { StatisticTimeCardContainer } from '../statistic/components/StatisticTimeCardContainer';

import {
  getStartForNewProjectTime,
  ProjectHours,
  useProjectHoursState,
} from './ProjectHours';
import {
  ProjectTimeMask,
  ProjectTimeMaskInit,
  ProjectTimeMaskProps,
} from './ProjectTimeMask';
import { TimeTemplatesWidget } from './TimeTemplatesWidget';
import { TimeTrackerMenuTabs } from './TimeTrackerMenuTabs';
import { TimeTracker, useTimeTracker } from './use-time-tracker';
import { useTimeTrackerTab } from './use-time-tracker-tab';
import { WorkHours } from './WorkHours';

export interface TimeTrackerMenuProps {
  open: boolean;
  onClose: () => void;
  tracker: TimeTracker;
  onClick: () => void;
}

function TimeTrackerPageHeader(props: {
  workTimeDisabled?: boolean;
  projectTimeDisabled?: boolean;
  onTabChange: (value) => void;
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const canGoBack = useCanGoBack();
  const closeUrl = '/more';

  const title = t(`NAVIGATION.TIMETRACKING`);

  usePageTitle(title);

  return (
    <div className={styles.header}>
      <div className={styles.headerTop}>
        <IconButton
          onClick={() => {
            if (canGoBack) {
              navigate(-1);
            } else {
              navigate(closeUrl);
            }
          }}
          className={styles.goBack}
        >
          {canGoBack ? <ArrowBackIcon /> : <CloseIcon />}
        </IconButton>
      </div>

      <div className={styles.headerTabs}>
        <TabList
          sx={{ padding: '.5rem' }}
          onChange={(e, val) => props.onTabChange(val)}
        >
          {props.workTimeDisabled !== true && (
            <Tab
              label={t('TIME_TRACKER.WORKING_TIME')}
              value={TimeTrackerMenuTabs.workingTime}
              data-test-id="workingTimeTab"
            />
          )}
          {props.projectTimeDisabled !== true && (
            <Tab
              label={t('TIME_TRACKER.PROJECT_TIME')}
              value={TimeTrackerMenuTabs.projectTime}
              data-test-id="projectTimeTab"
            />
          )}
        </TabList>
      </div>
    </div>
  );
}

export function TimeTrackerMenuPage() {
  const tracker = useTimeTracker({
    amplitudeEntryPoint: '',
  });
  const { t } = useTranslation();

  const ContentX = <TimeTrackerMenuContent />;

  return (
    <Context.Provider value={tracker}>
      <StackProvider
        initialView={{
          view: ContentX,
          title: t('NAVIGATION.TIMETRACKING'),
        }}
      >
        <TimeTrackerMenuPageInner />
      </StackProvider>
    </Context.Provider>
  );
}

export function TimeTrackerMenuPageInner() {
  const { current } = useHistoryStack();
  return <>{current.view}</>;
}

const Context = createContext<TimeTracker>(null);

export function TimeTrackerMenu(props: TimeTrackerMenuProps) {
  const { open, onClose, tracker } = props;
  const { t } = useTranslation();

  const [initialView, setInitialView] = useState(() => {
    return {
      view: <TimeTrackerMenuContent />,
      title: t('TIME_TRACKER.TITLE'),
    };
  });

  useEffect(() => {
    if (open) {
      setInitialView({
        view: <TimeTrackerMenuContent />,
        title: t('TIME_TRACKER.TITLE'),
      });
    }
  }, [open, t]);

  return (
    <Context.Provider value={tracker}>
      <NavigationOverlay
        classes={{
          wrapper: styles.wrapper,
          paper: styles.paper,
        }}
        open={open}
        initialView={initialView}
        close={onClose}
      />
    </Context.Provider>
  );
}

const PRESET_DATE_STATE_KEY = 'project-hours-date';
const CHOOSEN_USER_STATE_KEY = 'currently-choosen-time-track-user';

function TimeTrackerMenuContent() {
  const { t } = useTranslation();
  const user = useUser();
  const tracker = useContext(Context);
  const canEditAll = canEditTimeTrackingForOthers(user, undefined);

  const canViewWorkTimeTracking = useCanView(AppParts.WORKTIMETRACKING);
  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);
  const canViewTimeCard = useCanView(AppParts.TIMECARD);

  const { setCurrentViewState, getCurrentViewState } = useHistoryStack();

  const [userToTrack, setUserToTrack] = useState<IUser | User>(
    getCurrentViewState(CHOOSEN_USER_STATE_KEY) || user
  );

  const projectHoursState = useProjectHoursState({ userToTrack });

  const [presetDate] = useState(() => {
    return getCurrentViewState<Date>(PRESET_DATE_STATE_KEY);
  });

  const { hideSuggestion } = useSuggestionsContext();

  const onAdd = useCallback(
    (init, createdFrom?: Entities) => {
      setCurrentViewState(PRESET_DATE_STATE_KEY, init.date);
      const { events, date } = projectHoursState;
      const adjustedDate = getStartForNewProjectTime(
        date.from,
        events,
        tracker
      );

      setAddProjectHoursOpen(true);
      setAddProjectHours({
        init: { date: adjustedDate, ...init },
        createdFrom,
      });
    },
    [setCurrentViewState, projectHoursState, tracker]
  );

  useEffect(() => {
    setCurrentViewState(CHOOSEN_USER_STATE_KEY, userToTrack);
  }, [setCurrentViewState, userToTrack]);

  const handleCopy = useEventCallback(async (item: TimeTracking) => {
    // Copy selected project time item. If it has manual start and end time, set
    // start time after the end time of the last item of today (if any).
    const kind =
      item.timeTrackingKind === TimeTrackingKind.VON_BIS
        ? TimeTrackingKind.VON_BIS
        : TimeTrackingKind.ANZAHL;

    let date = new Date();

    if (kind === TimeTrackingKind.VON_BIS) {
      date = getStartForNewProjectTime(date, projectHoursState.events, tracker);
    }

    const init: ProjectTimeMaskInit = {
      kind: kind,
      date: date,
      projectProcess: item.projectProcess,
      project: item.project,
      customer: item.customer,
      ticket: item.ticket,
      contract: item.contract,
      activityArticle: item.activityArticle,
      activityArticleId: item.activityArticle?.id,
      position: item.position,
      comment: item.comment,
      customFieldList: item.customFieldList,
    };

    setEditProjectHoursView({
      view: (
        <ProjectTimeMask
          user={userToTrack}
          init={init}
          amplitudeEntryPoint="TimeTrackingCopy"
        />
      ),
    });
    setEditProjectHoursOpen(true);
  });

  const onEdit = useCallback(
    (data: TimeTracking) => {
      setCurrentViewState(
        PRESET_DATE_STATE_KEY,
        DateTime.fromISO(data.date).toJSDate()
      );

      setEditProjectHoursOpen(true);
      setEditProjectHoursView({
        view: (
          <ProjectTimeMask
            user={userToTrack}
            item={{ ...data, insertTime: undefined }}
            amplitudeEntryPoint="TimeTrackingMenu"
            onCopy={handleCopy}
          />
        ),
      });
    },
    [handleCopy, setCurrentViewState, userToTrack]
  );

  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const [addProjectHoursOpen, setAddProjectHoursOpen] = useState(false);
  const [editProjectHoursOpen, setEditProjectHoursOpen] = useState(false);
  const [editProjectHoursView, setEditProjectHoursView] =
    useState<IStackItem>(null);

  const [addProjectHours, setAddProjectHours] =
    useState<ProjectTimeMaskProps>();

  const [showTimeTemplatesMask, setShowTimeTemplatesMask] =
    useState<boolean>(false);

  const projectHoursStateInner = useProjectHoursState({
    presetDate,
    userToTrack,
  });

  const projectTimeTrackingDisplayTemplates = useSetting(
    settings.projectTimeTrackingDisplayTemplates()
  );

  const workTimeDisabled = !canViewTimeCard && !canViewWorkTimeTracking;
  const projectTimeDisabled = !canViewProjectTimeTracking;

  const [tab, setTab] = useTimeTrackerTab({
    workTimeDisabled,
    projectTimeDisabled,
  });

  return (
    <TabContext value={tab}>
      <section className={styles.root}>
        <NavigationOverlay
          classes={{
            wrapper: styles.wrapper,
            paper: styles.paper,
          }}
          open={addProjectHoursOpen}
          initialView={{
            view: (
              <ProjectTimeMask
                user={userToTrack}
                init={addProjectHours?.init}
                amplitudeEntryPoint="TimeTrackingMenu"
                onCompleted={() => {
                  const id = addProjectHours?.init?.id;
                  if (id) hideSuggestion(id);
                }}
                createdFrom={addProjectHours?.createdFrom}
              />
            ),
            title: t('TIME_TRACKER.PROJECT_TIME'),
          }}
          close={() => setAddProjectHoursOpen(false)}
        />

        <NavigationOverlay
          classes={{
            wrapper: styles.wrapper,
            paper: styles.paper,
          }}
          open={editProjectHoursOpen}
          initialView={editProjectHoursView}
          close={() => setEditProjectHoursOpen(false)}
        />
        <TimeTrackerPageHeader
          workTimeDisabled={workTimeDisabled}
          projectTimeDisabled={projectTimeDisabled}
          onTabChange={setTab}
        />
        {tab === TimeTrackerMenuTabs.workingTime && (
          <div
            className={styles.workTimeContent}
            data-test-id="workingTimeTabContent"
          >
            <div className={styles.innerWrap}>
              {canViewWorkTimeTracking && (
                <div className={styles.timeContainer}>
                  <WorkHours tracker={tracker} />
                </div>
              )}
              {canViewTimeCard && (
                <div className={styles.statisticsContainer}>
                  <StatisticTimeCardContainer />
                </div>
              )}
            </div>
          </div>
        )}

        {tab === TimeTrackerMenuTabs.projectTime && (
          <div
            className={clsx(styles.projectTimeContent, {
              [styles.mobile]: !isTablet,
            })}
            data-test-id="projectTimeTabContent"
          >
            <div className={styles.projectContainer}>
              <ProjectHours
                userToTrack={userToTrack}
                setUserToTrack={setUserToTrack}
                canEditAll={canEditAll}
                presetDate={presetDate}
                onCreateEvent={onAdd}
                onEventSelected={onEdit}
                tracker={tracker}
                onOpenTimeTemplateMask={() => setShowTimeTemplatesMask(true)}
                {...projectHoursStateInner}
              />
            </div>
            {isTablet && !projectTimeTrackingDisplayTemplates.value && (
              <div>
                <ExpandButton
                  icon={<ChevronRight />}
                  textStart="top"
                  title={t('COMMON.TIMETRACKING.TEMPLATE_plural')}
                  color="text03"
                  onClick={() => projectTimeTrackingDisplayTemplates.set(true)}
                />
              </div>
            )}
            {isTablet && projectTimeTrackingDisplayTemplates.value && (
              <div className={styles.widgetContainer}>
                <TimeTemplatesWidget
                  onTemplateSelect={onAdd}
                  onCollapse={() =>
                    projectTimeTrackingDisplayTemplates.set(false)
                  }
                />
              </div>
            )}
          </div>
        )}

        <NavigationOverlay
          classes={{
            wrapper: styles.dialogWrapper,
            paper: styles.paper,
          }}
          open={showTimeTemplatesMask}
          initialView={{
            view: (
              <TimeTemplatesWidget
                mobileMode={true}
                onTemplateSelect={(init, createdFrom) => {
                  const { events, date } = projectHoursState;
                  const adjustedDate = getStartForNewProjectTime(
                    date.from,
                    events,
                    tracker
                  );

                  onAdd({ date: adjustedDate, ...init }, createdFrom);
                  setShowTimeTemplatesMask(false);
                }}
              />
            ),
            title: 'Templates',
          }}
          close={() => {
            setShowTimeTemplatesMask(false);
          }}
        />
      </section>
    </TabContext>
  );
}
