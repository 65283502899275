import styles from './Login.module.scss';

import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { ArrowBack } from '@mui/icons-material';
import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import md5 from 'md5';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Path } from 'react-router-dom';
import { useRxDB } from 'rxdb-hooks';

import { ReactComponent as CircleNotch } from '@work4all/assets/icons/circle_notch.svg';

import { FixedLengthInput } from '@work4all/components/lib/input/fixed-length-input/FixedLengthInput';
import {
  TakeRestHeight,
  YSplit,
} from '@work4all/components/lib/layout/y-split';

import {
  components,
  httpClient,
  IUser,
  Navigate,
  useLocation,
  useUser,
} from '@work4all/data';
import { USER_META_ID } from '@work4all/data/lib/db';

import { MfaMode } from '@work4all/models/lib/Enums/MfaMode.enum';

import Bubble from '../../assets/icons/Bubble_4.svg';
import { ApplicationName } from '../../utils/ApplicationName.enum';
import { BaseActionButton } from '../mask-overlays/locked-inputs';

import { ErrorMessage } from './components/error-message/ErrorMessage';
import { TextField } from './components/text-field';
import { getAutodiscoveryBaseUrl } from './utils/getAutodiscoveryBaseUrl';

interface IForm {
  apiUrl?: string;
  password: string;
}

const initialValues: components['schemas']['AuthRequestV2'] & IForm = {
  username: '',
  password: '',
  apiUrl: '',
};

const mfaModeByIdx = {
  0: MfaMode.NORMAL,
  1: MfaMode.TOKEN,
  2: MfaMode.MAIL,
};

export type LoginFormikFields = typeof initialValues;

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LoginPageWrapper>
      {({
        onSubmit,
        loading,
        error,
        setAutoDiscovery,
        autodiscovery,
        mfaMode,
        onMfaCancel,
        onMfaSubmit,
      }) => {
        const handleMfaChange = async (
          e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          if (e.target.value.length >= 6) {
            e.target.setSelectionRange(0, 0);
            e.target.blur();
            e.target.disabled = true;
            await onMfaSubmit(e.target.value);
            e.target.disabled = false;
          }
        };

        return (
          <>
            <img className={styles['login__bubble-icon']} src={Bubble} alt="" />
            {!mfaMode && (
              <>
                <div className={styles.titleWrapper}>
                  <Typography
                    className={styles['login__title']}
                    component="h4"
                    variant="h4"
                  >
                    {t('LOGIN.WELCOME')}
                  </Typography>
                </div>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                  <Form className={styles['login__form']}>
                    {error && (
                      <ErrorMessage
                        data-test-id="login-error-message"
                        message={
                          t(`LOGIN.MESSAGE.${error.type}`) ||
                          t('LOGIN.WRONG_PASSWORD')
                        }
                      />
                    )}
                    <TextField
                      label={t('LOGIN.EMAIL')}
                      name="username"
                      type="email"
                      required
                    />
                    <TextField
                      label={t('LOGIN.PASSWORD')}
                      name="password"
                      type="password"
                      required
                    />
                    {!autodiscovery && (
                      <TextField
                        label={t('LOGIN.API_URL')}
                        name="apiUrl"
                        onClear={(formikHelpers) => {
                          formikHelpers.setValue('');
                        }}
                      />
                    )}
                    <ButtonBase className={styles['autodiscovery-controller']}>
                      <FormControlLabel
                        classes={{
                          root: styles['autodiscovery-controller__label-root'],
                          label: styles['autodiscovery-controller__label'],
                        }}
                        checked={autodiscovery}
                        onChange={() => setAutoDiscovery(!autodiscovery)}
                        value="autodiscovery"
                        control={
                          <Checkbox
                            classes={{
                              root: styles[
                                'autodiscovery-controller__checkbox'
                              ],
                              checked:
                                styles[
                                  'autodiscovery-controller__checkbox--checked'
                                ],
                            }}
                            size="small"
                          />
                        }
                        label={t('LOGIN.API_AUTODETECT')}
                      />
                    </ButtonBase>
                    <Button
                      type="submit"
                      className={styles['login__submit-btn']}
                      variant="text"
                      color="secondary"
                    >
                      <span className={styles['login__submit-btn-label']}>
                        {loading ? (
                          <CircleNotch className={styles.circle} />
                        ) : (
                          t('LOGIN.LOGIN')
                        )}
                      </span>
                    </Button>
                  </Form>
                </Formik>
              </>
            )}
            {mfaMode && (
              <>
                <div className={styles.titleWrapper}>
                  <Typography
                    className={styles['login__title']}
                    component="h4"
                    variant="h4"
                  >
                    {t('LOGIN.MFA.TITLE')}
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  className={styles['login__subtitle']}
                >
                  {t('LOGIN.MFA.DESC.' + mfaMode)}
                </Typography>

                <FixedLengthInput
                  length={6}
                  onChange={handleMfaChange}
                  autoFocus
                />

                <Stack className={styles['login__demo-container']}>
                  <BaseActionButton
                    className={styles['login__demo-link']}
                    onClick={onMfaCancel}
                    icon={<ArrowBack />}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className={styles['login__demo-text']}
                    >
                      {t('LOGIN.MFA.BACK')}
                    </Typography>
                  </BaseActionButton>
                </Stack>
              </>
            )}
          </>
        );
      }}
    </LoginPageWrapper>
  );
};

interface LoginPageWrapperProps {
  children: (props: {
    onSubmit: (values: LoginFormikFields) => Promise<void>;
    onMfaSubmit: (mfaCode: string) => Promise<void>;
    onMfaCancel: () => Promise<void>;
    mfaMode: MfaMode;
    loading: boolean;
    error: {
      message: string;
      type: number;
    };
    autodiscovery: boolean;
    setAutoDiscovery: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}

interface AuthLoginBody {
  username: string;
  passwordHash: string;
  application: ApplicationName.work4all20;
  mfaCode: string;
}

export const LoginPageWrapper: React.FC<LoginPageWrapperProps> = (
  props: LoginPageWrapperProps
) => {
  const { t } = useTranslation();
  const [autodiscovery, setAutoDiscovery] = useState(true);
  const db = useRxDB();
  const user = useUser();
  const [error, setError] = useState<{ message: string; type: number } | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const [mfaMode, setMfaMode] = useState<MfaMode>(null);

  const location = useLocation();
  const _username = useRef(null);
  const _passwordHash = useRef(null);
  const _apiUrl = useRef(null);
  const _baseUrl = useRef(null);
  const _autodiscoverRes = useRef(null);
  const _mfaCode = useRef(null);

  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  // Remove jumping on iOS
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
      Keyboard.addListener('keyboardWillShow', (info) => {
        document.getElementById('ios-scroll-fix').style.height = '200px';
      });

      Keyboard.addListener('keyboardWillHide', () => {
        document.getElementById('ios-scroll-fix').style.height = '0';
      });
      return () => {
        Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  if (user) {
    const locationState = location.state as { from: Path };
    return <Navigate to={locationState?.from || '/home'} replace />;
  }

  const getMfaViaMail = async (baseUrl: string, mailAddress: string) => {
    return fetch(baseUrl + '/api/auth/mfacode?mailAddress=' + mailAddress);
  };

  const onMfaCancel = async () => {
    setMfaMode(null);
    _mfaCode.current = '';
  };

  const onSubmit = async (values: LoginFormikFields) => {
    _username.current = values.username.trim();
    _passwordHash.current = md5(values.password);

    if (autodiscovery) {
      _autodiscoverRes.current = await getAutodiscoveryBaseUrl(
        _username.current || ''
      );
      _baseUrl.current = _autodiscoverRes.current?.data?.[0] || '';
      _apiUrl.current = `${_baseUrl.current}/api`;
    } else {
      _apiUrl.current = values.apiUrl?.trim().replace(/\/$/, '') || '';
      _baseUrl.current = new URL(_apiUrl.current).origin;
    }
    performLogin();
  };

  const onMfaSubmit = async (mfaCode: string) => {
    _mfaCode.current = mfaCode;
    await performLogin();
    _mfaCode.current = null;
    setMfaMode(null);
  };

  const performLogin = async () => {
    const baseUrl = _baseUrl.current;
    const apiUrl = _apiUrl.current;
    const username = _username.current;
    const passwordHash = _passwordHash.current;
    const autodiscoverRes = _autodiscoverRes.current;
    setError(null);
    setLoading(true);

    if (autodiscoverRes?.status !== 200 && autodiscovery) {
      setError({
        message: t('LOGIN.MESSAGE'),
        type: autodiscoverRes?.status,
      });
      setLoading(false);
      return;
    }
    try {
      // TODO The API response is not typed correctly after the recent changes.
      const res = await httpClient.post<
        components['schemas']['AuthResultV2'],
        AuthLoginBody
      >({
        url: `${apiUrl}/Auth/login`,
        body: {
          username,
          passwordHash,
          application: ApplicationName.work4all20,
          mfaCode: _mfaCode.current,
        },
        headers: {
          'x-work4all-apiurl': baseUrl,
        },
        contentType: 'application/json',
      });

      if (res.data === null) {
        setError({ message: t('LOGIN.API_NOT_FOUND'), type: 404 });
        setLoading(false);
        return;
      }

      if (res.data.mandanten.length === 0) {
        setError({ message: t('LOGIN.NO_MANDANTEN'), type: 403 });
        setLoading(false);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = res.data as any;

      const roles: IUser['roles'] = data.currentUser.userRoles.map((role) => ({
        id: role.code,
        name: role.name,
      }));

      const user: IUser = {
        benutzerCode: data.benutzerCode,
        benutzerRechte: data.benutzerRechte,
        token: data.token,
        refreshToken: data.refreshToken,
        baseUrl,
        mandanten: data.mandanten,
        roles,
        firstName: data.currentUser.vorname,
        lastName: data.currentUser.nachname,
        shortName: data.currentUser.zeichen,
        displayName: data.currentUser.anzeigename,
        salutation: data.currentUser.briefAnrede,
        phone: data.currentUser.telefon,
        email: data.currentUser.eMail,
        departmentName: data.currentUser.abteilung,
        isMaster: data.currentUser.master,
        kundennummer: data.kundennummer,
        senderMailaddresses: data.currentUser.senderMailaddresses,
        kommtGehtMinimaleStartZeit: data.currentUser.kommtGehtMinimaleStartZeit,
        mfaMode: data.currentUser.mfaMode
          ? mfaModeByIdx[data.currentUser.mfaMode]
          : undefined,
        supplierCode: data.currentUser.lieferantenCode,
        workTimeTrackingMinBreakTime:
          data.currentUser.workTimeTrackingMinBreakTime,
      };

      sessionStorage.removeItem('currentTimeSettings');
      await db.upsertLocal(USER_META_ID, {
        user,
      });
    } catch (error) {
      console.error('Login Error:', error);
      const errorData = JSON.parse(error?.data);
      if (errorData?.type === 15) {
        setMfaMode(mfaModeByIdx[errorData?.mfaMode]);
        if (mfaModeByIdx[errorData?.mfaMode] === MfaMode.MAIL) {
          getMfaViaMail(baseUrl, username);
        }
      } else {
        setError({
          message: errorData?.message,
          type: errorData?.type,
        });
      }
    }

    if (!unmountedRef.current) {
      setLoading(false);
    }
  };

  return (
    <YSplit>
      <TakeRestHeight>
        <div className={styles.login}>
          <div className={styles['login__top-block']} />
          {props.children({
            onSubmit,
            onMfaSubmit,
            onMfaCancel,
            mfaMode,
            loading,
            error,
            autodiscovery,
            setAutoDiscovery,
          })}
        </div>
        <div id="ios-scroll-fix" />
      </TakeRestHeight>
    </YSplit>
  );
};
