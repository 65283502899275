import { useEffect, useState } from 'react';
import { TableInstance } from 'react-table';

import { TableStateBagProvider } from '@work4all/components';
import { TablePrefilterProvider } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { throwInDev } from '@work4all/utils';

import { BottomTableViewProps } from './types';

export const BottomViewsTableView = ({
  entity,
  prefilter,
}: BottomTableViewProps) => {
  const [tables, setTables] = useState(null);
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  useEffect(() => {
    (() => {
      import('../../../containers/file-entities-lists').then((res) =>
        setTables(res.EntityToTable)
      );
    })();
  }, []);

  if (!tables) return null;

  const Table = tables[entity];

  if (!Table) {
    throwInDev(`Table for entity ${entity} is not defined.`);
    return null;
  }

  return (
    <TablePrefilterProvider value={{ prefilter }}>
      <TableStateBagProvider tableInstance={tableInstance}>
        <Table ref={setTableInstance} />
      </TableStateBagProvider>
    </TablePrefilterProvider>
  );
};
