import { useCallback } from 'react';

import { useDataMutation } from '@work4all/data';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DocumentsTypesIntersection } from './types';

interface Props {
  entity: Entities;
  getNote: (id: string | number) => string;
  childDocumentList: (Document | Letter)[];
}

export const useMutateDocumentFiles = (props: Props) => {
  const { entity, getNote, childDocumentList } = props;

  const [mutate] = useDataMutation<
    DocumentsTypesIntersection<EMode.entity>,
    EMode.upsert
  >({
    entity,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
  });

  const mutateDocumentFiles = useCallback(async () => {
    const promises: (void | Promise<void | DocumentsTypesIntersection<EMode.entity>>)[] =
      [];
    childDocumentList.forEach((doc) => {
      const updatedNote = getNote(doc.id);
      if (updatedNote) {
        promises.push(
          mutate({
            id: doc.id,
            noteHtml: updatedNote,
          })
        );
      }
    });

    if (promises.length) {
      return await Promise.all(promises);
    }
    return;
  }, [childDocumentList, getNote, mutate]);

  return { mutateDocumentFiles };
};
