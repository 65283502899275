import styles from './OptionsPopover.module.scss';

import { Popover } from '@mui/material';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Option } from '../../types';

import { OptionItem } from './OptionItem';

type OptionsPopoverProps = {
  popoverState: ReturnType<typeof usePopoverState>;
  options?: Option[];
};

export const OptionsPopover = ({
  popoverState,
  options,
}: OptionsPopoverProps) => {
  const { handleClick: _, ...popoverProps } = popoverState;

  return (
    <Popover {...popoverProps} classes={{ paper: styles.popoverPaper }}>
      {options?.map((option) => {
        return (
          <OptionItem
            {...option}
            key={option.name}
            onClick={() => {
              popoverState.onClose();
              option.onClick();
            }}
          />
        );
      })}
    </Popover>
  );
};
