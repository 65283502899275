import { useEffect } from 'react';

import {
  EmailPreview,
  RequestedEmailData,
} from '@work4all/components/lib/components/entity-preview/email-preview';
import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { EntityPreviewProps } from '@work4all/components/lib/components/entity-preview/types';

import { usePermissions } from '@work4all/data';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityActions } from '../../../../../../components/data-tables/entity-table/EntityActionProvider';
import { useEmailActions } from '../../../../../mask-overlays/mask-overlay/views/email/EmailActions';

type IEmailPreviewContainerProps = EntityPreviewProps;

const additionalVars = { rewriteInlineAttachements: true };

export function EmailPreviewContainer(props: IEmailPreviewContainerProps) {
  const {
    ids,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    onVisibilityToggle,
    convertProps,
  } = props;
  const emailId = ids[0];

  const emails = useEntityPreviewData<EMail>(
    [emailId],
    Entities.eMail,
    RequestedEmailData,
    additionalVars
  );

  const email = emails?.[0];

  useSyncEmailStatus({
    disabled: email == null,
    shouldSync({ id }) {
      return email?.id === id;
    },
  });
  const { canAdd } = usePermissions();

  const { emailActionDefinitions } = useEmailActions({
    hasRight: canAdd({ entity: Entities.eMail }),
    outbound: email?.kind === EMailKind.AUSGEHEND ? true : false,
  });

  const { addActions } = useEntityActions();

  useEffect(() => {
    addActions({
      left: emailActionDefinitions,
    });
  }, [emailActionDefinitions, addActions]);

  if (!email) {
    return null;
  }

  return (
    <EmailPreview
      onCloseClick={onCloseClick}
      onVisibilityToggle={onVisibilityToggle}
      onEditClicked={onEditClicked}
      email={email}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
    />
  );
}
