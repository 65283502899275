import styles from './../../MaskOverlay.module.scss';

import * as ReactSentry from '@sentry/react';
import { cloneDeep, isEqual, merge } from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks';

import {
  useCheckModuleRight,
  useFormPlus,
  useTransaction,
  useUser,
} from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useEntityEventsContext } from '@work4all/data/lib/entity-events/entity-events-context';
import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';
import {
  ITempFileManagerContext,
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BankDetails } from '@work4all/models/lib/Classes/BankDetails.entity';
import { BusinessPartnerUnion } from '@work4all/models/lib/Classes/BusinessPartnerUnion.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DeliveryKind } from '@work4all/models/lib/Classes/DeliveryKind.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { PaymentKind } from '@work4all/models/lib/Classes/PaymentKind.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { usePageTitle } from '../../../../../hooks';
import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { ValidationErrors as ApiValidationErrors } from '../../../../apollo/ValidationErrors';
import { MaskTabContext } from '../../../mask-tabs/MaskTabContext';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { LockOverride } from '../../components/LockOverride';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContext,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { useMaskLock } from '../../hooks/use-mask-lock';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { parseTemplate } from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import {
  ErpInitialView,
  useErpInitialView,
} from './components/initial-view/ErpInitialView';
import { usePartnerNumberAlert } from './components/partner-number-alert/PartnerNumberAlert';
import { SignatureProvider } from './components/signature/signature-provider';
import { ErpTabPanels } from './components/tab-panels/ErpTabPanels';
import { ErpTabs } from './components/tab-panels/ErpTabs';
import { ErpDataIntersection as ErpData } from './ErpData';
import { ERPMaskHeaderActions } from './ERPMaskHeaderActions';
import { getRequiredEntity } from './getRequiredEntity';
import {
  useShadowBzObjectApi,
  ValidationErrors,
} from './hooks/use-bz-shadow-object-api';
import { ShadowBzObjectApiProvider } from './hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import { createResponse } from './hooks/use-bz-shadow-object-api/use-shadow-bz-object-graphql';
import { useERPOverlayControllerTabState } from './use-erp-overlay-controller-tab-state';

const SIGNED_INT_MAX_VALUE = 2147483647;

const ERPOverlayControllerInternal = (props: MaskControllerProps) => {
  const { t } = useTranslation();
  const mask = useMaskConfig(props);

  const lock = useMaskLock(mask);

  const { emit: emitEntityEvent } = useEntityEventsContext();

  const shouldUseRegularQueryData = !lock.isLoading && lock.isLocked;
  const shouldUseShadowObjectApi = !lock.isLoading && !lock.isLocked;

  const requiredEntity = getRequiredEntity(mask.entity);

  const template = parseTemplate(mask);

  const isProjectType = template?.entity === 'project';

  const projectRequestData = useMemo(
    () => ({
      filter: [{ id: { $eq: template?.id } }],
      entity: Entities.project,
      data: {
        id: null,
        customer: {
          id: null,
        },
        supplier: {
          id: null,
        },
      },
    }),
    [template?.id]
  );

  const { data: projectData, loading: isProjectLoading } =
    useDataProvider<Project>(projectRequestData, !isProjectType);

  const initialProps = useErpInitialView(
    !template && !mask.id,
    props.onAfterSave
  );

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const query = useDataProvider(
    useMemo(() => {
      const fields = createResponse(mask.entity);
      const data = {
        id: null,
        ...fields.data[mask.entity],
      };

      return {
        skip: !shouldUseRegularQueryData,
        filter: [{ id: { $eq: mask.id } }],
        entity: mask.entity,
        data,
      };
    }, [mask.entity, mask.id, shouldUseRegularQueryData])
  );

  const parentEntity = template?.businessPartnerType
    ? template?.businessPartnerType
    : initialProps.businessPartner?.__typename === 'Kunde'
    ? Entities.customer
    : Entities.supplier;

  const [shadowBzObject, shadowBzObjectApi, validationErrors] =
    useShadowBzObjectApi({
      entity: mask.entity,
      id: mask.id,
      parentEntity,
      parentId: template?.businessPartnerId || initialProps.businessPartner?.id,
      contactId: template?.entity === Entities.contact ? template?.id : null,
      skip:
        !shouldUseShadowObjectApi ||
        (mask.isCreateMode && !template && !initialProps.businessPartner),
    });

  const data =
    (shouldUseRegularQueryData
      ? query.data[0]
      : shouldUseShadowObjectApi
      ? shadowBzObject?.data
      : null) ?? null;

  const loading = shouldUseRegularQueryData
    ? query.loading
    : shouldUseShadowObjectApi
    ? shadowBzObjectApi.loading
    : false;

  useTransaction({
    name: 'ERP Mask - Open',
    eventInProgress: loading,
  });

  /*
   * We are refetching the opened invoice in case the
   * user converted the invoice to ra to show the
   * ra `banderoleInfoText`.
   */
  useEntityChanges({
    entity: Entities.invoice,
    changeType: [ChangeType.ITEM_UPDATED],
    onEntityChanged: (info) => {
      if (info.objCode !== Number(mask.id)) return;
      if (shouldUseRegularQueryData) query.refetch();
      if (shouldUseShadowObjectApi) shadowBzObjectApi.refetch();
    },
  });

  const normalizedData = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(data), customFields);
  }, [data, customFields]);

  const { isDirty } = shadowBzObjectApi;

  const [mutateError, setMutateError] = useState<string | null>(null);

  const clearError = useCallback(() => setMutateError(null), []);

  usePageTitle(t(`COMMON.${mask.entity.toUpperCase()}`), {
    priority: 1,
  });

  const files = useMemo(() => {
    return shadowBzObject?.data.erpAttachmentList?.map((x) => {
      return {
        ...x,
        fileName: x.fileInfos?.fileEntityFilename,
        lastModificationDate: x.updateTime,
      };
    });
  }, [shadowBzObject?.data.erpAttachmentList]);
  const tempFileManager = useTempFileManager(files);

  const attachments =
    useAttachementsRelation<InputErpAnhangAttachementsRelation>(
      tempFileManager,
      Entities.erpAttachment,
      'id'
    );

  const showNumberDialog = usePartnerNumberAlert();
  const handleSubmit = useCallback(async () => {
    const result = await shadowBzObjectApi.persist(attachments?.attachements);
    emitEntityEvent({
      type: 'upsert',
      entity: mask.entity,
      data: undefined,
    });
    if (result.data.persistShadowBzObject.businessPartnerAssignedNumber)
      await showNumberDialog({
        businessPartnerType: parentEntity,
        businessPartnerAssignedNumber:
          result.data.persistShadowBzObject.businessPartnerAssignedNumber,
        bussinessParner:
          result.data.persistShadowBzObject.data.businessPartnerContactCombined
            .businessPartner.data,
      });
    // TODO This doesn't correctly implement the "save and close" functionality
    // because it doesn't pass the saved data to the `onAfterSave` callback.
    // There is no easy way to get around this, because the `id` property (which
    // is the only one that truly matters) is not included in the requested
    // GraphQL fields.
    //
    // This functionality for this mask is not used anywhere at the moment, but
    // could be required in the future. In which case we would need to find a
    // way to resolve this issue.
    //
    // The reason for not including the `id` field is not requested is to avoid
    // updating the original entity stored in the Apollo's cache when a shadow
    // object is updated, since they have the same `__typename` and `id`.
    //
    // One way to resolve this issue is to only request the `id` field in the
    // persist mutation and remove it from all other mutations.
    props.onAfterSave?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowBzObjectApi.persist, props.onAfterSave, attachments?.attachements]);

  const shadowBzObjectApiWithAttachements = useMemo(() => {
    const computedIsDirty = attachments?.isDirty || isDirty;
    return {
      ...shadowBzObjectApi,
      isDirty: computedIsDirty,
      persist: async () =>
        await shadowBzObjectApi.persist(attachments?.attachements),
      positions: normalizedData.positionList,
    };
  }, [
    attachments?.isDirty,
    attachments?.attachements,
    isDirty,
    shadowBzObjectApi,
    normalizedData.positionList,
  ]);

  useConfirmBeforeCloseMask(shadowBzObjectApiWithAttachements.isDirty);

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const formData = useDeepMemo(
    () => ({ ...normalizedData, positionList: [] }),
    [{ ...normalizedData, positionList: [] }]
  );

  const hasMissingBusinessPartnerForProject = useMemo(() => {
    if (isProjectLoading) return false;
    if (
      projectData &&
      isProjectType &&
      !shadowBzObject?.data.businessPartnerId
    ) {
      const projectWithCustomer = Boolean(projectData[0].customer?.id);
      const projectWithSupplier = Boolean(projectData[0].supplier?.id);

      if (requiredEntity === Entities.customer) {
        return !projectWithCustomer;
      } else if (requiredEntity === Entities.supplier) {
        return !projectWithSupplier;
      } else {
        return false;
      }
    }
    return false;
  }, [
    isProjectLoading,
    isProjectType,
    projectData,
    requiredEntity,
    shadowBzObject?.data.businessPartnerId,
  ]);

  useEffect(() => {
    if (hasMissingBusinessPartnerForProject && !initialProps.open) {
      initialProps.onOpen();
    }
  }, [shadowBzObject, hasMissingBusinessPartnerForProject, initialProps]);

  const handleInitialViewConfirm = (
    businessPartner: (Customer | Supplier)[]
  ) => {
    if (isProjectType && shadowBzObjectApi) {
      const { __typename, id, mainContact } = businessPartner[0];
      const contactId = mainContact?.id;

      shadowBzObjectApi.modify({
        businessPartnerId: id,
        contactId,
        additionalAddress1CompanyId: id,
        additionalAddress2CompanyId: id,
        additionalAddress3CompanyId: id,
        additionalAddress1CompanyType: __typename.toUpperCase(),
        additionalAddress2CompanyType: __typename.toUpperCase(),
        additionalAddress3CompanyType: __typename.toUpperCase(),
      });
    } else {
      initialProps.onConfirm(businessPartner);
    }
  };

  if (!data?.businessPartnerId && !loading) {
    return (
      <ErpInitialView
        {...initialProps}
        entity={requiredEntity}
        onConfirm={handleInitialViewConfirm}
      />
    );
  }

  return (
    <ShadowBzObjectApiProvider value={shadowBzObjectApiWithAttachements}>
      <MaskContextProvider value={maskContext}>
        <ERPForm
          data={formData}
          isLoading={loading}
          isDirty={shadowBzObjectApiWithAttachements.isDirty}
          error={mutateError}
          validationErrors={validationErrors}
          onClearError={clearError}
          onModify={shadowBzObjectApi.modify}
          onSubmit={handleSubmit}
          entity={mask.entity}
          tempFileManager={tempFileManager}
        />
      </MaskContextProvider>
    </ShadowBzObjectApiProvider>
  );
};

interface ERPFormProps {
  data: ErpData;
  isLoading: boolean;
  isDirty: boolean;
  error: string | null;
  entity: Entities;
  onClearError: () => void;
  onModify: (data: ErpData) => void;
  onSubmit: () => Promise<void>;
  tempFileManager: ITempFileManagerContext;
  validationErrors: ValidationErrors;
}

// TODO OPTIMIZATION
// 1. isDirty should update only really needed childs not whole ERPForm
// 2. PositionList update should update only Positions related components not whole ERPForm
const ERPForm = memo(function ERPForm(props: ERPFormProps) {
  const {
    data: dataIn,
    isLoading,
    isDirty,
    error,
    entity,
    onClearError,
    onModify,
    onSubmit,
    tempFileManager,
    validationErrors,
  } = props;

  const { t } = useTranslation();
  const mask = useMaskContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let id: SnackbarKey;
    if (error) {
      id = enqueueSnackbar(error, { onClose: onClearError });
    }
    return () => {
      closeSnackbar(id);
    };
  }, [closeSnackbar, enqueueSnackbar, error, onClearError]);

  const schema = useEntityJsonSchema(entity);
  const customRules = useCallback(
    (input: ErpData) => {
      const errors: Partial<
        Record<
          keyof ErpData,
          {
            message: string;
            type: string;
          }
        >
      > = {};

      if (!input.note) {
        errors.note = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }
      if (!input.businessPartnerId) {
        errors['businessPartnerContactCombined.businessPartner'] = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };
      }
      if (mask.entity === Entities.contract) {
        if (input.contractNumber > SIGNED_INT_MAX_VALUE) {
          errors['contractNumber'] = {
            message: t('ERROR.TOO_BIG_NUMBER', {
              value: SIGNED_INT_MAX_VALUE + 1,
            }),
            type: 'customValidation',
          };
        }
      } else {
        if (input.number > SIGNED_INT_MAX_VALUE) {
          errors['number'] = {
            message: t('ERROR.TOO_BIG_NUMBER', {
              value: SIGNED_INT_MAX_VALUE + 1,
            }),
            type: 'customValidation',
          };
        }
      }

      if (Object.entries(errors).length) {
        return errors;
      }

      return true;
    },
    [t, mask]
  );

  // TODO: workaround - maybe there is possiblity to fix it on API level
  const optionalNumberSchema = useMemo(() => {
    const clone = cloneDeep(schema);
    if (!clone) return schema;
    if (clone.properties.number)
      clone.properties.number.type = ['number', 'null'];
    if (clone.properties.deliveryNoteNumber)
      clone.properties.deliveryNoteNumber.type = ['string', 'null'];
    if (clone.properties.costCenter) clone.properties.costCenter = undefined;

    return clone;
  }, [schema]);
  const resolver = useJSONSchemaResolver(optionalNumberSchema, customRules);

  const data = useMemo(() => normalizeFormValue(dataIn), [dataIn]);

  const form = useFormPlus<ErpData>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    context: {
      schema,
    },
  });

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
    watch,
  } = form;

  useEffect(() => {
    // Resetting values is an expensive operation. If the value hasn't changed,
    // we can skip it to improve responsiveness. We still need to reset to
    // update dirty fields.
    form.reset(data, {
      keepValues: isEqual(form.getValues(), data),
      keepErrors: true,
    });

    // Every time the form is reset we reset the dirty fields.
    persistedDirtyFieldsRef.current = {};
  }, [form, data]);

  useEffect(() => {
    validationErrors.forEach((x) => {
      let property: keyof ErpData = x.property;
      if (x.property === 'number' && mask.entity === Entities.contract)
        property = 'contractNumber';

      form.setError(property, {
        message: ApiValidationErrors.translateError(x.code),
        type: 'apiValidation',
      });
    });
  }, [t, validationErrors, form, mask]);

  useFormUpdate<ErpData>(
    {
      paymentKind: (paymentKind: PaymentKind) => ({
        paymentId: paymentKind?.id ?? 0,
      }),
      currency: (currency: Currency) => ({ currencyId: currency?.id ?? 0 }),
      user: (user) => ({ userId: user?.id ?? 0 }),
      user2: (user2) => ({ user2Id: user2?.id ?? 0 }),
      priceGroup: (priceGroup) => ({ priceGroupId: priceGroup?.id }),
      project: (project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues(
          'businessPartnerContactCombined.businessPartner'
        );

        if (businessPartnerField !== null) {
          return { projectId };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            'businessPartnerContactCombined.businessPartner': businessPartner,
          };
        }
      },
      deliveryKind: (deliveryKind: DeliveryKind) => ({
        deliveryKindId: deliveryKind?.id ?? 0,
      }),
      partialInvoiceLogic: (partialInvoiceLogic) => ({
        partialInvoiceLogicId: partialInvoiceLogic?.id ?? 0,
      }),
      bankDetails: (bankDetails: BankDetails) => ({
        bankDetailsId: bankDetails?.id ?? 0,
      }),
      'businessPartnerContactCombined.businessPartner': (
        businessPartner: Customer | Supplier
      ) => {
        return {
          businessPartnerType: getBusinessPartnerType(businessPartner),
          businessPartnerId: businessPartner?.id ?? 0,
          bankDetailsId: 0,
          bankDetails: null,
          contactId: businessPartner?.isPrivateCustomer
            ? 0
            : businessPartner?.mainContactId || 0,
        };
      },
      'businessPartnerContactCombined.contact': (contact) => ({
        contactId: contact?.id ?? 0,
      }),
      'additionalAddress1.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress1CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress1CompanyId: businessPartner?.id ?? 0,
          additionalAddress1ContactId: businessPartner?.isPrivateCustomer
            ? 0
            : mainContactId || 0,
        };
      },
      'additionalAddress1.contact': (contact) => ({
        additionalAddress1ContactId: contact?.id ?? 0,
      }),
      'additionalAddress2.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress2CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress2CompanyId: businessPartner?.id ?? 0,
          additionalAddress2ContactId: businessPartner?.isPrivateCustomer
            ? 0
            : mainContactId || 0,
        };
      },
      'additionalAddress2.contact': (contact) => ({
        additionalAddress2ContactId: contact?.id ?? 0,
      }),
      'additionalAddress3.businessPartner': (
        businessPartner: BusinessPartnerUnion
      ) => {
        const mainContactId = businessPartner?.mainContactId;
        return {
          additionalAddress3CompanyType:
            getBusinessPartnerType(businessPartner),
          additionalAddress3CompanyId: businessPartner?.id ?? 0,
          additionalAddress3ContactId: businessPartner?.isPrivateCustomer
            ? 0
            : mainContactId || 0,
        };
      },
      'additionalAddress3.contact': (contact) => ({
        additionalAddress3ContactId: contact?.id ?? 0,
      }),
      costCenter: (costCenter) => {
        return { costCenterNumber: costCenter?.number };
      },
    },
    form
  );

  const hasError = Object.entries(errors).length > 0;
  // Form value is mutable, so we need to clone it here to compare changes
  // later.
  const lastFormValues = useRef<ErpData>(null);

  // Keep track of fields that ever changed in the form since last reset. This
  // is required to determine if a field was changed even if the value is the
  // same as the initial one.
  const persistedDirtyFieldsRef = useRef<
    (typeof form)['formState']['dirtyFields']
  >({});

  useEffect(() => {
    const subscription = form.watch((formValues, info) => {
      // Updates caused by calling `form.reset` will have an empty `name`
      // property. These should be skipped.
      if (!info.name) return;

      const formDirtyFields = form.formState.dirtyFields;
      const persistentDirtyFields = persistedDirtyFieldsRef.current;

      // Send all fields that were changed since last reset.
      const dirtyFields = merge(persistentDirtyFields, formDirtyFields);

      const isValueEqual = isEqual(formValues, lastFormValues.current);
      const isBusinessPartnerSelected = !!formValues.businessPartnerId;
      const isFormDirty = Object.keys(dirtyFields).length > 0;

      if (isValueEqual || !isBusinessPartnerSelected || !isFormDirty) {
        return;
      }

      const { __typename, ...input } = formValues;
      if (!(input as InboundDeliveryNote).deliveryDate)
        (input as InboundDeliveryNote).deliveryDate = null;
      if (!(input as Offer).dispositionStart)
        (input as Offer).dispositionStart = null;
      if (!(input as Offer).dispositionEnd)
        (input as Offer).dispositionEnd = null;
      if (!(input as Offer).serviceStartDate)
        (input as Offer).serviceStartDate = null;
      if (!(input as Offer).serviceEndDate)
        (input as Offer).serviceEndDate = null;
      if (!(input as Offer).contractStartDate)
        (input as Offer).contractStartDate = null;
      if (!(input as Invoice).paid) (input as Invoice).paid = undefined;
      if (!(input as Invoice).ra) (input as Invoice).ra = undefined;
      if (!(input as Offer).outgoingDeliveryDate)
        (input as Offer).outgoingDeliveryDate = null;

      const toModify = pickUpdateFields(input, dirtyFields);
      const toModifyPrepared = prepareInputWithCustomFields(toModify);

      if (toModify.number > SIGNED_INT_MAX_VALUE) return;

      lastFormValues.current = cloneDeep(formValues);
      persistedDirtyFieldsRef.current = dirtyFields;

      onModify(toModifyPrepared);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, onModify]);

  const { canAdd, canEdit } = usePermissions();

  const checkModuleRight = useCheckModuleRight();
  const hasPreviewFeatures = checkModuleRight(
    ModuleAccessRightType.FEATURE_WORK_4_ALL_PREVIEW_FEATURES
  );

  const title =
    mask.entity === Entities.invoice && (data as Offer)?.value < 0
      ? t('COMMON.CREDIT_NOTE')
      : undefined;

  const shouldRenderIndividualTab =
    mask.customFields && mask.customFields.length > 0;

  const [tab, setTab] = useERPOverlayControllerTabState(
    mask.params?.tab ?? 'miscellaneous'
  );

  const { locked, user: lockedBy } = useLock();
  const frozen = watch('frozen');
  const isClosed = watch('isClosed');
  const ra = watch('ra');
  const paid = watch('paid');
  const priceGroup = watch('priceGroup');
  const currency = watch('currency');

  const savingDisabled = Boolean(
    ra ||
      paid ||
      isLoading ||
      locked ||
      priceGroup?.isGrossPrice ||
      (currency && currency?.name !== 'EUR')
  );

  const formDisabled =
    savingDisabled || frozen || isClosed || !hasPreviewFeatures;

  const user = useUser();

  const banderoleInfoText = useMemo<string | JSX.Element>(() => {
    if (isClosed) {
      return t('ERP.BANDEROLE.CLOSED');
    }

    if (frozen) {
      return t('ERP.BANDEROLE.FROZEN');
    }

    if (ra) {
      return t('ERP.BANDEROLE.RA');
    }

    if (paid) {
      return t('ERP.BANDEROLE.PAID');
    }

    if (priceGroup?.isGrossPrice) {
      return t('ERP.BANDEROLE.GROSS_PRICEGROUP');
    }

    if (currency && currency?.name !== 'EUR') {
      return t('ERP.BANDEROLE.NOT_EUR');
    }

    return undefined;
  }, [isClosed, frozen, ra, paid, priceGroup?.isGrossPrice, currency, t]);

  return (
    <LockOverride
      forceLock={formDisabled}
      lockReason={hasPreviewFeatures ? undefined : t('MASK.ERP.PREVIEW')}
    >
      <FormProvider {...form}>
        {/*
          'defaultValue' has to be added because in the current implementation of the tabs error
          handling (MaskTabContext) we assigns 'other' unassigned errors to the default Tab
        */}
        <MaskTabContext
          defaultValue={'miscellaneous'}
          value={tab}
          onChange={setTab}
        >
          <Form onSubmit={handleSubmit(onSubmit)} className={styles.maskForm}>
            <SignatureProvider>
              <TempFileManagerContext.Provider value={tempFileManager}>
                <MaskOverlayHeader
                  infoBanderoleText={banderoleInfoText}
                  isLoading={isLoading}
                  title={title || t(`COMMON.${mask.entity.toUpperCase()}`)}
                  subTitle={data.note}
                  actions={
                    <ERPMaskHeaderActions
                      hasPreviewFeatures={hasPreviewFeatures}
                      isSubmitting={isSubmitting}
                      isDirty={isDirty}
                      hasError={hasError}
                      canSave={
                        !savingDisabled &&
                        (!locked || lockedBy.id === user.benutzerCode) &&
                        (mask.isCreateMode
                          ? canAdd({ entity: mask.entity })
                          : canEdit({ entity: mask.entity, record: data }))
                      }
                    />
                  }
                  tabs={
                    <ErpTabs
                      isCreateMode={mask.isCreateMode}
                      renderIndividualTab={shouldRenderIndividualTab}
                      isLoading={isLoading}
                    />
                  }
                />
                <ErpTabPanels
                  isCreateMode={mask.isCreateMode}
                  renderIndividualTab={shouldRenderIndividualTab}
                />
              </TempFileManagerContext.Provider>
            </SignatureProvider>
          </Form>
        </MaskTabContext>
      </FormProvider>
    </LockOverride>
  );
});

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

export const ERPOverlayController = ReactSentry.withProfiler(
  ERPOverlayControllerInternal
);
